/*
 * File: IconContainer.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 10, 2023 at 8:17 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './IconContainer.styles';


/**
 * Component
 */

export const IconContainer = ({
	className, children, center, iconColor
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.IconContainer className={className} $iconColor={iconColor} $center={center}>{children}</S.IconContainer>
	);
};


/**
 * Configuration
 */

IconContainer.displayName = 'IconContainer';
IconContainer.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	iconColor: PropTypes.string,
	center: PropTypes.bool
};
IconContainer.defaultProps = {
	children: null,
	className: null,
	iconColor: null,
	center: false
};
