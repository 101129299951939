/*
 * File: Blog.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 4:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import validator from 'validator';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { getBlogTopics, getBlogPosts } from '../../../services/blog';

// Slices
import { updateBlogTopics } from '../../../store/slices/blog/blog.slice';

// Components
import {
	Meta, AppNavigation, Typography, BlogSection, Button, LocaleLink, TextInput, EmptyComponent, Spinner, ErrorComponent, SchemaScript
} from '../../../components';

// Styles
import * as S from './Blog.styles';


/**
 * Component
 */

const Blog = ({ meta, locale, data }) => {

	// Get current blog state from hook
	const blogState = useSelector((state) => state.blog.value);

	// Create state handlers
	const [pageStatus, setPageStatus] = useState((blogState.isSet !== true && !data?.topics) || ((!data?.stories?.posts || data?.stories?.posts.length === 0) && (!data?.featured?.posts || data?.featured?.posts.length === 0)) ? 'idle' : 'success');
	const [searchOpen, setSearchOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [searchActive, setSearchActive] = useState(searchText !== '');
	const [topicData, setTopicData] = useState(data?.topics);
	const [featuredStories, setFeaturedStories] = useState({ posts: data?.featured?.posts || [] });
	const [stories, setStories] = useState({
		posts: data?.stories?.posts || [],
		totalPosts: data?.stories?.totalPosts || 0,
		hasNextPage: data?.stories?.hasNextPage || false,
		endCursor: data?.stories?.endCursor
	});

	// Create reference for components
	const heroRef = useRef();
	const isMounted = useRef(true);

	// Use hooks
	const navigate = useNavigate();

	// Get location state
	const location = useLocation();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Build data from state
	const topics = blogState.isSet === true || !topicData ? blogState.topics : topicData;

	// Check if link is action function
	const isLinkActive = (to) => location.pathname === to || location.pathname === `${stateLocale.localePath}${to}`;

	// Handle search bar visibility
	const toggleSearchBarVisiblilty = () => {
		setSearchOpen(!searchOpen);
	};

	// Handle on search input change action
	const handleOnChange = (event) => {

		// Update search value
		const { value } = event.target;
		setSearchText(value);

		// Update button status
		if (!validator.isEmpty(value, { ignore_whitespace: true })) {
			setSearchActive(true);
		} else {
			setSearchActive(false);
		}
	};

	// Handle search action
	const handleSearchAction = () => {

		// Validate search content
		if (validator.isEmpty(searchText, { ignore_whitespace: true })) {
			toastError(uiMode, 'Please enter something in the search bar.');
			return;
		}

		// Navigate to search page
		navigate(`${stateLocale.localePath}/blog/search?keywords=${encodeURIComponent(searchText)}`);
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch blog topics if necessary
			if (blogState.isSet !== true && !topicData) {

				// Fetch blog topics
				const { data: datum } = await getBlogTopics();

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setTopicData(datum.topics);

					// Dispatch new state
					dispatch(updateBlogTopics(datum.topics));
				}
			} else if (blogState.isSet !== true) {

				// Dispatch new state
				dispatch(updateBlogTopics(topicData));
			}

			// Fetch blog posts if necessary
			if (!stories.posts || stories.posts.length === 0) {

				// Fetch blog topics
				const { data: datum } = await getBlogPosts({ pageSize: 12 });

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setFeaturedStories(datum.featured);
					setStories(datum.stories);
				}
			}

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>
				{/* Hero Section */}
				<S.HeroSection ref={heroRef}>
					<S.HeroContentContainer>

						{/* Content */}
						<S.TitleContainer>
							<LocaleLink to="/blog">
								<Typography tag="h1" weight="extrabold" className="animate">
									<span className="floatingThe">The</span>
									700 Rivers Blog
								</Typography>
							</LocaleLink>
						</S.TitleContainer>

						{/* Topic Container */}
						<S.TopicContainer>
							{topics.slice(0, 4).map((topic) => (
								<LocaleLink to={`/blog/topic/${topic.slug}`} key={topic.slug}>
									<S.Topic className={isLinkActive(`/blog/topic/${topic.slug}`) ? 'animate active' : 'animate'}><Typography weight="semibold">{topic.title}</Typography></S.Topic>
								</LocaleLink>
							))}
						</S.TopicContainer>

						{/* Search Button */}
						<S.SearchButton className={searchOpen ? 'animate open' : 'animate'} onClick={toggleSearchBarVisiblilty}>
							<FontAwesomeIcon icon={['fass', 'magnifying-glass']} />
						</S.SearchButton>

						{/* Search Bar */}
						<S.SearchBarContainer className={searchOpen ? 'animate open' : 'animate'}>
							<TextInput
								placeholder="Search..."
								type="text"
								onChange={handleOnChange}
								value={searchText}
							/>
							<S.SearchBarButton
								size={1.1}
								icon={['fass', 'arrow-right']}
								disabled={!searchActive}
								className={searchActive ? 'animate active' : 'animate'}
								onClick={handleSearchAction}
							/>
						</S.SearchBarContainer>

					</S.HeroContentContainer>
				</S.HeroSection>

				{/* Featured Story */}
				{featuredStories.posts.length > 0 && (
					<S.FeaturedContainer>
						<S.ContentPadding>
							<BlogSection
								blogs={[featuredStories.posts[0]]}
								perRow={1}
								limit={1}
								centeredText
								locale={stateLocale}
							/>
						</S.ContentPadding>
					</S.FeaturedContainer>
				)}

				{/* Featured Stories */}
				{featuredStories.posts.length > 1 && (
					<S.SectionContainer>
						<Typography tag="h3" weight="bold" className="title">Featured stories</Typography>
						<BlogSection
							blogs={featuredStories.posts.slice(1, 5)}
							perRow={4}
							limit={4}
							locale={stateLocale}
						/>
					</S.SectionContainer>
				)}

				{/* Call To Action */}
				<S.ContentPadding style={{ marginTop: '100px' }}>
					<S.CallToAction>
						<S.CTAInner>
							<Typography tag="h3" weight="bold">Make a difference (and give a great gift)</Typography>
							<Typography tag="p" style={{ fontSize: '1.2rem' }} weight="light">Give the gift of 700 Rivers or treat yourself to something special.</Typography>
							<Button size="large" onClick={() => { navigate(`${stateLocale.localePath}/shop/soaps`); }}>Shop gifts & products</Button>
						</S.CTAInner>
					</S.CallToAction>
				</S.ContentPadding>

				{/* All Stories */}
				<S.SectionContainer>
					<Typography tag="h3" weight="bold" className="title">All stories</Typography>
					{stories.posts?.length > 0
						? (
							<BlogSection
								blogs={stories.posts}
								perRow={4}
								limit={12}
								total={stories.totalPosts}
								showPagination
								nextCursor={stories.hasNextPage ? stories.endCursor : undefined}
								fetchParams={{ pageSize: 12 }}
								locale={stateLocale}
							/>
						)
						: (
							<EmptyComponent
								title="Whoops! Our blog is empty"
								message="Try searching our blog or select a topic to find what you're looking for."
							/>
						)}
				</S.SectionContainer>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Schema.org BreadcrumbList */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				'@id': `${process.env.APP_URL}/blog/#BreadcrumbList`,
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						item: {
							'@id': `${process.env.APP_URL}/blog/#ListItem`,
							name: 'Blog',
							url: `${process.env.APP_URL}${stateLocale.localePath}/blog`
						}
					}
				]
			}}
			/>

			{/* Schema.org CollectionPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage', 'CollectionPage'],
				'@id': `${process.env.APP_URL}/blog/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}/blog`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				breadcrumb: {
					'@id': `${process.env.APP_URL}/blog/#BreadcrumbList`
				},
				potentialAction: [
					{
						'@type': 'ReadAction',
						target: `${process.env.APP_URL}${stateLocale.localePath}/blog`
					}
				]
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Blog.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Blog.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Blog;
