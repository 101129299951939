/*
 * File: PrivacyPolicy.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: January 18, 2023 at 3:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './PrivacyPolicy.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const PrivacyPolicy = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation hidePadding>
				<LegalNavigation>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Privacy Policy
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								By using the 700 Rivers platform, you understand that we gather and securely store certain information about you that applies to your use of 700 Rivers. This includes profile information you provide to us, content you create, and automated data like IP addresses and device identifiers. We use this information to provide a customized experience, improve our technology, and allow trusted third-parties to advertise to you. Please read the “Your Choices” section below to learn more about ways you can monitor what data we collect about you.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							700 Rivers, LLC. (together with its affiliates, “700 Rivers”) endeavors to protect your personal information. This Privacy Policy informs you about our practices regarding the treatment of information we collect from you online (e.g., through our websites) and offline (e.g., through customer support channels, our retail locations, and in-person promotional activities), the kinds of information we may collect, how that information is used, with whom we share it, and how you can opt-out of a use or correct or change such information.
						</Typography>
						<Typography tag="p" weight="light">
							This Privacy Policy is incorporated into 700 Rivers’
							{' '}
							<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
							{' '}
							by reference, as if set forth fully therein, which must be reviewed and accepted by you.
						</Typography>
						<Typography tag="p" weight="light">
							If you have any questions about our privacy policies, please contact us at
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Privacy Policy Questions">legal@sevenhundredrivers.com</LocaleLink>
							. By providing your personal information to 700 Rivers, you signify your acceptance of the Terms of Use and agree that 700 Rivers may collect, use, and disclose your personal information as described herein.
						</Typography>

						{/* 1. Scope */}
						<br />
						<Typography tag="h2" weight="semibold">
							1. Scope
						</Typography>
						<Typography tag="p" weight="light">
							This Privacy Policy applies to personal information that is processed by 700 Rivers in the course of our business, including via 700 Rivers’ web properties (e.g., 700 Rivers.com) and through any mobile apps, forums, social media accounts, blogs, retail stores, and other online or offline offerings (together with any and all future online and offline offerings, the “Services”).
						</Typography>

						{/* 2. Information We Collect */}
						<br />
						<Typography tag="h2" weight="semibold">
							2. Information We Collect
						</Typography>

						{/* Personally Identifiable Information */}
						<Typography tag="h3" weight="semibold">
							Personally Identifiable Information
						</Typography>
						<Typography tag="p" weight="light">
							The types of personally identifiable information we collect (directly from you or from third party sources) and our privacy practices depend on the nature of the relationship you have with 700 Rivers and the requirements of applicable law. Below is more information on the ways we collect personal information and how we use it.
						</Typography>

						{/* Types of Personal Information */}
						<Typography tag="h3" weight="semibold">
							Types of Personal Information
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers collects personal information from its current, prospective, and former customers, visitors, and guests (collectively “Individuals”) through the following channels:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">1. Information Provided Directly to Us:</Typography>
							{' '}
							When you use the Services, contact us directly, or engage in certain activities, such as registering for an account with 700 Rivers, placing an order on our website, or signing up to receive emails about our latest products and services, we may ask you to provide some or all of the following types of information: your name, email address, mailing address, phone number, payment information, such as a credit card number, billing address, and/or other related information required to complete the purchase (such payment information, “Financial Information”).
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">2. Communications:</Typography>
							{' '}
							We may collect personal information from you such as your email address, phone number or mailing address when you choose to request information about our Services, visit our retail stores, register to receive communications over email or text messaging, participate in promotions, or loyalty programs, or otherwise communicate with us. Where allowed by applicable law, we may also record and store customer support calls for training and quality purposes.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">3. Survey and Reviews:</Typography>
							{' '}
							We (or third parties acting on our behalf) may contact you to participate in research, surveys or beta testing or to provide reviews and testimonials. If you decide to participate, you may be asked to provide certain information, which may include personal Information. All information collected from your participation in our research, surveys, reviews or testimonial process, or beta testing is provided by you voluntarily. We may use such information to improve our products and/or Services, and in any manner consistent with this Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">4. Sweepstakes and Contests:</Typography>
							{' '}
							If we run a sweepstake or contest, we may ask you to provide contact information (e.g., a phone number or an email address) or other personal information. Your contact information may be used to reach you about the sweepstakes or contest, and, to the extent permitted by law, for other promotional, marketing and business purposes. In compliance with the laws of your jurisdiction, as a participant you may have the opportunity to opt-out of any communications that are not related to awarding prizes.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">5. Public Posts:</Typography>
							{' '}
							We may offer publicly accessible blogs, social media pages, product review pages, or similar forums. Please be aware that when you disclose information about yourself on any public area of the Services or on any public sites operated by third parties, such content will be considered “public” and will not be subject to the privacy protections provided in this Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">6. Information from Third Parties:</Typography>
							{' '}
							We may receive information about you from other sources, including through third party services and organizations (e.g., Experian), to supplement information provided by you. This supplemental information allows us to enhance our ability to provide you with information about our business, products and Services.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">7. Automated Data:</Typography>
							{' '}
							We may collect certain information automatically through our Services and/or other methods of technical analysis, such as your IP address, cookie identifiers, mobile carrier, mobile advertising identifiers, MAC address, IMEI, Advertiser ID, and other device identifiers that are automatically assigned to your computer or device when you access the Internet, browser type and language, geo location information, hardware type, operating system, Internet service provider, pages that you visit before and after using the Services, in-store activity through (e.g., through closed circuit TVs for security monitoring or geofencing to identify traffic), the date and time of your visit, the amount of time you spend on each page, information about the links you click and pages you view within the Services, and other actions taken through use of the Services (such as preferences).
						</Typography>
						<Typography tag="p" weight="light">
							Please note that if you visit one of our stores, we may collect personal data from you and such data may be linked to information we also collect online or through email and other marketing campaigns.
						</Typography>

						{/* 3. How We Use Your Information */}
						<br />
						<Typography tag="h2" weight="semibold">
							3. How We Use Your Information
						</Typography>
						<Typography tag="p" weight="light">
							By providing personally identifiable information to us, we are able to perform a number of business functions, such as:
						</Typography>

						{/* Provide Products, Services, or Information Requested */}
						<Typography tag="h3" weight="semibold">
							Provide Products, Services, or Information Requested
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use information about you to fulfill requests for products, Services or information, including to:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Respond to questions, comments, and other requests;</li>
								<li>Provide access to certain features of the Services;</li>
								<li>Personalize product recommendations;</li>
								<li>Communicate with you about logistical matters, including product deliveries and shipping;</li>
								<li>Conduct internal research and development;</li>
								<li>Review and respond to requests for customer support;</li>
								<li>Allow you to register for online profiles or in-person/in-store events.</li>
							</ul>
						</Typography>

						{/* Administrative Purposes */}
						<Typography tag="h3" weight="semibold">
							Administrative Purposes
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use personal information for administrative purposes, including to:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Measure interest in our products and Services;</li>
								<li>Develop new products and Services;</li>
								<li>Ensure quality control;</li>
								<li>Send emails or text messages to the email address or telephone number you provide to us to verify your account and for informational and operational purposes, such as account management, customer service, delivery updates, or system maintenance;</li>
								<li>Process payment(s) for products or services purchased;</li>
								<li>Process applications and transactions;</li>
								<li>Prevent potentially prohibited or illegal activities;</li>
								<li>Maintain and administer our Services; and</li>
								<li>Enforce our Terms of Service</li>
							</ul>
						</Typography>

						{/* Marketing */}
						<Typography tag="h3" weight="semibold">
							Marketing
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use personal information to provide you with marketing materials that may be of interest, including:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>To notify you about offers, products and Services that may be of interest or about which you have previously expressed an interest (e.g., back-in-stock notifications);</li>
								<li>For other purposes disclosed at the time that you provide personal information; or</li>
								<li>Otherwise with your consent. You may contact us at any time to opt-out of the use of your personal information for marketing purposes, as further described in Section 6.</li>
							</ul>
						</Typography>

						{/* Research and Development */}
						<Typography tag="h3" weight="semibold">
							Research and Development
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use personal information alone or in the aggregate with information obtained from other sources, in order to help us improve our existing products and Services or develop new products and Services.
						</Typography>
						<Typography tag="p" weight="light">
							From time to time, 700 Rivers may perform research (online and offline) via surveys. We may engage third party service providers to conduct such surveys on our behalf. All survey responses are voluntary, and the information collected will be used for research and reporting purposes to help us better serve Individuals by learning more about their needs and the quality of the products and services we provide. The survey responses may be utilized to determine the effectiveness of our Services, various types of communications, advertising campaigns and/or promotional activities. If you participate in a survey, the information given will be used along with that of other study participants.
						</Typography>

						{/* Direct Mail, Email and Outbound Telemarketing */}
						<Typography tag="h3" weight="semibold">
							Direct Mail, Email and Outbound Telemarketing
						</Typography>
						<Typography tag="p" weight="light">
							Individuals who provide us with personal information, or whose personal information we obtain from third parties, may, as permitted by law, receive periodic emails, newsletters, mailings, text messages or phone calls from us with information on our or our business partners’ products and services or upcoming special offers/events that we believe may be of interest. We offer the option to decline these communications at no cost by following the instructions in Section 6.
						</Typography>

						{/* Anonymous and Aggregated Information */}
						<Typography tag="h3" weight="semibold">
							Anonymous and Aggregated Information
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use personal information and other information about you to create anonymized and aggregated information, such as de-identified demographic or psychographic information, information about the computer or device from which you access the Services or other analyses we create. Anonymized and aggregated information is used for a variety of functions, including the measurement of visitors’ interest in and use of various portions or features of the Services. Anonymized or aggregated information is not personal information, and we may use such information in a number of ways, including research, internal analysis, analytics and any other legally permissible purposes. We may also share this information with third parties for our or their purposes in an anonymized or aggregated form that is designed to prevent anyone from identifying you.
						</Typography>

						{/* Shared Content */}
						<Typography tag="h3" weight="semibold">
							Shared Content
						</Typography>
						<Typography tag="p" weight="light">
							From time to time, we may offer referral services of features that allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services or purchase products from us.
						</Typography>

						{/* Other */}
						<Typography tag="h3" weight="semibold">
							Other
						</Typography>
						<Typography tag="p" weight="light">
							700 Rivers may use personal information for which we have a legitimate interest, such as fraud protection or compliance with legal obligations, or any other purpose disclosed to you at the time you provide personal information or with your consent.
						</Typography>

						{/* 4. Cookies, Pixels, and Similar Technologies */}
						<br />
						<Typography tag="h2" weight="semibold">
							4. Cookies, Pixels, and Similar Technologies
						</Typography>
						<Typography tag="p" weight="light">
							We (along with third parties that provide content, advertising or other functionality on our Services), may use cookies, pixel tags, local storage and other technologies (“Technologies”) to automatically collect information through the Services. Technologies that are essentially small data files placed on your computer, tablet, mobile phone or other devices that allow us to record certain pieces of information whenever you visit or interact with our website, services, applications, messaging and tools, and to recognize you across devices. You can find more information about the individual cookies we use on our Websites and the purposes for which we use them by reading our
							{' '}
							<LocaleLink to="/legal/cookie-policy" target="_blank">Cookie Policy</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							Our uses of such Technologies fall into the following general categories:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">1. Operational.</Typography>
							{' '}
							We may use Technologies that are necessary to the operation of our Services. This includes Technologies that allow you access to our websites, Services, applications, and tools; that are required to identify irregular site behavior, prevent fraudulent activity and improve security; or that allow you to make use of functions such as shopping carts, saved search or similar functions.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">2. Performance-Related.</Typography>
							{' '}
							We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use our Services, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content, applications, services or tools.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">3. Functionality-Related.</Typography>
							{' '}
							We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into the Service or keeping track of your specified preferences, interests or past items viewed so that we may enhance the presentation of content.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">4. Advertising-Related.</Typography>
							{' '}
							We may use first-party or third party cookies and web beacons to deliver content, including ads relevant to your interests, on our Services or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement. We may also use third parties to serve targeted advertisements to people similar to our customer base (e.g., by uploading a hashed customer list to Facebook Custom Audiences) or incorporating a pixel from the third-party on our Site. Even if you have disabled certain cookies, advertisements may still be displayed to you through these third-party channels.
						</Typography>
						<Typography tag="p" weight="light">
							If you would like to opt-out of the Technologies we employ, you may do so by blocking, deleting or disabling them as your browser or device permits. See Section 6 for additional opt-out options. Please note that blocking, deleting, or disabling these Technologies will affect the functionality of our Services and may prevent you from being able to access certain features.
						</Typography>

						{/* 5. How We Share Your Information */}
						<br />
						<Typography tag="h2" weight="semibold">
							5. How We Share Your Information
						</Typography>
						<Typography tag="p" weight="light">
							We share information collected through the Services with the following categories of parties:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">1. Vendors and Service Providers.</Typography>
							{' '}
							We may share any information we receive with vendors and service providers. The types of service providers to whom we entrust personal information include service providers for: (i) order fulfillment and warehousing; (ii) web hosting, analytics and related services; (iii) payment processing, fraud protection, and credit risk reduction; (iv) customer service activities such as inquiries and shipping; and (v) in connection with the provision of the Services. 700 Rivers’ policy is to execute appropriate contracts with service providers that prohibit them from using or sharing personal information except as necessary to perform the contracted services on our behalf or to comply with applicable legal requirements, and this Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">2. Business Partners.</Typography>
							{' '}
							700 Rivers may share personal information with its business partners and affiliates for our and our affiliates’ internal business purposes or to provide you with a product or service that you have requested. 700 Rivers may also provide personal information to business partners with whom we may jointly offer products or services, or whose products or services we believe may be of interest to you. In such cases, our business partner’s name will appear, along with 700 Rivers.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">3. Marketing.</Typography>
							{' '}
							700 Rivers may allow third party advertising partners to set tracking tools (e.g., cookies) to collect information regarding your activities on our Services (e.g., your IP address, page(s) visited, time of day). We may also share such de-identified information as well as selected personal information (such as demographic information and past purchase history) we have collected with third party advertising partners. Advertising partners may use this information (and similar information collected from other companies) for purposes of delivering targeted advertisements to you when you visit non-700 Rivers related websites within their networks. This practice is commonly referred to as “interest-based advertising” or “online behavioral advertising”. We may allow access to other data collected by the Service to facilitate transmittal of information that may be useful, relevant, valuable or otherwise of interest to you.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">4. Professional Advisors.</Typography>
							{' '}
							We share personal data with our legal, financial, insurance and other advisors in connection with corporate transactions or in connection with the management of all or part of our business or operations.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">5. Legal Disclosure / Law Enforcement.</Typography>
							{' '}
							We may access, preserve and disclose your personal information, other account information and content if we believe doing so is required or appropriate: (i) to comply with law enforcement or national security requests and legal process, such as, a court order or subpoena; (ii) to respond to your requests; (iii) to protect yours, ours or others’ rights, property or safety; (iv) to enforce 700 Rivers policies or contracts; (v) to collect amounts owed to 700 Rivers; (vi) to comply with records retention policies; (vii) when we believe access, preservation, or disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (viii) if we, in good faith, believe that access, preservation, or disclosure is otherwise necessary or advisable. In addition, from time to time, server logs may be reviewed for security purposes – e.g., to detect unauthorized activity on the Services. In such cases, server log data containing IP addresses may be shared with law enforcement bodies in order to allow them to identify users in connection with their investigation of unauthorized activities.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">6. Merger, Sale, or Other Asset Transfers.</Typography>
							{' '}
							If we are involved in a merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets or transition of service to another provider, then your information may be sold or transferred as part of such a transaction as permitted by law and/or contract. In such event, 700 Rivers will endeavor to direct the transferee to use personal information in a manner that is consistent with the Privacy Policy in effect at the time such personal information was collected.
						</Typography>

						{/* 6. Managing Your personal information / Opt-out */}
						<br />
						<Typography tag="h2" weight="semibold">
							6. Managing Your personal information / Opt-out
						</Typography>
						<Typography tag="p" weight="light">
							We want to communicate with you only if you want to hear from us. You have the right to object to and opt-out of certain uses and disclosures of your personal information. Where you have consented to 700 Rivers’ processing of your personal information, you may withdraw that consent at any time and opt-out to further processing by contacting
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Data Processing Consent">legal@sevenhundredrivers.com</LocaleLink>
							. Please note that even if you opt-out, we may still collect and use non-personal information regarding your activities on our Service and/or information from the advertisements on third party websites for non-interest based advertising purposes, such as, to determine the effectiveness of the advertisements.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">1. Email.</Typography>
							{' '}
							You can unsubscribe from email, text and phone communications at any time. If you would like to opt-out of receiving marketing via email, click the unsubscribe link on the footer of marketing emails. We will process your request within a reasonable time after receipt, in accordance with applicable laws. 700 Rivers will continue to send you non promotional, service emails concerning your account, such as emails relating to available upgrades, billing and payment information, outstanding balance on your account, and other emails relating to your account and/or your use of the Site.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">2. Direct Mail.</Typography>
							{' '}
							If you would like to opt-out of receiving communication via postal mail, contact
							{' '}
							<LocaleLink to="mailto:support@sevenhundredrivers.com?Subject=Communication Opt-Out">support@sevenhundredrivers.com</LocaleLink>
							. To learn more about your rights as a consumer and to receive less national advertising mail, please visit the Direct Marketing Association at
							{' '}
							<LocaleLink to="http://www.dmachoice.org" target="_blank">http://www.dmachoice.org</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">3. Account.</Typography>
							{' '}
							You may review, update, or modify your account information at any time by logging into your 700 Rivers account. You may also deactivate or delete your 700 Rivers account by emailing
							{' '}
							<LocaleLink to="mailto:support@sevenhundredrivers.com?Subject=Account Deletion Request">support@sevenhundredrivers.com</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">4. “Do Not Track.”</Typography>
							{' '}
							Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. We do not override these settings or options. However, in order to access certain content, features, services, products, or benefits of the Services, you may be required to provide us with certain information, including some types of personally identifying information. If you do not wish to provide such information through the Service or if you opt to utilize the aforementioned privacy features of your computer system or web browser, you may be unable to obtain certain content, features, services, products, or benefits of the Site.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">5. Cookies and Similar Technologies.</Typography>
							{' '}
							You may stop or restrict the placement of cookies on your computer or remove them from your browser by adjusting your web browser preferences. Please note that cookie-based opt-outs may not be effective on mobile interfaces. However, on many mobile devices, application users may opt-out of certain mobile advertisements via their device settings. The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from our data partners and our other advertising partners that participate in self-regulatory programs. You can access these, and also learn more about targeted advertising and consumer choice and privacy, at
							{' '}
							<LocaleLink target="_blank" to="https://www.networkadvertising.org/managing/opt_out.asp">www.networkadvertising.org/managing/opt_out.asp</LocaleLink>
							,
							{' '}
							<LocaleLink target="_blank" to="https://www.youronlinechoices.eu/">www.youronlinechoices.eu/</LocaleLink>
							{' '}
							or
							{' '}
							<LocaleLink target="_blank" to="https://www.youradchoices.ca/">www.youradchoices.ca/</LocaleLink>
							, and
							{' '}
							<LocaleLink target="_blank" to="https://www.aboutads.info/choices/">www.aboutads.info/choices/</LocaleLink>
							. You can also choose not to be included in Google Analytics
							{' '}
							<LocaleLink target="_blank" to="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</LocaleLink>
							. Please note that cookie-based opt-outs must be performed on each device and browser that you wish to have opted-out. For example, if you have opted-out on your computer browser, that opt-out will not be effective on your mobile device. You must separately opt-out on each device. Advertisements on third party websites that contain the AdChoices link, and that link to this Privacy Policy, may have been directed to you based on anonymous non personal information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt-out of the advertising partners’ use of this information for interest-based advertising purposes.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">6. Rights of Access, Rectification, Erasure, and Restriction.</Typography>
							{' '}
							Where permitted by applicable law, you may inquire as to whether 700 Rivers is processing personal information about you, request access to personal information, and ask that we correct, amend or delete your personal information where it is inaccurate or has been processed in violation of your rights under data privacy laws that apply in the country where you live. Where otherwise permitted by applicable law, you may send an email to
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Data Request">legal@sevenhundredrivers.com</LocaleLink>
							{' '}
							or use any of the methods set out in this Privacy Policy to request access to, receive, seek rectification, or request erasure of personal information held about you by 700 Rivers. Please include your full name, email address associated with your account and a detailed description of your data request. Such requests will be processed in accordance with local laws. Although 700 Rivers will use good faith efforts to provide Individuals with access to their personal information, there may be circumstances in which 700 Rivers is unable to provide access, including but not limited to where the information contains legal privilege, would compromise others’ privacy or other legitimate rights, where the burden or expense of providing access would be disproportionate to the risks to the Individual’s privacy, in the case in question or where it is commercially proprietary. If we determine that access should be restricted in any particular instance, we will provide you with an explanation of why that determination has been made and a contact point for any further inquiries. To protect your privacy, 700 Rivers will take commercially reasonable steps to verify your identity before granting access to or making any changes to your personal information.
						</Typography>

						{/* 7. Security & Retention */}
						<br />
						<Typography tag="h2" weight="semibold">
							7. Security & Retention
						</Typography>
						<Typography tag="p" weight="light">
							The security of your personally identifiable information is important to us and 700 Rivers is committed to handling such information carefully. We maintain physical, electronic and procedural safeguards to guard your personally identifiable information. We use secure socket layer technology to protect the security of your payment information and we encrypt your personally identifiable information when it is sent over the Internet. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personally identifiable information, we cannot guarantee its absolute security. By using the Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy and administrative issues relating to your use of the Services. If we learn of a security breach, we may attempt to notify you electronically by posting a notice on the Services or sending an email to you. You may have a legal right to receive this notice in writing.
						</Typography>
						<Typography tag="p" weight="light">
							We retain personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements and comply with applicable laws. When we are no longer required to retain your personal information as described above, we will destroy, erase, or de-identify it in accordance with our data retention policies and applicable law. Legal requirements, however, may require us to retain some or all of the personal information we hold for a period of time that is longer than that for which we might otherwise hold it.
						</Typography>

						{/* 8. Children’s Privacy */}
						<br />
						<Typography tag="h2" weight="semibold">
							8. Children’s Privacy
						</Typography>
						<Typography tag="p" weight="light">
							Our Services are not directed to children under the age of 13. In addition, you are not permitted to use our Services if you do not meet the minimum age requirement applicable to our Services in your jurisdiction. We do not knowingly collect personal information from children under the age of 13. If you learn that your child has provided us with personal information without your consent, you may alert us at
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Data Removal Request">legal@sevenhundredrivers.com</LocaleLink>
							. If we learn that we have collected personal information of a child under the age of 13 (or under the age of 16 in certain jurisdictions, such as EU member countries) we will take steps to delete such information from our files as soon as possible and terminate the child’s account unless we receive verifiable parental consent.
						</Typography>

						{/* 9. California Residents */}
						<br />
						<Typography tag="h2" weight="semibold">
							9. California Residents
						</Typography>
						<Typography tag="p" weight="light">
							California law requires us to disclose certain information related to our privacy practices, as set forth below.
						</Typography>

						{/* What Personal Information We Collect */}
						<Typography tag="h3" weight="semibold">
							What Personal Information We Collect
						</Typography>
						<Typography tag="p" weight="light">
							The list below identifies the categories of personal information we have collected over the preceding 12-month period, as well as examples in each category. For more information about the personal information we collect, please see Section 2.
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Name, email address, billing address, shipping address, phone number</li>
								<li>Purchase history</li>
								<li>Geolocation data</li>
								<li>Internet protocol (IP) address, billing address, shipping address</li>
								<li>IP address, cookie identifiers, mobile carrier, mobile advertising identifiers, MAC address, IMEI, Advertiser ID, and other device identifiers that are automatically assigned to your computer or device when you access the Internet, browser type and language, geo-location information, hardware type, operating system, Internet service provider, pages that you visit before and after using the Services, the date and time of your visit, the amount of time you spend on each page, information about the links you click and pages you view within the Services, and other actions taken through use of the platform</li>
								<li>Payment information, such as credit card number</li>
								<li>Recordings of customer support calls</li>
								<li>Inferences about the type of products you prefer; demographic and psychographic inferences</li>
							</ul>
						</Typography>
						<Typography tag="p" weight="light">
							The sources of the personal information we collect above include personal information that you directly provide to us (through the Services), personal information we receive from third party partners (for example, certain marketing, research or survey partners), and personal information that we generate internally. Please see Section 2 for additional details.
						</Typography>

						{/* How We Use Your Personal Information */}
						<Typography tag="h3" weight="semibold">
							How We Use Your Personal Information
						</Typography>
						<Typography tag="p" weight="light">
							Please see Section 3 of the Privacy Policy.
						</Typography>

						{/* What Personal Information We Share */}
						<Typography tag="h3" weight="semibold">
							What Personal Information We Share
						</Typography>
						<Typography tag="p" weight="light">
							Please see Section 5 of the Privacy Policy for information on the personal information we share with third parties. In the last 12 months, we have disclosed all of the categories of personal information described in Section 2 for business purposes. For example, we may share your IP address with service providers that provide crash monitoring and site optimization services to us. California residents have the right to opt out of our disclosures of personal information that we have disclosed to third parties for valuable consideration. What is covered as a “sale” under California law is not yet clear, but we currently do not “sell” your information as we understand it.
						</Typography>

						{/* Your Rights */}
						<Typography tag="h3" weight="semibold">
							Your Rights
						</Typography>
						<Typography tag="p" weight="light">
							As a resident of California, you have certain rights, such as:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">1. Right to access personal information.</Typography>
							{' '}
							You may be entitled to receive the specific pieces of your personal information we have collected in the 12 months preceding your request.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">2. Right to data portability.</Typography>
							{' '}
							You may be entitled to receive a copy of your electronic personal information in a readily usable format.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">3. Right to know.</Typography>
							{' '}
							You may be entitled to receive information regarding the categories of personal information we collected, the sources from which we collected personal information, the purposes for which we collected and shared personal information, the categories of personal information that we sold and the categories of third parties to whom the personal information was sold, and the categories of personal information that we disclosed for a business purpose in the 12 months preceding your request.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">4. Right to deletion.</Typography>
							{' '}
							You may be entitled to request that we delete the personal information that we have collected from you. We will use commercially reasonable efforts to honor your request, in compliance with applicable laws. Please note, however, that we may need to keep such information, such as for our legitimate business purposes or as required to comply with applicable law.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">5. Right to opt-out of certain sharing with third parties.</Typography>
							{' '}
							You may be entitled to direct us to stop disclosing your personal information to third parties for monetary or other valuable consideration. What is covered as a “sale” under California law is not yet clear, but we currently do not “sell” your information as we understand it.
						</Typography>
						<Typography tag="p" weight="light">
							You may freely exercise these rights without fear of being denied goods or services. If you are a California resident and would like to exercise one of your rights, please contact us. Please note that California law requires us to verify the requests we receive from you when you exercise certain of the rights listed above. We (or third parties we engage to assist us) may ask you to provide certain information to us in order for us to verify the request.
						</Typography>
						<Typography tag="p" weight="light">
							In addition to these rights, pursuant to California’s “Shine the Light” law, California residents who share personal information with us have the right to request and obtain from us once per year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties. If you would like to exercise this right, please use the contact information listed in this section to contact us.
						</Typography>

						{/* 10. Additional Topics */}
						<br />
						<Typography tag="h2" weight="semibold">
							10. Additional Topics
						</Typography>

						{/* Payment Processors */}
						<Typography tag="h3" weight="semibold">
							Payment Processors
						</Typography>
						<Typography tag="p" weight="light">
							When you make purchases through the Services, we process your payments via a third-party payment processor. In these instances, the third party application may collect certain Financial Information from you to process a payment on behalf of 700 Rivers, including your name, email address, address and other billing information in which case the use and storage of your Financial Information is governed by the third party terms, conditions and privacy policies. If you apply for or otherwise take part in a financing offer through one of our financing partners, you will be asked to provide information (which may include Financial Information) to that financing partner, either directly or through the Services. The use of that information by the financing partner will be subject to the financing partner’s terms, conditions and privacy policies. Our treatment of any Financial Information that we may Process on your behalf, or that we receive from our financing partners or payment processors, is subject to this Privacy Policy. For more information on our use of third party payment processors and financing partners, including the name(s) of the entities that we partner with, please email
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Financial Partner Request">legal@sevenhundredrivers.com</LocaleLink>
							.
						</Typography>

						{/* International Data Transfers */}
						<Typography tag="h3" weight="semibold">
							International Data Transfers
						</Typography>
						<Typography tag="p" weight="light">
							You agree that all personal information collected via or by 700 Rivers may be transferred, processed and stored anywhere in the world, including but not limited to, the United States, the European Union, in the cloud, on our servers, on the servers of our affiliates or on the servers of our service providers. By providing information to 700 Rivers, you explicitly consent to the storage of your personal information in these locations.
						</Typography>

						{/* Third-Party Sites and Services */}
						<Typography tag="h3" weight="semibold">
							Third-Party Sites and Services
						</Typography>
						<Typography tag="p" weight="light">
							The Service may contain links to other websites and other websites may reference or link to the 700 Rivers website or other Services. These other domains and websites are not controlled by us, and 700 Rivers does not endorse or make any representations about third party websites or social media platforms. We encourage you to read the privacy policies of each and every website and application with which you interact. We do not endorse, screen or approve, and are not responsible for the privacy practices or content of such other websites or applications. We do not control the use of cookies or the collection of information by these third parties, nor how they manage such information. It is solely your obligation to review and understand the privacy practices and policies of these third parties.
						</Typography>

						{/* 11. Updating this Privacy Policy */}
						<br />
						<Typography tag="h2" weight="semibold">
							11. Updating this Privacy Policy
						</Typography>
						<Typography tag="p" weight="light">
							We reserve the right to change, modify, add, or remove portions of this Privacy Policy at any time. You should check this page periodically for changes. Your continued use of this Site following the posting of changes to this Privacy Policy will be considered your consent to those changes. In the event that we believe that the security of your personal information in our possession or control may have been compromised, we may seek to notify you of that development.
						</Typography>

						{/* Contact Us */}
						<br />
						<Typography tag="h2" weight="semibold">
							Contact Us
						</Typography>
						<Typography tag="p" weight="light">
							If you have any questions about this policy or your rights when using our product, please email us at
							{' '}
							<LocaleLink to="mailto:legal@sevenhundredrivers.com?Subject=Privacy Policy">legal@sevenhundredrivers.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

PrivacyPolicy.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
PrivacyPolicy.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default PrivacyPolicy;
