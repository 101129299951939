/*
 * File: Topbar.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 8:30 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useRef, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';
import { IconButton } from '../../IconButton';
import { Typography } from '../../Typography';

// Constants
import { FREE_SHIPPING_REQUIRED_LIMIT } from '../../../../Constants';

// Styles
import * as S from './Topbar.styles';


/**
 * Component
 */

export const Topbar = ({
	toggleNavigation, toggleCart, isLarge, showCart, setAnnouncementBarHeight
}) => {

	// Create state handlers
	const [topbarTop, setTopbarTop] = useState(40);
	const [isScrolled, setIsScrolled] = useState(false);

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Create reference for components
	const announcementRef = useRef();
	const isMounted = useRef(true);

	// Handle actions on window resize
	useWindowResize(() => {

		// Update height
		setTopbarTop(announcementRef?.current?.clientHeight);
		setAnnouncementBarHeight(announcementRef?.current?.clientHeight);
	});

	// Handle update on component mount
	useEffect(() => {

		// Update height
		setTimeout(() => {
			if (announcementRef?.current?.clientHeight) {
				setTopbarTop(announcementRef.current?.clientHeight);
				setAnnouncementBarHeight(announcementRef.current?.clientHeight);
			}
		}, 50);

		// Initialize temporary state
		isMounted.current = true;

		// Attach scroll listener
		if (isMounted.current) {
			document.addEventListener('scroll', () => {
				if (isMounted.current) {

					// Get current scroll status
					const st = document.scrollingElement.scrollTop;

					// Update scrolled status
					setIsScrolled(st >= 40);
				}
			}, true);
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<>
			{/* Announcement Bar */}
			<S.AnnouncementBar ref={announcementRef}>
				<Typography weight="medium" variation="2">
					Spend $
					{FREE_SHIPPING_REQUIRED_LIMIT}
					{' '}
					or more for free shipping!
				</Typography>
				{' '}
				<LocaleLink to="/shop/soaps">
					<Typography weight="medium" variation="2">
						Shop Soaps
					</Typography>
				</LocaleLink>
				{' '}
				<Typography weight="medium" variation="2">
					|
				</Typography>
				{' '}
				<LocaleLink to="/shop/self-care">
					<Typography weight="medium" variation="2">
						Shop Self Care
					</Typography>
				</LocaleLink>
			</S.AnnouncementBar>

			{/* Topbar */}
			<S.Topbar $top={isScrolled ? 0 : topbarTop}>
				<Padding left right isLarge={isLarge}>
					<S.TopbarWrapper $largeGap className="left">
						<S.NavigationToggle
							className="isMobile"
							icon={['fasl', 'bars']}
							size={1.6}
							onClick={() => { toggleNavigation(); }}
							style={{ marginTop: '2px' }}
						/>
						<S.NavigationLink className="isNotMobile" to="/shop/soaps">
							<Typography weight="semibold">Soaps</Typography>
						</S.NavigationLink>
						<S.NavigationLink className="isNotMobile" to="/shop/candles">
							<Typography weight="semibold">Candles</Typography>
						</S.NavigationLink>
						<S.NavigationLink className="isNotMobile" to="/shop/gift-bundles">
							<Typography weight="semibold">Gift Bundles</Typography>
						</S.NavigationLink>
						<S.NavigationLink className="isNotMobile" to="/corporate-gifts">
							<Typography weight="semibold">Corporate Gifts</Typography>
						</S.NavigationLink>
					</S.TopbarWrapper>
					<S.TopbarWrapper fullWidth>
						<LocaleLink to="/">
							<S.LogoImage alt="700 Rivers Logo" title="700 Rivers Logo" src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-vertical.png`} />
						</LocaleLink>
					</S.TopbarWrapper>
					<S.TopbarWrapper className="right">
						<S.NavigationLink className="isNotMobile" to="/stores">
							<Typography weight="semibold">Find Us In Store</Typography>
						</S.NavigationLink>
						<LocaleLink to="/search">
							<IconButton
								className="isNotMobile"
								icon={['fasr', 'magnifying-glass']}
								size={1.25}
								style={{ marginTop: '2px' }}
							/>
						</LocaleLink>
						<LocaleLink to="/rewards/join">
							<IconButton
								className="isNotMobile"
								icon={['fal', 'circle-user']}
								size={1.6}
								style={{ marginTop: '1px' }}
							/>
						</LocaleLink>
						{showCart && (
							<S.CartContainer onClick={() => { toggleCart(); }}>
								<S.NavigationToggle
									icon={['fasl', 'bag-shopping']}
									size={1.6}
								/>
								<S.CartCount>{`${cart?.products?.length || 0}`}</S.CartCount>
							</S.CartContainer>
						)}
					</S.TopbarWrapper>
				</Padding>
			</S.Topbar>
		</>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	toggleNavigation: PropTypes.func,
	toggleCart: PropTypes.func,
	isLarge: PropTypes.bool,
	showCart: PropTypes.bool,
	setAnnouncementBarHeight: PropTypes.func
};
Topbar.defaultProps = {
	toggleNavigation: null,
	toggleCart: null,
	isLarge: false,
	showCart: true,
	setAnnouncementBarHeight: null
};
