/*
 * File: ConfirmationModal.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 18, 2023 at 1:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { smallBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		flex-direction: row;
	}
	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
`;

export const IconContainer = styled.div`
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerLighter : theme.secondaryBackground)};
	border-radius: 5px;
	display: inline-flex;
	margin: 0px 0px 15px 0px;

	svg {
		color: ${({ $type, theme }) => ($type === 'danger' ? theme.statusDangerBase : theme.primaryText)};
		font-size: 1.3rem;
	}
`;
