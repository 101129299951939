/*
 * File: IconContainer.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 2, 2023 at 12:15 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const IconContainer = styled.div`
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	display: inline-flex;
	margin: ${({ $center }) => ($center ? '0px auto 15px auto' : '0px 0px 15px 0px')};
	position: relative;

	svg {
		font-size: 1.42rem;
		color: ${({ $iconColor, theme }) => ($iconColor != null ? $iconColor : theme.primaryText)}
	}
`;
