/*
 * File: SignUpModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 22, 2023 at 10:45 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Utilities
import { toastError, toastMessage } from '../../utilities/toaster';

// Services
import { postEmailList } from '../../services/contact';

// Components
import { Button } from '../Button';
import { ConfettiBurst } from '../ConfettiBurst';
import { LocaleLink } from '../LocaleLink';
import { Modal } from '../Modal';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';

// Styles
import * as S from './SignUpModal.styles';


/**
 * Component
 */

export const SignUpModal = ({
	className, isOpen, handleClose, onSignUp
}) => {

	// Create state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Handle sign up action
	const handleSignUp = async () => {

		// Get parameters
		const {
			name, email, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (name && name !== '' && validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}

		// Set submitting state
		setIsSubmitting(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('subscribe_email_list'); } catch (e) {}

		// Subscribe to email list
		postEmailList({
			name,
			email,
			question_input: questionInput,
			_magic,
			_magic2,
			recaptchaToken,
		}).then(() => {

			// Clear inputs
			setInputValues({});

			// Set submitting state
			setIsSubmitting(false);

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 100);

			// Show confirmation
			toastMessage(uiMode, 'Welcome! Thank you for signing up. Awesome things are on the way to your inbox!');

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 5000);

			// Hide modal
			setTimeout(() => {
				handleClose();
				onSignUp();
			}, 3000);

		}).catch(({ response }) => {

			// Set loading state
			setIsSubmitting(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble signing you up. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<S.LogoContainer>
					<S.LogoImage alt="700 Rivers Logo" title="700 Rivers Logo" src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-vertical.png`} />
					<S.ConfettiTarget ref={confettiTargetRef} />
				</S.LogoContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">Welcome to 700 Rivers!</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Save 10% on your next order when you join our newsletter! Be the first to know about new products, artisan stories, sales, and the BIG impact you are making when you purchase from 700 Rivers!</Typography>

				{/* Input Container */}
				<S.InputContainer>

					{/* Name Input */}
					<TextInput
						placeholder="Your Name"
						name="name"
						type="text"
						error={nameError}
						value={inputValues.name || ''}
						autoComplete="name"
						containerClassName="formInput"
						onFocus={() => { setNameError(null); }}
						onKeyUp={() => { setNameError(null); }}
						onBlur={() => { setNameError(null); }}
						onChange={handleOnChange}
					/>

					{/* Email Input */}
					<TextInput
						placeholder="Email"
						name="email"
						type="text"
						error={emailError}
						value={inputValues.email || ''}
						autoComplete="email"
						containerClassName="formInput"
						onFocus={() => { setEmailError(null); }}
						onKeyUp={() => { setEmailError(null); }}
						onBlur={() => { setEmailError(null); }}
						onChange={handleOnChange}
						style={{ marginTop: '20px' }}
					/>

				</S.InputContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isSubmitting} isLoading={isSubmitting} variant="solid" onClick={handleSignUp}>Sign Up</Button>
					<LocaleLink to="/rewards/login" onClick={handleClose}><Typography variation="2">I already have an account</Typography></LocaleLink>
				</S.ActionContainer>

				{/* Disclaimer */}
				<S.DisclaimerContainer>
					<Typography variation="3" weight="light" tag="p">
						Applies to new email subscribers only. To take advantage of this offer, sign up on
						{' '}
						<LocaleLink to="https://www.sevenhundredrivers.com" target="_blank">sevenhundredrivers.com</LocaleLink>
						. The one-time use discount code will be delivered to your email address.
					</Typography>
					<Typography variation="3" weight="light" tag="p" style={{ marginTop: '8px' }}>
						By signing up, you agree to 700 Rivers&apos;
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
						{' '}
						and
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Privacy Policy</LocaleLink>
						.
					</Typography>
				</S.DisclaimerContainer>
			</S.ModalInner>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</Modal>
	);
};


/**
 * Configuration
 */

SignUpModal.displayName = 'SignUpModal';
SignUpModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	onSignUp: PropTypes.func,
};
SignUpModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	onSignUp: null
};
