/*
 * File: Overview.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 2, 2023 at 5:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, LegalNavigation, Typography, LocaleLink
} from '../../../../components';

// Styles
import * as S from './Overview.styles';


/**
 * Component
 */

const Overview = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation hidePadding>
				<LegalNavigation allowOverflow>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							700 Rivers Terms &amp; Policies
						</Typography>

						{/* Featured Policies */}
						<S.PoliciesContainer>

							{/* Terms of Service */}
							<LocaleLink to="/legal/terms">
								<S.PolicyCard className="animate">

									{/* Content */}
									<S.TextHolder>
										<Typography tag="h3" weight="semibold">Terms of Service</Typography>
										<Typography tag="p" weight="light">Our agreement with a user that uses the 700 Rivers platform and mobile apps.</Typography>
									</S.TextHolder>

									{/* Illustration */}
									<S.Illustration className="legal-cover-2" />

									{/* CTA */}
									<S.CTAHolder>
										<S.CTALeft>
											<Typography>Read More</Typography>
										</S.CTALeft>
										<S.CTARight>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.CTARight>
									</S.CTAHolder>
								</S.PolicyCard>
							</LocaleLink>

							{/* Privacy Policy */}
							<LocaleLink to="/legal/privacy">
								<S.PolicyCard className="animate">

									{/* Content */}
									<S.TextHolder>
										<Typography tag="h3" weight="semibold">Privacy Policy</Typography>
										<Typography tag="p" weight="light">Our policy regarding what information we collect at 700 Rivers, how we use it, and what choices you have as a user.</Typography>
									</S.TextHolder>

									{/* Illustration */}
									<S.Illustration className="legal-cover-3" />

									{/* CTA */}
									<S.CTAHolder>
										<S.CTALeft>
											<Typography>Read More</Typography>
										</S.CTALeft>
										<S.CTARight>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.CTARight>
									</S.CTAHolder>
								</S.PolicyCard>
							</LocaleLink>

							{/* Security Promise */}
							<LocaleLink to="/legal/security-promise">
								<S.PolicyCard className="animate">

									{/* Content */}
									<S.TextHolder>
										<Typography tag="h3" weight="semibold">Security Promise</Typography>
										<Typography tag="p" weight="light">We take security seriously at 700 Rivers, and we are proud to employ the latest tech to protect your data.</Typography>
									</S.TextHolder>

									{/* Illustration */}
									<S.Illustration className="legal-cover-1" />

									{/* CTA */}
									<S.CTAHolder>
										<S.CTALeft>
											<Typography>Read More</Typography>
										</S.CTALeft>
										<S.CTARight>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.CTARight>
									</S.CTAHolder>
								</S.PolicyCard>
							</LocaleLink>

							{/* Cookie Statement */}
							<LocaleLink to="/legal/cookie-policy">
								<S.PolicyCard className="animate">

									{/* Content */}
									<S.TextHolder>
										<Typography tag="h3" weight="semibold">Cookie Statement</Typography>
										<Typography tag="p" weight="light">Our policies on how we store and use cookies to enhance your experience on 700 Rivers.</Typography>
									</S.TextHolder>

									{/* Illustration */}
									<S.Illustration className="legal-cover-4" />

									{/* CTA */}
									<S.CTAHolder>
										<S.CTALeft>
											<Typography>Read More</Typography>
										</S.CTALeft>
										<S.CTARight>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.CTARight>
									</S.CTAHolder>
								</S.PolicyCard>
							</LocaleLink>
						</S.PoliciesContainer>
					</S.Wrapper>
				</LegalNavigation>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Overview.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
Overview.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Overview;
