/*
 * File: RadioGroup.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: March 30, 2023 at 1:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './RadioGroup.styles';
import { Typography } from '../Typography';


/**
 * Component
 */

export const RadioGroup = ({
	containerClassName, options, value, family, onChange
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={containerClassName}>
			{options.map((option) => (
				<S.RadioRow key={option.value}>
					<S.Radio
						type="radio"
						name={family}
						value={option.value}
						checked={value === option.value}
						onChange={(e) => {
							if (e.target.checked) {
								onChange(option.value);
							}
						}}
					/>
					<S.LabelContainer>
						<Typography tag="p" variation="1" weight="semibold">{option.label}</Typography>
						{option.subLabel && <Typography tag="p" variation="3" className="subLabel">{option.subLabel}</Typography>}
					</S.LabelContainer>
				</S.RadioRow>
			))}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
	containerClassName: PropTypes.string,
	value: PropTypes.string,
	family: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
		subLabel: PropTypes.string,
	})),
};
RadioGroup.defaultProps = {
	containerClassName: null,
	value: '',
	onChange: null,
	options: []
};
