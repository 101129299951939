/*
 * File: Wholesale.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 27, 2023 at 2:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography, LocaleLink, IconContainer
} from '../../../components';

// Styles
import * as S from './Wholesale.styles';


/**
 * Component
 */

const Wholesale = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>
						<S.ContentPanel>
							<IconContainer>
								<FontAwesomeIcon icon={['fasl', 'box']} />
							</IconContainer>
							<S.Content>
								<Typography tag="h1" weight="extrabold">700 Rivers Wholesale</Typography>
								<Typography tag="p" className="subtitle">Interested in purchasing 700 Rivers products for your store, event, or gift collection? Our team is happy to help put together the perfect package for your customers.</Typography>
								<Typography tag="h4" weight="bold" style={{ marginTop: '25px' }}>
									Our soaps and self-care products
									<FontAwesomeIcon icon={['fass', 'arrow-down-right']} style={{ marginLeft: '8px', fontSize: '1.3rem' }} />
								</Typography>
							</S.Content>
							<S.ExamplesSection>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'seedling']} /></S.ExampleIcon>
									<Typography>Are made from high-quality and natural, skin-friendly ingredients</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'heart']} /></S.ExampleIcon>
									<Typography>Are an impact-focused, ethical and sustainable addition to your boutique, store, or gift shop</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'gift']} /></S.ExampleIcon>
									<Typography>Are a perfect addition to gift boxes and subscription boxes</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'party-horn']} /></S.ExampleIcon>
									<Typography>Are great for corporate gifting, events, and much more</Typography>
								</S.Example>
							</S.ExamplesSection>
						</S.ContentPanel>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/orange-clove-candles.jpg`}
								alt="700 Rivers Orange Clove candles with natural ingredients"
							/>
						</S.MediaPanel>
					</S.HeroSection>

					{/* Content Padding */}
					<S.ContentPadding left right dynamic>

						{/* Faire Widget */}
						<S.ContentSection id="faire" style={{ margin: '80px 0px -10px', paddingBottom: '0px' }}>

							{/* Content */}
							<S.Content>
								<Typography tag="h3" weight="extrabold">Place a wholesale order easily on Faire.</Typography>
								<Typography tag="p" className="subtitle">
									Visit us on
									{' '}
									<LocaleLink to="https://700rivers.faire.com" target="_blank">Faire.com</LocaleLink>
									{' '}
									to see our wholesale pricing and find the perfect products for your store.
								</Typography>
							</S.Content>

							{/* Widget */}
							<iframe
								src="https://www.faire.com/embed/bw_y4tdx4tynd"
								width="900"
								height="600"
								scrolling="no"
								style={{
									margin: '40px 0px 0px 0px',
									border: 'none',
									display: 'block',
									maxWidth: '100%',
									width: '900px',
									height: '600px'
								}}
								title="700 Rivers Faire Widget"
							/>
						</S.ContentSection>

						{/* Impact Section */}
						<S.ContentSection>
							<S.Content>
								<Typography tag="h3" weight="extrabold">A global impact with each purchase.</Typography>
								<Typography tag="p" className="subtitle">Each purchase of our high-quality, eco-friendly products directly employ women across the world that are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.</Typography>
								<Typography tag="p" weight="semibold" style={{ marginTop: '20px' }}>With your help, 700 Rivers has been able to give back to our local community and global family.</Typography>
							</S.Content>
							<S.ExamplesSection>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'heart']} /></S.ExampleIcon>
									<Typography>We employ 20+ artisans who have escaped and overcome human trafficking</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'pencil']} /></S.ExampleIcon>
									<Typography>We have provided school supplies for hundreds of children in Bangladesh</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'dollar-sign']} /></S.ExampleIcon>
									<Typography>We&apos;ve donated funds, resources, and masks to local nonprofits in Raleigh</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'mask-face']} /></S.ExampleIcon>
									<Typography>During the pandemic, we donated hundreds of masks to an orphanage in Bangladesh</Typography>
								</S.Example>
							</S.ExamplesSection>
						</S.ContentSection>

						{/* Order Section */}
						<S.ContentSection>

							{/* Content */}
							<S.Content>
								<Typography tag="h3" weight="extrabold">Ready to place an order?</Typography>
								<Typography tag="p" className="subtitle">
									Find us on
									{' '}
									<LocaleLink to="https://700rivers.faire.com" target="_blank">Faire.com</LocaleLink>
									{' '}
									to place a direct order, or reach out to our team. We&apos;re happy to answer questions about minimums, pricing, customizations, and anything else.
								</Typography>
							</S.Content>

							{/* Wholesale Options */}
							<S.CardContainer>

								{/* Faire */}
								<S.CardLink to="https://700rivers.faire.com" target="_blank">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'box']} />
										<Typography tag="h4" weight="semibold">Find us on Faire</Typography>
										<Typography tag="p" weight="light">Place a wholesale order directly through Faire using our referral link.</Typography>
										<S.ActionContainer>
											<Typography>Go to Faire</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>

								{/* Contact Us */}
								<S.CardLink to="/contact#wholesale">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'envelope']} />
										<Typography tag="h4" weight="semibold">Contact Us</Typography>
										<Typography tag="p" weight="light">Send us a message or an email to hello@sevenhundredrivers.com to place an order.</Typography>
										<S.ActionContainer>
											<Typography>Reach out</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>
							</S.CardContainer>
						</S.ContentSection>
					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Wholesale.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Wholesale.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Wholesale;
