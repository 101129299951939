/*
 * File: Home.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 10:51 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getProductsForCollection } from '../../../services/product';

// Components
import {
	Meta, AppNavigation, SchemaScript, Typography, LocaleLink, IconContainer, ReviewsSection
} from '../../../components';

// Styles
import * as S from './Home.styles';

// Constants
import { PRODUCT_COLLECTIONS, PRODUCT_REVIEWS } from '../../../../Constants';


/**
 * Component
 */

const Home = ({
	meta, locale, data, user
}) => {

	// Create state handlers
	const [favoritesComponentStatus, setFavoritesComponentStatus] = useState(!data?.products ? 'idle' : 'success');
	const [newArrivalsComponentStatus, setNewArrivalsComponentStatus] = useState('idle');
	const [giftBundlesComponentStatus, setGiftBundlesComponentStatus] = useState('idle');
	const [favoriteProducts, setFavoriteProducts] = useState(data?.products);
	const [newArrivalProducts, setNewArrivalProducts] = useState([]);
	const [giftBundlesProducts, setGiftBundlesProducts] = useState([]);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current user from hook
	let currentUser = useSelector((state) => state.user.value);
	if (user) currentUser = user;

	// Handle fetch data for favorites component
	const fetchDataForFavoritesComponent = async () => {

		// Update page status
		setFavoritesComponentStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.OUR_FAVORITES.id, 4);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setFavoriteProducts(productData.products);

				// Update page statue
				setFavoritesComponentStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setFavoritesComponentStatus('error');
			}
		}
	};

	// Handle fetch data for new arrivals component
	const fetchDataForNewArrivalsComponent = async () => {

		// Update page status
		setNewArrivalsComponentStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.NEW_ARRIVALS.id, 4);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setNewArrivalProducts(productData.products);

				// Update page statue
				setNewArrivalsComponentStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setNewArrivalsComponentStatus('error');
			}
		}
	};

	// Handle fetch data for gift bundles component
	const fetchDataForGiftBundlesComponent = async () => {

		// Update page status
		setGiftBundlesComponentStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.GIFT_BUNDLES.id, 4);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setGiftBundlesProducts(productData.products);

				// Update page statue
				setGiftBundlesComponentStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setGiftBundlesComponentStatus('error');
			}
		}
	};

	// Handle actions on favorites component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (favoritesComponentStatus === 'idle') {

			// Fetch data state for component
			fetchDataForFavoritesComponent();
		}
	}, [favoritesComponentStatus]);

	// Handle actions on new arrivals component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (newArrivalsComponentStatus === 'idle') {

			// Fetch data state for component
			fetchDataForNewArrivalsComponent();
		}
	}, [newArrivalsComponentStatus]);

	// Handle actions on gift bundles component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (giftBundlesComponentStatus === 'idle') {

			// Fetch data state for component
			fetchDataForGiftBundlesComponent();
		}
	}, [giftBundlesComponentStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render marketing banner
	const renderMarketingBanner = () => {

		// Get current month
		const month = new Date().getMonth();

		// Initialize content
		let image = '';
		let title = '';
		let action = { url: '', title: '' };
		let theme = { accent: '', text: '' };

		// Set dynamic content
		if (month < 8) {
			image = 'product-banner-ordinary.jpg';
			title = 'Shop Small with a Big Impact';
			action = { url: '/shop/candles', title: 'Shop Candles' };
			theme = { accent: '#a45d6f', text: 'white' };
		} else if (month < 10) {
			image = 'product-banner-fall.jpg';
			title = 'Shop Small This Fall';
			action = { url: '/shop/candles', title: 'Shop Candle Scents' };
			theme = { accent: '#ffbd28', text: 'white' };
		} else {
			image = 'product-banner-holiday.jpg';
			title = 'Fall-la-la In Love With Holiday Scents';
			action = { url: '/shop/candles', title: 'Shop the Holiday Collection' };
			theme = { accent: '#ce444b', text: 'white' };
		}

		// Return banner
		return (
			<S.HeroBanner $image={`${process.env.CDN_URL}/public/images/${image}`}>
				<S.HeroContent $textColor={theme.text}>
					<Typography tag="h5">NEW!</Typography>
					<Typography tag="h1" weight="bold">{title}</Typography>
					<LocaleLink to={action.url}>
						<S.CTAButton
							$accent={theme.accent}
							variation={theme.text === 'white' ? 'white' : 'default'}
						>
							{action.title}
						</S.CTAButton>
					</LocaleLink>
				</S.HeroContent>
			</S.HeroBanner>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage'],
				'@id': `${process.env.APP_URL}/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.sevenhundredrivers.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>

						{/* Account CTA */}
						{currentUser?.isAnonymous !== false && (
							<S.HeroAccountCTA>
								<Typography tag="h3">
									Hi, want a more
									{' '}
									<Typography weight="bold">personalized experience</Typography>
									?
									{' '}
									<LocaleLink to="/rewards/login">Sign In</LocaleLink>
								</Typography>
							</S.HeroAccountCTA>
						)}

						{/* Marketing Banner */}
						{renderMarketingBanner()}

					</S.HeroSection>

					{/* Publicity Section */}
					<S.PublicitySection>

						{/* Title */}
						<Typography tag="h4" variation="2" weight="bold">Loved By</Typography>

						{/* Logo Container */}
						<S.PublicityLogoRow>

							{/* the Skimm */}
							<LocaleLink to="https://www.theskimm.com/picks/things-to-get-you-in-the-festive-and-spooky-fall-mood-4xQEvRPIRGyTXnOvEQIm4p" target="_blank">
								<S.PublicityLogoContainer>
									<S.ForegroundLogo className="animate foregroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/skimm-logo-foreground.png`} />
									<S.BackgroundLogo className="animate backgroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/skimm-logo-background.png`} />
								</S.PublicityLogoContainer>
							</LocaleLink>

							{/* Bloomberg */}
							<LocaleLink to="https://www.bloomberg.com/news/articles/2021-10-28/beautyologie-s-fair-trade-is-next-frontier-in-beauty-supply-chain" target="_blank">
								<S.PublicityLogoContainer>
									<S.ForegroundLogo className="animate foregroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/bloomberg-logo-foreground.png`} />
									<S.BackgroundLogo className="animate backgroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/bloomberg-logo-background.png`} />
								</S.PublicityLogoContainer>
							</LocaleLink>

							{/* Reader's Digest */}
							<LocaleLink to="https://www.rd.com/list/gifts-for-girlfriend/" target="_blank">
								<S.PublicityLogoContainer>
									<S.ForegroundLogo className="animate foregroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/readers-digest-logo-foreground.png`} />
									<S.BackgroundLogo className="animate backgroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/readers-digest-logo-background.png`} />
								</S.PublicityLogoContainer>
							</LocaleLink>

							{/* Gift Shop Plus */}
							<LocaleLink to="https://stationerytrends.com/article/40-under-40-honoring-the-class-of-2022/" target="_blank">
								<S.PublicityLogoContainer>
									<S.ForegroundLogo className="animate foregroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/gift-shop-plus-logo-foreground.png`} />
									<S.BackgroundLogo className="animate backgroundLogo" $image={`${process.env.CDN_URL}/public/assets/logos/gift-shop-plus-logo-background.png`} />
								</S.PublicityLogoContainer>
							</LocaleLink>

						</S.PublicityLogoRow>
					</S.PublicitySection>

					{/* Our Favorites */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection>
							<Typography tag="h2" weight="bold">Our Favorites</Typography>
							<Typography tag="p" variation="2">Products loved by our team and our community</Typography>
						</S.TitleSection>

						{/* Favorites Collection */}
						<S.FavoritesCollections
							products={favoriteProducts}
							showMarkup={false}
							showFilterBar={false}
							showMore
							moreLink="/shop/favorites"
						/>

					</S.ContentSection>

					{/* Gift Bundles */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">Curated Gift Bundles</Typography>
						</S.TitleSection>

						{/* Gift Bundles Collection */}
						<S.GiftBundlesCollections
							products={giftBundlesProducts}
							showMarkup={false}
							showFilterBar={false}
							showMore
							moreLink="/shop/gift-bundles"
						/>

					</S.ContentSection>

					{/* New Arrivals */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">New Arrivals</Typography>
						</S.TitleSection>

						{/* New Arrivals Collection */}
						<S.NewArrivalsCollections
							products={newArrivalProducts}
							showMarkup={false}
							showFilterBar={false}
							showMore
							moreLink="/shop/new-arrivals"
						/>

					</S.ContentSection>

					{/* Impact Section */}
					<S.ContentSection>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'heart']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									The Impact Behind Your Purchase
								</Typography>
								<Typography tag="p" weight="light">
									700 Rivers soaps are hand-crafted by Bangladeshi artisans that have escaped human trafficking and are now steadily employed with dignified work. When you use a 700 Rivers soap bar, you can get a fresh start to your day while helping our Bangladeshi artisans get a fresh start in life.
								</Typography>
								<Typography tag="p" weight="light">
									We at 700 Rivers provide our artisans with fair wages, a dignified career, and a safe work environment.
								</Typography>
								<S.LinkContainer to="/mission">
									<Typography>Read more about our mission</Typography>
									<FontAwesomeIcon icon={['fass', 'arrow-right']} />
								</S.LinkContainer>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/artisan-family-group.jpg`}
									alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Collections */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection>
							<Typography tag="h2" weight="bold">Curated Collections</Typography>
							<Typography tag="p" variation="2">Treat yourself to something special, or find the perfect gift</Typography>
						</S.TitleSection>

						{/* Product Collections */}
						<S.ProductCollections>

							{/* Soaps */}
							<S.ProductCollection className="animate" to="/shop/soaps">
								<S.CollectionImage
									$image={`${process.env.CDN_URL}/public/images/web-f7-700-Rivers_r1_03D_4163.jpg`}
								/>
								<S.CollectionContent>
									<Typography tag="h4" weight="semibold">Fresh Start Soaps</Typography>
									<Typography variation="2" weight="regular">Handcrafted by strong women</Typography>
								</S.CollectionContent>
							</S.ProductCollection>

							{/* Bath Bombs */}
							<S.ProductCollection className="animate" to="/shop/bath-bombs">
								<S.CollectionImage
									$image={`${process.env.CDN_URL}/public/images/CalmingLavender_4d283744-42ad-41b1-8e10-3aa8033bd8da.jpg`}
								/>
								<S.CollectionContent>
									<Typography tag="h4" weight="semibold">Bath Bombs</Typography>
									<Typography variation="2" weight="regular">A spa-like experience for a great cause</Typography>
								</S.CollectionContent>
							</S.ProductCollection>

							{/* Candles */}
							<S.ProductCollection className="animate" to="/shop/candles">
								<S.CollectionImage
									$image={`${process.env.CDN_URL}/public/images/web-0-700-Rivers_r1_03D_4140.jpg`}
								/>
								<S.CollectionContent>
									<Typography tag="h4" weight="semibold">Shine Bright Candles</Typography>
									<Typography variation="2" weight="regular">Shine bright and spread the light</Typography>
								</S.CollectionContent>
							</S.ProductCollection>

							{/* Curated Gift Bundles */}
							<S.ProductCollection className="animate" to="/shop/gift-bundles">
								<S.CollectionImage
									$image={`${process.env.CDN_URL}/public/images/hands-holding-soap-banner.jpg`}
								/>
								<S.CollectionContent>
									<Typography tag="h4" weight="semibold">Curated Gift Bundles</Typography>
									<Typography variation="2" weight="regular">The perfect gift for any occasion</Typography>
								</S.CollectionContent>
							</S.ProductCollection>
						</S.ProductCollections>
					</S.ContentSection>

					{/* Reviews */}
					<S.ContentSection style={{ marginTop: '60px' }}>

						{/* Title */}
						<S.TitleSection>
							<Typography tag="h2" weight="bold">Reviews from our Customers</Typography>
						</S.TitleSection>

						{/* Review Section */}
						<S.ReviewSection>

							{/* Customer Reviews */}
							<ReviewsSection reviews={PRODUCT_REVIEWS} />

							{/* CTA Button */}
							<LocaleLink to="/shop/all">
								<S.ReviewActionButton variant="outline">
									Shop All Products
									<FontAwesomeIcon icon={['fass', 'arrow-right']} />
								</S.ReviewActionButton>
							</LocaleLink>

						</S.ReviewSection>
					</S.ContentSection>

					{/* CTA Section */}
					<S.ContentSection>
						<S.CardContainer>

							{/* Seasonal Subscription */}
							<S.CardLink to="/seasonal-subscription">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'party-horn']} />
									<Typography tag="h4" weight="semibold">The ultimate treat yourself</Typography>
									<Typography tag="p" weight="light">Subscribe to get your seasonal favorites delivered every three months</Typography>
									<S.ActionContainer>
										<Typography>Explore the seasonal subscription</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>

							{/* Shop Gift Cards */}
							<S.CardLink to="/shop/gift-cards">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'gift']} />
									<Typography tag="h4" weight="semibold">The gift of choice</Typography>
									<Typography tag="p" weight="light">Take the guesswork out of gifting with a pick that never goes out of style.</Typography>
									<S.ActionContainer>
										<Typography>Shop Gift Cards</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>
						</S.CardContainer>
					</S.ContentSection>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Home.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
	user: PropTypes.shape(),
};
Home.defaultProps = {
	meta: {},
	locale: {},
	data: null,
	user: null,
};


/**
 * Exports
 */

export default Home;
