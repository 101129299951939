/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on October 30, 2023 at 8:24 AM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 30, 2023 at 2:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getRetailLocations = (data) => api.get('/retail/locations', { params: data });
