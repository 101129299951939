/*
 * File: ReferFriend.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 11:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { referFriend } from '../../../services/user';

// Components
import {
	Meta, AppNavigation, Typography, TextInput, TextArea, Emoji, ConfettiBurst, IconContainer
} from '../../../components';

// Styles
import * as S from './ReferFriend.styles';


/**
 * Component
 */

const ReferFriend = ({ meta, locale }) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [referralEmailsError, setReferralEmailsError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle restart flow
	const restartFlow = async () => {
		setCurrentStep(1);
	};

	// Handle submit referral form function
	const handleSubmitReferral = async () => {

		// Get parameters
		const {
			email, referralEmails
		} = inputValues;

		// Handle actions for step
		if (currentStep === 0) {

			// Validate parameters
			if (!email || !validator.isEmail(email)) {
				setEmailError({ message: 'Please enter your email address' });
				return;
			}

			// Move to next step
			setCurrentStep(1);

		} else if (currentStep === 1) {

			// Parse emails
			const emails = (referralEmails || '').split(',').map((em) => em.trim());

			// Validate parameters
			if (emails.length === 0) {
				setReferralEmailsError({ message: 'Please enter at least one email address' });
				return;
			}
			if (emails.some((em) => !em || !validator.isEmail(em))) {
				setReferralEmailsError({ message: 'One or more email addresses are invalid' });
				return;
			}

			// Set loading state
			setIsLoading(true);

			// Get reCAPTCHA token
			let recaptchaToken = null;
			try { recaptchaToken = await executeRecaptcha('submit_friend_referral'); } catch (e) {}

			// Refer friend
			referFriend({ email, referralEmails: emails, recaptchaToken }).then(() => {

				// Set loading state
				setIsLoading(false);

				// Clear inputs
				setInputValues({ email });

				// Show confirmation
				setCurrentStep(2);
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

				// Reset confetti
				setTimeout(() => {
					setFireConfetti(false);
				}, 3000);

			}).catch(({ response }) => {

				// Set loading state
				setIsLoading(false);

				// Show error message
				if (response?.data?.message) {
					toastError(uiMode, response?.data?.message);
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble sending your referral. Please try again.');
				}
			});
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render text content
	const renderTextContent = () => (
		<S.ContentContainer>
			<IconContainer>
				<FontAwesomeIcon icon={['fasl', 'gift']} />
			</IconContainer>
			<Typography tag="h5">
				Refer a Friend
			</Typography>
			<Typography tag="h2" weight="bold">
				Give 10% Off. Get a Discount.
			</Typography>
			<Typography tag="h4">
				Like 700 Rivers? Love our Mission? Tell your friends about 700 Rivers and you&apos;ll get discount points for each person who purchases — and they&apos;ll get 10% off their first order, too.
			</Typography>
		</S.ContentContainer>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Content */}
					<S.HeroSection>

						{/* Media Panel */}
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/soaps-group-jute.jpg`}
								alt="700 Rivers soaps on a background of jute plants"
							/>
						</S.MediaPanel>

						{/* Content Panel */}
						<S.ContentPanel>

							{/* Content */}
							{(currentStep === 0 || currentStep === 1) && renderTextContent()}

							{/* Email Form */}
							{currentStep === 0 && (
								<S.Form className="animate" isVisible={currentStep === 0}>

									{/* Inputs */}
									<TextInput
										label="Your Email Address"
										name="email"
										type="text"
										error={emailError}
										value={inputValues.email || ''}
										autoComplete="email"
										containerClassName="formInput"
										onFocus={() => { setEmailError(null); }}
										onKeyUp={() => { setEmailError(null); }}
										onBlur={() => { setEmailError(null); }}
										onChange={handleOnChange}
										smartField
									/>

									{/* Continue Action */}
									<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSubmitReferral(); }} size="large" className="floatShadow">Next</S.ActionButton>

								</S.Form>
							)}

							{/* Friend Email Form */}
							{currentStep === 1 && (
								<S.Form className="animate" isVisible={currentStep === 1}>

									{/* Inputs */}
									<TextArea
										label="Friends' Email Addresses"
										name="referralEmails"
										type="text"
										rows={2}
										error={referralEmailsError}
										value={inputValues.referralEmails || ''}
										containerClassName="formInput"
										onFocus={() => { setReferralEmailsError(null); }}
										onKeyUp={() => { setReferralEmailsError(null); }}
										onBlur={() => { setReferralEmailsError(null); }}
										onChange={handleOnChange}
										placeholder="friend1@example.com, friend2@example.com"
										smartField
									/>

									{/* Question Input */}
									<input
										id="question_input"
										name="question_input"
										type="text"
										value={inputValues.question_input || ''}
										onChange={handleOnChange}
										className="question-input"
									/>

									{/* Honeypots */}
									<input
										type="text"
										name="_magic"
										value={inputValues._magic || ''}
										onChange={handleOnChange}
										className="gotcha-field"
									/>
									<input
										type="text"
										name="_magic2"
										value={inputValues._magic2 || ''}
										onChange={handleOnChange}
										style={{ display: 'none' }}
									/>

									{/* Continue Action */}
									<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSubmitReferral(); }} size="large" className="floatShadow">Next</S.ActionButton>

								</S.Form>
							)}

							{/* Confirmation Content */}
							{currentStep === 2 && (
								<S.ConfirmationContainer className="animate" isVisible={currentStep === 2}>

									{/* Content Pane */}
									<S.ConfirmationPane>

										{/* Content */}
										<S.ConfirmationContent>

											{/* Content */}
											<Emoji symbol="🎉" size={3.5} label="Celebration" />
											<S.ConfettiTarget ref={confettiTargetRef} />
											<Typography tag="h2" weight="bold">Thank you.</Typography>
											<Typography tag="h4">Your invite has been sent — just remind your friends to check their emails!</Typography>

											{/* Continue Action */}
											<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { restartFlow(); }} size="large" variant="outline" className="floatShadow">Invite More Friends</S.ActionButton>

										</S.ConfirmationContent>
									</S.ConfirmationPane>
								</S.ConfirmationContainer>
							)}

						</S.ContentPanel>
					</S.HeroSection>
				</S.Wrapper>
			</AppNavigation>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

ReferFriend.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
ReferFriend.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default ReferFriend;
