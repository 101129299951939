/*
 * File: Notice.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Notice.styles';


/**
 * Component
 */

export const Notice = ({ children }) => (
	<S.Notice>
		{children}
	</S.Notice>
);


/**
 * Configuration
 */

Notice.displayName = 'Notice';
Notice.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Notice.defaultProps = {
	children: null
};
