/*
 * File: BlogPost.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: December 2, 2024 at 9:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink as LocaleLinkBase } from '../../../components';

// Constants
import {
	mediumBreakpoint
} from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 70px 0px 50px;
	margin: 0 auto;

	* {
		text-align: left;
	}
`;

export const ContentPadding = styled.div`
	margin: 0px auto;
	max-width: 750px;
	width: 100%;
`;

export const TopicContainer = styled.div`
	margin: 20px 0px 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	span {
		cursor: pointer;
	}
`;

export const DetailContainer = styled.div`
	display: flex;
	margin: 35px 0px 50px;
	gap: 20px;
	flex-direction: column;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
		gap: 40px;
	}
`;

export const Detail = styled.div`
	svg {
		margin-right: 8px;
	}
`;

export const ContentContainer = styled.div`
	margin: 60px 0px 30px;
`;

export const ShareContainer = styled.div`
	margin: 70px 0px 0px;
`;

export const ShareWidgetContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin: 10px 0px 0px;

	span {
		font-size: 0.85rem;
	}
	.facebook {
		background-color: ${ColorValues.facebookBrand};
		border-color: ${ColorValues.facebookBrand};
	}
	.twitter {
		background-color: ${ColorValues.twitterBrand};
		border-color: ${ColorValues.twitterBrand};
	}
	.reddit {
		background-color: ${ColorValues.redditBrand};
		border-color: ${ColorValues.redditBrand};
	}
	.pinterest {
		background-color: ${ColorValues.pinterestBrand};
		border-color: ${ColorValues.pinterestBrand};
	}
	.linkedin {
		background-color: ${ColorValues.linkedinBrand};
		border-color: ${ColorValues.linkedinBrand};
	}
	.email {
		background-color: ${ColorValues.brandPrimaryBase};
		border-color: ${ColorValues.brandPrimaryBase};
	}

`;

export const SectionContainer = styled.div`
	margin: 100px 0px 30px;

	.title {
		margin: 0px 0px 20px;
	}
`;

export const BlogImage = styled.img`
	border-radius: 20px;
	width: 100%;
	max-height: 600px;
	object-fit: cover;
`;

export const LocaleLink = styled(LocaleLinkBase)`
	text-decoration: none;
`;
