/*
 * File: api.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:08 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import axios from 'axios';


/**
 * API Handler
 */

const api = axios.create({
	baseURL: `${process.env.API_URL}${process.env.API_MOUNT}`,
	withCredentials: true,
});


/**
 * Configure API
 */

api.interceptors.request.use(async (config) => {
	const headers = { ...config.headers };
	return { ...config, headers };
});

api.interceptors.response.use((response) => response, (error) => Promise.reject(error));


/**
 * Exports
 */

export default api;
