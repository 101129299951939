/*
 * File: TextArea.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on April 15, 2022 at 1:24 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
 	flex-direction: column;
 	position: relative;

 	& > label {
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
 	}
`;

const SIZES = {
	fit: css`
		width: 100%;
		font-size: 0.9rem;
	`,
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
 `,
	medium: css`
		font-size: 1rem;
		min-height: 2.8125rem;
 `,
};

export const TextArea = styled.textarea`
 	appearance: none;
 	padding: 0.75rem 0.75rem;
	border-radius: 0px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	color: ${({ theme }) => theme.primaryText};
	line-height: normal;
	width: 100%;
	outline: none;
	background-color: ${({ theme }) => theme.inputBackground};

 	${({ size }) => SIZES[size]}

	&:focus {
		border-color: ${({ theme }) => theme.inputFocusBorder};
	}

	&:read-only {
		background-color: ${({ theme }) => theme.inputSecondayBackground};
		border-color: ${({ theme }) => theme.inputBorder};
	}

 	${({ error }) => error && css`
		color: ${({ theme }) => theme.statusDangerBase};
		border-color: ${({ theme }) => theme.statusDangerBase};

		:focus {
			border-color: ${({ theme }) => theme.statusDangerBase};
		}
	`}
`;

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
 	color: ${({ theme }) => theme.statusDangerBase};
 	max-width: 100%;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
 	}
`;
