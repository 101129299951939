/*
 * File: Routes.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { Navigate, matchPath } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../utilities/locale';

// Slice
import { initialState as initialLocale } from '../store/slices/locale/locale.slice';

// Navigation
import { AuthRouteWrapper } from '../navigation/AuthRouteWrapper';
import { PublicRouteWrapper } from '../navigation/PublicRouteWrapper';
import { UserRouteWrapper } from '../navigation/UserRouteWrapper';

// Components
import {
	Account,
	Artisans,
	Blog,
	BlogPost,
	BlogTopic,
	BlogSearch,
	CandleMaking,
	Cart,
	Collections,
	Contact,
	CorporateGifts,
	Error,
	Home,
	Mission,
	Presskit,
	ProductDetail,
	Retailers,
	SupplyChain,
	ContactPreferences,
	CookiePolicy,
	LegalOverview,
	PrivacyPolicy,
	SecurityPromise,
	Terms,
	ReturnRefundPolicy,
	Wholesale,
	ShopAccessories,
	ShopAllProducts,
	ShopSoaps,
	ShopSelfCare,
	ShopFavorites,
	ShopNewArrivals,
	ShopGiftCards,
	ShopGiftBundles,
	Search,
	SignUp,
	Subscription,
	Login,
	ReferFriend,
	ShopCandles,
	ShopBathBombs,
	StoreLocator,

	// WorkWithCatherina
} from './pages';
import { PRODUCT_COLLECTIONS, ROLES } from '../../Constants';


/**
 * Helpers
 */

const filterProps = (path, props) => {

	// If data is undefined, return props
	if (!props || !props.data) return props;

	// Search for route path
	const routes = Object.keys(props.data).filter((key) => key.startsWith('/'));
	const matchRoute = routes.find((route) => matchPath(path, route));

	// Check for exact match
	let exactMatch = true;
	if (typeof window !== 'undefined') {
		const fullPath = (props?.locale?.url?.fullPath !== '' ? props?.locale?.url?.fullPath?.replace(/\/$/, '') : props?.locale?.url?.fullPath) || '';
		let basePath = fullPath.startsWith(props?.locale?.localePath) ? fullPath.replace(props?.locale?.localePath, '') : fullPath;
		if (basePath === '') basePath = '/';
		exactMatch = fullPath === window.location.pathname || `${fullPath}/` === window.location.pathname || basePath === window.location.pathname;
	}

	// Return data
	return (props.data ? {
		...props,
		data: {
			...props?.data.global,
			...matchRoute && exactMatch ? props?.data[matchRoute] : undefined
		}
	} : props);
};

const prepareComponent = (routeObj) => (Array.isArray(routeObj) ? routeObj.map((route) => ({
	...route,
	component: route.component(route.path)
})) : {
	...routeObj,
	component: routeObj.component(routeObj.path)
});


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current user from props
	const userObj = props?.user;

	// Render routes
	return [].concat(...[

		/**
		 * Marketing Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.OUR_FAVORITES.id, limit: 4 } }
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Home
							{...filterProps(path, props)}
							meta={{
								title: '700 Rivers - Ethical, Sustainable Soaps, Candles, and More.',
								description: '700 Rivers is a global, ethical, and sustainable lifestyle brand that creates all-natural products that allow us to be kind to ourselves, to our planet, and to others.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/mission',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Mission
							{...filterProps(path, props)}
							meta={{
								title: 'Our Mission - 700 Rivers',
								description: 'Our mission is to create high-quality, eco-friendly products that employ women across the world who are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/our-artisans',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Artisans
							{...filterProps(path, props)}
							meta={{
								title: 'Our Artisans - 700 Rivers',
								description: 'We couldn\'t do what we do without the amazing women that bring our products to life.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/supply-chain',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<SupplyChain
							{...filterProps(path, props)}
							meta={{
								title: 'Our Supply Chain - 700 Rivers',
								description: 'At 700 Rivers, we believe in an ethical and transparent supply chain. Our soaps are made in an amazing country by even more amazing women.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/contact',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Contact
							{...filterProps(path, props)}
							meta={{
								title: 'Contact Us - 700 Rivers',
								description: '700 Rivers is a global, ethical, and sustainable lifestyle brand that creates all-natural products that allow us to be kind to ourselves, kind to others, and kind to our planet. Our mission is to create high-quality, eco-friendly products that employ women across the world that are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/seasonal-subscription',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Subscription
							{...filterProps(path, props)}
							meta={{
								title: '700 Rivers\' Seasonal Subscription',
								description: 'Subscribe to 700 Rivers and get your seasonal favorites delivered right to your door every three months.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/retailers',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Retailers
							{...filterProps(path, props)}
							meta={{
								title: 'For Retailers - 700 Rivers',
								description: 'Interested in carrying 700 Rivers soaps, candles, or bath bombs in your shop? Find us on Faire or email us to place a wholesale order.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/stores',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [
				{ function: 'retail/getRetailLocations', restrictToPath: true }
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<StoreLocator
							{...filterProps(path, props)}
							meta={{
								title: 'Find Us In Store - 700 Rivers',
								description: 'Find one of our boutique partners nearby that carries 700 Rivers products.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/wholesale',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Wholesale
							{...filterProps(path, props)}
							meta={{
								title: 'Wholesale Guide - 700 Rivers',
								description: 'Find information about available products, quantities, and options when buying 700 Rivers products for your shop.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/corporate-gifts',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<CorporateGifts
							{...filterProps(path, props)}
							meta={{
								title: 'Corporate Gifting - 700 Rivers',
								description: 'At 700 Rivers, we are honored to help you express that same appreciation to your clients, partners, or employees. Whether you\'re looking for meaningful corporate gifts or event favors, we can accommodate orders from 100 units to thousands.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/brand',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Presskit
							{...filterProps(path, props)}
							meta={{
								title: 'Brand & Presskit - 700 Rivers',
								description: 'We love our brand and want to share it. Check out our 700 Rivers brand guidelines, press images, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/search',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Search
							{...filterProps(path, props)}
							meta={{
								title: 'Search - 700 Rivers',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/refer-a-friend',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ReferFriend
							{...filterProps(path, props)}
							meta={{
								title: 'Refer-a-Friend to 700 Rivers',
								description: 'Like 700 Rivers? Love the Planet? Tell your friends about 700 Rivers and you\'ll get $10 off for each person who purchases — and they\'ll get $10 off their first order, too.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Business Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/candle-making-class',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<CandleMaking
							{...filterProps(path, props)}
							meta={{
								title: 'Schedule a 700 Rivers Candle-Making Class',
								description: 'Looking for a fun, engaging, and impact-driven activity for your team? 700 Rivers offers in-depth candle-making classes for small and large groups.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),

		/* prepareComponent(createLocaleVariants({
			path: '/work-with-catherina',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<WorkWithCatherina
							{...filterProps(path, props)}
							meta={{
								title: 'Catherina Gomes - Speaker, Consultant, Impact Entrepreneur',
								description: 'TODO',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})), */


		/**
		 * User Account Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/rewards/join',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<AuthRouteWrapper redirect="/account" user={userObj} locale={localeObj}>
						<SignUp
							{...filterProps(path, props)}
							meta={{
								title: 'Sign Up - 700 Rivers',
								description: 'Join 700 Rivers rewards to get special offers and collect points you can use towards new products!',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</AuthRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/rewards/login',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Login
							{...filterProps(path, props)}
							meta={{
								title: 'Log In - 700 Rivers',
								description: 'Get started with your 700 Rivers reward points and special offers!',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/account',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.STANDARD]} user={userObj} locale={localeObj}>
						<Account
							{...filterProps(path, props)}
							meta={{
								title: 'My Rewards - 700 Rivers',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Blog Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/blog',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogPosts', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Blog
							{...filterProps(path, props)}
							meta={{
								title: 'The 700 Rivers Blog',
								description: 'The best place to stay plugged in with the latest 700 Rivers stories, news, and events.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/story/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogPost', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogPost
							{...filterProps(path, props)}
							meta={{
								title: '{{story.title}} - The 700 Rivers Blog',
								description: '{{story.description}}',
								image: '{{story.featureImage.url}}',
								bodyClasses: 'hide-captcha',
								ogType: 'article',
								showArticle: true,
								fbSiteName: 'https://www.facebook.com/The700Rivers',
								author: '{{story.author.name}}',
								publishedTime: '{{story.publishedTime}}',
								modifiedTime: '{{story.modifiedTime}}',
								ogTitle: '{{story.metaTitle}}',
								twTitle: '{{story.metaTitle}}',
								ogDescription: '{{story.metaDescription}}',
								twDescription: '{{story.metaDescription}}',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/blog/topic/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'blog/getBlogTopic', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogTopic
							{...filterProps(path, props)}
							meta={{
								title: '{{topic.title}} - The 700 Rivers Blog',
								description: 'Explore updates and stories about {{topic.title}} on the 700 Rivers Blog',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/blog/search',
			exact: true,
			shouldIndex: false,
			isDynamic: true,
			functions: [
				{ function: 'blog/getBlogPosts', restrictToPath: true },
				{ function: 'blog/getBlogTopics', restrictToPath: false },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<BlogSearch
							{...filterProps(path, props)}
							meta={{
								title: 'Results for "{{searchQuery}}" - The 700 Rivers Blog',
								description: 'Explore updates and stories about "{{searchQuery}}" on the 700 Rivers Blog',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Product Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/shop/all',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.ALL_PRODUCTS.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.ALL_PRODUCTS.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopAllProducts
							{...filterProps(path, props)}
							meta={{
								title: 'All Products - 700 Rivers',
								description: 'Our products are handcrafted by women that have escaped human trafficking and are now paid fair, living wages for their work.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/soaps',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SOAPS.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SOAPS.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopSoaps
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Soaps - 700 Rivers',
								description: 'Our all-natural soaps are handmade by women in Bangladesh that been granted a fresh start at life after escaping human trafficking.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/candles',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.CANDLES.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.CANDLES.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopCandles
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Candles - 700 Rivers',
								description: 'Shine bright and spread the light with 700 Rivers candles! Focus on mindfulness and self-care with your choice of 700 Rivers signature scents.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/bath-bombs',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.BATH_BOMBS.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.BATH_BOMBS.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopBathBombs
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Bath Bombs - 700 Rivers',
								description: 'Treat yourself to a day of well-being and enjoy the spa-like experience of a 700 Rivers Bath Bomb.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/self-care',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SELF_CARE.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SELF_CARE.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopSelfCare
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Self Care - 700 Rivers',
								description: 'Focus on mindfulness and self-care with your choice of 700 Rivers signature candles and bath bombs.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/new-arrivals',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.NEW_ARRIVALS.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.NEW_ARRIVALS.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopNewArrivals
							{...filterProps(path, props)}
							meta={{
								title: 'Shop New Arrivals - 700 Rivers',
								description: 'Shop our limited edition scents that you can only find here (while they last, that is.)',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/favorites',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.OUR_FAVORITES.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.OUR_FAVORITES.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopFavorites
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Our Favorites - 700 Rivers',
								description: 'Shop products loved by our team and our community.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/gift-cards',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.GIFT_CARDS.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.GIFT_CARDS.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopGiftCards
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Gift Cards - 700 Rivers',
								description: 'Give the gift of 700 Rivers to a special someone in your life.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/soap-candle-accessories',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SOAP_CANDLE_ACCESSORIES.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.SOAP_CANDLE_ACCESSORIES.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopAccessories
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Soap & Candle Accessories - 700 Rivers',
								description: 'Pick up the perfect products to accompany your 700 Rivers soaps and signature candles.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/shop/gift-bundles',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.GIFT_BUNDLES.handle } },
				{ function: 'product/getProductsForCollection', restrictToPath: true, params: { id: PRODUCT_COLLECTIONS.GIFT_BUNDLES.id } },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ShopGiftBundles
							{...filterProps(path, props)}
							meta={{
								title: 'Shop Curated Gift Bundles - 700 Rivers',
								description: 'Elevate your gifting with an ethical, curated 700 Rivers gift bundles.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/products/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getProduct', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ProductDetail
							{...filterProps(path, props)}
							meta={{
								title: '{{product.title}} - 700 Rivers',
								description: '{{product.description}}',
								image: '{{product.primaryImage.url}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/collections',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Collections
							{...filterProps(path, props)}
							meta={{
								title: 'Our Collections - 700 Rivers',
								description: 'Shop all 700 Rivers soaps, candles, bath bombs, and more. Each purchase makes a difference!',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Cart Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/cart',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Cart
							{...filterProps(path, props)}
							meta={{
								title: 'Your Shopping Cart - 700 Rivers',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * General Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/contact-preferences/:token',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<ContactPreferences
							{...filterProps(path, props)}
							meta={{
								title: 'Update Preferences - 700 Rivers',
								description: 'Update your 700 Rivers contact preferences.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Legal Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/legal',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<LegalOverview
							{...filterProps(path, props)}
							meta={{
								title: 'Legal - 700 Rivers',
								description: '700 Rivers\'s Terms and Policies, including security guidelines, Terms of Service, cookie policies, privacy, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/cookie-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<CookiePolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Cookie Policy - 700 Rivers',
								description: 'Our Cookie Policy explains what these technologies are and why we use them, as well as your rights to control our use of them.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/privacy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<PrivacyPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Privacy Policy - 700 Rivers',
								description: 'We care about your privacy. Our privacy policy will help you understand what information is collected, how it\'s used, and what choices you have.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/security',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<SecurityPromise
							{...filterProps(path, props)}
							meta={{
								title: 'Security Promise - 700 Rivers',
								description: 'At 700 Rivers, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/terms',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<Terms
							{...filterProps(path, props)}
							meta={{
								title: 'Terms of Service - 700 Rivers',
								description: 'Our Terms of Service detail and clarify your rights and responsibilities when using 700 Rivers.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/refund-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<ReturnRefundPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Return & Refund Policy - 700 Rivers',
								description: 'Our Return & Refund Policy details and clarify your rights when requesting a refund for a 700 Rivers purchase.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Redirects
		 */

		prepareComponent(createLocaleVariants({
			path: '/collections/bath-bombs',
			exact: true,
			shouldIndex: false,
			component() {
				return (<Navigate to={`${localeObj.localePath}/shop/bath-bombs`} />);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<Error
						code={404}
						{...filterProps(path, props)}
						locale={localeObj}
						meta={{
							title: 'Page Not Found - 700 Rivers',
							description: 'Whoops! We can\'t seem to find the page you\'re looking for.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})
	].filter(Boolean));
};
