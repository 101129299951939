/*
 * File: ProductRow.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 10:17 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 15, 2023 at 6:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'beautiful-react-hooks';

// Utilities
import { formatCost } from '../../../utilities/cost';
import { toastError } from '../../../utilities/toaster';
import { optimizeImage } from '../../../utilities/product';
import { recordGTMEcommerceEvent } from '../../../utilities/analytics';

// Slices
import { updateCart as updateCartSlice } from '../../../store/slices/cart/cart.slice';

// Services
import { updateCart } from '../../../services/cart';

// Components
import { Typography } from '../../Typography';
import { TextInput } from '../../TextInput';
import { Button } from '../../Button';

// Styles
import * as S from './ProductRow.styles';


/**
 * Component
 */

export const ProductRow = ({
	product, isHeader, isSmall, allowActions
}) => {

	// Get state handlers
	const [quantity, setQuantity] = useState(product?.quantity || 1);

	// Use hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update product quantity function
	const handleChangeQuantity = useDebouncedCallback(async (e) => {

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({ products: [{ id: product.id, variantId: product.selectedVariant.id, quantity: e.target.value }] });

			// Update cart
			dispatch(updateCartSlice(cart));

		} catch (error) {}
	}, [], 400);

	// Handle remove product function
	const handleRemoveProduct = async () => {

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({ products: [{ id: product.id, variantId: product.selectedVariant.id, quantity: 0 }] });

			// Record GTM event
			recordGTMEcommerceEvent('remove_from_cart', {
				currency: 'USD',
				value: product.numericPrice,
				items: [
					{
						item_id: `${product.id.replace('gid://shopify/Product/', '')}-${product.variantId.replace('gid://shopify/ProductVariant/', '')}`,
						item_name: product.title,
						index: 0,
						affiliation: product.vendor || '700 Rivers',
						item_brand: product.vendor || '700 Rivers',
						price: product.numericPrice,
						quantity: product.quantity,
						...product.productType ? {
							item_category: product.productType
						} : undefined,
						...product.tags && product.tags.length > 0 ? {
							item_category2: product.tags[0]
						} : undefined,
						...product.tags && product.tags.length > 1 ? {
							item_category3: product.tags[1]
						} : undefined,
						...product.tags && product.tags.length > 2 ? {
							item_category4: product.tags[2]
						} : undefined,
						...product.tags && product.tags.length > 3 ? {
							item_category5: product.tags[3]
						} : undefined,
						...product.selectedVariant && product.selectedVariant.title && product.selectedVariant.title !== 'Default Title' ? {
							item_variant: product.selectedVariant.title,
						} : undefined
					}
				]
			});

			// Update cart
			dispatch(updateCartSlice(cart));

		} catch (error) {

			// Show error message
			toastError(uiMode, 'We\'re having trouble removing this product from your cart. Give it another go.');
		}
	};

	// Handle cart change action
	useEffect(() => {

		// Update product quantity
		setQuantity(product?.quantity || 1);

	}, [product]);

	// Render component
	return (
		<S.ProductRow isHeader={isHeader}>

			{/* Image */}
			{isHeader
				? (
					<S.GrowRow>
						<Typography weight="light">Product</Typography>
					</S.GrowRow>
				)
				: <S.ProductImage image={optimizeImage(product?.selectedVariant?.image?.url || product.images?.[0]?.url, 600)} ratio={0.75} />}

			{/* Name */}
			{!isHeader && (
				<S.GrowRow>
					<Typography tag="p">{product.title}</Typography>
					{product.variants.length > 1 && product.selectedVariant != null && <Typography tag="p" variation="3" className="productVariant">{product.selectedVariant.title}</Typography>}
					{allowActions && (
						<Button variant="text" onClick={handleRemoveProduct}>
							<Typography variation="button-small" weight="medium">REMOVE</Typography>
						</Button>
					)}
				</S.GrowRow>
			)}

			{/* Cost */}
			<S.CostRow>
				{isHeader
					? <Typography weight="light">Cost</Typography>
					: (
						<Typography tag="p" weight="semibold">
							$
							{formatCost(product.selectedVariant?.price?.amount)}
						</Typography>
					)}
			</S.CostRow>

			{/* Quantity */}
			<S.QuantityRow>
				{isHeader
					? <Typography weight="light" className={isSmall ? 'alignRight' : 'alignLeft'}>Quantity</Typography>
					: (
						<div>
							{allowActions ? (
								<TextInput
									type="number"
									value={quantity}
									onChange={(e) => {
										setQuantity(e.target.value > 0 ? e.target.value : 1);
										handleChangeQuantity(e);
									}}
								/>
							) : <Typography tag="p" weight="semibold" className={isSmall ? 'alignRight' : 'alignLeft'}>{quantity}</Typography>}
						</div>
					)}
			</S.QuantityRow>

			{/* Total Cost */}
			{!isSmall && (
				<S.TotalRow>
					{isHeader
						? <Typography weight="light">Total</Typography>
						: (
							<Typography tag="p" weight="semibold">
								$
								{formatCost(quantity * (product.selectedVariant?.price?.amount || 0))}
							</Typography>
						)}
				</S.TotalRow>
			)}
		</S.ProductRow>
	);
};


/**
 * Configuration
 */

ProductRow.displayName = 'ProductRow';
ProductRow.propTypes = {
	product: PropTypes.shape(),
	isHeader: PropTypes.bool,
	isSmall: PropTypes.bool,
	allowActions: PropTypes.bool
};
ProductRow.defaultProps = {
	product: null,
	isSmall: false,
	isHeader: false,
	allowActions: true
};
