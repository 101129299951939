/*
 * File: Artisans.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Card, LocaleLink, Padding } from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, mobileBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentSection = styled.div`
	padding: 80px 0px;
	margin: 0px 0px;
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 50%;
	text-align: left;
	margin: 0px auto;
	width: 88%;
	padding: 100px 0px;

	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 8rem;
	}
	h4 {
		margin: 12px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	p {
		margin: 20px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const CompactContentPanel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 50%;
	gap: 100px;
	background-color: ${ColorValues.white};
	padding: 60px;
	max-width: 1100px;
	margin: 0px auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: 50%;
`;

export const VideoPanel = styled.div`
	position: relative;
	flex-shrink: 0;
`;

export const DetailPanel = styled.div`
	position: relative;
	flex: 50%;

	* {
		text-align: left;
	}
	p {
		margin: 15px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h4 {
		margin: 15px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;

export const HeroImage = styled.img`
	width: 100%;
`;

export const Content = styled.div`
	max-width: 600px;
	width: 100%;
	margin: ${({ center }) => (center ? '0px auto' : '0px 0px')};

	* {
		text-align: ${({ center }) => (center ? 'center' : 'left')};
	}
	.subtitle {
		color: ${({ theme }) => theme.secondaryText};
		margin: 7px 0px 0px;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const VideoElement = styled.video`
	width: 100%;
	max-height: 600px;
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
		margin: 10px 0px 0px;
	}
`;

export const BenefitsGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 34px;
	margin: 50px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const BenefitBlock = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 6px 0px 0px;
	}
	* {
		text-align: left;
	}
`;

export const LinkContainer = styled(LocaleLink)`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 0px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const ImageBannerSection = styled.div`
	display: flex;
	width: 100%;
	height: 1500px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		height: 500px;
		width: 100%;
	}
`;

export const ImageBanner = styled.div`
	flex: 33.333%;
	height: 100%;
	flex-grow: 0;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
