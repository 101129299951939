/*
 * File: ProductRow.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 10:17 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 25, 2023 at 9:57 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { ProductImage as BaseImage } from '../../ProductImage';


/**
 * Styles
 */

export const ProductRow = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;

	${({ isHeader, theme }) => (isHeader ? css`
		padding: 0px 0 15px;
 	` : css`
	 	padding: 30px 0;
		border-top: 1px solid ${theme.secondaryDecoration};
	`)}

	p {
		text-align: left;
	}
	span {
		color: ${({ theme }) => theme.secondaryText};
	}
	.alignRight {
		display: block;
		text-align: right !important;
	}
	.alignLeft {
		display: block;
		text-align: left !important;
	}
	.productVariant {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const GrowRow = styled.div`
	flex-grow: 1;

	button {
		padding: 0px;
		margin: 5px 0 0;

		span {
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
`;

export const QuantityRow = styled.div`
	width: 80px;
	flex-shrink: 0;

	input {
		width: 100%;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		opacity: 1;
	}
`;

export const CostRow = styled.div`
	width: 80px;
	flex-shrink: 0;
`;

export const TotalRow = styled.div`
	width: 100px;
	flex-shrink: 0;

	p,
	span {
		display: block;
		text-align: right;
	}
`;

export const ProductImage = styled(BaseImage)`
	max-width: 108px;
	height: 81px;
	padding: 0px;
`;
