/*
 * File: ProductDetail.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 25, 2023 at 8:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { getDataStateForPage } from '../../../utilities/state';
import { toastError } from '../../../utilities/toaster';
import { formatCost } from '../../../utilities/cost';
import { scrollPageTo } from '../../../utilities/position';
import { formatOfferDate, optimizeImage } from '../../../utilities/product';
import { buildDynamicMeta } from '../../../components/Meta';
import { createStateLocale } from '../../../utilities/locale';
import { recordGTMEcommerceEvent } from '../../../utilities/analytics';

// Services
import { updateCart } from '../../../services/cart';
import { getProduct } from '../../../services/product';

// Slices
import { pushComponentState } from '../../../store/slices/component/component.slice';
import { updateCart as updateCartSlice } from '../../../store/slices/cart/cart.slice';

// Components
import {
	Meta, AppNavigation, ErrorComponent, Spinner, ProductImage, Typography, SuggestedProducts, SchemaScript, Select, Button, ShareWidget
} from '../../../components';

// Constants
import { SHARE_OPTIONS } from '../../../../Constants';
import { navigationDropdownDelay } from '../../../styles/constants';

// Styles
import * as S from './ProductDetail.styles';


/**
 * State
 */

let shareWidgetTimer = null;


/**
 * Component
 */

const ProductDetail = ({ meta, locale, data }) => {

	// Get component parameters
	const { slug } = useParams();

	// Get state handlers
	const [pageStatus, setPageStatus] = useState(data?.isError ? 'error' : 'idle');
	const [componentData, setComponentData] = useState(data || { product: { title: 'Product' } });
	const [primaryImage, setPrimaryImage] = useState(data?.product?.images?.[0]?.url);
	const [selectedVariantId, setSelectedVariantId] = useState((data?.product?.variants?.length || 0) > 1 ? null : data?.product?.variants?.[0]?.id);
	const [selectedVariant, setSelectedVariant] = useState((data?.product?.variants?.length || 0) > 1 ? null : data?.product?.variants?.[0]);
	const [showShareWidget, setShowShareWidget] = useState(false);
	const [displayPrice, setDisplayPrice] = useState((data?.product?.variants?.length || 0) > 1 ? data?.product?.displayPrice : `$${formatCost(data?.product?.variants?.[0]?.price.amount)}`);

	// Create references
	const navigationRef = useRef();
	const isMounted = useRef(true);
	const enableDynamicLoad = useRef(false);

	// Use hooks
	const dispatch = useDispatch();

	// Get current component data state
	const componentState = useSelector((state) => (state.component));

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Update primary image
	const updatePrimaryImage = (image) => {
		setPrimaryImage(image?.url);
	};

	// Handle add to cart function
	const addToCart = async (e) => {

		// Prevent default action
		e.preventDefault();

		// Update cart
		try {

			// Get product
			const product = componentData?.product;

			// Update cart
			const { data: { cart } } = await updateCart({ products: [{ id: product.id, variantId: selectedVariantId, add: 1 }] });

			// Get current variant
			const currentVariant = product?.variants?.length === 1 ? product?.variants[0] : null;

			// Create item id
			let itemId = `${product.id.replace('gid://shopify/Product/', '')}`;
			if (currentVariant) itemId = `${itemId}-${currentVariant.id.replace('gid://shopify/ProductVariant/', '')}`;

			// Record GTM event
			recordGTMEcommerceEvent('add_to_cart', {
				currency: 'USD',
				value: product.numericPrice,
				items: [
					{
						item_id: itemId,
						item_name: product.title,
						index: 0,
						affiliation: product.vendor || '700 Rivers',
						item_brand: product.vendor || '700 Rivers',
						price: product.numericPrice,
						quantity: 1,
						...product.productType ? {
							item_category: product.productType
						} : undefined,
						...product.tags && product.tags.length > 0 ? {
							item_category2: product.tags[0]
						} : undefined,
						...product.tags && product.tags.length > 1 ? {
							item_category3: product.tags[1]
						} : undefined,
						...product.tags && product.tags.length > 2 ? {
							item_category4: product.tags[2]
						} : undefined,
						...product.tags && product.tags.length > 3 ? {
							item_category5: product.tags[3]
						} : undefined,
						...currentVariant && currentVariant.title && currentVariant.title !== 'Default Title' ? {
							item_variant: currentVariant.title,
						} : undefined
					}
				]
			});

			// Update cart
			dispatch(updateCartSlice(cart));

			// Open cart sidebar
			navigationRef.current.openCart();

		} catch (error) {

			// Show error message
			toastError(uiMode, 'We\'re having trouble adding this product to your cart. Give it another go.');
		}
	};

	// Record view
	const recordView = (product) => {

		// Get current variant
		const currentVariant = product?.variants?.length === 1 ? product?.variants[0] : null;

		// Create item id
		let itemId = `${product.id.replace('gid://shopify/Product/', '')}`;
		if (currentVariant) itemId = `${itemId}-${currentVariant.id.replace('gid://shopify/ProductVariant/', '')}`;

		// Record GTM event
		recordGTMEcommerceEvent('view_item', {
			currency: 'USD',
			value: product.numericPrice,
			items: [
				{
					item_id: itemId,
					item_name: product.title,
					index: 0,
					affiliation: product.vendor || '700 Rivers',
					item_brand: product.vendor || '700 Rivers',
					price: product.numericPrice,
					quantity: 1,
					...product.productType ? {
						item_category: product.productType
					} : undefined,
					...product.tags && product.tags.length > 0 ? {
						item_category2: product.tags[0]
					} : undefined,
					...product.tags && product.tags.length > 1 ? {
						item_category3: product.tags[1]
					} : undefined,
					...product.tags && product.tags.length > 2 ? {
						item_category4: product.tags[2]
					} : undefined,
					...product.tags && product.tags.length > 3 ? {
						item_category5: product.tags[3]
					} : undefined,
					...currentVariant && currentVariant.title && currentVariant.title !== 'Default Title' ? {
						item_variant: currentVariant.title,
					} : undefined
				}
			]
		});
	};

	// Initialize data
	const initializeData = async () => {

		// Get data state for page
		getDataStateForPage(componentState, setComponentData, async ({ requiresLoad, isError, data: stateData }) => {
			if (isError) {

				// Update page status
				if (isMounted.current) setPageStatus('error');

			} else if (requiresLoad && pageStatus === 'idle') {

				// Update page status
				if (isMounted.current) setPageStatus('loading');

				// Initialize component data
				try {
					const { data: productData } = await getProduct(slug);

					// Check if mounted
					if (isMounted.current) {

						// Set product variant
						setSelectedVariantId(productData?.product?.variants?.[0]?.id);
						setSelectedVariant(productData?.product?.variants?.[0]);

						// Set primary image
						setPrimaryImage(productData?.product?.images?.[0]?.url);

						// Set display price
						setDisplayPrice(`$${formatCost(productData?.product?.variants?.[0]?.price.amount)}`);

						// Set component data
						setComponentData(productData);

						// Push new component state
						dispatch(pushComponentState(productData));

						// Record view
						recordView(productData?.product);

						// Update page status
						setPageStatus('success');
					}
				} catch (e) {

					// Update page status
					if (isMounted.current) setPageStatus('error');
				}
			} else if (!requiresLoad && pageStatus === 'idle') {
				if (isMounted.current) {

					// Update page status
					setPageStatus('success');

					// Set product variant
					setSelectedVariantId(stateData?.product?.variants?.[0]?.id);
					setSelectedVariant(stateData?.product?.variants?.[0]);

					// Set primary image
					setPrimaryImage(stateData?.product?.images?.[0]?.url);

					// Set display price
					setDisplayPrice(`$${formatCost(stateData?.product?.variants?.[0]?.price.amount)}`);

					// Initialize component data
					setComponentData(stateData);

					// Record view
					recordView(stateData?.product);
				}
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		if (name === 'variantId') {

			// Set selected variant id
			setSelectedVariantId(value);

			// Set primary image from variant
			const variant = (componentData?.product?.variants || []).find((v) => v.id === value);
			if (variant && variant.image) {
				setPrimaryImage(variant.image.url);
			}

			// Set selected variant
			setSelectedVariantId(variant);

			// Set display price
			if (variant && variant.price) {
				setDisplayPrice(`$${formatCost(variant.price.amount)}`);
			}
		}
	};

	// Handle component initialization
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Initialize data
			initializeData();
		}
	}, [pageStatus, componentState]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Only change if component state finalized
		if (componentState && componentState.status === 'final') {

			// Set page status if necessary
			if (pageStatus !== 'idle') {

				// Reset page status
				setPageStatus('idle');

			} else if (enableDynamicLoad.current === true) {

				// Initialize data
				initializeData();
			}
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [slug]);

	// Handle actions on user change
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Enable dynamic load
		enableDynamicLoad.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component function
	const renderComponent = () => {

		// Return error component if necessary
		if (pageStatus === 'error') {
			return <ErrorComponent />;
		}

		// Return loading component if necessary
		if (pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		}

		// Render component
		return (
			<>
				{/* Schema.org Product */}
				<SchemaScript schema={{
					'@context': 'http://schema.org/',
					'@type': 'Product',
					'@id': `${process.env.APP_URL}/products/${componentData?.product?.handle}/#Product`,
					name: componentData?.product?.title,
					url: `${process.env.APP_URL}/products/${componentData?.product?.handle}`,
					image: componentData?.product?.images?.map((image) => image.url),
					description: componentData?.product?.description,
					brand: {
						'@type': 'Brand',
						name: '700 Rivers'
					},
					mpn: componentData?.product?.variants?.[0]?.mpn,
					gtin: '',
					sku: componentData?.product?.variants?.[0]?.sku,
					productID: componentData?.product?.handle,
					category: componentData?.product?.typeName,
					isFamilyFriendly: true,
					offers: {
						'@type': 'Offer',
						availability: 'http://schema.org/InStock',
						price: componentData?.product?.numericPrice != null ? componentData?.product?.numericPrice : componentData?.product?.displayPrice,
						priceCurrency: 'USD',
						itemCondition: 'https://schema.org/NewCondition',
						priceValidUntil: formatOfferDate(),
						url: `${process.env.APP_URL}/products/${componentData?.product?.handle}`
					},
					potentialAction: [
						{
							'@type': 'BuyAction',
							target: `${process.env.APP_URL}/products/${componentData?.product?.handle}`,
						},
						{
							'@type': 'ReviewAction',
							target: `${process.env.APP_URL}/products/${componentData?.product?.handle}`,
						},
						{
							'@type': 'ShareAction',
							target: `${process.env.APP_URL}/products/${componentData?.product?.handle}`,
						}
					]
				}}
				/>

				{/* Schema.org ItemPage */}
				<SchemaScript schema={{
					'@context': 'http://schema.org/',
					'@type': 'ItemPage',
					'@id': `${process.env.APP_URL}/products/${componentData?.product?.handle}/#WebPage`,
					name: buildDynamicMeta(meta.title, componentData),
					description: buildDynamicMeta(meta.description, componentData),
					url: `${process.env.APP_URL}/products/${componentData?.product?.handle}`,
					inLanguage: stateLocale.localeBaseId,
					isPartOf: {
						'@id': `${process.env.APP_URL}/#WebSite`
					},
					publisher: {
						'@id': 'https://www.sevenhundredrivers.com/#Organization',
					},
					mainEntity: {
						'@id': `${process.env.APP_URL}/products/${componentData?.product?.handle}/#Product`,
					}
				}}
				/>

				{/* Product */}
				<S.ProductWrapper>

					{/* Details */}
					<S.DetailContainer>

						{/* Left Detail */}
						<S.LeftDetail>

							{/* Main Product Image */}
							<ProductImage image={optimizeImage(primaryImage, 1200)} ratio={0.7} />

							{/* Product Image Collection */}
							{componentData?.product?.images?.length > 1 && (
								<S.ProductImageCollection>
									{componentData?.product?.images?.map((image) => (
										<S.PreviewImage
											key={image.url}
											className="animate"
											$image={optimizeImage(image.url, 300)}
											onClick={() => { updatePrimaryImage(image); }}
										/>
									))}
								</S.ProductImageCollection>
							)}
						</S.LeftDetail>

						{/* Right Detail */}
						<S.RightDetail>

							{/* Product Details */}
							<S.ProductDetails>
								<Typography tag="p" variation="3" weight="regular" className="secondary">
									700 RIVERS
								</Typography>
								<Typography tag="h1" weight="semibold">
									{componentData?.product?.title}
								</Typography>
								<Typography tag="p" weight="light">
									{displayPrice}
								</Typography>
							</S.ProductDetails>

							{/* Product Options */}
							<S.ProductOptionContainer>
								{(componentData?.product?.variants?.length || 0) > 1 && (
									<Select
										label="Select an option"
										name="variantId"
										options={(componentData?.product?.variants || []).map((variant) => ({
											value: variant.id,
											label: `${variant.title} - $${formatCost(variant.price.amount)}`
										}))}
										values={Array.isArray(selectedVariantId) ? selectedVariantId : [selectedVariantId || '']}
										containerClassName="formInput"
										onChange={handleOnChange}
									/>
								)}
							</S.ProductOptionContainer>

							{/* Purchase Action */}
							<S.ButtonContainer>
								{componentData?.product?.availableForSale && componentData?.product?.variants?.[0]?.availableForSale && (!componentData?.product?.variants?.[0]?.currentlyNotInStock || componentData?.product?.isGiftCard) ? (
									<S.Button onClick={addToCart} disabled={!selectedVariantId}>
										<Typography variation="button-small" weight="semibold">ADD TO CART</Typography>
									</S.Button>
								) : (
									<S.Button variation="secondary" variant="outline" style={{ cursor: 'default' }}>
										<Typography variation="button-small" weight="semibold">OUT OF STOCK</Typography>
									</S.Button>
								)}
							</S.ButtonContainer>

							{/* Share Action */}
							<S.ShareWidgetContainer
								onMouseEnter={() => {
									clearTimeout(shareWidgetTimer);
									setShowShareWidget(true);
								}}
								onMouseLeave={() => {
									clearTimeout(shareWidgetTimer);
									shareWidgetTimer = setTimeout(() => {
										setShowShareWidget(false);
									}, navigationDropdownDelay);
								}}
							>
								<Button variant="text">
									<FontAwesomeIcon icon={['fass', 'share']} />
									<Typography variation="button-small" weight="semibold">SHARE</Typography>
								</Button>
								<ShareWidget
									className={showShareWidget ? 'shareWidget animate show' : 'shareWidget animate'}
									adjustOnMobile
									fromBottom
									closeWidget={() => { setShowShareWidget(false); }}
									content={{
										title: `${componentData?.product?.title} on 700 Rivers`,
										description: componentData?.product?.description,
										url: `${process.env.APP_URL}/products/${componentData?.product?.handle}`
									}}
									items={[
										SHARE_OPTIONS.FACEBOOK,
										SHARE_OPTIONS.TWITTER,
										SHARE_OPTIONS.LINKEDIN,
										SHARE_OPTIONS.REDDIT,
										SHARE_OPTIONS.PINTEREST,
										SHARE_OPTIONS.EMAIL
									]}
								/>
							</S.ShareWidgetContainer>

							{/* Made With */}
							<S.MadeWith>
								<Typography tag="p" variation="1" weight="light" className="fullWidth">
									Designed with
									{' '}
									<FontAwesomeIcon icon={['fass', 'heart']} className="heart" />
									{' '}
									in Raleigh, NC
								</Typography>
							</S.MadeWith>

							{/* Description */}
							<S.DescriptionContainer className="paragraphContent" dangerouslySetInnerHTML={{ __html: componentData?.product?.descriptionHtml }} />

							{/* Bundle Products */}
							{selectedVariant?.isBundle && (
								<S.BundleContainer>

									{/* Title Section */}
									<S.BundleTitle>
										<Typography tag="h3" weight="bold">Included in this bundle</Typography>
									</S.BundleTitle>

									{/* Bundle Products */}
									<S.BundleProducts>
										{selectedVariant?.bundleProducts?.map((bundleProduct) => (
											<S.BundleLink
												key={bundleProduct.product?.id}
												to={`/products/${bundleProduct.product?.handle}`}
											>
												<S.BundleProduct>
													<S.BundleImage $image={bundleProduct.product?.images?.[0]?.url} />
													<Typography tag="h5" weight="light">
														{bundleProduct.quantity}
														{' '}
														x
														{' '}
														{bundleProduct.product.title}
													</Typography>
												</S.BundleProduct>
											</S.BundleLink>
										))}
									</S.BundleProducts>
								</S.BundleContainer>
							)}

						</S.RightDetail>
					</S.DetailContainer>

					{/* Suggested Products */}
					<SuggestedProducts product={componentData?.product} />

				</S.ProductWrapper>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={componentData} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation ref={navigationRef}>
				<S.Wrapper>
					{renderComponent()}
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ProductDetail.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
ProductDetail.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default ProductDetail;
