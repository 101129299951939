/*
 * File: Collections.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 11, 2023 at 11:07 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography
} from '../../../components';

// Styles
import * as S from './Collections.styles';


/**
 * Component
 */

const Collections = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Title Section */}
					<S.TitleSection>
						<Typography tag="h1" weight="semibold">All Collections</Typography>
					</S.TitleSection>

					{/* Collection Selectors */}
					<S.CollectionSelectors>

						{/* Our Favorites */}
						<S.CollectionSelector to="/shop/favorites" $image={`${process.env.CDN_URL}/public/images/orange-clove-candles.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Our Favorites</Typography>
						</S.CollectionSelector>

						{/* Soaps */}
						<S.CollectionSelector to="/shop/soaps" $image={`${process.env.CDN_URL}/public/images/soaps-group-jute.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Fresh Start Soaps</Typography>
						</S.CollectionSelector>

						{/* Signature Candles */}
						<S.CollectionSelector to="/shop/candles" $image={`${process.env.CDN_URL}/public/images/web-f9-700-Rivers_r1_03D_4180.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Signature Candles</Typography>
						</S.CollectionSelector>

						{/* Soap & Candle Accessories */}
						<S.CollectionSelector to="/shop/soap-candle-accessories" $image={`${process.env.CDN_URL}/public/images/gold-wick trimmer.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Soap & Candle Accessories</Typography>
						</S.CollectionSelector>

						{/* Bath Bombs */}
						<S.CollectionSelector to="/shop/bath-bombs" $image={`${process.env.CDN_URL}/public/images/CalmingLavender_4d283744-42ad-41b1-8e10-3aa8033bd8da.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Bath Bombs</Typography>
						</S.CollectionSelector>

						{/* Curated Gift Bundles */}
						<S.CollectionSelector to="/shop/gift-bundles" $image={`${process.env.CDN_URL}/public/images/hands-holding-soap-banner.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">Curated Gift Bundles</Typography>
						</S.CollectionSelector>

						{/* New Arrivals */}
						<S.CollectionSelector to="/shop/new-arrivals" $image={`${process.env.CDN_URL}/public/images/web-f-700-Rivers_r1_03D_4058.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">New Arrivals</Typography>
						</S.CollectionSelector>

						{/* All Products */}
						<S.CollectionSelector to="/shop/all" $image={`${process.env.CDN_URL}/public/images/web-9-700-Rivers_r1_03D_4121.jpg`}>
							<S.SelectorBackground />
							<Typography tag="h4" weight="medium">All Products</Typography>
						</S.CollectionSelector>

					</S.CollectionSelectors>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Collections.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Collections.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Collections;
