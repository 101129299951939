/*
 * File: StoreLocator.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink, Padding } from '../../../components';

// Constants
import { mediumBreakpoint } from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: relative;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		height: 750px;
	}
`;

export const LocatorPadding = styled(Padding)`
	position: relative;
	display: block;
	height: 600px;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-top: -170px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		height: 100%;
		margin-top: 0px;
	}
`;

export const TitleBanner = styled.div`
	text-align: left;
	padding: 30px;
	z-index: 1000;
	position: relative;
	background-color: ${({ theme }) => theme.cardBackground};
	box-shadow: ${ColorValues.navigationShadow};
	border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};

	h3 {
		text-align: left;
		font-family: 'Unna', Georgia, Times, serif;
		margin: -5px 0px 0px;
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
		margin: -5px 0px 15px;
	}
`;

export const StoreLocator = styled.div`
	z-index: 2;
	position: relative;
	max-width: 100%;
	width: 100%;
	background-color: ${({ theme }) => theme.cardBackground};
	height: 100%;
	box-shadow: ${ColorValues.elementShadow};
	overflow: scroll;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 350px;
	}
`;

export const MapContainer = styled.div`
	width: 100%;
	height: 400px;
	position: relative;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 1;
	}
`;

export const CardContainer = styled.div`
	background-color: ${({ theme }) => theme.cardBackground};
`;

export const RetailCard = styled.div`
	padding: 24px 30px;
	text-align: left;
	border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	cursor: pointer;
	position: relative;

	* {
		text-align: left;
	}
	h4 {
		font-family: 'Unna', Georgia, Times, serif;
		line-height: 1.2;
		margin: 0px 0px 5px;
	}
	button {
		margin: 14px 0px 0px;
		width: 100%;
	}
`;

export const WebsiteLink = styled(LocaleLink)`
	text-decoration: none;
`;

export const SelectedContainer = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;

	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;
