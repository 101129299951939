/*
 * File: analytics.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on November 15, 2023 at 11:18 AM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 15, 2023 at 4:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Constants
 */

// State
const isProduction = process.env.ENV === 'production';


/**
 * Utilities
 */

export const recordGTMEvent = (action, data) => {

	// Ensure window is defined
	if (isProduction && typeof window !== 'undefined') {

		// Fire GTM event
		window.dataLayer.push({
			...data,
			event: action,
		});
	}
};

export const recordGTMEcommerceEvent = (action, data) => {

	// Ensure window is defined
	if (isProduction && typeof window !== 'undefined') {

		// Fire GTM event
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object
		window.dataLayer.push({
			event: action,
			ecommerce: {
				...data
			}
		});
	}
};
