/*
 * File: fontawesome.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 9:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faCircleUser,
	faTimes,
	faHandWave,
	faSunBright,
	faWater,
	faUserVneckHairLong,
	faPaw,
	faEarthAsia,
	faFlowerTulip,
	faHandHoldingHand,
	faHelmetSafety,
	faUsers,
	faMessageHeart,
	faPenPaintbrush,
} from '@fortawesome/pro-light-svg-icons';
import {
	faCheckCircle,
	faCircleXmark,
	faQuestion,
	faTriangleExclamation,
	faQuoteLeft
} from '@fortawesome/pro-solid-svg-icons';
import {
	faShoppingBag,
	faStar,
	faBars,
	faClock,
	faCalendar,
	faEnvelope,
	faArrowsRotate,
	faTag,
	faBox,
	faLeaf,
	faGift,
	faPartyHorn,
	faSeedling,
	faHeart as faHeartLight,
	faMaskFace,
	faPencil,
	faDollarSign,
	faTruckFast,
	faFire,
	faHouseChimney,
	faMortarPestle,
	faFlagUsa,
	faBoxOpen,
	faLocationDot,
	faPen,
	faPenToSquare,
	faArrowRightFromBracket,
	faXmark as faXmarkLight,
} from '@fortawesome/sharp-light-svg-icons';
import {
	faHeart,
	faMagnifyingGlass,
	faArrowRight,
	faArrowDown,
	faArrowLeft,
	faAngleRight,
	faAngleLeft,
	faTrophy,
	faArrowDownRight,
	faXmark,
	faShare,
	faEnvelope as faEnvelopeSolid,
	faStar as faStarSolid,
	faPlus,
} from '@fortawesome/sharp-solid-svg-icons';
import {
	faMagnifyingGlass as faMagnifyingGlassRegular,
	faHashtag,
	faCheck,
	faChevronDown,
	faChevronUp,
	fa1,
	fa2,
	fa3,
	fa4,
	fa5,
	fa6,
	fa7,
	fa8,
} from '@fortawesome/sharp-regular-svg-icons';
import {
	faFacebook,
	faFacebookF,
	faInstagram,
	faTwitter,
	faRedditAlien,
	faPinterestP,
	faLinkedinIn,
	faTiktok,
} from '@fortawesome/free-brands-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(
		faFacebook,
		faFacebookF,
		faInstagram,
		faTwitter,
		faRedditAlien,
		faPinterestP,
		faLinkedinIn,
		faTiktok,
		faMagnifyingGlass,
		faMagnifyingGlassRegular,
		faShoppingBag,
		faStar,
		faHeart,
		faCircleUser,
		faBars,
		faTimes,
		faClock,
		faCalendar,
		faEnvelope,
		faHashtag,
		faArrowRight,
		faArrowLeft,
		faCheck,
		faAngleRight,
		faAngleLeft,
		faChevronDown,
		faChevronUp,
		faArrowsRotate,
		faTag,
		faBox,
		faTrophy,
		faCheckCircle,
		faCircleXmark,
		faQuestion,
		faTriangleExclamation,
		faArrowDownRight,
		faLeaf,
		faGift,
		faPartyHorn,
		faSeedling,
		faHeartLight,
		faMaskFace,
		faPencil,
		faDollarSign,
		faHandWave,
		faXmark,
		faSunBright,
		faWater,
		faUserVneckHairLong,
		faPaw,
		faTruckFast,
		faEarthAsia,
		faShare,
		faEnvelopeSolid,
		faFire,
		faFlowerTulip,
		faHouseChimney,
		faMortarPestle,
		fa1,
		fa2,
		fa3,
		fa4,
		fa5,
		fa6,
		fa7,
		fa8,
		faArrowDown,
		faHandHoldingHand,
		faFlagUsa,
		faBoxOpen,
		faHelmetSafety,
		faUsers,
		faMessageHeart,
		faPenPaintbrush,
		faLocationDot,
		faStarSolid,
		faPlus,
		faPen,
		faPenToSquare,
		faArrowRightFromBracket,
		faQuoteLeft,
		faXmarkLight
	);
}
