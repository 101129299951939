/*
 * File: Retailers.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 27, 2023 at 2:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography, LocaleLink, IconContainer
} from '../../../components';

// Styles
import * as S from './Retailers.styles';


/**
 * Component
 */

const Retailers = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Content */}
					<S.HeroSection>
						<S.ContentPanel>
							<IconContainer>
								<FontAwesomeIcon icon={['fasl', 'tag']} />
							</IconContainer>
							<Typography tag="h1" weight="bold">
								For Retailers
							</Typography>
							<Typography tag="h4">
								Interested in adding 700 Rivers&apos; products to your catalog? Check out our wholesale options below.
							</Typography>
							<Typography tag="p" weight="light">
								Each purchase of our high-quality, eco-friendly products directly employ women across the world that are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.
							</Typography>
							<S.LinkContainer to="/retailers#wholesale">
								<Typography>Place a Wholesale Order</Typography>
								<FontAwesomeIcon icon={['fass', 'arrow-right']} />
							</S.LinkContainer>
						</S.ContentPanel>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/catherina-candle-promotional.jpg`}
								alt="Founder of 700 Rivers, Catherina Gomes, sampling a 700 Rivers candle at the Atlanta Market"
							/>
						</S.MediaPanel>
					</S.HeroSection>

					{/* Product Section */}
					<S.ContentSection center tall>
						<S.Content center>
							<S.IconRow>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'heart']} />
								</IconContainer>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'seedling']} />
								</IconContainer>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'gift']} />
								</IconContainer>
							</S.IconRow>
							<Typography tag="h3" weight="extrabold">Ethically made, eco friendly, and all natural. Your customers will love it.</Typography>
							<Typography tag="p" className="subtitle">At 700 Rivers, we believe in an ethical and transparent supply chain. Our soaps are made in an amazing country, Bangladesh, by even more amazing women. Our candles and bath bombs are made right here in the USA, and we use only the highest quality natural ingredients for everything we make.</Typography>
							<S.LinkContainer to="/supply-chain" $center>
								<Typography>Read more about our supply chain</Typography>
								<FontAwesomeIcon icon={['fass', 'arrow-right']} />
							</S.LinkContainer>
						</S.Content>
					</S.ContentSection>

					{/* Hero Content */}
					<S.HeroSection>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/artisan-soap-interview.jpg`}
								alt="A 700 Rivers artisan holding a 700 Rivers soap"
							/>
						</S.MediaPanel>
						<S.ContentPanel>
							<S.Content>
								<Typography tag="h3" weight="extrabold">A global impact with each purchase.</Typography>
								<Typography tag="p" className="subtitle">Each purchase of our high-quality, eco-friendly products directly employ women across the world that are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.</Typography>
								<Typography tag="p" weight="semibold" style={{ marginTop: '20px' }}>With your help, 700 Rivers has been able to give back to our local community and global family.</Typography>
							</S.Content>
							<S.ExamplesSection>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'heart']} /></S.ExampleIcon>
									<Typography>We employ 20+ artisans who have escaped and overcome human trafficking</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'pencil']} /></S.ExampleIcon>
									<Typography>We have provided school supplies for hundreds of children in Bangladesh</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'dollar-sign']} /></S.ExampleIcon>
									<Typography>We&apos;ve donated funds, resources, and masks to local nonprofits in Raleigh</Typography>
								</S.Example>
								<S.Example>
									<S.ExampleIcon><FontAwesomeIcon icon={['fasl', 'mask-face']} /></S.ExampleIcon>
									<Typography>During the pandemic, we donated hundreds of masks to an orphanage in Bangladesh</Typography>
								</S.Example>
							</S.ExamplesSection>
						</S.ContentPanel>
					</S.HeroSection>

					{/* Faire Widget */}
					<S.ContentSection id="faire" style={{ marginBottom: '-120px' }} center>

						{/* Content */}
						<S.Content center>
							<Typography tag="h3" weight="extrabold">Place a wholesale order easily on Faire.</Typography>
							<Typography tag="p" className="subtitle">
								Visit us on
								{' '}
								<LocaleLink to="https://700rivers.faire.com" target="_blank">Faire.com</LocaleLink>
								{' '}
								to see our wholesale pricing and find the perfect products for your store.
							</Typography>
						</S.Content>

						{/* Widget */}
						<iframe
							src="https://www.faire.com/embed/bw_y4tdx4tynd"
							width="900"
							height="600"
							scrolling="no"
							style={{
								margin: '40px auto 0px',
								border: 'none',
								display: 'block',
								maxWidth: '100%',
								width: '900px',
								height: '600px'
							}}
							title="700 Rivers Faire Widget"
						/>
					</S.ContentSection>

					{/* Order Section */}
					<S.ContentSection id="wholesale" style={{ paddingTop: '20px', marginBottom: '-70px' }} center>

						{/* Content */}
						<S.Content>
							<Typography tag="h3" weight="extrabold">Ready to place an order?</Typography>
							<Typography tag="p" className="subtitle">
								Find us on
								{' '}
								<LocaleLink to="https://700rivers.faire.com" target="_blank">Faire.com</LocaleLink>
								{' '}
								to place a direct order, or reach out to our team. We&apos;re happy to answer questions about minimums, pricing, customizations, and anything else.
							</Typography>
							<S.LinkContainer to="/wholesale">
								<Typography>Check out our Wholesale Guide</Typography>
								<FontAwesomeIcon icon={['fass', 'arrow-right']} />
							</S.LinkContainer>
						</S.Content>

						{/* Wholesale Options */}
						<S.CardContainer>

							{/* Faire */}
							<S.CardLink to="https://700rivers.faire.com" target="_blank">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'box']} />
									<Typography tag="h4" weight="semibold">Find us on Faire</Typography>
									<Typography tag="p" weight="light">Place a wholesale order directly through Faire using our referral link.</Typography>
									<S.ActionContainer>
										<Typography>Go to Faire</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>

							{/* Contact Us */}
							<S.CardLink to="/contact#wholesale">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'envelope']} />
									<Typography tag="h4" weight="semibold">Contact Us</Typography>
									<Typography tag="p" weight="light">Send us a message or an email to hello@sevenhundredrivers.com to place an order.</Typography>
									<S.ActionContainer>
										<Typography>Reach out</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>
						</S.CardContainer>
					</S.ContentSection>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Retailers.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Retailers.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Retailers;
