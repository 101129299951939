/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 21, 2023 at 8:34 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getCollections = () => api.get('/collections');

export const getCollection = (id) => api.get(`/collections/${id}`);

export const getProduct = (id) => api.get(`/products/${id}`);

export const getProducts = ({ query }) => api.get('/products', { params: { query } });

export const getProductsForCollection = (id, limit) => api.get(`/collections/${id}/products`, { params: { limit } });

export const getSuggestedProducts = ({ productId, limit = 4 }) => api.get('/products/suggested', { params: { productId, limit } });
