/*
 * File: Constants.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 16, 2021 at 9:11 PM
 * Copyright © 2021 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 3:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

export const ROLES = {
	ALL: 'all',
	STANDARD: 'standard'
};


/**
 * Background Jobs
 */

export const BACKGROUND_JOBS = {
	HANDLE_REVOKABLE_SESSIONS: 'handle_revokable_sessions',
	HANDLE_BIRTHDAY_EVENTS: 'handle_birthday_events',
	HANDLE_CAMPAIGN_ACTIONS: 'handle_campaign_actions'
};


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en'];
export const DEFAULT_LOCALE = 'en';
export const AVAILABLE_I18N_NAMESPACES = {
	TRANSLATIONS: 'translations'
};


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * General
 */

export const DEFAULT_TIMEZONE = 'America/New_York';
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // Enforce password with at least 8 char with at least 1 lower case, 1 upper case and 1 digit
export const MAX_QUERY_LIMIT = 1000;


/**
 * Cookies
 */

export const SESSION_TOKEN_COOKIE = 'e_s_token';
export const CART_TOKEN_COOKIE = 'e_c_token';
export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';
export const OFFER_WIDGET_ACTION_COOKIE = 'offer_action_taken';
export const CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
export const CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
export const CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
export const CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return 'qa.sevenhundredrivers.com';
		case 'staging':
			return 'staging.sevenhundredrivers.com';
		case 'production':
			return process.env.APP_URL.replace('https://', ''); // www.sevenhundredrivers.com
		default:
			return null;
	}
};


/**
 * Headers
 */

export const SESSION_TOKEN_HEADER = 'x-rivers-e-s-token';
export const CART_TOKEN_HEADER = 'x-rivers-e-c-token';
export const CLIENT_PLATFORM_HEADER = 'x-rivers-client-platform';
export const CLIENT_VERSION_HEADER = 'x-rivers-client-version';


/**
 * Expirations
 */

export const SESSION_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CART_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const OFFER_WIDGET_ACTION_COOKIE_EXPIRATION = 2 * 24 * 60 * 60 * 1000; // 2 days
export const IMPERSONATION_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours
export const RESET_REQUEST_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours
export const USER_INVITE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://*.sevenhundredrivers.com',
	'https://sevenhundredrivers-*.herokuapp.com',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * Analytics Action Types
 */

export const ANALYTICS_ACTION_TYPES = {
	USER_CREATED: 'user_created',
	USER_CREATED_ACCOUNT: 'user_created_account',
	USER_LOGGED_OUT: 'user_logged_out',
	USER_LOGGED_IN: 'user_logged_in',
	BEGIN_PASS_RESET: 'begin_pass_reset',
	CONTACT_SUBMISSION: 'contact_submission',
	CORPORATE_GIFTING_FORM_SUBMISSION: 'corporate_gifting_form_submission',
	EMAIL_LIST_SUBSCRIBED: 'email_list_subscribed',
	JOINED_REWARDS: 'joined_rewards',
	LOGGED_IN_REWARDS: 'logged_in_rewards',
	SENT_INVITE: 'sent_invite',
	SENT_INVITE_UNCLAIMED_REWARDS: 'sent_invite_unclaimed_rewards',
	REFERRED_EMAIL: 'referred_email',
	OUTBOUND_URL: 'outbound_url',
	SEASONAL_SUBSCRIPTION_CREATED: 'seasonal_subscription_created'
};


/**
 * Share Options
 */

export const SHARE_OPTIONS = {
	FACEBOOK: 'facebook',
	TWITTER: 'twitter',
	LINKEDIN: 'linkedin',
	REDDIT: 'reddit',
	PINTEREST: 'pinterest',
	EMAIL: 'email'
};


/**
 * SendGrid Lists
 */

export const LIST_SENDGRID_AUDIENCE = '8ebccdd5-18dd-420e-af59-644530ab6278';
export const LIST_SENDGRID_NEWSLETTER = '5ad610ec-4310-441e-8b6f-546afe94502d';
export const LIST_SENDGRID_REWARDS = '65eb3efb-6e8d-43f3-9fd3-8e7dae9745af';
export const LIST_SENDGRID_SEASONAL_SUBSCRIPTION = 'd293e404-b56c-4dc7-9bbf-6300571cb2fc';
export const LIST_SENDGRID_CORPORATE_GIFTING = 'a7406b16-90f6-4fd1-9356-035cd936759e';


/**
 * Mailchimp Audiences
 */

export const CORE_MAILCHIMP_AUDIENCE = '88ee616b03';


/**
 * State Options
 */

export const STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

export const STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];


/**
 * Collection Options
 */

export const COLLECTION_OPTIONS = [
	{
		id: 'soap_bar',
		name: 'Fresh Start Soaps',
		description: 'Our all-natural soaps are handmade by women in Bangladesh that been granted a fresh start at life after escaping human trafficking.'
	},
	{
		id: 'bath_bomb',
		name: 'Bath Bombs',
		description: 'Treat yourself to a day of well-being and enjoy the spa-like experience of a 700 Rivers Bath Bomb.'
	},
	{
		id: 'candle',
		name: 'Shine Bright Candles',
		description: 'Shine bright and spread the light with 700 Rivers candles! Focus on mindfulness and self-care with your choice of our 700 Rivers signature scents.'
	},
	{
		id: 'groups',
		name: 'Starter Kits',
		description: 'Not sure where to start? Choose from a starter pack of our customers\' favorites.'
	}
];


/**
 * Additional Cost Rules
 */

export const ADDITIONAL_COST_RULES = [];


/**
 * Store
 */

export const FREE_SHIPPING_REQUIRED_LIMIT = 75;


/**
 * Rewards Actions
 */

export const REWARDS_EARN_ACTIONS = {

	/**
	 * Base Actions
	 */

	REFER_FRIEND: {
		name: 'Refer a Friend Reward',
		description: 'Referred a friend to 700 Rivers 📣',
		id: 'refer_friend',
		amount: 1000
	},
	BIRTHDAY: {
		name: 'Happy Birthday! 🎉',
		description: 'A birthday present from 700 Rivers. Happy Birthday! 🎉',
		id: 'birthday',
		amount: 500
	},
	JOIN_REWARDS: {
		name: 'Joined 700 Rivers Rewards',
		description: 'Joined the 700 Rivers Rewards program 👋',
		id: 'join_rewards',
		amount: 500
	},
	EACH_DOLLAR_SPENT: {
		name: '700 Rivers Purchase',
		description: 'Purchased 700 Rivers products 🛍️',
		id: 'each_dollar_spent',
		amount: 10
	},
	INVITE_USER: {
		name: 'Invited Friend',
		description: 'Invited a friend to 700 Rivers Rewards 🎁',
		id: 'invite_user',
		amount: 20
	},

	/**
	 * Ad-Hoc Actions
	 */

	HOLIDAY_2023_SETUP_REWARDS_FOR_AUDIENCE: {
		name: 'Joined 700 Rivers Rewards',
		description: 'Joined the 700 Rivers Rewards program 👋',
		id: 'holiday_2023_setup_rewards_for_audience',
		amount: 500
	}
};

export const REWARDS_TYPES = {
	GIFT_CARD: 'GIFT_CARD'
};

export const REWARDS_SPEND_ACTIONS = {
	GIFT_CARD_10: {
		id: 'GIFT_CARD_10',
		name: '$10 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 10,
		pointValue: 1000,
		available: true
	},
	GIFT_CARD_20: {
		id: 'GIFT_CARD_20',
		name: '$20 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 20,
		pointValue: 2000,
		available: true
	},
	GIFT_CARD_25: {
		id: 'GIFT_CARD_25',
		name: '$25 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 25,
		pointValue: 2500,
		available: true
	},
	GIFT_CARD_50: {
		id: 'GIFT_CARD_50',
		name: '$50 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 50,
		pointValue: 5000,
		available: true
	},
	GIFT_CARD_75: {
		id: 'GIFT_CARD_75',
		name: '$75 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 75,
		pointValue: 7500,
		available: true
	},
	GIFT_CARD_100: {
		id: 'GIFT_CARD_100',
		name: '$100 700 Rivers Discount Code',
		type: REWARDS_TYPES.GIFT_CARD,
		dollarValue: 100,
		pointValue: 10000,
		available: true
	}
};


/**
 * Notification Types
 */

export const NOTIFICATION_TYPES = { // Update preferences check in utilities/notification.js when added new types
	SYSTEM: 'system',
	PRODUCT_MARKETING: 'product_marketing'
};


/**
 * Email Templates
 */

export const EMAIL_TEMPLATES = {
	WELCOME_EMAIL: 'welcomeEmail',
	JOIN_REWARDS: 'joinRewards',
	LOGIN_REWARDS: 'loginRewards',
	REWARDS_INVITE_EMAIL: 'rewardsInviteEmail',
	REFERRAL_EMAIL: 'referralEmail',
	HAPPY_BIRTHDAY_EMAIL: 'happyBirthdayEmail',
	REWARD_BALANCE_CAMPAIGN_EMAIL: 'rewardBalanceCampaignEmail',
	POINTS_EARNED_EMAIL: 'pointsEarnedEmail',
	POINTS_EARNED_UNCLAIMED_REWARDS_INVITE_EMAIL: 'pointsEarnedUnclaimedRewardsInviteEmail',
	CREATE_GIFT_CARD_EMAIL: 'createGiftCardEmail',
	SHARE_GIFT_CARD_EMAIL: 'shareGiftCardEmail',
	SEASONAL_SUBSCRIPTION_SIGN_UP_EMAIL: 'seasonalSubscriptionSignUpEmail',
	HOLIDAY_2023_SETUP_REWARDS_FOR_AUDIENCE_EMAIL: 'holiday2023SetupRewardsForAudienceEmail'
};


/**
 * Notification Channels
 */

export const NOTIFICATION_CHANNELS = {
	IN_APP: 'inapp',
	PUSH: 'push',
	EMAIL: 'email'
};


/**
 * Notification Audiences
 */

export const NOTIFICATION_AUDIENCES = {
	ALL_USERS: 'all_users',
	JOINED_REWARDS: 'joined_rewards'
};


/**
 * Product Collections
 */

export const PRODUCT_COLLECTIONS = {
	SOAPS: {
		id: '267310694442',
		handle: 'fresh-start-soaps',
		isSmart: false
	},
	BATH_BOMBS: {
		id: '267310727210',
		handle: 'bath-bombs',
		isSmart: false
	},
	CANDLES: {
		id: '270471135274',
		handle: 'candles',
		isSmart: false
	},
	SELF_CARE: {
		id: '273546805290',
		handle: 'self-care',
		isSmart: true
	},
	GIFT_CARDS: {
		id: '273546838058',
		handle: 'gift-cards',
		isSmart: true
	},
	NEW_ARRIVALS: {
		id: '273546903594',
		handle: 'new-arrivals',
		isSmart: false
	},
	HOLIDAY_CANDLES: {
		id: '273781194794',
		handle: 'holiday-candle-collection',
		isSmart: false
	},
	ALL_PRODUCTS: {
		id: '273546969130',
		handle: 'all-products-storefront',
		isAll: true,
		isSmart: true
	},
	OUR_FAVORITES: {
		id: '273787191338',
		handle: 'our-favorites',
		isSmart: false
	},
	SOAP_CANDLE_ACCESSORIES: {
		id: '274474008618',
		handle: 'soap-candle-accessories',
		isSmart: false
	},
	GIFT_BUNDLES: {
		id: '274474074154',
		handle: 'curated-gift-bundles',
		isSmart: false
	}
};


/**
 * Reviews
 */

export const PRODUCT_REVIEWS = [
	{
		content: 'Y\'all these soaps are AMAZING!\n\nNot only do they smell phenomenal (my personal favorite is the orange turmeric) but they support an amazing cause run by a Raleigh local.',
		author: 'Holly B.',
	},
	{
		content: 'I love products that are sustainable and give back to others. From the packaging to the handcrafted soaps, 700 Rivers leaves an authentic mark worth imprinting. I love products that give back to those in need, so being able to help Bangladeshi artisans start a new life while feeling fresh and clean is an easy win, win!',
		author: 'Amath D.',
	},
	{
		content: 'I gifted my Aunt 700 Rivers soaps because she is one of the strongest women in my life, always supporting the other women of multiple generations around her. She takes care of her 2 granddaughters, her daughter, her 8 nieces, and her mother in law. ❤️ Thank you for making her feel loved, special, and pampered on this Christmas Day! Just as she deserves. Merry Christmas!',
		author: 'Sarah B.',
	},
	{
		content: 'I love these soaps because it is great for my skin and I know my purchase is helping another woman. These soaps are a great indulgence as a way to treat myself!',
		author: 'Mariangela W.',
	},
	{
		content: 'These soaps make my skin feel great and smell amazing! My favorite scent is Lemon Citrus - I love that it smells so fresh! I would highly recommend to anyone looking for high quality handmade soaps.',
		author: 'Jessica N.',
	}
];


/**
 * Subscription Types
 */

export const SUBSCRIPTION_TYPES = {
	SEASONAL_SUBSCRIPTION: 'seasonal_subscription'
};


/**
 * Subscription Cycles
 */

export const SUBSCRIPTION_QUARTERLY_CYCLES = [
	'03-01', // Mar 1
	'05-01', // Jun 1
	'09-01', // Sept 1
	'12-01' // Dec 1
];


/**
 * Account Confirmation Options
 */

export const ACCOUNT_CONFIRMATION_OPTIONS = {
	SEASONAL_SUBSCRIPTION: 'seasonal_subscription'
};
