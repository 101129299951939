/*
 * File: Wholesale.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 23, 2023 at 11:08 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, LocaleLink, Padding } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 50%;
	text-align: left;
	margin: 0px auto;
	width: 88%;
	padding: 100px 0px;

	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 8rem;
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: 50%;
`;

export const HeroImage = styled.img`
	width: 100%;
`;

export const ContentSection = styled.div`
	padding: 80px 0px;
	margin: 0px 0px;
`;

export const Content = styled.div`
	max-width: 600px;
	width: 100%;

	* {
		text-align: left;
	}
	.subtitle {
		color: ${({ theme }) => theme.secondaryText};
		margin: 7px 0px 0px;
	}
`;

export const ExamplesSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 22px 0px 0px;
`;

export const Example = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	* {
		text-align: left;
	}
`;

export const ExampleIcon = styled.div`
	width: 40px;
	height: 40px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-shrink: 0;

	svg {
		color: ${({ theme }) => theme.primaryText};
		font-size: 1.2rem;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 50px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;
