/*
 * File: CopyrightFooter.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:17 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';
import { Padding } from '../Padding';
import { LocaleLink } from '../LocaleLink';

// Styles
import * as S from './CopyrightFooter.styles';


/**
 * Component
 */

export const CopyrightFooter = ({ isLarge }) => (
	<Padding left right isLarge={isLarge}>
		<S.CopyrightFooter>
			<LocaleLink to="/">
				<S.LogoMark alt="700 Rivers Logo" title="700 Rivers Logo" src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-mark.png`} />
			</LocaleLink>
			<Typography tag="span">{`© ${new Date().getFullYear()} 700 Rivers LLC. All rights reserved.`}</Typography>
		</S.CopyrightFooter>
	</Padding>
);


/**
 * Configuration
 */

CopyrightFooter.displayName = 'CopyrightFooter';
CopyrightFooter.propTypes = {
	isLarge: PropTypes.bool
};
CopyrightFooter.defaultProps = {
	isLarge: false
};
