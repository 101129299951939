/*
 * File: ProductCard.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 10:47 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 11, 2023 at 10:34 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: block;
	position: relative;

	a,
	a:link,
	a:visited {
		text-decoration: none;
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
	}
	h4 {
		color: ${({ theme }) => theme.primaryText} !important;
	}
	p {
		color: ${({ theme }) => theme.primaryText} !important;
	}
`;

export const ImageContainer = styled.div`
	width: 100%;
	position: relative;
`;

export const InnerCard = styled.div`
	position: relative;
`;

export const DetailContainer = styled.div`
	padding: 14px 0px 20px;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: start;
	gap: 8px;

	h5 {
		flex-grow: 1;
		text-align: left;
	}
	.compareAtPrice {
		color: ${({ theme }) => theme.secondaryText} !important;
		text-decoration: line-through;
	}
`;

export const ReviewContainer = styled.div`
	padding: 0px 20px 20px;
`;

export const CartOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 50px;
	bottom: 25px;
	left: 25px;
	display: flex;
	align-items: end;
	justify-content: start;
	pointer-events: none;
	opacity: 0;

	button {
		pointer-events: none;
	}
	&.showOverlay {
		opacity: 1;

		button {
			pointer-events: all;
		}
	}
`;

export const CardOverlay = styled.div`
	position: absolute;
	width: calc(100% + 50px);
	height: calc(100% + 80px);
	top: -25px;
	left: -25px;

	&.showOverlay {
		box-shadow: ${ColorValues.elementShadow};
		background-color: ${ColorValues.white};
	}
`;
