/*
 * File: ReviewsSection.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 22, 2023 at 11:30 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const ReviewController = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 50px;

	.reviewButton {
		background-color: ${({ theme }) => theme.secondaryBackground};
		border: none;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		flex-shrink: 0;
	}
	h5 {
		margin: 20px 0px 0px;
	}
`;

export const ReviewContainer = styled.div`
	opacity: 1;

	* {
		text-align: center;
	}
	&.fadeOut {
		opacity: 0;
	}
	&.fadeIn {
		opacity: 1;
	}
`;
