/*
 * File: Subscription.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Button } from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, mobileBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const ContentSection = styled.div`
	padding: 100px 0px 80px;
	margin: 0px 0px;
`;

export const StepsGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 34px;
	margin: 50px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const StepBlock = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 6px 0px 0px;
	}
	* {
		text-align: left;
	}
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
		margin: 10px 0px 0px;
	}
`;

export const SignUpForm = styled.div`
	display: flex;
	padding: 0px 0px 50px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	gap: 60px;
	flex-direction: column;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
		padding: 50px 0px 50px;
	}
	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
`;

export const ContentPane = styled.div`
	margin-top: 50px;
	display: flex;
	flex-grow: 1;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		margin-top: 0px;
	}
`;

export const FormPane = styled.div`
	width: 100%;
	max-width: 440px;
	flex-shrink: 0;
	position: relative;
`;

export const ContentContainer = styled.div`
	text-align: left;
	max-width: 600px;

	h1 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		text-align: left;
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Form = styled.form`
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

	button {
		width: 100%;
	}
	.formInput {
		margin: 20px 0 0;
	}
	h3 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
		margin: 0px 0px 30px;
	}
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const ConfirmationContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const ConfirmationPane = styled.div`
	text-align: center;
	border-radius: 10px;
	padding: 40px 20px;
	position: relative;
	overflow: hidden;

	h3 {
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfirmationBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const ConfirmationContent = styled.div`
	position: relative;
	z-index: 2;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
`;

export const BannerImage = styled.div`
	width: 100%;
	height: 400px;
	margin: 0px 0px 0px 0px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
`;

export const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;
	cursor: pointer;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const ReviewSection = styled.div`
	max-width: 900px;
	width: 100%;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	a,
	a:visited,
	a:link {
		text-decoration: none;
	}
`;

export const ReviewActionButton = styled(Button)`
	margin: 40px 0px 0px;
`;

export const PanelSection = styled.div`
	padding: 0px 0px 40px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: ${({ $wideText }) => ($wideText ? '55%' : '50%')};
	text-align: left;
	margin: 0px auto;
	width: 100%;
	padding: 50px 50px;
	background-color: ${({ $white }) => ($white ? ColorValues.white : 'transparent')};

	* {
		text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 6rem;
		width: 88%;
	}
	p {
		margin: 10px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h3 {
		font-family: 'Unna', Georgia, Times, serif;
	}
	h4 {
		margin: 12px 0px 20px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: ${({ $wideText }) => ($wideText ? '45%' : '50%')};
`;

export const MediaPanelImage = styled.img`
	width: 100%;
`;

export const ProductImageContainer = styled.div`
	position: relative;
	margin: 0px 0px 25px;

	.quantityIcon {
		position: absolute;
		margin: 0px;
		top: 50px;
		left: 115px;
		box-shadow: ${ColorValues.elementDarkShadow};
		background-color: white;
	}
`;

export const ProductImage = styled.div`
	width: 140px;
	height: 140px;
	border-radius: 4px;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
