/*
 * File: ShopCandles.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: May 24, 2024 at 1:17 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getCollection, getProductsForCollection } from '../../../services/product';

// Components
import {
	Meta, AppNavigation, Spinner, ErrorComponent, Typography, ImageBanner
} from '../../../components';

// Constants
import { PRODUCT_COLLECTIONS } from '../../../../Constants';

// Styles
import * as S from './ShopCandles.styles';


/**
 * Helpers
 */

const sortCandleSizes = (products, tag) => {
	if (!products) return [];
	return products.filter((product) => (product.tags || []).includes(tag));
};


/**
 * Component
 */

const ShopCandles = ({ meta, locale, data }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.collection ? 'idle' : 'success');
	const [holidayProductStatus, setHolidayProductStatus] = useState('idle');
	const [accessoriesProductStatus, setAccessoriesProductStatus] = useState('idle');
	const [collection, setCollection] = useState(data?.collection);
	const [holidayProducts, setHolidayProducts] = useState([]);
	const [accessoriesProducts, setAccessoriesProducts] = useState([]);
	const [products12oz, setProducts12oz] = useState(sortCandleSizes(data?.products, '12 oz'));
	const [products8oz, setProducts8oz] = useState(sortCandleSizes(data?.products, '8 oz'));
	const [products5oz, setProducts5oz] = useState(sortCandleSizes(data?.products, '5 oz'));

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch collection
			const { data: collectionData } = await getCollection(PRODUCT_COLLECTIONS.CANDLES.handle);

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.CANDLES.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setCollection(collectionData.collection);
				setProducts12oz(sortCandleSizes(productData.products, '12 oz'));
				setProducts8oz(sortCandleSizes(productData.products, '8 oz'));
				setProducts5oz(sortCandleSizes(productData.products, '5 oz'));

				// Update page statue
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle fetch data for holiday component
	const fetchHolidayProducts = async () => {

		// Update component status
		setHolidayProductStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.HOLIDAY_CANDLES.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setHolidayProducts(productData.products);

				// Update component statue
				setHolidayProductStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update component status
				setHolidayProductStatus('error');
			}
		}
	};

	// Handle fetch data for accessories component
	const fetchAccessoriesProducts = async () => {

		// Update component status
		setAccessoriesProductStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.SOAP_CANDLE_ACCESSORIES.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setAccessoriesProducts(productData.products);

				// Update component statue
				setAccessoriesProductStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update component status
				setAccessoriesProductStatus('error');
			}
		}
	};

	// Handle actions on holiday products component state change
	useEffect(() => {

		// Ensure component loading is not complete
		if (holidayProductStatus === 'idle') {

			// Fetch data state for component
			fetchHolidayProducts();
		}
	}, [holidayProductStatus]);

	// Handle actions on accessories products component state change
	useEffect(() => {

		// Ensure component loading is not complete
		if (accessoriesProductStatus === 'idle') {

			// Fetch data state for component
			fetchAccessoriesProducts();
		}
	}, [accessoriesProductStatus]);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>

				{/* Banner Section */}
				<ImageBanner images={[
					{
						src: `${process.env.CDN_URL}/public/images/orange-clove-candles.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/web-6-700-Rivers_r1_03D_4126.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/web-f8-700-Rivers_r1_03D_4154.jpg`,
					}
				]}
				/>

				{/* Title Section */}
				<S.TitleSection>
					<Typography tag="h1" weight="semibold">Signature Candles</Typography>
					<S.Description dangerouslySetInnerHTML={{ __html: collection.bodyHtml }} />
				</S.TitleSection>

				{/* Holiday Collection */}
				{holidayProducts.length > 0 && (
					<S.ContentSection>
						<S.ContentTitle>
							<Typography tag="h3" weight="bold">Shine Bright Collection</Typography>
							<Typography tag="p" weight="light" variation="2">The perfect signature scents for a rejuvenating & uplifting summer season!</Typography>
						</S.ContentTitle>
						<S.ProductCollection products={holidayProducts} showMarkup={false} showFilterBar productName="holiday candles" />
					</S.ContentSection>
				)}

				{/* Glow & Grow Candles - 12 oz */}
				<S.ContentSection>
					<S.ContentTitle>
						<Typography tag="h3" weight="bold">Glow & Grow Candles - 12 oz</Typography>
						<Typography tag="p" weight="light" variation="2">Includes a seed paper dust cover to transform your used candle vessel into a vase of beautiful flowers.</Typography>
					</S.ContentTitle>
					<S.ProductCollection products={products12oz} showMarkup={false} showFilterBar productName="glow & grow candles" />
				</S.ContentSection>

				{/* Signature Candles - 8 oz */}
				<S.ContentSection>
					<S.ContentTitle>
						<Typography tag="h3" weight="bold">Signature Candles - 8 oz</Typography>
					</S.ContentTitle>
					<S.ProductCollection products={products8oz} showMarkup={false} showFilterBar productName="signature candles" />
				</S.ContentSection>

				{/* Signature Candles - 5 oz */}
				<S.ContentSection>
					<S.ContentTitle>
						<Typography tag="h3" weight="bold">Signature Candles - 5 oz</Typography>
					</S.ContentTitle>
					<S.ProductCollection products={products5oz} showMarkup={false} showFilterBar productName="signature candles" />
				</S.ContentSection>

				{/* Accessories Collection */}
				{accessoriesProducts.length > 0 && (
					<S.ContentSection>
						<S.ContentTitle>
							<Typography tag="h3" weight="bold">Soap & Candle Accessories</Typography>
							<Typography tag="p" weight="light" variation="2">Pick up the perfect products to accompany your 700 Rivers soaps and signature candles.</Typography>
						</S.ContentTitle>
						<S.ProductCollection products={accessoriesProducts} showMarkup={false} showFilterBar productName="soap and candle accessories" />
					</S.ContentSection>
				)}
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ShopCandles.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
ShopCandles.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default ShopCandles;
