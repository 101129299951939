/*
 * File: Spinner.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 6, 2022 at 12:13 AM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	width: 100%;
	height: 100%;
`;
