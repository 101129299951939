/*
 * File: BlogCard.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 28, 2023 at 10:53 AM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 10:37 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { formatMinuteLength } from '../../../../utilities/dateTime';

// Components
import { Badge } from '../../Badge';
import { Typography } from '../../Typography';

// Styles
import * as S from './BlogCard.styles';


/**
 * Component
 */

export const BlogCard = ({
	className,
	story,
	centeredText,
	perRow
}) => {

	// Create state handlers
	const [blogHeight, setBlogHeight] = useState(0);

	// Create references for elements
	const blogRef = useRef();

	// Build title variation for context
	const buildTitleTag = () => {
		if (perRow === 1) return 'h2';
		if (perRow === 2 || perRow === 3) return 'h4';
		return 'h5';
	};

	// Handle blog card height
	const updateBlogCardHeight = () => {
		setBlogHeight(blogRef.current.clientWidth / 1.6);
	};

	// Handle actions on component load
	useEffect(() => {

		// Set blog item height
		if (blogRef?.current) {
			updateBlogCardHeight();
		}
	}, []);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set blog item height
		if (blogRef?.current) {
			updateBlogCardHeight();
		}
	});

	// Render component
	return (
		<S.Wrapper className={className} to={`/story/${story.slug}`} ref={blogRef}>

			{/* Feature Image */}
			<S.FeatureImage $image={story.featureImage?.url} $height={blogHeight} alt={story.featureImage?.alt || `${story.title} on the 700 Rivers Blog`} className="animateOpacity" $perRow={perRow} />

			{/* Topic */}
			<S.TopicContainer style={{ textAlign: centeredText ? 'center' : 'left', ...centeredText ? { justifyContent: 'center' } : undefined }}>
				{story.tags.map((tag) => <Badge size="medium" variation="pill" key={tag.slug}><Typography weight="semibold" variation="2">{tag.title}</Typography></Badge>)}
			</S.TopicContainer>

			{/* Title */}
			<Typography tag={buildTitleTag()} className={centeredText ? 'h1' : ''} weight="extrabold" style={{ textAlign: centeredText ? 'center' : 'left' }}>{story.title}</Typography>

			{/* Detail Container */}
			<S.DetailContainer style={{ textAlign: centeredText ? 'center' : 'left' }}>
				<Typography variation="1" weight="semibold" style={{ textAlign: centeredText ? 'center' : 'left' }}>{formatMinuteLength(story.timeToRead)}</Typography>
			</S.DetailContainer>

			{/* Read Action */}
			<S.ReadAction style={{ textAlign: centeredText ? 'center' : 'left' }}>
				<Typography variation="1" weight="semibold">Read more</Typography>
				<FontAwesomeIcon icon={['fass', 'arrow-right']} />
			</S.ReadAction>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

BlogCard.displayName = 'BlogCard';
BlogCard.propTypes = {
	className: PropTypes.string,
	story: PropTypes.shape(),
	centeredText: PropTypes.bool,
	perRow: PropTypes.number,
};
BlogCard.defaultProps = {
	className: null,
	story: {},
	centeredText: false,
	perRow: 4
};
