/*
 * File: ShareWidget.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 6, 2022 at 12:55 AM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 12:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Constants
import { SHARE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './ShareWidget.styles';


/**
 * Component
 */

export const ShareWidget = ({
	items,
	adjustOnMobile,
	className,
	closeWidget,
	fromBottom,
	content: {
		title: shareTitle, image: shareImage, url: shareURL
	}
}) => {

	// Create references for elements
	const scrollRef = useRef();

	// Handle render share action
	const renderShareAction = (option) => {

		// Initialize options
		let action = () => {};
		let title = '';
		let icon = [];
		let iconSize = '0.9rem';
		let color = '';

		// Get options for action
		switch (option) {
			case SHARE_OPTIONS.FACEBOOK:
				title = 'Share to Facebook';
				icon = ['fab', 'facebook-f'];
				iconSize = '0.9rem';
				color = 'facebookBrand';
				action = () => {

					// Share to Facebook
					const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareURL)}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			case SHARE_OPTIONS.TWITTER:
				title = 'Share to Twitter';
				icon = ['fab', 'twitter'];
				iconSize = '0.9rem';
				color = 'twitterBrand';
				action = () => {

					// Create content
					const content = `Check out ${shareTitle} 👀`;

					// Share to Twitter
					const url = `https://twitter.com/share?url=${encodeURIComponent(shareURL)}&text=${encodeURIComponent(content)}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			case SHARE_OPTIONS.LINKEDIN:
				title = 'Share to LinkedIn';
				icon = ['fab', 'linkedin-in'];
				iconSize = '0.9rem';
				color = 'linkedinBrand';
				action = () => {

					// Share to LinkedIn
					const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareURL)}}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			case SHARE_OPTIONS.REDDIT:
				title = 'Share to Reddit';
				icon = ['fab', 'reddit-alien'];
				iconSize = '0.9rem';
				color = 'redditBrand';
				action = () => {

					// Create content
					const content = `Check out ${shareTitle}`;

					// Share to Reddit
					const url = `https://reddit.com/submit?url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(content)}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			case SHARE_OPTIONS.PINTEREST:
				title = 'Share to Pinterest';
				icon = ['fab', 'pinterest-p'];
				iconSize = '0.9rem';
				color = 'pinterestBrand';
				action = () => {

					// Share to Pinterest
					const url = `https://pinterest.com/pin/create/bookmarklet/?media=${encodeURIComponent(shareImage)}&url=${encodeURIComponent(shareURL)}&is_video=true&description=${encodeURIComponent(shareTitle)}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			case SHARE_OPTIONS.EMAIL:
				title = 'Share to Email';
				icon = ['fass', 'envelope'];
				iconSize = '0.8rem';
				color = 'twitterBrand';
				action = () => {

					// Create content
					const content = `Check out ${shareTitle}`;

					// Share to email
					const url = `mailto:?Subject=${encodeURIComponent(content)}&Body=${encodeURIComponent(shareURL)}`;
					window.open(url, '_blank');

					// Close widget
					closeWidget();
				};
				break;
			default:
				break;
		}

		// Render option
		return (
			<S.ShareAction onClick={action} key={option} className="animate">
				<S.IconContainer color={color} iconSize={iconSize}>
					<S.NavigationIcon icon={icon} />
				</S.IconContainer>
				<span>{title}</span>
			</S.ShareAction>
		);
	};

	// Handle actions on class change
	useEffect(() => {

		// Update scroll position
		if (className.includes('show')) {
			scrollRef.current.scrollTo(0, 0);
		}
	}, [className]);


	// Render component
	return (
		<S.ShareWidget className={className} adjustOnMobile={adjustOnMobile} fromBottom={fromBottom}>
			<S.DropdownArrow
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 8"
				width="1em"
				height="1em"
				verticalPropKey="top"
				adjustOnMobile={adjustOnMobile}
				fromBottom={fromBottom}
			>
				<path d="M0 8c7 0 10-8 12-8s5 8 12 8z" />
			</S.DropdownArrow>
			<S.NavigationContainer ref={scrollRef}>
				{items.map((item) => renderShareAction(item))}
			</S.NavigationContainer>
		</S.ShareWidget>
	);
};


/**
 * Configuration
 */

ShareWidget.displayName = 'ShareWidget';
ShareWidget.propTypes = {
	items: PropTypes.arrayOf(PropTypes.string),
	adjustOnMobile: PropTypes.bool,
	fromBottom: PropTypes.bool,
	className: PropTypes.string,
	closeWidget: PropTypes.func,
	content: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		url: PropTypes.string
	}),
	source: PropTypes.shape({
		type: PropTypes.string,
		id: PropTypes.string
	})
};
ShareWidget.defaultProps = {
	items: [],
	adjustOnMobile: null,
	fromBottom: false,
	className: null,
	closeWidget: null,
	content: {},
	source: {}
};
