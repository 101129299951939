/*
 * File: ReviewsSection.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 7, 2023 at 11:35 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

// Styles
import * as S from './ReviewsSection.styles';


/**
 * Timer
 */

let reviewTimer = null;


/**
 * Component
 */

export const ReviewsSection = ({
	className, reviews
}) => {

	// Create state handlers
	const [currentIndex, setCurrentIndex] = useState(0);
	const [containerClass, setContainerClass] = useState(null);

	// Create reference handlers
	const currentIndexRef = useRef(0);
	const isMounted = useRef(true);

	// Handle update current review
	const updateCurrentReview = (delta, isManual = false) => {

		// Cancel interval
		if (isManual) {
			if (reviewTimer != null) clearInterval(reviewTimer);
			reviewTimer = null;
		}

		// Get new index
		let newIndex = currentIndexRef.current + delta;
		if (newIndex === reviews.length) newIndex = 0;
		else if (newIndex < 0) newIndex = reviews.length - 1;

		// Fade out container
		if (isMounted.current) {
			setContainerClass('fadeOut');
		}

		// Update review index
		setTimeout(() => {

			// Update state
			if (isMounted.current) {
				setCurrentIndex(newIndex);
				setContainerClass('fadeIn');
			}
		}, 300);

		// Update review index
		currentIndexRef.current = newIndex;

		// Start new interval
		if (isManual) {
			// eslint-disable-next-line no-use-before-define
			createInterval();
		}
	};

	// Handle interval
	const createInterval = () => {

		// Create interval
		reviewTimer = setInterval(async () => {

			// Move to next review
			updateCurrentReview(1);

		}, 6 * 1000);
	};

	// Handle actions on component load
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Start interval
		createInterval();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<S.ReviewController className={className}>

			{/* Previous Button */}
			<IconButton
				size={1.1}
				icon={['fass', 'arrow-left']}
				className="reviewButton"
				onClick={() => { updateCurrentReview(-1, true); }}
			/>

			{/* Review Container */}
			<S.ReviewContainer className={containerClass ? `animate ${containerClass}` : 'animate'}>

				{/* Review */}
				<Typography tag="h4" weight="light">
					{reviews[currentIndex].content}
				</Typography>

				{/* Attribution */}
				<Typography tag="h5" fontStyle="italic">
					{reviews[currentIndex].author}
				</Typography>

			</S.ReviewContainer>

			{/* Next Button */}
			<IconButton
				size={1.1}
				icon={['fass', 'arrow-right']}
				className="reviewButton"
				onClick={() => { updateCurrentReview(1, true); }}
			/>
		</S.ReviewController>
	);
};


/**
 * Configuration
 */

ReviewsSection.displayName = 'ReviewsSection';
ReviewsSection.propTypes = {
	className: PropTypes.string,
	reviews: PropTypes.arrayOf(PropTypes.shape({
		content: PropTypes.string,
		author: PropTypes.string
	}))
};
ReviewsSection.defaultProps = {
	className: null,
	reviews: []
};
