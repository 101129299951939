/*
 * File: Meta.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:22 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 24, 2023 at 1:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';


/**
 * Helpers
 */

export const buildDynamicMeta = (content, data) => {

	// If no dynamic data, return template
	if (!data || !content) return content;

	// Initialize dynamic content
	let dynamicContent = content;

	// Search for template matches
	const matches = dynamicContent.match(/\{{(.*?)\}}/g);

	// Replace content
	matches?.forEach((match) => {
		const refObj = match.replace(/[{}]/g, '');
		const refs = refObj.split('.');
		let value = data;
		refs?.forEach((ref, index) => {
			if (value != null) {
				value = value[ref];
			} else {
				value = index === refs.length - 1 ? '' : {};
			}
		});
		if (value) value = value.replace(/\s\s+/g, ' ').trim();
		dynamicContent = dynamicContent.replace(match, value);
	});

	// Return content
	return dynamicContent;
};


/**
 * Component
 */

export const Meta = ({ meta, locale, data }) => {

	// Build full data
	const dataObj = {
		...data,
	};

	// Build meta title elements
	const title = buildDynamicMeta(meta.title, dataObj);
	const ogTitle = buildDynamicMeta(meta.ogTitle, dataObj);
	const twTitle = buildDynamicMeta(meta.twTitle, dataObj);

	// Build meta description elements
	const description = buildDynamicMeta(meta.description, dataObj);
	const ogDescription = buildDynamicMeta(meta.ogDescription, dataObj);
	const twDescription = buildDynamicMeta(meta.twDescription, dataObj);

	// Build meta image elements
	const image = buildDynamicMeta(meta.image, dataObj);

	// Build meta article elements
	const author = buildDynamicMeta(meta.author, data);
	const publishedTime = buildDynamicMeta(meta.publishedTime, data);
	const modifiedTime = buildDynamicMeta(meta.modifiedTime, data);

	// Create open graph meta parameters
	let ogImage = `${process.env.CDN_URL}/public/social/social-cover.png`;
	if (image) ogImage = image;
	if (meta.ogImage) ogImage = meta.ogImage;

	// Create twitter meta parameters
	let twImage = `${process.env.CDN_URL}/public/social/social-cover.png`;
	if (image) twImage = image;
	if (meta.twImage) twImage = meta.twImage;

	// Return component
	return (
		<>
			{/* Site Meta */}
			<Helmet prioritizeSeoTags>
				<title>{title}</title>
				{description && <meta name="description" content={description} />}
				<meta charset="UTF-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#000000" />
				{meta.shouldIndex === false && <meta name="robots" content="noindex" />}
			</Helmet>

			{/* Localization */}
			<Helmet prioritizeSeoTags>
				<link rel="canonical" href={locale.url ? locale.url.full : ''} />
				<link rel="alternate" hrefLang="en-US" href={locale.url ? `${process.env.APP_URL}${locale.url.path}` : ''} />
			</Helmet>

			{/* Article Meta */}
			{meta.showArticle === true && (
				<Helmet prioritizeSeoTags>
					{meta.fbSiteName && <meta property="article:publisher" content={meta.fbSiteName} />}
					{publishedTime && <meta property="article:published_time" content={publishedTime} />}
					{modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}
				</Helmet>
			)}

			{/* Author Meta */}
			{author && (
				<Helmet prioritizeSeoTags>
					<meta name="author" content={author} />
				</Helmet>
			)}

			{/* Open Graph Meta */}
			{meta.showSocial !== false && (
				<Helmet prioritizeSeoTags>
					<meta property="og:title" content={ogTitle || title} />
					{(ogDescription || description) && <meta property="og:description" content={ogDescription || description} />}
					<meta property="og:type" content={meta.ogType ? meta.ogType : 'website'} />
					<meta property="og:url" content={meta.canonicalUrl} />
					<meta property="og:image" content={ogImage} />
					<meta property="og:site_name" content={meta.siteName ? meta.siteName : '700 Rivers'} />
					<meta property="og:locale" content={locale.localeId} />
				</Helmet>
			)}

			{/* Twitter Meta */}
			{meta.showSocial !== false && (
				<Helmet prioritizeSeoTags>
					<meta name="twitter:card" content="summary" />
					<meta name="twitter:site" content="@700rivers" />
					<meta name="twitter:creator" content="@700rivers" />
					<meta name="twitter:title" content={twTitle || title} />
					{(twDescription || description) && <meta name="twitter:description" content={twDescription || description} />}
					<meta name="twitter:image" content={twImage} />
				</Helmet>
			)}

			{/* Mobile Meta */}
			<Helmet prioritizeSeoTags>
				<meta name="apple-mobile-web-app-title" content={meta.siteName ? meta.siteName : '700 Rivers'} />
				<meta name="application-name" content={meta.siteName ? meta.siteName : '700 Rivers'} />
			</Helmet>

			{/* Body */}
			<Helmet prioritizeSeoTags>
				<body className={meta.bodyClasses || 'hide-captcha'} />
			</Helmet>
		</>
	);
};


/**
 * Configuration
 */

Meta.displayName = 'Meta';
Meta.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Meta.defaultProps = {
	meta: {},
	locale: {},
	data: null
};
