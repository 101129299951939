/*
 * File: Presskit.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 22, 2023 at 4:19 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';

// Components
import { Meta, AppNavigation, Typography } from '../../../components';

// Styles
import * as S from './Presskit.styles';


/**
 * Component
 */

const Presskit = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>
						<S.HeroTitleContent>
							<Typography tag="h1" weight="extrabold">
								700 Rivers Brand & Presskit
							</Typography>
						</S.HeroTitleContent>
						<S.HeroSubtitleContent>
							<Typography tag="p" weight="light">
								We want to make it easy for you to have the correct version of our logo, colors, and images for your needs.
							</Typography>
						</S.HeroSubtitleContent>
					</S.HeroSection>

					{/* Logo Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>
							<Typography tag="h2" weight="bold">
								Logos
							</Typography>
							<Typography tag="p" weight="light">
								Our logo is often the first and most memorable interaction someone will have with our brand. It has been precisely drawn for optimum visual balance. It is critical that the correct logo file be used to our specifications described below.
							</Typography>
							<Typography tag="p" weight="light">
								If your audience isn&apos;t familiar with 700 Rivers, we recommend using our full vertical logo.
							</Typography>
							<Typography tag="p" weight="light">
								If your audience is familiar with 700 Rivers, you may want to use the mark on its own.
							</Typography>
							<S.DownloadLink href={`${process.env.CDN_URL}/public/presskit/logos/700-rivers-logos.zip`} download>
								Download all logos
								<FontAwesomeIcon icon={['fass', 'arrow-down-right']} />
							</S.DownloadLink>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.LogoGrid>

								{/* Vertical Logo */}
								<S.LogoColumn>
									<S.LogoContainer $vertical>
										<S.Logo
											src={`${process.env.CDN_URL}/public/presskit/logos/vertical/700-rivers-logo-vertical.png`}
											alt="700 Rivers Vertical Logo"
											title="700 Rivers Vertical Logo"
										/>
									</S.LogoContainer>
									<S.DescriptionContainer>
										<S.Description>
											<Typography tag="h3" weight="bold">
												Vertical Logo
											</Typography>
										</S.Description>
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/vertical/700-rivers-logo-vertical.eps`}
												download
											>
												EPS
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/vertical/700-rivers-logo-vertical.svg`}
												download
											>
												SVG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/vertical/700-rivers-logo-vertical.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.LogoColumn>

								{/* Horizontal Logo */}
								<S.LogoColumn>
									<S.LogoContainer>
										<S.Logo
											src={`${process.env.CDN_URL}/public/presskit/logos/horizontal/700-rivers-logo-horizontal.png`}
											alt="700 Rivers Horizontal Logo"
											title="700 Rivers Horizontal Logo"
										/>
									</S.LogoContainer>
									<S.DescriptionContainer>
										<S.Description>
											<Typography tag="h3" weight="bold">
												Horizontal Logo
											</Typography>
										</S.Description>
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/horizontal/700-rivers-logo-horizontal.eps`}
												download
											>
												EPS
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/horizontal/700-rivers-logo-horizontal.svg`}
												download
											>
												SVG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/horizontal/700-rivers-logo-horizontal.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.LogoColumn>

								{/* Logo Mark */}
								<S.LogoColumn>
									<S.LogoContainer $vertical>
										<S.Logo
											src={`${process.env.CDN_URL}/public/presskit/logos/mark/700-rivers-logo-mark.png`}
											alt="700 Rivers Logo Mark"
											title="700 Rivers Logo Mark"
										/>
									</S.LogoContainer>
									<S.DescriptionContainer>
										<S.Description>
											<Typography tag="h3" weight="bold">
												Logo Mark
											</Typography>
										</S.Description>
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/mark/700-rivers-logo-mark.eps`}
												download
											>
												EPS
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/mark/700-rivers-logo-mark.svg`}
												download
											>
												SVG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/logos/mark/700-rivers-logo-mark.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.LogoColumn>


							</S.LogoGrid>
						</S.ContentPanel>
					</S.PresskitSection>

					{/* Do's and Don'ts Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>

							{/* Content */}
							<Typography tag="h2" weight="bold">
								Do&apos;s and Don&apos;ts
							</Typography>
							<Typography tag="p" weight="light">
								Please treat our logo with respect. There are a number of things that should never be done to our logo, a few of which are illustrated below. In brief: never alter our logo in any way.
							</Typography>

							{/* Bad Examples */}
							<S.BadExamplesContainer>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t use the mark in a word
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t compress or distort the logo
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t use any color other than our brand sunrise for the logo
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t use a different typeface for the wordmark
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t use the wordmark without the logomark
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t use the mark in an illustration
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t redraw the mark
								</Typography>
								<Typography tag="p">
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									Don&apos;t alter the mark in any way
								</Typography>
							</S.BadExamplesContainer>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.BadExamplesGrid>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-1.png`}
										alt="Don't Example #1"
										title="Don't Example #1"
									/>
								</S.BadExample>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-2.png`}
										alt="Don't Example #2"
										title="Don't Example #2"
									/>
								</S.BadExample>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-3.png`}
										alt="Don't Example #3"
										title="Don't Example #3"
									/>
								</S.BadExample>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-4.png`}
										alt="Don't Example #4"
										title="Don't Example #4"
									/>
								</S.BadExample>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-5.png`}
										alt="Don't Example #5"
										title="Don't Example #5"
									/>
								</S.BadExample>
								<S.BadExample>
									<FontAwesomeIcon icon={['fass', 'xmark']} />
									<img
										src={`${process.env.CDN_URL}/public/presskit/bad-examples/bad-example-6.png`}
										alt="Don't Example #6"
										title="Don't Example #6"
									/>
								</S.BadExample>
							</S.BadExamplesGrid>
						</S.ContentPanel>
					</S.PresskitSection>

					{/* Brand Colors Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>
							<Typography tag="h2" weight="bold">
								Brand Colors
							</Typography>
							<Typography tag="p" weight="light">
								Color is a strong brand identifier - it provides contrast and helps define hierarchy. We believe that color should be used boldly, but appropriately, to make content the clearest. Always use the exact color values as listed below.
							</Typography>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.ColorGrid>

								{/* Sunrise Light */}
								<S.BrandColor>
									<S.ColorSwatch className="sunriseLight" />
									<Typography tag="h4" weight="bold">Sunrise Light</Typography>
									<Typography tag="p" variation="2" weight="light">Use for brand highlights</Typography>
									<S.ColorCodesContainer>
										<S.ColorKey>rgb</S.ColorKey>
										<S.ColorCode><span>rgb(232, 187, 59)</span></S.ColorCode>
										<S.ColorKey>hex</S.ColorKey>
										<S.ColorCode><span>#E8BB3B</span></S.ColorCode>
										<S.ColorKey>cmyk</S.ColorKey>
										<S.ColorCode><span>0&nbsp;&nbsp;19&nbsp;&nbsp;75&nbsp;&nbsp;9</span></S.ColorCode>
									</S.ColorCodesContainer>
								</S.BrandColor>

								{/* Sunrise Dark */}
								<S.BrandColor>
									<S.ColorSwatch className="sunriseDark" />
									<Typography tag="h4" weight="bold">Sunrise Dark</Typography>
									<Typography tag="p" variation="2" weight="light">Use for brand contrast and shadow</Typography>
									<S.ColorCodesContainer>
										<S.ColorKey>rgb</S.ColorKey>
										<S.ColorCode><span>rgb(191, 155, 46)</span></S.ColorCode>
										<S.ColorKey>hex</S.ColorKey>
										<S.ColorCode><span>#BF9B2E</span></S.ColorCode>
										<S.ColorKey>cmyk</S.ColorKey>
										<S.ColorCode><span>0&nbsp;&nbsp;19&nbsp;&nbsp;76&nbsp;&nbsp;25</span></S.ColorCode>
									</S.ColorCodesContainer>
								</S.BrandColor>

								{/* Pale Fiber */}
								<S.BrandColor>
									<S.ColorSwatch className="paleFiber" />
									<Typography tag="h4" weight="bold">Pale Fiber</Typography>
									<Typography tag="p" variation="2" weight="light">Use as content background</Typography>
									<S.ColorCodesContainer>
										<S.ColorKey>rgb</S.ColorKey>
										<S.ColorCode><span>rgb(250, 249, 246)</span></S.ColorCode>
										<S.ColorKey>hex</S.ColorKey>
										<S.ColorCode><span>#FAF9F6</span></S.ColorCode>
										<S.ColorKey>cmyk</S.ColorKey>
										<S.ColorCode><span>0&nbsp;&nbsp;0&nbsp;&nbsp;2&nbsp;&nbsp;2</span></S.ColorCode>
									</S.ColorCodesContainer>
								</S.BrandColor>

								{/* Twilight Dark */}
								<S.BrandColor>
									<S.ColorSwatch className="twilightDark" />
									<Typography tag="h4" weight="bold">Twilight Dark</Typography>
									<Typography tag="p" variation="2" weight="light">Use for text & illustrations</Typography>
									<S.ColorCodesContainer>
										<S.ColorKey>rgb</S.ColorKey>
										<S.ColorCode><span>rgb(74, 74, 74)</span></S.ColorCode>
										<S.ColorKey>hex</S.ColorKey>
										<S.ColorCode><span>#4A4A4A</span></S.ColorCode>
										<S.ColorKey>cmyk</S.ColorKey>
										<S.ColorCode><span>0&nbsp;&nbsp;0&nbsp;&nbsp;0&nbsp;&nbsp;71</span></S.ColorCode>
									</S.ColorCodesContainer>
								</S.BrandColor>

								{/* Twilight White */}
								<S.BrandColor>
									<S.ColorSwatch className="twilightWhite" />
									<Typography tag="h4" weight="bold">Twilight White</Typography>
									<Typography tag="p" variation="2" weight="light">Use as content background</Typography>
									<S.ColorCodesContainer>
										<S.ColorKey>rgb</S.ColorKey>
										<S.ColorCode><span>rgb(255, 255, 255)</span></S.ColorCode>
										<S.ColorKey>hex</S.ColorKey>
										<S.ColorCode><span>#FFFFFF</span></S.ColorCode>
										<S.ColorKey>cmyk</S.ColorKey>
										<S.ColorCode><span>0&nbsp;&nbsp;0&nbsp;&nbsp;0&nbsp;&nbsp;0</span></S.ColorCode>
									</S.ColorCodesContainer>
								</S.BrandColor>

							</S.ColorGrid>
						</S.ContentPanel>
					</S.PresskitSection>

					{/* Brand Fonts Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>
							<Typography tag="h2" weight="bold">
								Brand Fonts
							</Typography>
							<Typography tag="p" weight="light">
								Fonts are an important part of our brand identity - they help define the look and feel of 700 Rivers, both in print and online. We use our brand fonts to display our content, highlight features, and emphasize what is important on our platform.
							</Typography>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.FontGrid>

								{/* Lexend */}
								<S.BrandFont>
									<S.FontHolder className="lexend">
										<Typography tag="h2" weight="black">Lexend Black</Typography>
										<Typography tag="h2" weight="bold">Lexend Bold</Typography>
										<Typography tag="h2" weight="regular">Lexend Regular</Typography>
									</S.FontHolder>
									<Typography tag="h4" weight="bold">Lexend</Typography>
									<Typography tag="p" variation="2" weight="light">
										Lexend is a clean and clear font with a very warm and inviting feeling to it. We use Lexend for our headers, navigation and content.
									</Typography>
								</S.BrandFont>

								{/* Unna */}
								<S.BrandFont>
									<S.FontHolder className="unna">
										<Typography tag="h2" weight="bold">Unna Bold</Typography>
										<Typography tag="h2" weight="regular">Unna Regular</Typography>
									</S.FontHolder>
									<Typography tag="h4" weight="bold">Unna</Typography>
									<Typography tag="p" variation="2" weight="light">
										Unna is a bold, well-finished, neoclassical font. We use Unna for items of emphasis on our website.
									</Typography>
								</S.BrandFont>

							</S.FontGrid>
						</S.ContentPanel>
					</S.PresskitSection>

					{/* Color Distribution Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>
							<Typography tag="h2" weight="bold">
								Color Distribution
							</Typography>
							<Typography tag="p" weight="light">
								Of equal importance to our color values is how they are used in relation to one another. We rely heavily on our primary sunrise, and use cascading neutral values to designate content hierarchy.
							</Typography>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.ColorDistributionContainer>
								<S.DistributionItem className="sunriseLight" />
								<S.DistributionItem className="sunriseDark" />
								<S.DistributionItem className="twilightDark" />
								<S.DistributionItem className="twilightWhite" />
								<S.DistributionItem className="paleFiber" />
							</S.ColorDistributionContainer>
						</S.ContentPanel>
					</S.PresskitSection>

					{/* Press Images Section */}
					<S.PresskitSection>

						{/* Description Panel */}
						<S.DescriptionPanel>
							<Typography tag="h2" weight="bold">
								Press Images
							</Typography>
							<Typography tag="p" weight="light">
								We prefer to be represented in media with our most current look. If you are looking for an image that describes 700 Rivers and our work culture, feel free to use any of these photos that we&apos;ve already taken.
							</Typography>
							<S.DownloadLink href={`${process.env.CDN_URL}/public/presskit/press-images/700-rivers-press-images.zip`} download>
								Download all images
								<FontAwesomeIcon icon={['fass', 'arrow-down-right']} />
							</S.DownloadLink>
						</S.DescriptionPanel>

						{/* Content Panel */}
						<S.ContentPanel>
							<S.ImageGrid>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/catherina-candle-promotional.jpg`}
										alt="Founder of 700 Rivers, Catherina Gomes, sampling a 700 Rivers candle at the Atlanta Market"
										title="Founder of 700 Rivers, Catherina Gomes, sampling a 700 Rivers candle at the Atlanta Market"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/catherina-candle-promotional.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/catherina-candle-promotional.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group.jpg`}
										alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
										title="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group-soaps.jpg`}
										alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes, holding 700 Rivers soaps"
										title="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes, holding 700 Rivers soaps"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group-soaps.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/artisan-family-group-soaps.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/soap-family-marble.jpg`}
										alt="Original four scents of 700 Rivers soaps on a white marble background"
										title="Original four scents of 700 Rivers soaps on a white marble background"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/soap-family-marble.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/soap-family-marble.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/hands-holding-soap.jpg`}
										alt="Artisan hands holding 700 Rivers soap bar"
										title="Artisan hands holding 700 Rivers soap bar"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/hands-holding-soap.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/hands-holding-soap.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/soaps-group-jute.jpg`}
										alt="Original four scents of 700 Rivers soaps on a natural jute fiber background"
										title="Original four scents of 700 Rivers soaps on a natural jute fiber background"
									/>
									<S.DescriptionContainer>
										<S.Description />
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/soaps-group-jute.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/soaps-group-jute.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

								{/* Image */}
								<S.ImageContainer>
									<S.PressImage
										src={`${process.env.CDN_URL}/public/presskit/press-images/catherina-interview-portrait.jpg`}
										alt="Catherina Gomes, Founder and CEO of 700 Rivers"
										title="Catherina Gomes, Founder and CEO of 700 Rivers"
									/>
									<S.DescriptionContainer>
										<S.Description>
											<Typography tag="h3" weight="bold">
												Catherina Gomes
											</Typography>
											<Typography tag="p" variation="3" weight="regular" fontStyle="italic">
												Founder & CEO
											</Typography>
										</S.Description>
										<S.DownloadContainer>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/catherina-interview-portrait.jpg`}
												download
											>
												JPG
											</S.InlineDownloadLink>
											<S.InlineDownloadLink
												href={`${process.env.CDN_URL}/public/presskit/press-images/catherina-interview-portrait.png`}
												download
											>
												PNG
											</S.InlineDownloadLink>
										</S.DownloadContainer>
									</S.DescriptionContainer>
								</S.ImageContainer>

							</S.ImageGrid>
						</S.ContentPanel>
					</S.PresskitSection>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Presskit.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Presskit.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Presskit;
