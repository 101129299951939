/*
 * File: CelebrationModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 2, 2023 at 12:17 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { ConfettiBurst } from '../ConfettiBurst';
import { IconContainer } from '../IconContainer';

// Styles
import * as S from './CelebrationModal.styles';


/**
 * Component
 */

export const CelebrationModal = ({
	className, isOpen, handleClose, title, content
}) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle actions on component load
	useEffect(() => {
		if (isOpen) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 100);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 5000);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose showClose>
			<S.ModalInner>

				{/* Icon */}
				<IconContainer>
					<FontAwesomeIcon icon={['fasl', 'gift']} />
					<S.ConfettiTarget ref={confettiTargetRef} />
				</IconContainer>

				{/* Content */}
				<Typography tag="h2" weight="bold">{title}</Typography>
				<Typography tag="p" weight="light">{content}</Typography>

			</S.ModalInner>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</Modal>
	);
};


/**
 * Configuration
 */

CelebrationModal.displayName = 'CelebrationModal';
CelebrationModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string,
	content: PropTypes.string,
};
CelebrationModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	title: '',
	content: ''
};
