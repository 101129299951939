/*
 * File: OfferWidget.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 10:41 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { getCookie, setCookie } from '../../utilities/cookies';

// Constants
import { UI_MODE_OPTIONS, OFFER_WIDGET_ACTION_COOKIE, OFFER_WIDGET_ACTION_COOKIE_EXPIRATION } from '../../../Constants';

// Components
import { ToolTip } from '../ToolTip';
import { SignUpModal } from '../SignUpModal';

// Styles
import * as S from './OfferWidget.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Constants
 */

const TOOLTIP_WIDTH = 248;


/**
 * Component
 */

export const OfferWidget = ({
	className
}) => {

	// Set state
	const [shouldShowRewards, setShouldShowRewards] = useState(getCookie(OFFER_WIDGET_ACTION_COOKIE) !== 'complete');
	const [showRewardsModal, setShowRewardsModal] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Create references for elements
	const widgetRef = useRef();

	// Handle hide widget
	const handleHideWidget = (e) => {

		// Prevent default
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		// Update state
		setShouldShowRewards(false);

		// Update cookie
		setCookie(OFFER_WIDGET_ACTION_COOKIE, 'complete', OFFER_WIDGET_ACTION_COOKIE_EXPIRATION);
	};

	// Handle actions on component load
	useEffect(() => {
		setTimeout(() => {

			// Show tooltip
			ReactTooltip.show(widgetRef?.current);

			// Update position
			if (widgetRef?.current) {
				setTooltipPosition({ x: widgetRef.current.getBoundingClientRect().x - TOOLTIP_WIDTH - 5, y: window.innerHeight - 80 });
			}
		}, 10);
	}, []);

	// Handle actions on window resize
	useWindowResize(() => {

		// Update position
		if (widgetRef?.current) {
			setTooltipPosition({ x: widgetRef.current.getBoundingClientRect().x - TOOLTIP_WIDTH - 5, y: window.innerHeight - 80 });
		}
	});

	// Render component
	return (
		<S.Wrapper className={className} $position={tooltipPosition} $width={TOOLTIP_WIDTH}>
			{shouldShowRewards && (
				<S.OfferWidget
					ref={widgetRef}
					data-tip="Save 10% on your next order!"
					data-for="offer-widget-tooltip"
					onClick={() => { setShowRewardsModal(true); }}
				>
					<FontAwesomeIcon icon={['fass', 'trophy']} />
					<S.CloseButton
						icon={['fal', 'times']}
						size={0.85}
						className="widgetCloseButton"
						onClick={handleHideWidget}
					/>
				</S.OfferWidget>
			)}
			<ToolTip
				id="offer-widget-tooltip"
				border
				borderColor={theme.layoutBorder}
				place="left,top"
				resizeHide={false}
				scrollHide={false}
				event="none"
				eventOff="none"
			/>
			<SignUpModal
				isOpen={showRewardsModal}
				handleClose={() => { setShowRewardsModal(false); }}
				onSignUp={handleHideWidget}
			/>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

OfferWidget.displayName = 'OfferWidget';
OfferWidget.propTypes = {
	className: PropTypes.string
};
OfferWidget.defaultProps = {
	className: null,
};
