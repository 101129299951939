/*
 * File: ShopFavorites.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 17, 2023 at 12:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getCollection, getProductsForCollection } from '../../../services/product';

// Components
import {
	Meta, AppNavigation, Spinner, ErrorComponent, Typography, ImageBanner
} from '../../../components';

// Constants
import { PRODUCT_COLLECTIONS } from '../../../../Constants';

// Styles
import * as S from './ShopFavorites.styles';


/**
 * Component
 */

const ShopFavorites = ({ meta, locale, data }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.collection ? 'idle' : 'success');
	const [collection, setCollection] = useState(data?.collection);
	const [products, setProducts] = useState(data?.products);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch collection
			const { data: collectionData } = await getCollection(PRODUCT_COLLECTIONS.OUR_FAVORITES.handle);

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.OUR_FAVORITES.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setCollection(collectionData.collection);
				setProducts(productData.products);

				// Update page statue
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>

				{/* Banner Section */}
				<ImageBanner images={[
					{
						src: `${process.env.CDN_URL}/public/images/artisan-family-group.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/web-f7-700-Rivers_r1_03D_4163.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/hands-holding-soap.jpg`,
					}
				]}
				/>

				{/* Title Section */}
				<S.TitleSection>
					<Typography tag="h1" weight="semibold">Our Favorites</Typography>
					<S.Description dangerouslySetInnerHTML={{ __html: collection.bodyHtml }} />
				</S.TitleSection>

				{/* Products */}
				<S.ProductCollection products={products} showMarkup={false} showFilterBar productName="favorite products" />
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ShopFavorites.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
ShopFavorites.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default ShopFavorites;
