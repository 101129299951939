/*
 * File: SuggestedProducts.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on July 3, 2022 at 12:10 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled from 'styled-components';

// Components
import { ProductCollection as BaseProductCollection } from '../ProductCollection';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: block;
	padding: 40px 0px 50px;

	* {
		text-align: left;
	}
`;

export const CollectionDescription = styled.div`
	padding: 0px 0px 20px;

	h2 {
		margin: 0px 0px 10px;
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		width: 100%;
		max-width: 800px;
	}
`;

export const ProductCollection = styled(BaseProductCollection)`
    margin: 0px 0px 60px;
`;
