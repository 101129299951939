/*
 * File: Cart.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 27, 2022 at 11:25 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 11, 2023 at 11:19 AM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';
import { Typography } from '../Typography';

// Constants
import { mediumBreakpoint, smallBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	padding-bottom: 90px;
	position: relative;
`;

export const ContentContainer = styled.div`
	margin: -30px auto 0px;
	text-align: center;
	padding-bottom: 30px;

	h2 {
		margin-bottom: 20px;
	}
	h3 {
		margin-bottom: 10px;
	}
	h4, h5 {
		margin: 5px 0 35px;
	}
	@media only screen and (min-width: ${smallBreakpoint}em) {
		margin: 30px auto 0px;
	}
`;

export const MessageContainer = styled.div`
	width: 100%;
	margin: -40px auto 0px;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		margin: 0px auto 0px;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 800px;
	}

	* {
		text-align: center !important;
	}
	a,
	a:visited,
	a:link {
		color: inherit;
	}
`;

export const DescriptionContainer = styled.div`
	width: 100%;
	margin: 0px auto 50px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 700px;
	}
`;

export const ActionContainer = styled.div`
	text-align: center;
	margin: -30px auto 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	button {
		width: 100%;
		font-size: 1.0rem;
		margin-top: 15px;
		padding-left: 50px;
		padding-right: 50px;
	}

	a {
		${({ fullWidth }) => (fullWidth ? css`
			width: 95%;

			@media only screen and (min-width: ${mediumBreakpoint}em) {
				max-width: 400px;
			}
		` : css`
			margin: 0 10px;
		`)};
	}

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-wrap: nowrap;
	}
`;

export const ButtonLink = styled(LocaleLink)`
	text-decoration: none;
`;

export const LottieLink = styled.div`
	text-decoration: none;
	width: 100%;
	display: block;
	margin: 0 auto;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 600px;
	}
`;

export const NoticeContainer = styled.div`
	margin: 10px 0 30px 0;
`;

export const InnerNotice = styled.div`
	display: inline-block;
`;

export const NoticeText = styled(Typography)`
	margin-right: 12px;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;


export const CartContainer = styled.div`
	margin: 20px 0px 0px;
`;

export const BottomActionContainer = styled.div`
	margin: 25px 0 0 0;
	text-align: right;

	span.cost {
		font-size: 1.4rem;
		margin-left: 10px;
	}
	button {
		margin: 20px 0 0 auto;
		width: 140px !important;
	}
`;

export const CostsContainer = styled.div`
	margin-bottom: 0px;
`;

export const CostItem = styled.div`
	text-align: right;
	margin: 8px 0px;

	span.cost {
		font-size: 1.1rem;
		margin-left: 10px;
	}
`;

export const FreeShippingContainer = styled.div`
	* {
		font-size: 0.9rem;
	}
`;

export const ProgressBar = styled.div`
	height: 8px;
	width: 100%;
	background-color: ${({ theme }) => theme.secondaryBackground};
	margin: 12px 0px 0px;
	position: relative;

	${({ $limitWidth }) => ($limitWidth && css`
		max-width: 350px;
	`)};
`;

export const ProgressInner = styled.div`
	position: absolute;
	height: 100%;
	top: 0px;
	left: 0px;
	width: ${({ $percentage }) => $percentage}%;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
`;
