/*
 * File: dateTime.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:39 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: May 2, 2024 at 9:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Constants
import { DEFAULT_TIMEZONE } from '../Constants';

// Plugins
dayjs.extend(timezonePlugin);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);


/**
 * Configuration
 */

TimeAgo.addDefaultLocale(en);


/**
 * Utilities
 */

export const formatToRelativeTime = (date) => {
	const timeAgo = new TimeAgo('en-US');
	return timeAgo.format(date);
};

export const formatDateString = (date, format, timezone = DEFAULT_TIMEZONE, adjustTimezone = true) => (adjustTimezone ? dayjs(date || new Date()).tz(timezone).format(format) : dayjs(date || new Date()).format(format));

export const parseDateString = (value, format, timezone) => (timezone ? dayjs.tz(value, format, timezone) : dayjs(value, format));

export const dateObj = (date, timezone = DEFAULT_TIMEZONE) => dayjs(date || new Date()).tz(timezone);

export const formatMinuteLength = (minutes) => `${Math.ceil(minutes)} min read`;

export const isValidDateFormat = (value, format) => dayjs(value, format, true).isValid();

export const isValidTimeZone = (tz) => {
	if (!tz) {
		return false;
	}
	if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
		return false;
	}
	try {
		Intl.DateTimeFormat(undefined, { timeZone: tz });
		return true;
	} catch (ex) {
		return false;
	}
};
