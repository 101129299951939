/*
 * File: Search.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { ProductCollection as BaseProductCollection, TextInput } from '../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	max-width: 1000px;
	margin: 0px auto;

	.searchResultsTitle {
		text-align: left;
		margin: 10px 0px 0px 0px;
	}
`;

export const ProductCollection = styled(BaseProductCollection)`
    margin: 0px 0px 60px;
`;

export const SearchContainer = styled.div`
	margin: 80px 0px 0px 0px;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
	}
	* {
		text-align: left;
	}
`;

export const SearchBarContainer = styled.div`
	position: relative;
	flex-grow: 1;

	svg {
		position: absolute;
		z-index: 2;
		top: 17px;
		left: 18px;
		font-size: 0.95rem;
	}
`;

export const ResultsSection = styled.div`
	margin: 80px 0px 0px;

	h3 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
		margin: 0px 0px 12px;
	}
`;

export const SearchBar = styled(TextInput)`
	padding-left: 45px;
`;

export const SearchToolbar = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0px 0px;
`;
