/*
 * File: DateInput.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 19, 2023 at 9:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';

// Components
import { Typography } from '../Typography';
import { TextInput } from '../TextInput';

// Styles
import * as S from './DateInput.styles';
import 'react-datepicker/dist/react-datepicker.css';


/**
 * Component
 */

export const DateInput = forwardRef(({
	id, label, error, type, size, icon, name, containerClassName, dateFormat, onChange, ...rest
}, ref) => (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate">
				{label}
			</Typography>
		)}
		{icon && (
			<S.IconWrapper>
				<FontAwesomeIcon icon={icon} />
			</S.IconWrapper>
		)}
		<DatePicker
			ref={ref}
			id={id}
			name={name}
			error={error}
			popperClassName="date-input-popper"
			dateFormat={dateFormat}
			onChange={(e) => { onChange({ target: { name, value: e } }); }}
			customInput={<TextInput type={type} size={size} withIcon={!!icon} className={rest.className ? `${rest.className} animate` : 'animate'} />}
			popperProps={{ strategy: 'fixed' }}
			{...rest}
		/>
		{error && error.message && (
			<S.FloatingWrapper title={error.message} className="animate">
				<Typography tag="p" variation="3" className="animate">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
));


/**
 * Configuration
 */

DateInput.displayName = 'DateInput';
DateInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	type: PropTypes.oneOf(['text', 'password', 'email', 'search', 'number']),
	size: PropTypes.oneOf(['fit', 'small', 'medium']),
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	error: PropTypes.shape(),
	dateFormat: PropTypes.string,
	onChange: PropTypes.func
};
DateInput.defaultProps = {
	icon: null,
	type: 'text',
	size: 'medium',
	placeholder: '',
	label: '',
	containerClassName: null,
	error: null,
	id: null,
	name: null,
	dateFormat: 'MMM d, yyyy',
	onChange: null
};
