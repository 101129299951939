/*
 * File: Typography.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 27, 2022 at 10:52 AM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Typography.styles';


/**
 * Component
 */

export const Typography = forwardRef(({
	children, tag, variation, weight, truncate, center, fontStyle, ...rest
}, ref) => (
	<S.Typography ref={ref} as={tag} variation={variation} weight={weight} truncate={truncate} center={center} fontStyle={fontStyle} {...rest}>
		{children}
	</S.Typography>
));


/**
 * Configuration
 */

Typography.displayName = 'Typography';
Typography.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	tag: PropTypes.oneOf(['label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
	variation: PropTypes.oneOf(['1', '2', '3', 'button-small', 'button-medium']),
	weight: PropTypes.oneOf(['extralight', 'light', 'regular', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
	truncate: PropTypes.bool,
	center: PropTypes.bool,
	fontStyle: PropTypes.oneOf(['italic', 'bold']),
};
Typography.defaultProps = {
	children: null,
	tag: 'span',
	variation: '1',
	weight: 'regular',
	truncate: false,
	center: true,
	fontStyle: null
};
