/*
 * File: ReturnRefundPolicy.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 16, 2023 at 11:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './ReturnRefundPolicy.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const ReturnRefundPolicy = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation hidePadding>
				<LegalNavigation>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Return & Refund Policy
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								We accept returns or exchanges within 14 days of purchase. Products must be in their original packaging and unopened to be eligible for a refund. We process refunds within 10 business days of receiving your products.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated April 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							We have a 14-day return policy, which means you have 14 days after receiving your item to request a return.
						</Typography>
						<Typography tag="p" weight="light">
							To be eligible for a return, your item must be in the same condition that you received it, unopened, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.
						</Typography>
						<Typography tag="p" weight="light">
							To start a return, you can contact us at
							{' '}
							<LocaleLink to="mailto:support@sevenhundredrivers.com?Subject=Purchase Return">support@sevenhundredrivers.com</LocaleLink>
							. We will provide instructions on how to send us your products to return or exchange.
						</Typography>
						<Typography tag="p" weight="light">
							If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.
						</Typography>
						<Typography tag="p" weight="light">
							You can always contact us for any return questions at
							{' '}
							<LocaleLink to="mailto:support@sevenhundredrivers.com?Subject=Return Question">support@sevenhundredrivers.com</LocaleLink>
							.
						</Typography>

						{/* Damages and issues */}
						<br />
						<Typography tag="h3" weight="semibold">
							Damages and issues
						</Typography>
						<Typography tag="p" weight="light">
							Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.
						</Typography>

						{/* Exceptions / non-returnable items */}
						<br />
						<Typography tag="h3" weight="semibold">
							Exceptions / non-returnable items
						</Typography>
						<Typography tag="p" weight="light">
							Certain types of items cannot be returned, like perishable goods and custom products (such as special orders or personalized items). Please get in touch if you have questions or concerns about your specific item.
						</Typography>
						<Typography tag="p" weight="light">
							Unfortunately, we cannot accept returns on sale items or gift cards.
						</Typography>

						{/* Exchanges */}
						<br />
						<Typography tag="h3" weight="semibold">
							Exchanges
						</Typography>
						<Typography tag="p" weight="light">
							The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.
						</Typography>

						{/* European Union 14 day cooling off period */}
						<br />
						<Typography tag="h3" weight="semibold">
							European Union 14 day cooling off period
						</Typography>
						<Typography tag="p" weight="light">
							Notwithstanding the above, if the merchandise is being shipped into the European Union, you have the right to cancel or return your order within 14 days, for any reason and without a justification. As above, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.
						</Typography>

						{/* Refunds */}
						<br />
						<Typography tag="h3" weight="semibold">
							Refunds
						</Typography>
						<Typography tag="p" weight="light">
							We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too.
						</Typography>
						<Typography tag="p" weight="light">
							If more than 15 business days have passed since we’ve approved your return, please contact us at
							{' '}
							<LocaleLink to="mailto:support@sevenhundredrivers.com?Subject=Refund Question">support@sevenhundredrivers.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ReturnRefundPolicy.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
ReturnRefundPolicy.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default ReturnRefundPolicy;
