/*
 * File: ImageBanner.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 12, 2023 at 2:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { largeBreakpoint, mediumBreakpoint, mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 6px;
	height: 200px;
	overflow: hidden;

	.hideMobile {
		display: none;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		.hideMobile {
			display: block;
		}
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		height: 300px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		height: 420px;
		grid-gap: 20px;
	}
`;

export const BannerImage = styled.div`
	width: 100%;
	height: 100%;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
