/*
 * File: Mission.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: December 21, 2023 at 9:17 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, SchemaScript, HeroSection, Typography, Emoji, IconContainer, LocaleLink
} from '../../../components';

// Styles
import * as S from './Mission.styles';


/**
 * Component
 */

const Mission = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle actions on component load
	useEffect(() => {

		// Activate Curate.io feed
		const d = document; const s = 'script';
		const i = d.createElement('script'); i.async = 1; i.charset = 'UTF-8';
		i.src = 'https://cdn.curator.io/published/93f56b1b-7316-461c-af0d-3e2940501ba6.js';
		const [e] = d.getElementsByTagName(s);
		e.parentNode.insertBefore(i, e);

	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Schema.org AboutPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage', 'AboutPage'],
				'@id': `${process.env.APP_URL}/mission/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}/mission`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.sevenhundredrivers.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Hero */}
					<HeroSection video={`${process.env.CDN_URL}/public/video/bangladesh-river-journey.mp4`} isVideo innerPadding className="videoBanner" />

					{/* Hero Content */}
					<S.HeroContent>
						<Typography tag="h1" weight="bold">
							700 Rivers is a global, ethical, and sustainable lifestyle brand that creates all-natural products that allow us to be kind to ourselves, kind to others, and kind to our planet.
						</Typography>
						<Typography tag="h4" weight="light">
							Our mission is to create high-quality, eco-friendly products that employ women across the world who are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.
						</Typography>
					</S.HeroContent>

					{/* Content Padding */}
					<S.ContentPadding>

						{/* 700 Rivers' Story Video Section */}
						<S.LargeContentSection style={{ textAlign: 'center' }}>

							{/* Title */}
							<S.TitleSection>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'seedling']} />
								</IconContainer>
								<Typography tag="h2" weight="bold">The 700 Rivers Story</Typography>
							</S.TitleSection>

							{/* Schema.org VideoObject */}
							<SchemaScript schema={{
								'@context': 'http://schema.org',
								'@type': 'VideoObject',
								name: 'The 700 Rivers Story',
								description: 'At 700 Rivers, our mission is to create high-quality, eco-friendly products that employ women across the world who are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.',
								thumbnailUrl: [
									`${process.env.CDN_URL}/public/video/thumbnails/700-rivers-story-video-thumb-1.jpg`,
									`${process.env.CDN_URL}/public/video/thumbnails/700-rivers-story-video-thumb-2.jpg`,
								],
								uploadDate: '2023-11-07T08:00:00+08:00',
								duration: 'PT3M7S',
								contentUrl: 'https://d9ohp2yhcg3t2.cloudfront.net/video/700-rivers-story-video-2.mp4'
							}}
							/>

							{/* 700 Rivers' Story Video */}
							<S.FullVideo
								playsInline
								autoPlay
								loop
								muted
								controls
							>
								<source src="https://d9ohp2yhcg3t2.cloudfront.net/video/700-rivers-story-video-2.mp4" type="video/mp4" />
							</S.FullVideo>
						</S.LargeContentSection>

						{/* Impact Section */}
						<S.ContentSection>
							<S.PanelSection>
								<S.ContentPanel $wideText $white>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'heart']} />
									</IconContainer>
									<Typography tag="h3" weight="bold">
										The Impact Behind Your Purchase
									</Typography>
									<Typography tag="p" weight="light">
										700 Rivers soaps are hand-crafted by Bangladeshi artisans that have escaped human trafficking and are now steadily employed with dignified work. When you use a 700 Rivers soap bar, you can get a fresh start to your day while helping our Bangladeshi artisans get a fresh start in life.
									</Typography>
									<Typography tag="p" weight="light">
										We at 700 Rivers provide our artisans with fair wages, a dignified career, and a safe work environment.
									</Typography>
									<S.LinkContainer to="/our-artisans">
										<Typography>Read more about our artisans</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.LinkContainer>
								</S.ContentPanel>
								<S.MediaPanel $wideText>
									<S.MediaPanelImage
										src={`${process.env.CDN_URL}/public/images/artisan-family-group.jpg`}
										alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
									/>
								</S.MediaPanel>
							</S.PanelSection>
						</S.ContentSection>

						{/* Product Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">Our Products</Typography>
							</S.TitleSection>

							{/* Product Collections */}
							<S.ProductCollections>

								{/* Soaps */}
								<S.ProductCollection className="animate" to="/shop/soaps">
									<S.CollectionImage
										$image={`${process.env.CDN_URL}/public/images/web-f7-700-Rivers_r1_03D_4163.jpg`}
									>
										<S.ImageBackground />
										<S.CollectionContent>
											<Typography tag="h4" weight="semibold">Fresh Start Soaps</Typography>
											<Typography variation="2" weight="regular">Handcrafted by women who have escaped human trafficking in Mymensingh, Bangladesh</Typography>
										</S.CollectionContent>
									</S.CollectionImage>
								</S.ProductCollection>

								{/* Bath Bombs */}
								<S.ProductCollection className="animate" to="/shop/bath-bombs">
									<S.CollectionImage
										$image={`${process.env.CDN_URL}/public/images/CalmingLavender_4d283744-42ad-41b1-8e10-3aa8033bd8da.jpg`}
									>
										<S.ImageBackground />
										<S.CollectionContent>
											<Typography tag="h4" weight="semibold">Bath Bombs</Typography>
											<Typography variation="2" weight="regular">Handmolded with love in Raleigh, North Carolina, USA</Typography>
										</S.CollectionContent>
									</S.CollectionImage>
								</S.ProductCollection>

								{/* Candles */}
								<S.ProductCollection className="animate" to="/shop/candles">
									<S.CollectionImage
										$image={`${process.env.CDN_URL}/public/images/web-0-700-Rivers_r1_03D_4140.jpg`}
									>
										<S.ImageBackground />
										<S.CollectionContent>
											<Typography tag="h4" weight="semibold">Shine Bright Candles</Typography>
											<Typography variation="2" weight="regular">Poured and packaged in Raleigh, North Carolina, USA</Typography>
										</S.CollectionContent>
									</S.CollectionImage>
								</S.ProductCollection>
							</S.ProductCollections>

							{/* Features Section */}
							<S.FeaturesGrid>

								{/* Feature #1 - Ethically Made */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'earth-asia']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Ethically Made
									</Typography>
									<Typography tag="p" weight="light">
										Our products are all made by women that have escaped human trafficking and are now being paid fair, living wages to handcraft our all-natural soaps.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #2 - Safe Ingredients */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasr', 'check']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Safe Ingredients
									</Typography>
									<Typography tag="p" weight="light">
										Our ingredients are all natural and we never include any unnecessary chemicals.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #3 - Eco-friendly */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'leaf']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Eco-friendly
									</Typography>
									<Typography tag="p" weight="light">
										Our ingredients and packaging are all sustainably sourced. Our packaging is not only plastic-free but also 100% biodegradable!
									</Typography>
								</S.FeatureBlock>

								{/* Feature #4 - Women-led */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'user-vneck-hair-long']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Women-led
									</Typography>
									<Typography tag="p" weight="light">
										We are female founded and focused on women’s empowerment around the world. 700 Rivers is also a member of the Female Founder’s Collective.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #5 - Cruelty-free */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'paw']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Cruelty-free
									</Typography>
									<Typography tag="p" weight="light">
										Our products are never tested on animals.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #6 - Speedy shipping */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'truck-fast']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Speedy shipping
									</Typography>
									<Typography tag="p" weight="light">
										Our team works hard to prepare your order as soon as we receive it!
									</Typography>
								</S.FeatureBlock>
							</S.FeaturesGrid>
						</S.ContentSection>

						{/* Founder Section */}
						<S.LargeContentSection style={{ textAlign: 'center' }}>
							<S.FounderSection>

								{/* Founder Photo */}
								<S.FounderPhotoContainer>
									<S.FounderPhoto src={`${process.env.CDN_URL}/public/images/catherina-gomes-founder.jpg`} />
								</S.FounderPhotoContainer>

								{/* Founder Intro */}
								<S.FounderIntro>
									<Typography tag="h2" weight="bold">
										Hi! My name is Cathy and I am the founder of 700 Rivers.
									</Typography>
									<Typography tag="p" weight="light">
										My family is originally from Bangladesh, a beautiful though impoverished land, and immigrated to America before I was born. Growing up in the USA, my family was able to achieve the American dream, where my sisters and I all got a great education and successful careers. This is significant to our family because in Bangladesh, women are not considered equal and their educations and careers are not prioritized as they are for men.
									</Typography>
									<Typography tag="p" weight="light">
										I have always known how fortunate I am for being able to grow up in a land of opportunity but I started thinking about the women in Bangladesh (and all over the world) that do not have the means to uproot their lives to a country of opportunity and what that means for their lives, their futures, and their children’s future. So I set out to create an opportunity.
									</Typography>
									<Typography tag="p" weight="light">
										Follow our journey
										{' '}
										<LocaleLink to="https://www.instagram.com/700rivers/" target="_blank">@700rivers</LocaleLink>
									</Typography>
								</S.FounderIntro>
							</S.FounderSection>
						</S.LargeContentSection>

						{/* Name Section */}
						<S.ContentSection>
							<S.PanelSection>
								<S.ContentPanel $wideText $white>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'water']} />
									</IconContainer>
									<Typography tag="h3" weight="bold">Why 700 Rivers?</Typography>
									<Typography tag="p" weight="light">
										Bangladesh is home to more than 700 rivers and waterways, which provide the country&apos;s people with life-giving water and fertile land. Much like our namesake, 700 Rivers seeks to enrich the lives of our workers and sustain our environment.
									</Typography>
									<S.LinkContainer to="/supply-chain">
										<Typography>Read more about our supply chain</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.LinkContainer>
								</S.ContentPanel>
								<S.MediaPanel $wideText>
									<S.MediaPanelImage
										src={`${process.env.CDN_URL}/public/images/bangladesh-river-noka.jpg`}
										alt="Standing on a traditional noka boat on a river in Bangladesh"
									/>
								</S.MediaPanel>
							</S.PanelSection>
						</S.ContentSection>

						{/* Social Media Feed */}
						<S.SocialFeedContainer>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">
									Come say hi on social
									{' '}
									<Emoji symbol="👋" size={2.5} label="Wave" />
								</Typography>
							</S.TitleSection>

							{/* Curator Feed */}
							<div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noreferrer">Powered by Curator.io</a></div>

						</S.SocialFeedContainer>

						{/* CTA Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">More from 700 Rivers</Typography>
							</S.TitleSection>

							{/* Card Container */}
							<S.CardContainer>

								{/* Seasonal Subscription */}
								<S.CardLink to="/seasonal-subscription">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'party-horn']} />
										<Typography tag="h4" weight="semibold">The ultimate treat yourself</Typography>
										<Typography tag="p" weight="light">Subscribe to get your seasonal favorites delivered every three months</Typography>
										<S.ActionContainer>
											<Typography>Explore the seasonal subscription</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>

								{/* Wholesale */}
								<S.CardLink to="/wholesale">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'tag']} />
										<Typography tag="h4" weight="semibold">700 Rivers Wholesale</Typography>
										<Typography tag="p" weight="light">Our team is happy to put together the perfect package for your customers</Typography>
										<S.ActionContainer>
											<Typography>Explore wholesale opportunities</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>
							</S.CardContainer>
						</S.ContentSection>

					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Mission.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Mission.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Mission;
