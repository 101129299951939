/*
 * File: GiftCardPreviewModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 3:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError, toastSuccess } from '../../utilities/toaster';

// Services
import { sendGiftCard } from '../../services/user';

// Components
import { Button } from '../Button';
import { ConfettiBurst } from '../ConfettiBurst';
import { LocaleLink } from '../LocaleLink';
import { Modal } from '../Modal';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';
import { IconContainer } from '../IconContainer';

// Styles
import * as S from './GiftCardPreviewModal.styles';


/**
 * Component
 */

export const GiftCardPreviewModal = ({
	className, isOpen, handleClose, giftCard
}) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showShareWidget, setShowShareWidget] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle share action
	const handleShareAction = async () => {

		// Get parameters
		const { email } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your account email address' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		sendGiftCard({ email, cardId: `${giftCard.id}` }).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Success! We\'ve sent an email to your friend with their discount code.');

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2000);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Show error message
			if (response?.data?.message === 'This email is already in use.') {
				toastError(uiMode, 'This email is already in use. Please choose another email.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your account email. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 100);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 5000);

			// Clear email
			setInputValues({});
		}
	}, [isOpen]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<IconContainer>
					<FontAwesomeIcon icon={['fasl', 'gift']} />
					<S.ConfettiTarget ref={confettiTargetRef} />
				</IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">Your discount code is here!</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">To use your discount code, simply copy and paste your code into the promo code box on the checkout page. Happy shopping!</Typography>

				{/* Gift Card Container */}
				<S.GiftCardContainer>
					<S.RewardGiftCard>
						<S.LogoMark
							alt="700 Rivers Logo"
							title="700 Rivers Logo"
							src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-mark.png`}
						/>
						<Typography tag="h3" weight="semibold">700 Rivers Discount Code</Typography>
						<S.CodeContainer>
							<S.Code>
								<Typography tag="h4" weight="medium">{giftCard?.code}</Typography>
							</S.Code>
							<S.Value>
								<Typography tag="h4" weight="medium">
									$
									{giftCard?.balance}
								</Typography>
							</S.Value>

						</S.CodeContainer>
					</S.RewardGiftCard>
				</S.GiftCardContainer>

				{/* Actions */}
				{!showShareWidget ? (
					<S.ActionContainer>
						<Button onClick={() => { setShowShareWidget(true); }} variant="text"><Typography variation="2" weight="medium">Send discount code to a friend</Typography></Button>
					</S.ActionContainer>
				) : (
					<S.ShareContainer>

						{/* Inputs */}
						<TextInput
							label="Friend's Email"
							placeholder="e.g. hello@acme.com"
							name="email"
							error={emailError}
							value={inputValues.email || ''}
							containerClassName="modalInput"
							autoComplete="email"
							onFocus={() => { setEmailError(null); }}
							onKeyUp={() => { setEmailError(null); }}
							onBlur={() => { setEmailError(null); }}
							onChange={handleOnChange}
						/>

						{/* Actions */}
						<Button disabled={isLoading} isLoading={isLoading} onClick={handleShareAction} variant="solid"><Typography variation="2" weight="medium">Share discount code</Typography></Button>

					</S.ShareContainer>
				)}

				{/* Disclaimer */}
				<S.DisclaimerContainer>
					<Typography variation="3" weight="light" tag="p">
						Applies to orders online at sevenhundredrivers.com only. Valid on one single order only. Choosing to share this discount code will send an email to the recipient with instructions for redeeming.
					</Typography>
					<Typography variation="3" weight="light" tag="p" style={{ marginTop: '8px' }}>
						By using or sharing this discount code, you agree to 700 Rivers&apos;
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
						{' '}
						and
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Privacy Policy</LocaleLink>
						.
					</Typography>
				</S.DisclaimerContainer>
			</S.ModalInner>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</Modal>
	);
};


/**
 * Configuration
 */

GiftCardPreviewModal.displayName = 'GiftCardPreviewModal';
GiftCardPreviewModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	giftCard: PropTypes.shape(),
};
GiftCardPreviewModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	giftCard: null
};
