/*
 * File: ReferFriend.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 5, 2023 at 9:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;

	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
`;

export const HeroSection = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentSection = styled.div`
	padding: 80px 0px;
	max-width: 1200px;
	width: 88%;
	margin: 0px auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: ${({ tall }) => (tall ? '12% 8rem' : '8% 8rem')};
		margin: ${({ center }) => (center ? '0px auto' : '0px 0px')};
		width: 100%;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 50%;
	text-align: left;
	margin: 0px auto;
	width: 88%;
	padding: 100px 0px;
	position: relative;

	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 8rem;
	}
	h5 {
		margin: 0px 0px 5px;
	}
	h4 {
		margin: 12px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	p {
		margin: 20px 0 0px;
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: 50%;
`;

export const DetailPanel = styled.div`
	position: relative;
	flex: 50%;
`;

export const HeroImage = styled.img`
	width: 100%;
`;

export const Content = styled.div`
	max-width: 600px;
	width: 100%;
	margin: ${({ center }) => (center ? '0px auto' : '0px 0px')};

	* {
		text-align: ${({ center }) => (center ? 'center' : 'left')};
	}
	.subtitle {
		color: ${({ theme }) => theme.secondaryText};
		margin: 7px 0px 0px;
	}
`;

export const Form = styled.form`
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

	button {
		margin: 25px 0px 0px;
		width: 100%;
	}
	.formInput {
		margin: 20px 0 0;
	}
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const CheckboxRow = styled.div`
	display: flex;
	margin: 20px 0 0 0;

	span {
		flex-grow: 1;
		text-align: left;
		margin: 0 0 0 12px;
		color: ${({ theme }) => theme.primaryText};
		position: relative;
		top: -1px;

		a,
		a:link,
		a:visited {
			color: inherit;
		}
	}
`;

export const CheckboxContainer = styled.div`
	margin: 25px 0px 35px 0px;
`;

export const ConfirmationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const ConfirmationPane = styled.div`
	text-align: center;
	position: relative;
	overflow: hidden;

	button {
		margin: 40px 0px 0px;
		width: 100%;
	}
	h3 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfirmationContent = styled.div`
	position: relative;
	z-index: 2;
	text-align: center !important;

	* {
		text-align: center !important;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
`;

export const ContentContainer = styled.div`
	margin: 0px 0px 20px 0px;
`;
