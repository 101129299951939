/*
 * File: SignUp.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 10, 2023 at 3:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
`;

export const ContentPadding = styled.div`
	margin: 0px auto;
	max-width: 500px;
	width: 100%;
	padding: 80px 0px 120px;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	margin: 0px 0px 30px 0px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const LogoContainer = styled.div`
	position: relative;
	margin: 0px 0px 5px 0px;
`;

export const LogoImage = styled.img`
	width: 90px;
	height: auto;
`;

export const InputContainer = styled.div`
	margin: 0px 0px 25px 0px;
`;

export const DisclaimerContainer = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.6rem !important;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 24px;
	right: 50%;
`;
