/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 26, 2023 at 8:41 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 3, 2023 at 10:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getBlogPost = ({ slug }) => api.get(`/blog/posts/${slug}`);

export const getBlogPosts = ({
	query, excludedStories, relatedId, pageCursor, pageSize = 12
}) => api.get('/blog/posts', {
	params: {
		query, pageCursor, pageSize, excludedStories, relatedId
	}
});
export const getBlogTopic = ({ slug }) => api.get(`/blog/topics/${slug}`);

export const getBlogTopics = () => api.get('/blog/topics');
