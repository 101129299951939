/*
 * File: ProductCollection.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 17, 2023 at 12:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { ProductCard } from '../ProductCard';
import { Typography } from '../Typography';
import { LocaleLink } from '../LocaleLink';

// Styles
import * as S from './ProductCollection.styles';


/**
 * Component
 */

export const ProductCollection = ({
	className, products, showMarkup, showFilterBar, showMore, moreLink, productName
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return products.length > 0 ? (
		<>
			{showFilterBar && <S.FilterBar />}
			<S.Wrapper>
				<S.ProductContainer className={className}>
					{products.map((productObj) => <ProductCard product={productObj} key={productObj.id} showMarkup={showMarkup} />)}
				</S.ProductContainer>
				{showMore && (
					<S.ShowMoreLink to={moreLink}>
						<S.ShowMoreContainer>
							<S.ShowMoreButton>
								<FontAwesomeIcon icon={['fass', 'arrow-right']} />
							</S.ShowMoreButton>
							<Typography tag="span" variation="2">See More</Typography>
						</S.ShowMoreContainer>
					</S.ShowMoreLink>
				)}
			</S.Wrapper>
		</>
	) : (
		<S.EmptyCard>
			<FontAwesomeIcon className="empty-icon" icon={['fasl', 'box-open']} size="5x" />
			<Typography tag="h2" weight="semibold" center>
				We&apos;re restocking our
				{' '}
				{productName}
			</Typography>
			<Typography tag="p" weight="light" center>
				Don&apos;t worry! More
				{' '}
				{productName}
				{' '}
				will be in stock soon. Come back in a few days to see our refreshed selection.
			</Typography>
			<LocaleLink to="/shop/all">
				<S.ActionButton variant="outline">
					Shop All Products
					<FontAwesomeIcon icon={['fass', 'arrow-right']} />
				</S.ActionButton>
			</LocaleLink>
		</S.EmptyCard>
	);
};


/**
 * Configuration
 */

ProductCollection.displayName = 'ProductCollection';
ProductCollection.propTypes = {
	className: PropTypes.string,
	products: PropTypes.arrayOf(PropTypes.shape()),
	showMarkup: PropTypes.bool,
	showFilterBar: PropTypes.bool,
	showMore: PropTypes.bool,
	moreLink: PropTypes.string,
	productName: PropTypes.string
};
ProductCollection.defaultProps = {
	className: null,
	products: [],
	showMarkup: false,
	showFilterBar: true,
	showMore: false,
	moreLink: null,
	productName: 'products'
};
