/*
 * File: Login.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 11:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastMessage } from '../../../utilities/toaster';

// Services
import { loginRewardsProgram } from '../../../services/user';

// Components
import {
	Meta, AppNavigation, Button, LocaleLink, Typography, TextInput, IconContainer
} from '../../../components';

// Styles
import * as S from './Login.styles';


/**
 * Component
 */

const Login = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Create state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Handle login action
	const handleLogin = async () => {

		// Get parameters
		const {
			email, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}

		// Set submitting state
		setIsSubmitting(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('login_rewards'); } catch (e) {}

		// Log in user
		loginRewardsProgram({
			email,
			question_input: questionInput,
			_magic,
			_magic2,
			recaptchaToken,
		}).then(() => {

			// Clear inputs
			setInputValues({});

			// Set submitting state
			setIsSubmitting(false);

			// Show confirmation
			toastMessage(uiMode, 'Welcome! We\'ve sent a magic link to your email inbox to access your 700 Rivers rewards!');

		}).catch(({ response }) => {

			// Set loading state
			setIsSubmitting(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble logging you in. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>
					<S.ContentPadding>

						{/* Icon */}
						<S.LogoContainer>
							<IconContainer>
								<FontAwesomeIcon icon={['fal', 'hand-wave']} />
							</IconContainer>
						</S.LogoContainer>

						{/* Content */}
						<Typography tag="h3" weight="bold">Welcome back to 700 Rivers!</Typography>
						<Typography tag="p" variation="1" weight="light" className="subtitle">Log in to your rewards account to earn points on each one of your purchases with 700 Rivers. You can spend your points on discounts, gift cards, and more.</Typography>

						{/* Input Container */}
						<S.InputContainer>

							{/* Email Input */}
							<TextInput
								placeholder="Email"
								name="email"
								type="text"
								error={emailError}
								value={inputValues.email || ''}
								autoComplete="email"
								containerClassName="formInput"
								onFocus={() => { setEmailError(null); }}
								onKeyUp={() => { setEmailError(null); }}
								onBlur={() => { setEmailError(null); }}
								onChange={handleOnChange}
								style={{ marginTop: '20px' }}
							/>

						</S.InputContainer>

						{/* Actions */}
						<S.ActionContainer>
							<Button disabled={isSubmitting} isLoading={isSubmitting} variant="solid" onClick={handleLogin}>Log In</Button>
							<LocaleLink to="/rewards/join"><Typography variation="2">Sign up for 700 Rivers rewards</Typography></LocaleLink>
						</S.ActionContainer>

						{/* Disclaimer */}
						<S.DisclaimerContainer>
							<Typography variation="3" weight="light" tag="p" style={{ marginTop: '8px' }}>
								By logging in, you agree to 700 Rivers&apos;
								{' '}
								<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
								{' '}
								and
								{' '}
								<LocaleLink to="/legal/terms" target="_blank">Privacy Policy</LocaleLink>
								.
							</Typography>
						</S.DisclaimerContainer>
					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Login.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Login.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Login;
