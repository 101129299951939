/*
 * File: SupplyChain.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 26, 2023 at 4:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography, IconContainer, LocaleLink
} from '../../../components';

// Constants
import { mediumBreakpoint } from '../../../styles/constants';

// Styles
import * as S from './SupplyChain.styles';


/**
 * Component
 */

const SupplyChain = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Check if mobile screen size
	const isMedium = useMediaQuery(`(max-width: ${mediumBreakpoint}em)`);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>
						<IconContainer>
							<FontAwesomeIcon icon={['fal', 'earth-asia']} />
						</IconContainer>
						<Typography tag="h1" weight="bold">
							Made in an amazing country by even more amazing women.
						</Typography>
						<Typography tag="h4" weight="light">
							At 700 Rivers, we believe in an ethical and transparent supply chain. Our soaps and packaging are made in the beautiful country of Bangladesh by women who have escaped human trafficking and are creating new opportunities for themselves and their families.
						</Typography>
					</S.HeroSection>

					{/* Bangladesh Section */}
					<S.ContentSection>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fal', 'hand-holding-hand']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									Designed in the USA.
									<br />
									Ethically handmade in Bangladesh.
								</Typography>
								<Typography tag="p" weight="light">
									All 700 Rivers&apos; soaps and self-care products are designed right here in Raleigh, North Carolina, USA. Our soaps are then handmade in Bangladesh by our artisans: women who have escaped and overcome human trafficking. Our candles, bath bombs, and self-care products are crafted here in the USA by our team.
								</Typography>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/bangladesh-supply-chain-map.jpg`}
									alt="Standing on a traditional noka boat on a river in Bangladesh"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Our Process Section */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">Our supply chain process.</Typography>
							<Typography tag="p" weight="light">From our artisans&apos; hands to yours, we strive to make our supply chain as simple and good for our planet as possible.</Typography>
						</S.TitleSection>

						{/* Steps Section */}
						<S.StepGrid>

							{/* Step #1 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'seedling']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Natural ingredients sourced
									</Typography>
									<Typography tag="p" weight="light">
										It all starts with natural, eco-friendly ingredients, sourced from all over the world. This includes moisturizing coconut oil, aloe vera, and even the natural jute fiber we use to make our packaging.
									</Typography>
								</S.StepContent>
							</S.StepBlock>

							{/* Step #2 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'hand-holding-hand']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Soaps handmade in Mymensingh
									</Typography>
									<Typography tag="p" weight="light">
										Our soaps are lovingly handcrafted in Mymensingh, Bangladesh by women who have escaped and overcome human trafficking. We strive to give our artisans a fresh start in life through fair, living wages.
									</Typography>
								</S.StepContent>
							</S.StepBlock>

							{/* Step #3 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'box-open']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Packaging made in Feni
									</Typography>
									<Typography tag="p" weight="light">
										Our packaging is handmade by the women of Shuktara Handmade Paper Project in Feni, Bangladesh. 700 Rivers’ soap boxes are handmade from jute, a vegetable fiber commonly grown in Bangladesh.
									</Typography>
								</S.StepContent>
							</S.StepBlock>

							{/* Step #4 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'flag-usa']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Arrives in the USA
									</Typography>
									<Typography tag="p" weight="light">
										Our fair trade partners in Dhaka, Bangladesh package and prepare the finished soaps for their journey overseas. Once in the USA, they go through customs before arriving at our warehouse.
									</Typography>
								</S.StepContent>
							</S.StepBlock>

							{/* Step #5 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'box']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Packaged by our warehouse team
									</Typography>
									<Typography tag="p" weight="light">
										700 Rivers partners with
										{' '}
										<LocaleLink to="https://poweredbygabis.org/" target="_blank">Gabi&apos;s</LocaleLink>
										, a non-profit organization in Raleigh, North Carolina, to help us package all of our orders. Gabi&apos;s employs 40+ people with special abilities to find fulfullment in everything they do.
									</Typography>
								</S.StepContent>
							</S.StepBlock>

							{/* Step #6 */}
							<S.StepBlock>
								<S.StepArrow>
									<FontAwesomeIcon icon={['fass', isMedium ? 'arrow-down' : 'arrow-right']} />
								</S.StepArrow>
								<S.StepContent>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'truck-fast']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Ships with love to you!
									</Typography>
									<Typography tag="p" weight="light">
										As soon as your order arrives, our team packages it, includes a card with our story and mission, and sends it on its way to your doorstep.
									</Typography>
								</S.StepContent>
							</S.StepBlock>
						</S.StepGrid>
					</S.ContentSection>

					{/* Soap Section */}
					<S.ContentSection style={{ paddingBottom: '0px' }}>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fal', 'user-vneck-hair-long']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									Who Makes Our Soaps?
								</Typography>
								<Typography tag="p" weight="light">
									Our soaps are made in Mymensingh, Bangladesh by artisans that have escaped human trafficking. 700 Rivers works to help employ 22 artisans with fair, living wages and a safe working environment. In addition to job training and fair employment, our artisans also have access to mental health counseling to help them on their journey of healing as they move forward from a traumatic past.
								</Typography>
								<Typography tag="p" weight="light">
									Our artisans are inspiring to us all, but most importantly, they are an inspiration to each other. Once our newly hired artisans have the chance to meet and work with other women within the organization that have overcome the trauma of their past, it gives each new artisan hope that they can overcome their past as well.
								</Typography>
								<S.LinkContainer to="/our-artisans">
									<Typography>Read more about our artisans</Typography>
									<FontAwesomeIcon icon={['fass', 'arrow-right']} />
								</S.LinkContainer>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/artisan-soap-interview.jpg`}
									alt="A 700 Rivers artisan holding a 700 Rivers soap"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Packaging Section */}
					<S.ContentSection style={{ paddingTop: '0px', paddingBottom: '0px' }}>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'seedling']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									Who Makes Our Packaging?
								</Typography>
								<Typography tag="p" weight="light">
									Our packaging is handmade by the women of Shuktara Handmade Paper Project in Feni, Bangladesh which employs 32 artisans. This was the first handmade paper project in Bangladesh and started in 1989. As the use of paper has declined in recent years, 700 Rivers has partnered with these women to ensure they can continue to work.  700 Rivers’ soap boxes are handmade from jute, a vegetable fiber commonly grown in Bangladesh. The jute is converted into pulp and then into sheets of paper which are cut and folded into the soap boxes. Since the soap boxes are made from vegetable fiber, they are eco-friendly and 100% biodegradable.
								</Typography>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/hands-holding-soap.jpg`}
									alt="Artisan hands holding a 700 Rivers Orange Turmeric soap"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Candle & Bath Bomb Section */}
					<S.ContentSection style={{ paddingTop: '0px' }}>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'fire']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									Who Makes Our Candles & Bath Bombs?
								</Typography>
								<Typography tag="p" weight="light">
									Our candles and bath bombs are handmade right here in Raleigh, North Carolina, USA by our 700 Rivers team members. A portion of the sale of each candle and bath bomb goes directly to the funding of scholarships for young Bangladeshi children to attend school.
								</Typography>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/orange-clove-candles.jpg`}
									alt="700 Rivers Orange Clove candles with natural ingredients"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* CTA Section */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">More from 700 Rivers</Typography>
						</S.TitleSection>

						{/* Card Container */}
						<S.CardContainer>

							{/* Mission */}
							<S.CardLink to="/mission">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'seedling']} />
									<Typography tag="h4" weight="semibold">Explore Our Mission</Typography>
									<Typography tag="p" weight="light">Our mission is to create high-quality, eco-friendly products that employ women across the world with fair wages</Typography>
									<S.ActionContainer>
										<Typography>Read more about our mission</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>

							{/* Artisans */}
							<S.CardLink to="/our-artisans">
								<S.OptionCard className="animate">
									<FontAwesomeIcon icon={['fasl', 'heart']} />
									<Typography tag="h4" weight="semibold">Our Artisans</Typography>
									<Typography tag="p" weight="light">We employ 20+ artisans in Mymensingh, Bangladesh who have all escaped and overcome human trafficking</Typography>
									<S.ActionContainer>
										<Typography>Meet our artisans</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-right']} />
									</S.ActionContainer>
								</S.OptionCard>
							</S.CardLink>
						</S.CardContainer>
					</S.ContentSection>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

SupplyChain.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
SupplyChain.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default SupplyChain;
