/*
 * File: Contact.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 18, 2024 at 3:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';
import { useLocation } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { postContactSubmission } from '../../../services/contact';

// Components
import {
	Meta, AppNavigation, SchemaScript, TextInput, Emoji, Checkbox, TextArea, Select, ConfettiBurst, Typography, Turnstile
} from '../../../components';

// Styles
import * as S from './Contact.styles';


/**
 * Constants
 */

const INTERESTED_OPTIONS = [
	{
		label: 'Help with our website',
		value: 'Help with our website',
		hash: 'help'
	},
	{
		label: 'Returns & Exchanges',
		value: 'Returns & Exchanges',
		hash: 'returns'
	},
	{
		label: 'Interested in Candle Making Classes',
		value: 'Interested in Candle Making Classes',
		hash: 'candlemaking'
	},
	{
		label: 'Account Issues',
		value: 'Account Issues',
		hash: 'account'
	},
	{
		label: 'Feedback or Suggestions',
		value: 'Feedback or Suggestions',
		hash: 'feedback'
	},
	{
		label: 'Wholesale Ordering from 700 Rivers',
		value: 'Wholesale Ordering from 700 Rivers',
		hash: 'wholesale'
	},
	{
		label: 'Press and Media Inquiries',
		value: 'Press and Media Inquiries',
		hash: 'press'
	},
	{
		label: 'Other',
		value: 'Other',
		hash: 'other'
	}
];


/**
 * Component
 */

const Contact = ({ meta, locale }) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [interestedError, setInterestedError] = useState(null);
	const [messageError, setMessageError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showContactForm, setShowContactForm] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [turnstileToken, setTurnstileToken] = useState(null);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get location state
	const location = useLocation();
	const { hash } = location;

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle submit contact form function
	const handleSubmitContactForm = async () => {

		// Get parameters
		const {
			name, email, interested, message, marketingAllowed, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}
		if (!interested || validator.isEmpty(interested, { ignore_whitespace: true })) {
			setInterestedError({ message: 'Please select an option for how we can help' });
			return;
		}
		if (!message || validator.isEmpty(message, { ignore_whitespace: true })) {
			setMessageError({ message: 'Please enter a few details about your request' });
			return;
		}

		// Validate Turnstile token
		if (!turnstileToken || validator.isEmpty(turnstileToken, { ignore_whitespace: true })) {
			toastError(uiMode, 'Please verify the CAPTCHA to continue.');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('submit_contact_form'); } catch (e) {}

		// Post contact submission
		postContactSubmission({
			name,
			email,
			interested,
			message,
			marketingAllowed,
			recaptchaToken,
			turnstileToken,
			question_input: questionInput,
			_magic,
			_magic2
		}).then(() => {

			// Hide form
			setShowContactForm(false);
			setTimeout(() => {

				// Clear inputs
				setInputValues({});

				// Set loading state
				setIsLoading(false);

				// Show confirmation
				setShowConfirmation(true);
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

			}, 300);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble sending your message. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle actions on component load
	useEffect(() => {

		// Set default option from url if necessary
		if (hash) {
			const selectedOption = INTERESTED_OPTIONS.find((option) => option.hash === hash.replace('#', ''));
			if (selectedOption) {
				setInputValues({ ...inputValues, interested: [selectedOption.value] });
			}
		}
	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Schema.org ContactPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': ['WebPage', 'ContactPage'],
				'@id': `${process.env.APP_URL}/contact/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}${stateLocale.localePath}/contact`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.sevenhundredrivers.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Content Pane */}
					<S.ContentPane>
						<S.ContentContainer>

							{/* Title */}
							<Emoji symbol="👋" size={3.5} label="Wave" />
							<Typography tag="h1" weight="bold">Designed for you in Raleigh.</Typography>
							<Typography tag="p">
								We&apos;re proud to be a lifestyle company based in North Carolina, where the summers are hot, the hot sauce is hotter, and Barbecue is a way of life. Welcome y&apos;all!
							</Typography>

						</S.ContentContainer>
					</S.ContentPane>

					{/* Form Pane */}
					<S.FormPane>

						{/* Contact Form */}
						<S.Form className="animate" isVisible={showContactForm}>

							{/* Inputs */}
							<TextInput
								label="Name"
								name="name"
								type="text"
								error={nameError}
								value={inputValues.name || ''}
								autoComplete="name"
								containerClassName="formInput"
								onFocus={() => { setNameError(null); }}
								onKeyUp={() => { setNameError(null); }}
								onBlur={() => { setNameError(null); }}
								onChange={handleOnChange}
								smartField
							/>
							<TextInput
								label="Email Address"
								name="email"
								type="text"
								error={emailError}
								value={inputValues.email || ''}
								autoComplete="email"
								containerClassName="formInput"
								onFocus={() => { setEmailError(null); }}
								onKeyUp={() => { setEmailError(null); }}
								onBlur={() => { setEmailError(null); }}
								onChange={handleOnChange}
								smartField
							/>

							{/* Inputs */}
							<Select
								label="How can we help?"
								name="interested"
								options={INTERESTED_OPTIONS}
								error={interestedError}
								values={Array.isArray(inputValues.interested) ? inputValues.interested : [inputValues.interested || '']}
								containerClassName="formInput"
								onFocus={() => { setInterestedError(null); }}
								onKeyUp={() => { setInterestedError(null); }}
								onBlur={() => { setInterestedError(null); }}
								onChange={handleOnChange}
								smartField
							/>
							<TextArea
								label="Any details to share?"
								name="message"
								type="text"
								rows={4}
								error={messageError}
								value={inputValues.message || ''}
								containerClassName="formInput"
								onFocus={() => { setMessageError(null); }}
								onKeyUp={() => { setMessageError(null); }}
								onBlur={() => { setMessageError(null); }}
								onChange={handleOnChange}
								smartField
							/>

							{/* Question Input */}
							<input
								id="question_input"
								name="question_input"
								type="text"
								value={inputValues.question_input || ''}
								onChange={handleOnChange}
								className="question-input"
							/>

							{/* Honeypots */}
							<input
								type="text"
								name="_magic"
								value={inputValues._magic || ''}
								onChange={handleOnChange}
								className="gotcha-field"
							/>
							<input
								type="text"
								name="_magic2"
								value={inputValues._magic2 || ''}
								onChange={handleOnChange}
								style={{ display: 'none' }}
							/>

							{/* Checkboxes */}
							<S.CheckboxContainer>
								<S.CheckboxRow>
									<Checkbox
										name="marketingAllowed"
										value={inputValues.marketingAllowed || false}
										onChange={handleOnChange}
									/>
									<Typography variation="2" weight="regular">
										Yes! I would like to receive updates, special offers, and other information from 700 Rivers
									</Typography>
								</S.CheckboxRow>
							</S.CheckboxContainer>

							{/* Continue Action */}
							<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSubmitContactForm(); }} size="large" className="floatShadow">Send Message</S.ActionButton>

							{/* Turnstile */}
							<Turnstile className="turnstile" setVerificationValue={setTurnstileToken} />

						</S.Form>

						{/* Confirmation Content */}
						<S.ConfirmationContainer className="animate" isVisible={showConfirmation}>

							{/* Content Pane */}
							<S.ConfirmationPane>

								{/* Content */}
								<S.ConfirmationContent>
									<Emoji symbol="🎉" size={3.5} label="Celebration" />
									<S.ConfettiTarget ref={confettiTargetRef} />
									<Typography tag="h3" weight="bold">Thanks for reaching out!</Typography>
									<Typography tag="p" variation="1">One of our team members will follow up shortly. In the meantime, check out our blog for other helpful topics.</Typography>
								</S.ConfirmationContent>

								{/* Content Background */}
								<S.ConfirmationBackground />

							</S.ConfirmationPane>
						</S.ConfirmationContainer>
					</S.FormPane>
				</S.Wrapper>
			</AppNavigation>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

Contact.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Contact.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Contact;
