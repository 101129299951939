/*
 * File: ConfirmationModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 10:38 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Button } from '../Button';
import { IconContainer } from '../IconContainer';
import { Modal } from '../Modal';
import { Typography } from '../Typography';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';


// Styles
import * as S from './ConfirmationModal.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Component
 */

export const ConfirmationModal = ({
	className, isOpen, handleClose, type, title, message, primaryButtonTitle, secondaryButtonTitle, primaryButtonAction, secondaryButtonAction, isLoading
}) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<IconContainer
					$type={type}
					iconColor={type === 'danger' ? currentTheme.statusDangerBase : currentTheme.primaryText}
				>
					<FontAwesomeIcon icon={type === 'danger' ? ['fas', 'triangle-exclamation'] : ['fas', 'question']} />
				</IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">{title}</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">{message}</Typography>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} variant="outline" variation="secondary" onClick={secondaryButtonAction}>{secondaryButtonTitle}</Button>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" variation={type === 'danger' ? 'warning' : 'default'} onClick={primaryButtonAction}>{primaryButtonTitle}</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	type: PropTypes.oneOf(['normal', 'danger']),
	title: PropTypes.string,
	message: PropTypes.string,
	primaryButtonTitle: PropTypes.string,
	secondaryButtonTitle: PropTypes.string,
	primaryButtonAction: PropTypes.func,
	secondaryButtonAction: PropTypes.func,
	isLoading: PropTypes.bool
};
ConfirmationModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	type: 'normal',
	title: 'Are you sure?',
	message: 'Are you sure you want to do this? This cannot be undone?',
	primaryButtonTitle: 'Yes',
	secondaryButtonTitle: 'Cancel',
	primaryButtonAction: null,
	secondaryButtonAction: null,
	isLoading: false
};
