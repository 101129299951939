/*
 * File: Topbar.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 2:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValues } from '../../../styles/colors';

// Components
import { IconButton } from '../../IconButton';
import { LocaleLink } from '../../LocaleLink';

// Constants
import { mobileBreakpoint, AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme }) => theme.primaryBackground};
	z-index: 999999999;
	box-shadow: ${ColorValues.navigationShadow};
	border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	padding: 0.78rem 0;
	pointer-events: all;
	position: fixed !important;
	top: ${({ $top }) => $top}px !important;
	width: 100%;
	transition: top 0.3s;
	-webkit-transition: top 0.3s;
	-moz-transition: top 0.3s;
	-o-transition: top 0.3s;

	* :not(svg,path) {
		height: 100%;
	}
	button {
		pointer-events: auto;
		align-items: center;
	}
	.searchBar {
		max-width: none;
    	width: 100%;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			max-width: 400px;
		}
	}
	.paddingLeft {
		margin-left: 20px;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 40%;
	gap: ${({ $largeGap }) => ($largeGap === true ? '32px' : '22px')};

	&.left {
		justify-content: start;
	}
	&.right {
		justify-content: end;
	}
	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
		width: 20%;
	`}
`;

export const NavigationTabContainer = styled.div`
	height: ${AppNavTopbarHeight}px !important;

	& div {
		display: flex;
	}
`;

export const LogoImage = styled.img`
	padding: 0.1rem 0px 0.1rem 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const NavigationToggle = styled(IconButton)`
	height: auto;
`;

export const CartContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	position: relative;
	cursor: pointer;

	* {
		height: auto !important;
	}
`;

export const CartCount = styled.div`
	position: absolute;
	font-size: 0.65rem;
	bottom: 9px;
	left: 0px;
	width: 100%;
	text-align: center;
`;

export const NavigationLink = styled(LocaleLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.primaryText};
	text-transform: uppercase;
	height: auto !important;

	span {
		font-size: 0.8rem;
		letter-spacing: 0.08rem;
	}
`;

export const AnnouncementBar = styled.div`
	padding: 8px 20px 12px;
	text-align: center;
	position: fixed !important;
	top: 0px !important;
	width: 100%;
	background-color: ${({ theme }) => theme.secondaryBackground};
	z-index: 999;

	* {
		color: ${({ theme }) => theme.primaryText};
	}
`;
