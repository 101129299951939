/*
 * File: CartSidebar.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 15, 2023 at 7:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Padding } from '../../Padding';
import { Cart } from '../../Cart';

// Styles
import * as S from './CartSidebar.styles';


/**
 * Component
 */

export const CartSidebar = ({
	toggleOverlay, className, isVisible
}) => {

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Render component
	return (
		<S.Sidebar className={className}>
			<Padding>

				{/* Mobile Navigation */}
				<S.MobileNavigation>
					<S.NavigationToggle icon={['fal', 'times']} size={1.5} onClick={() => { toggleOverlay(); }} />
				</S.MobileNavigation>

				{/* Cart */}
				<Cart data={{ cart }} isSmall emptyAction={() => { toggleOverlay(); }} isVisible={isVisible} />

			</Padding>
		</S.Sidebar>
	);
};


/**
 * Configuration
 */

CartSidebar.displayName = 'CartSidebar';
CartSidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func,
	isVisible: PropTypes.bool
};
CartSidebar.defaultProps = {
	className: null,
	toggleOverlay: null,
	isVisible: false
};
