/*
 * File: Cart.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 10:38 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 15, 2023 at 7:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getCart } from '../../../services/cart';

// Slices
import { pushComponentState } from '../../../store/slices/component/component.slice';

// Components
import {
	Meta, AppNavigation, Cart as BaseCart, SchemaScript
} from '../../../components';

// Styles
import * as S from './Cart.styles';


/**
 * Component
 */

const Cart = ({ meta, locale }) => {

	// Create reference for components
	const isMounted = useRef(true);

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [componentData, setComponentData] = useState({ cart });

	// Use hooks
	const dispatch = useDispatch();

	// Get current component data state
	const componentState = useSelector((state) => (state.component));

	// Initialize data
	const initializeData = async () => {

		// Update page status
		setPageStatus('loading');

		// Initialize component data
		try {
			const { data: cartData } = await getCart();

			// Check if mounted
			if (isMounted.current) {

				// Set component data
				setComponentData(cartData);

				// Push new component state
				dispatch(pushComponentState(cartData));

				// Update page status
				setPageStatus('success');
			}

		} catch (e) {

			// Update page status
			if (isMounted.current) {
				setPageStatus('error');
			}
		}
	};

	// Handle cart change action
	useEffect(() => {

		// Update component data
		setComponentData({ cart });

	}, [cart]);

	// Handle component initialization
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Initialize data
			initializeData();
		}
	}, [componentState, pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={componentData} locale={stateLocale} />

			{/* Schema.org CheckoutPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org/',
				'@type': 'CheckoutPage',
				'@id': `${process.env.APP_URL}/cart/#WebPage`,
				name: meta.title,
				description: meta.description,
				url: `${process.env.APP_URL}/cart`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				publisher: {
					'@id': 'https://www.sevenhundredrivers.com/#Organization',
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>
					<BaseCart data={componentData} status={pageStatus} isVisible={pageStatus === 'success'} />
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Cart.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Cart.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Cart;
