/*
 * File: ShopSoaps.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: December 14, 2023 at 10:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getCollection, getProductsForCollection } from '../../../services/product';

// Components
import {
	Meta, AppNavigation, Spinner, ErrorComponent, Typography, ImageBanner
} from '../../../components';

// Constants
import { PRODUCT_COLLECTIONS } from '../../../../Constants';

// Styles
import * as S from './ShopSoaps.styles';


/**
 * Helpers
 */

const sortSoapSizes = (products, tag) => {
	if (!products) return [];
	return products.filter((product) => (product.tags || []).includes(tag));
};


/**
 * Component
 */

const ShopSoaps = ({ meta, locale, data }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.collection ? 'idle' : 'success');
	const [accessoriesProductStatus, setAccessoriesProductStatus] = useState('idle');
	const [collection, setCollection] = useState(data?.collection);
	const [accessoriesProducts, setAccessoriesProducts] = useState([]);
	const [products100grams, setProducts100grams] = useState(sortSoapSizes(data?.products, '100 gram'));
	const [products50grams, setProducts50grams] = useState(sortSoapSizes(data?.products, '50 gram'));

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch collection
			const { data: collectionData } = await getCollection(PRODUCT_COLLECTIONS.SOAPS.handle);

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.SOAPS.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setCollection(collectionData.collection);
				setProducts100grams(sortSoapSizes(productData.products, '100 gram'));
				setProducts50grams(sortSoapSizes(productData.products, '50 gram'));

				// Update page statue
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle fetch data for accessories component
	const fetchAccessoriesProducts = async () => {

		// Update component status
		setAccessoriesProductStatus('loading');
		try {

			// Fetch products for collection
			const { data: productData } = await getProductsForCollection(PRODUCT_COLLECTIONS.SOAP_CANDLE_ACCESSORIES.id);

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setAccessoriesProducts(productData.products);

				// Update component statue
				setAccessoriesProductStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update component status
				setAccessoriesProductStatus('error');
			}
		}
	};

	// Handle actions on accessories products component state change
	useEffect(() => {

		// Ensure component loading is not complete
		if (accessoriesProductStatus === 'idle') {

			// Fetch data state for component
			fetchAccessoriesProducts();
		}
	}, [accessoriesProductStatus]);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>

				{/* Banner Section */}
				<ImageBanner images={[
					{
						src: `${process.env.CDN_URL}/public/images/web-f7-700-Rivers_r1_03D_4163.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/hands-holding-soap.jpg`,
					},
					{
						src: `${process.env.CDN_URL}/public/images/web-1-700-Rivers_r1_03D_4145.jpg`,
					}
				]}
				/>

				{/* Title Section */}
				<S.TitleSection>
					<Typography tag="h1" weight="semibold">Fresh Start Soaps</Typography>
					<S.Description dangerouslySetInnerHTML={{ __html: collection.bodyHtml }} />
				</S.TitleSection>

				{/* Fresh Start Soaps - Soap Bars */}
				<S.ContentSection>
					<S.ContentTitle>
						<Typography tag="h3" weight="bold">Fresh Start Soap Bars</Typography>
					</S.ContentTitle>
					<S.ProductCollection products={products100grams} showMarkup={false} showFilterBar productName="fresh start soaps" />
				</S.ContentSection>

				{/* Fresh Start Soaps - Travel Size Soap Bars */}
				<S.ContentSection>
					<S.ContentTitle>
						<Typography tag="h3" weight="bold">Travel Size Soap Bars</Typography>
					</S.ContentTitle>
					<S.ProductCollection products={products50grams} showMarkup={false} showFilterBar productName="fresh start soaps" />
				</S.ContentSection>

				{/* Accessories Collection */}
				{accessoriesProducts.length > 0 && (
					<S.ContentSection>
						<S.ContentTitle>
							<Typography tag="h3" weight="bold">Soap & Candle Accessories</Typography>
							<Typography tag="p" weight="light" variation="2">Pick up the perfect products to accompany your 700 Rivers soaps and signature candles.</Typography>
						</S.ContentTitle>
						<S.ProductCollection products={accessoriesProducts} showMarkup={false} showFilterBar productName="soap and candle accessories" />
					</S.ContentSection>
				)}
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ShopSoaps.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
ShopSoaps.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default ShopSoaps;
