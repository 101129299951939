/*
 * File: IconButton.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Button = styled.button`
 	display: flex;
 	appearance: none;
 	border: none;
 	background-color: transparent;
 	color: inherit;
 	cursor: pointer;

 	&:hover {
	 	opacity: 0.8;
 	}

 	svg {
	 	width: ${({ size }) => size}rem !important;
	 	height: ${({ size }) => size}rem !important;
 	}
`;
