/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:17 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const updateCart = (data) => api.post('/cart/update', data, { withCredentials: true });

export const checkOutCart = (data) => api.post('/cart/checkout', data);

export const getCart = () => api.get('/cart');
