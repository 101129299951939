/*
 * File: Cart.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 27, 2022 at 11:24 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 15, 2023 at 7:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

// Utilities
import { formatCost } from '../../utilities/cost';
import { toastError } from '../../utilities/toaster';
import { recordGTMEcommerceEvent } from '../../utilities/analytics';

// Services
import { checkOutCart } from '../../services/cart';

// Components
import { PageHeader } from '../PageHeader';
import { ErrorComponent } from '../ErrorComponent';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { ProductRow } from './ProductRow';
import { Emoji } from '../Emoji';

// Constants
import { AVAILABLE_I18N_NAMESPACES, FREE_SHIPPING_REQUIRED_LIMIT } from '../../../Constants';

// Animation
import animationData from '../../assets/animations/bicycle.json';

// Styles
import * as S from './Cart.styles';


/**
 * Component
 */

export const Cart = ({
	data, status, isSmall, showTitle, allowActions, emptyAction, showAdditionalCosts, isVisible
}) => {

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [recordView, setRecordView] = useState(true);

	// Use hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get translation hook
	const [t] = useTranslation(AVAILABLE_I18N_NAMESPACES.TRANSLATIONS);

	// Get cart
	const cart = data?.cart || { products: [], additionalCosts: [] };

	// Calculate cart metrics
	let productCost = 0;
	let totalCost = 0;
	if (cart?.products) {
		cart?.products.forEach((product) => {
			totalCost += product.selectedVariant.price.amount * product.quantity;
			productCost += product.selectedVariant.price.amount * product.quantity;
		});
	}

	// Add additional costs if necessary
	if (showAdditionalCosts && cart.additionalCosts.length > 0) {
		cart.additionalCosts.forEach((cost) => {
			totalCost += cost.cost;
		});
	}

	// Calculate amount required for free shipping
	let requiredForFreeShipping = FREE_SHIPPING_REQUIRED_LIMIT - productCost;
	if (requiredForFreeShipping < 0) requiredForFreeShipping = 0;

	// Handle check out action
	const handleCheckOut = async () => {

		// Update state
		setIsLoading(true);

		// Attempt checkout
		try {

			// Record GTM event
			if (cart && cart.isActive) {
				recordGTMEcommerceEvent('begin_checkout', {
					currency: 'USD',
					value: cart.totalCost,
					items: cart.products.map((product, index) => ({
						item_id: `${product.id.replace('gid://shopify/Product/', '')}-${product.variantId.replace('gid://shopify/ProductVariant/', '')}`,
						item_name: product.title,
						index,
						affiliation: product.vendor || '700 Rivers',
						item_brand: product.vendor || '700 Rivers',
						price: product.numericPrice,
						quantity: product.quantity,
						...product.productType ? {
							item_category: product.productType
						} : undefined,
						...product.tags && product.tags.length > 0 ? {
							item_category2: product.tags[0]
						} : undefined,
						...product.tags && product.tags.length > 1 ? {
							item_category3: product.tags[1]
						} : undefined,
						...product.tags && product.tags.length > 2 ? {
							item_category4: product.tags[2]
						} : undefined,
						...product.tags && product.tags.length > 3 ? {
							item_category5: product.tags[3]
						} : undefined,
						...product.selectedVariant && product.selectedVariant.title && product.selectedVariant.title !== 'Default Title' ? {
							item_variant: product.selectedVariant.title,
						} : undefined
					}))
				});
			}

			// Check out cart
			const { data: { checkout } } = await checkOutCart();

			// Open cart url
			window.location = checkout.checkoutUrl;

		} catch (error) {

			// Show error message
			toastError(uiMode, 'We\'re having trouble checking out right now. Give it another go.');
		}

		// Update state
		setIsLoading(false);
	};

	// Handle component initialization
	useEffect(() => {

		// Record GTM event
		if (cart && cart.isActive && isVisible && recordView) {

			// Update state
			setRecordView(false);

			// Record event
			recordGTMEcommerceEvent('view_cart', {
				currency: 'USD',
				value: cart.totalCost,
				items: cart.products.map((product, index) => ({
					item_id: `${product.id.replace('gid://shopify/Product/', '')}-${product.variantId.replace('gid://shopify/ProductVariant/', '')}`,
					item_name: product.title,
					index,
					affiliation: product.vendor || '700 Rivers',
					item_brand: product.vendor || '700 Rivers',
					price: product.numericPrice,
					quantity: product.quantity,
					...product.productType ? {
						item_category: product.productType
					} : undefined,
					...product.tags && product.tags.length > 0 ? {
						item_category2: product.tags[0]
					} : undefined,
					...product.tags && product.tags.length > 1 ? {
						item_category3: product.tags[1]
					} : undefined,
					...product.tags && product.tags.length > 2 ? {
						item_category4: product.tags[2]
					} : undefined,
					...product.tags && product.tags.length > 3 ? {
						item_category5: product.tags[3]
					} : undefined,
					...product.selectedVariant && product.selectedVariant.title && product.selectedVariant.title !== 'Default Title' ? {
						item_variant: product.selectedVariant.title,
					} : undefined
				}))
			});
		}

	}, [isVisible]);

	// Render component function
	const renderComponent = () => {

		// Return error component if necessary
		if (status === 'error') {
			return <ErrorComponent />;
		}

		// Return loading component if necessary
		if (status === 'loading') {
			return <Spinner />;
		}

		// Render component
		return cart.products.length === 0
			? (
				<S.ContentContainer>
					<S.LottieLink identifier="error-center-lottie">
						<Lottie loop play animationData={animationData} style={{ height: !isSmall ? 300 : 250 }} />
					</S.LottieLink>
					<S.MessageContainer>
						<Typography tag={!isSmall ? 'h2' : 'h3'} weight="semibold" isSmall>
							<Trans t={t} i18nKey="cart.emptyTitle">
								Your cart is currently empty!
							</Trans>
						</Typography>
						<S.DescriptionContainer>
							<Typography tag={!isSmall ? 'h4' : 'h5'} weight="light" isSmall>
								Add something special from our collection to get started.
							</Typography>
						</S.DescriptionContainer>
					</S.MessageContainer>
					<S.ActionContainer fullWidth>
						<S.ButtonLink onClick={(e) => {
							e.preventDefault();
							if (emptyAction) emptyAction();
							navigate(`${locale.localePath}/collections`);
						}}
						>
							<Button size="large">Shop all our products</Button>
						</S.ButtonLink>
					</S.ActionContainer>
				</S.ContentContainer>
			) : (
				<S.CartContainer>

					{/* Product Header */}
					<ProductRow isHeader isSmall={isSmall} allowActions={allowActions} />

					{/* Products */}
					{cart.products.map((product) => (
						<ProductRow
							product={product}
							key={product.id}
							isSmall={isSmall}
							allowActions={allowActions}
						/>
					))}

					{/* Additional Costs */}
					{cart.products.length > 0 && cart.additionalCosts.length > 0 && showAdditionalCosts && (
						<S.CostsContainer>
							{cart.additionalCosts.map((cost) => (
								<S.CostItem key={cost.id}>
									<Typography>{cost.name}</Typography>
									<Typography weight="semibold" className="cost">
										$
										{formatCost(cost.cost)}
									</Typography>
								</S.CostItem>
							))}
						</S.CostsContainer>
					)}

					{/* Check Out Button */}
					{cart.products.length > 0 && (
						<S.BottomActionContainer>
							<Typography>Subtotal</Typography>
							<Typography weight="semibold" className="cost">
								$
								{formatCost(totalCost)}
							</Typography>
							{allowActions && (
								<Button size="large" onClick={handleCheckOut} isLoading={isLoading} disabled={isLoading}>
									<Typography variation="button-small" weight="medium">CHECKOUT</Typography>
								</Button>
							)}
						</S.BottomActionContainer>
					)}
				</S.CartContainer>
			);
	};

	// Render component
	return (
		<S.Wrapper>

			{/* Header */}
			{showTitle && (
				<PageHeader
					title={cart?.products?.length > 0 ? `My Cart (${cart?.products?.length})` : 'My Cart'}
					isSmall
				/>
			)}

			{/* Free Shipping Progress */}
			<S.FreeShippingContainer>
				{requiredForFreeShipping > 0 ? (
					<Typography weight="light">
						You&apos;re
						{' '}
						<Typography weight="medium">{`$${formatCost(requiredForFreeShipping).replace('.00', '')}`}</Typography>
						{' '}
						away from free shipping!
					</Typography>
				) : (
					<Typography weight="light">
						You&apos;re getting free shipping!
						{' '}
						<Emoji symbol="🎉" label="party" size={0.95} />
					</Typography>
				)}
				<S.ProgressBar $limitWidth={!isSmall}>
					<S.ProgressInner $percentage={((FREE_SHIPPING_REQUIRED_LIMIT - requiredForFreeShipping) / FREE_SHIPPING_REQUIRED_LIMIT) * 100} />
				</S.ProgressBar>
			</S.FreeShippingContainer>

			{/* Component */}
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Cart.displayName = 'Cart';
Cart.propTypes = {
	data: PropTypes.shape(),
	status: PropTypes.oneOf(['idle', 'error', 'loading', 'success']),
	isSmall: PropTypes.bool,
	showTitle: PropTypes.bool,
	allowActions: PropTypes.bool,
	emptyAction: PropTypes.func,
	showAdditionalCosts: PropTypes.bool,
	isVisible: PropTypes.bool
};
Cart.defaultProps = {
	data: null,
	status: 'success',
	isSmall: false,
	showTitle: true,
	allowActions: true,
	emptyAction: null,
	showAdditionalCosts: false,
	isVisible: false
};
