/*
 * File: constants.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:32 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 21, 2023 at 9:12 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Breakpoints
 */

// Max Width
export const smallBreakpoint = 25; // 25em
export const mediumBreakpoint = 45; // 45em
export const largeBreakpoint = 60; // 60em
export const xLargeBreakpoint = 75; // 75em
export const xxLargeBreakpoint = 85; // 85em
export const xxxLargeBreakpoint = 92; // 92em

// Min Width
export const mobileBreakpoint = 62; // 62em


/**
 * Dimensions
 */

export const AppNavSidebarWidth = 340; // 340px
export const AppNavCartWidth = 520; // 520px
export const LegalNavSidebarWidth = 250; // 300px
export const AppNavTopbarHeight = 68; // 68px
export const AnnouncementBarHeight = 40; // 40px


/**
 * Delays
 */

export const navigationDropdownDelay = 600;
