/*
 * File: GetGiftCardModal.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 22, 2023 at 1:02 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 25px;
		padding: 0 20px;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	margin: 0px 0px 30px 0px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const LogoContainer = styled.div`
	position: relative;
	margin: 0px 0px 15px 0px;
`;

export const LogoImage = styled.img`
	width: 90px;
	height: auto;
`;

export const InputContainer = styled.div`
	margin: 0px 0px 25px 0px;
`;

export const DisclaimerContainer = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.6rem !important;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 24px;
	right: 50%;
`;

export const CurrentBalanceContainer = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	padding: 8px 10px 12px;

	p {
		margin-top: -5px;
	}
`;

export const RewardOptionsContainer = styled.div`
	padding: 20px 0px;
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(2, 1fr);
`;

export const RewardAction = styled.div`
	display: flex;
	padding: 12px;
	flex-direction: column;
	border: 2px solid ${({ theme, $isEnabled }) => ($isEnabled ? theme.brandPrimaryBase : theme.layoutBorder)};
	cursor: ${({ $isEnabled }) => ($isEnabled ? 'pointer' : 'default')};
	color: ${({ theme, $isEnabled }) => ($isEnabled ? theme.brandPrimaryBase : ColorValues.neutralForm)};

	${({ $isActive, $isEnabled }) => $isActive && $isEnabled && css`
		background-color: ${({ theme }) => theme.brandPrimaryBase};
		color: ${ColorValues.white};
 	`}
`;
