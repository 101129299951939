/*
 * File: Account.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on December 21, 2022 at 3:22 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: May 30, 2024 at 11:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { parseFirstLastName } from '../../../../utilities/utilities';
import { hexToRgb } from '../../../../utilities/color';
import { toastError } from '../../../utilities/toaster';
import { formatDateString } from '../../../../utilities/dateTime';

// Services
import { getCurrentUser, getRewardsOffers } from '../../../services/user';
import { manageSubscription } from '../../../services/subscription';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';

// Hooks
import ProgressProvider from '../../../hooks/ProgressProvider';

// Components
import {
	Meta,
	AppNavigation,
	Typography,
	Spinner,
	ErrorComponent,
	GetGiftCardModal,
	GiftCardPreviewModal,
	Button,
	IconContainer,
	CelebrationModal,
} from '../../../components';
import { PointsHistory } from './PointsHistory';
import { UpdateEmailModal } from './UpdateEmailModal';
import { UpdateProfileModal } from './UpdateProfileModal';

// Constants
import {
	REWARDS_TYPES, UI_MODE_OPTIONS, ACCOUNT_CONFIRMATION_OPTIONS, SUBSCRIPTION_TYPES
} from '../../../../Constants';
import { navigationDropdownDelay } from '../../../styles/constants';

// Styles
import { DarkTheme, LightTheme } from '../../../styles/colors';
import * as S from './Account.styles';
import 'react-circular-progressbar/dist/styles.css';


/**
 * State
 */

let optionsDropdownTimer = null;


/**
 * Component
 */

const Account = ({ meta, locale }) => {

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	const confirmation = searchParams.get('confirmation') ? decodeURIComponent(searchParams.get('confirmation')) : null;

	// Create reference for components
	const isMounted = useRef(true);

	// Create state handlers
	const [greetingContent, setGreetingContent] = useState('Hello friend!');
	const [offersStatus, setOffersStatus] = useState('idle');
	const [currentOfferGoal, setOfferGoal] = useState(null);
	const [availableRewards, setAvailableRewards] = useState([]);
	const [goalPercentage, setGoalPercentage] = useState(0);
	const [showGetGiftCardModal, setShowGetGiftCardModal] = useState(false);
	const [showGiftCardPreviewModal, setShowGiftCardPreviewModal] = useState(false);
	const [showCelebrationModal, setShowCelebrationModal] = useState(false);
	const [celebrationModalContent, setCelebrationModalContent] = useState({ title: '', content: '' });
	const [pendingSubscriptionManagement, setPendingSubscriptionManagement] = useState(false);
	const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
	const [editEmailModalOpen, setEditEmailModalOpen] = useState(false);
	const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
	const [createdGiftCard, setCreatedGiftCard] = useState(null);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current user from hook
	const currentUser = useSelector((state) => state.user.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;
	const primaryColor = hexToRgb(currentTheme.brandPrimaryBase);

	// Handle update account
	const updateAccount = async () => {

		// Fetch current user
		const { data } = await getCurrentUser();

		// Dispatch new user
		dispatch(updateUser(data.user));

		// Update offer status
		setOffersStatus('idle');
	};

	// Handle manage subscription
	const handleManageSubscription = async () => {

		// Set pending state
		setPendingSubscriptionManagement(true);

		// Fetch management url for subscription
		try {
			const { data: { portalUrl } } = await manageSubscription();

			// Set pending state
			if (isMounted.current) {
				setPendingSubscriptionManagement(false);
			}

			// Open portal url
			window.location = portalUrl;

		} catch ({ response }) {

			// Set pending state
			if (isMounted.current) {
				setPendingSubscriptionManagement(false);
			}

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble accessing your subscription. Please try again.');
			}
		}
	};

	// Handle fetch offers
	const fetchOffers = async () => {

		// Update page status
		setOffersStatus('loading');
		try {

			// Fetch blog story
			const { data: { rewards, currentOffer } } = await getRewardsOffers();

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setOfferGoal(currentOffer);
				setAvailableRewards(rewards);
				setGoalPercentage(currentOffer?.percentageComplete || 100);

				// Update component state
				setOffersStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setOffersStatus('error');
			}
		}
	};

	// Handle actions on offer status state change
	useEffect(() => {

		// Ensure initial offer loading is not complete
		if (offersStatus === 'idle') {

			// Fetch offer state for page
			fetchOffers();
		}
	}, [offersStatus]);

	// Handle actions on user change
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Get user's name
		const name = currentUser?.name;

		// Create greeting
		let greeting = 'Hello friend!';
		if (name) {

			// Parse first and last name
			const { firstName } = parseFirstLastName(name);

			// Update greeting
			if (firstName) {
				greeting = `Hello ${firstName}!`;
			}
		}

		// Set greeting
		setGreetingContent(greeting);

		// Check if should show confirmation modal
		if (confirmation != null) {
			switch (confirmation) {
				case ACCOUNT_CONFIRMATION_OPTIONS.SEASONAL_SUBSCRIPTION:
					setCelebrationModalContent({
						title: 'Welcome to our Seasonal Subscription!',
						content: 'We\'ll prepare and ship your seasonal products on the first day of the upcoming quarter. From your dashboard, you can manage your subscription, update your payment method, and more.'
					});
					setShowCelebrationModal(true);
					break;
				default:
					break;
			}
		}

		// Clear url parameters
		window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render offer goal
	const renderOfferGoal = (goal) => {

		// Create name of reward
		let rewardName = goal.reward.name;
		if (goal.reward.type === REWARDS_TYPES.GIFT_CARD) {
			rewardName = `$${goal.reward.dollarValue} discount code`;
		}

		// Create goal text
		return `Only ${goal.pointDifference} points until a ${rewardName}! 🎉`;
	};

	// Render subscription image
	const imageForSubscription = (type) => {
		if (type === SUBSCRIPTION_TYPES.SEASONAL_SUBSCRIPTION) {
			return `${process.env.CDN_URL}/public/images/web-f-700-Rivers_r1_03D_4058.jpg`;
		}
		return `${process.env.CDN_URL}/public/images/soaps-group-jute.jpg`;
	};

	// Render subscription content
	const contentForSubscription = (type) => {
		if (type === SUBSCRIPTION_TYPES.SEASONAL_SUBSCRIPTION) {
			return (
				<>
					<Typography variation="2" tag="p">700 Rivers Seasonal Subscription</Typography>
					<S.FeatureSet>
						<Typography tag="p" variation="3">3 x Ethically made 700 Rivers soap bar</Typography>
						<Typography tag="p" variation="3">1 x 700 Rivers Signature soy candle - 8 oz</Typography>
					</S.FeatureSet>
				</>
			);
		}
		return null;
	};

	// Render price for subscription
	const priceForSubscription = (data) => {
		if (data.price) {
			const { type, recurring, unit_amount: amount } = data.price;
			if (type === 'recurring') {
				return `$${amount / 100} (every ${recurring.interval_count} ${recurring.interval_count === 1 ? recurring.interval : `${recurring.interval}s`})`;
			}
		}
		return '$50 (every 3 months)';
	};

	// Render offers
	const renderOffers = () => {
		if (offersStatus === 'idle' || offersStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (offersStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<S.OfferCardInner>
				<S.RewardGiftCard>
					<S.LogoMark
						alt="700 Rivers Logo"
						title="700 Rivers Logo"
						src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-mark.png`}
					/>
					<Typography tag="h3" weight="semibold">700 Rivers Discount Code</Typography>
					<Typography tag="p" variation="2" weight="light">Get an online discount on your favorite 700 Rivers products.</Typography>
					<Button onClick={() => { setShowGetGiftCardModal(true); }}>
						<Typography variation="button-small" weight="semibold">Redeem Points for a Discount Code</Typography>
					</Button>
				</S.RewardGiftCard>
				<S.RewardComingSoon>
					<FontAwesomeIcon icon={['fasl', 'gift']} />
					<Typography tag="h4" weight="semibold">More rewards coming soon</Typography>
					<Typography tag="p" variation="2" weight="light">Drop back by to redeem rewards from some of our favorite businesses!</Typography>
				</S.RewardComingSoon>
			</S.OfferCardInner>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Greeting */}
					<S.GreetingContainer>
						<IconContainer>
							<FontAwesomeIcon icon={['fal', 'hand-wave']} />
						</IconContainer>
						<Typography tag="h1" weight="extrabold" className="animate">
							{greetingContent}
						</Typography>
						<Typography tag="h5" weight="regular" className="animate">
							Your 700 Rivers Rewards
						</Typography>
					</S.GreetingContainer>

					{/* Current Reward Balance */}
					<S.RewardsBalanceContainer>

						{/* Rewards Progress Circle */}
						<ProgressProvider valueStart={0} valueEnd={goalPercentage}>
							{(value) => (
								<CircularProgressbarWithChildren
									value={value}
									strokeWidth={6}
									styles={buildStyles({
										strokeLinecap: 'round',
										pathTransitionDuration: 0.5,
										pathColor: `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, 1)`,
										textColor: currentTheme.primaryText,
										trailColor: 'transparent',
										backgroundColor: currentTheme.brandPrimaryBase,
									})}
								>

									{/* Rewards Balance */}
									<S.RewardsBalance>
										<S.RewardsInner>
											<Typography tag="span" weight="semibold" className="animate">
												{currentUser?.currentPointBalance}
											</Typography>
											<Typography tag="p" weight="regular" className="animate">
												Reward Points
											</Typography>
										</S.RewardsInner>
									</S.RewardsBalance>

									{/* Account Action */}
									<S.AccountAction
										onMouseEnter={() => {
											clearTimeout(optionsDropdownTimer);
											setShowOptionsDropdown(true);
										}}
										onMouseLeave={() => {
											clearTimeout(optionsDropdownTimer);
											optionsDropdownTimer = setTimeout(() => {
												setShowOptionsDropdown(false);
											}, navigationDropdownDelay);
										}}
									>

										{/* Dropdown Toggle */}
										<S.DropdownToggle size={1.0} icon={['fasl', 'pen']} />

										{/* Navigation Dropdown */}
										<S.NavigationDropdown
											className={showOptionsDropdown ? 'animate auth show' : 'auth animate'}
											adjustOnMobile
											flipDirection
											items={[
												{
													icon: ['fasl', 'envelope'],
													title: 'Edit Rewards Email',
													action: () => {
														setShowOptionsDropdown(false);
														setEditEmailModalOpen(true);
													}
												},
												{
													icon: ['fasl', 'pen-to-square'],
													title: 'Edit Profile',
													action: () => {
														setShowOptionsDropdown(false);
														setEditProfileModalOpen(true);
													}
												},
											]}
											footerItems={[
												{
													icon: ['fasl', 'arrow-right-from-bracket'],
													title: 'Log out',
													action: () => { window.location = '/logout'; }
												}
											]}
										/>
									</S.AccountAction>
								</CircularProgressbarWithChildren>
							)}
						</ProgressProvider>

						{/* Current Goal */}
						{currentOfferGoal && (
							<S.GoalContainer>
								{renderOfferGoal(currentOfferGoal)}
							</S.GoalContainer>
						)}
					</S.RewardsBalanceContainer>

					{/* Actions Section */}
					{!currentUser?.birthdate && (
						<S.AccountSection>

							{/* Title */}
							<S.TitleContainer>
								<Typography tag="h4" weight="bold">Earn More Points</Typography>
							</S.TitleContainer>

							{/* Action Row Container */}
							<S.ActionsContainer>

								{/* Birthday Action Row */}
								{!currentUser?.birthdate && (
									<S.ActionRow>
										<FontAwesomeIcon icon={['fasl', 'gift']} />
										<Typography tag="h5">Add your birthday and we&apos;ll send you a special gift!</Typography>
										<Button
											variant="text"
											size="medium"
											onClick={() => { setEditProfileModalOpen(true); }}
										>
											<FontAwesomeIcon icon={['fass', 'plus']} />
											<Typography weight="semibold" variation="button-medium">Add my birthday</Typography>
										</Button>
										<S.ActionRowHighlight />
									</S.ActionRow>
								)}
							</S.ActionsContainer>
						</S.AccountSection>
					)}

					{/* Reward Offers Section */}
					<S.AccountSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4" weight="bold">My Offers</Typography>
						</S.TitleContainer>

						{/* Reward Offers */}
						<S.OffersContainer>{renderOffers()}</S.OffersContainer>
					</S.AccountSection>

					{/* Points Earned Section */}
					<S.AccountSection>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h4" weight="bold">My Reward Points</Typography>
						</S.TitleContainer>

						{/* Points Earned Card */}
						<S.PointsHistoryContainer>
							<PointsHistory history={currentUser?.pointsEarnedHistory} earned />
						</S.PointsHistoryContainer>
					</S.AccountSection>

					{/* Subscription Section */}
					{currentUser?.currentSubscription && currentUser?.currentSubscription?.isActive && (
						<S.AccountSection>

							{/* Title */}
							<S.TitleContainer>
								<Typography tag="h4" weight="bold">My Subscription</Typography>
								<Button onClick={handleManageSubscription} isLoading={pendingSubscriptionManagement}>
									<Typography variation="button-small" weight="semibold">Manage</Typography>
								</Button>
							</S.TitleContainer>

							{/* Subscription Card */}
							<S.SubscriptionCard>

								{/* Subscription Icon */}
								<S.SubscriptionIcon $image={imageForSubscription(currentUser?.currentSubscription?.type)} />

								{/* Subscription Type */}
								<S.SubscriptionColumn>
									<S.SubscriptionColumnHeader>
										<Typography variation="3">Subscription</Typography>
									</S.SubscriptionColumnHeader>
									<S.SubscriptionColumnContent>
										{contentForSubscription(currentUser?.currentSubscription?.type)}
									</S.SubscriptionColumnContent>
								</S.SubscriptionColumn>

								{/* Subscription Price */}
								<S.SubscriptionColumn>
									<S.SubscriptionColumnHeader>
										<Typography variation="3">Base Price</Typography>
									</S.SubscriptionColumnHeader>
									<S.SubscriptionColumnContent>
										<Typography variation="2" tag="p">{priceForSubscription(currentUser?.currentSubscription)}</Typography>
									</S.SubscriptionColumnContent>
								</S.SubscriptionColumn>

								{/* Payment Method */}
								<S.SubscriptionColumn>
									<S.SubscriptionColumnHeader>
										<Typography variation="3">Payment Method</Typography>
									</S.SubscriptionColumnHeader>
									<S.SubscriptionColumnContent>
										<Typography variation="2" tag="p">{currentUser?.currentSubscription?.payment_method}</Typography>
									</S.SubscriptionColumnContent>
								</S.SubscriptionColumn>

								{/* Subscription Renewal */}
								<S.SubscriptionColumn>
									<S.SubscriptionColumnHeader>
										<Typography variation="3">Renewal Date</Typography>
									</S.SubscriptionColumnHeader>
									<S.SubscriptionColumnContent>
										<Typography variation="2" tag="p">{formatDateString(currentUser?.currentSubscription?.current_period_end, 'MMM D, YYYY')}</Typography>
									</S.SubscriptionColumnContent>
								</S.SubscriptionColumn>

							</S.SubscriptionCard>
						</S.AccountSection>
					)}

					{/* Get Gift Card Modal */}
					<GetGiftCardModal
						isOpen={showGetGiftCardModal}
						handleClose={() => { setShowGetGiftCardModal(false); }}
						onCreate={(giftCard) => {

							// Update account
							updateAccount();

							// Set created gift card
							setCreatedGiftCard(giftCard);

							// Open gift card preview modal
							setShowGiftCardPreviewModal(true);
						}}
						availableRewards={availableRewards}
					/>

					{/* Gift Card Preview Modal */}
					<GiftCardPreviewModal
						isOpen={showGiftCardPreviewModal}
						handleClose={() => { setShowGiftCardPreviewModal(false); }}
						giftCard={createdGiftCard}
					/>

					{/* Update Profile Modal */}
					<UpdateProfileModal
						isOpen={editProfileModalOpen}
						handleClose={() => { setEditProfileModalOpen(false); }}
						updateUser={updateAccount}
					/>

					{/* Update Profile Modal */}
					<UpdateEmailModal
						isOpen={editEmailModalOpen}
						handleClose={() => { setEditEmailModalOpen(false); }}
						updateUser={updateAccount}
					/>

					{/* Celebration Modal */}
					<CelebrationModal
						isOpen={showCelebrationModal}
						handleClose={() => { setShowCelebrationModal(false); }}
						title={celebrationModalContent.title}
						content={celebrationModalContent.content}
					/>

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Account.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Account.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Account;
