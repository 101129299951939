/*
 * File: Contact.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button } from '../../../components';

// Constants
import { largeBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0px 0px 50px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	gap: 60px;
	flex-direction: column;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
		padding: 80px 0px 50px;
	}
	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
	.turnstile {
		margin: 20px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const ContentPane = styled.div`
	margin-top: 50px;
	display: flex;
	flex-grow: 1;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		margin-top: 0px;
	}
`;

export const FormPane = styled.div`
	width: 100%;
	max-width: 440px;
	flex-shrink: 0;
	position: relative;
`;

export const ContentContainer = styled.div`
	text-align: left;
	max-width: 600px;

	h1 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		text-align: left;
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Form = styled.form`
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

	button {
		width: 100%;
	}
	.formInput {
		margin: 20px 0 0;
	}
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const CheckboxRow = styled.div`
	display: flex;
	margin: 20px 0 0 0;

	span {
		flex-grow: 1;
		text-align: left;
		margin: 0 0 0 12px;
		color: ${({ theme }) => theme.primaryText};
		position: relative;
		top: -1px;

		a,
		a:link,
		a:visited {
			color: inherit;
		}
	}
`;

export const CheckboxContainer = styled.div`
	margin: 25px 0px 35px 0px;
`;

export const ConfirmationContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const ConfirmationPane = styled.div`
	text-align: center;
	border-radius: 10px;
	padding: 40px 20px;
	position: relative;
	overflow: hidden;

	h3 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfirmationBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const ConfirmationContent = styled.div`
	position: relative;
	z-index: 2;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
`;
