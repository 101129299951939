/*
 * File: NotificationSettings.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: December 29, 2022 at 9:04 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../utilities/toaster';

// Services
import { updateNotificationPreferences } from '../../services/user';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './NotificationSettings.styles';


/**
 * Component
 */

export const NotificationSettings = ({
	className,
	preferences,
	accessToken
}) => {

	// Create state handlers
	const [shouldUpdatePreferences, setShouldUpdatePreferences] = useState(false);
	const [inputValues, setInputValues] = useState({
		emailFromSystemFeedback: preferences.fromSystem.feedback.emailAllowed,
		emailFromSystemReminders: preferences.fromSystem.reminders.emailAllowed,
		emailFromSystemProduct: preferences.fromSystem.product.emailAllowed,
		emailFromSystemSecurity: preferences.fromSystem.security.emailAllowed,
		emailFromSystemSupport: preferences.fromSystem.support.emailAllowed,
		pushFromSystemFeedback: preferences.fromSystem.feedback.pushAllowed,
		pushFromSystemReminders: preferences.fromSystem.reminders.pushAllowed,
		pushFromSystemProduct: preferences.fromSystem.product.pushAllowed,
		pushFromSystemSecurity: preferences.fromSystem.security.pushAllowed,
		pushFromSystemSupport: preferences.fromSystem.support.pushAllowed,
	});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, checked } = event.target;
		setInputValues({ ...inputValues, [name]: checked });
	};

	// Handle debounce function for updating notification preferences
	const debounceUpdateNotificationPreferences = useDebouncedCallback(async (inputs) => {

		// Get parameters from inputs
		const {
			emailFromSystemFeedback,
			emailFromSystemReminders,
			emailFromSystemProduct,
			emailFromSystemSecurity,
			emailFromSystemSupport,
			pushFromSystemFeedback,
			pushFromSystemReminders,
			pushFromSystemProduct,
			pushFromSystemSecurity,
			pushFromSystemSupport,
		} = inputs;

		// Update notification preferences
		try {
			updateNotificationPreferences({
				preferences: {
					fromSystem: {
						feedback: {
							emailAllowed: emailFromSystemFeedback,
							pushAllowed: pushFromSystemFeedback,
						},
						reminders: {
							emailAllowed: emailFromSystemReminders,
							pushAllowed: pushFromSystemReminders,
						},
						product: {
							emailAllowed: emailFromSystemProduct,
							pushAllowed: pushFromSystemProduct,
						},
						security: {
							emailAllowed: emailFromSystemSecurity,
							pushAllowed: pushFromSystemSecurity,
						},
						support: {
							emailAllowed: emailFromSystemSupport,
							pushAllowed: pushFromSystemSupport,
						}
					}
				},
				token: accessToken
			});

			// Show success toast
			toastSuccess(uiMode, 'Success! Your preferences have been updated.');

		} catch (e) {

			// Show error state
			toastError(uiMode, 'Whoops. We\'re having trouble updating your preferences. Please try again.');
		}
	}, [], 1000);

	// Handle actions on input value change
	useEffect(() => {
		if (shouldUpdatePreferences) {

			// Update notification preferences
			debounceUpdateNotificationPreferences(inputValues);
		}

		// Update permission state
		setShouldUpdatePreferences(true);

	}, [inputValues]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Email Notifications */}
			<S.NotificationSection>

				{/* Title Content */}
				<S.TitleSection>
					<Typography tag="h4" weight="semibold">Email Notifications</Typography>
					<Typography tag="p">Get emails to find out what&apos;s going on when you&apos;re not online.</Typography>
				</S.TitleSection>

				{/* Toggle Section */}
				<S.ToggleSection>

					{/* Option 1 - Feedback */}
					<S.OptionRow>
						<S.Toggle
							name="emailFromSystemFeedback"
							checked={inputValues.emailFromSystemFeedback}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Feedback Emails</Typography>
							<Typography tag="p" variation="3" className="subtitle">Give feedback on 700 Rivers</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 2 - Reminders */}
					<S.OptionRow>
						<S.Toggle
							name="emailFromSystemReminders"
							checked={inputValues.emailFromSystemReminders}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Reminder Emails</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get notifications you may have missed</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 3 - Product */}
					<S.OptionRow>
						<S.Toggle
							name="emailFromSystemProduct"
							checked={inputValues.emailFromSystemProduct}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Insider Emails</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get insider tips on new products, sales and more from 700 Rivers</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 4 - Security */}
					<S.OptionRow>
						<S.Toggle
							name="emailFromSystemSecurity"
							checked={inputValues.emailFromSystemSecurity}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Security Alerts</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get alerts if we don&apos;t recognize a login to your account</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 5 - Support */}
					<S.OptionRow>
						<S.Toggle
							name="emailFromSystemSupport"
							checked={inputValues.emailFromSystemSupport}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Support Emails</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get updates on support requests and reports</Typography>
						</S.Description>
					</S.OptionRow>
				</S.ToggleSection>
			</S.NotificationSection>

			{/* Push Notifications */}
			<S.NotificationSection>

				{/* Title Content */}
				<S.TitleSection>
					<Typography tag="h4" weight="semibold">Push Notifications</Typography>
					<Typography tag="p">Get notifications to find out what&apos;s going on when you&apos;re on the go.</Typography>
				</S.TitleSection>

				{/* Toggle Section */}
				<S.ToggleSection>

					{/* Option 1 - Feedback */}
					<S.OptionRow>
						<S.Toggle
							name="pushFromSystemFeedback"
							checked={inputValues.pushFromSystemFeedback}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Feedback</Typography>
							<Typography tag="p" variation="3" className="subtitle">Give feedback on 700 Rivers</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 2 - Reminders */}
					<S.OptionRow>
						<S.Toggle
							name="pushFromSystemReminders"
							checked={inputValues.pushFromSystemReminders}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Reminders</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get notifications you may have missed</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 3 - Product */}
					<S.OptionRow>
						<S.Toggle
							name="pushFromSystemProduct"
							checked={inputValues.pushFromSystemProduct}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">
								Insider Tips
							</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get insider tips on new products, sales and more from 700 Rivers</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 4 - Security */}
					<S.OptionRow>
						<S.Toggle
							name="pushFromSystemSecurity"
							checked={inputValues.pushFromSystemSecurity}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Security Alerts</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get alerts if we don&apos;t recognize a login to your account</Typography>
						</S.Description>
					</S.OptionRow>

					{/* Option 5 - Support */}
					<S.OptionRow>
						<S.Toggle
							name="pushFromSystemSupport"
							checked={inputValues.pushFromSystemSupport}
							onChange={handleOnChange}
						/>
						<S.Description>
							<Typography tag="p">Support Requests</Typography>
							<Typography tag="p" variation="3" className="subtitle">Get updates on support requests and reports</Typography>
						</S.Description>
					</S.OptionRow>
				</S.ToggleSection>
			</S.NotificationSection>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

NotificationSettings.displayName = 'NotificationSettings';
NotificationSettings.propTypes = {
	className: PropTypes.string,
	preferences: PropTypes.shape(),
	accessToken: PropTypes.string
};
NotificationSettings.defaultProps = {
	className: null,
	preferences: {},
	accessToken: undefined
};
