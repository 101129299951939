/*
 * File: GetGiftCardModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 3:42 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../utilities/toaster';

// Services
import { createGiftCardReward } from '../../services/user';

// Components
import { Button } from '../Button';
import { LocaleLink } from '../LocaleLink';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { IconContainer } from '../IconContainer';

// Constants
import { REWARDS_TYPES } from '../../../Constants';

// Styles
import * as S from './GetGiftCardModal.styles';


/**
 * Component
 */

export const GetGiftCardModal = ({
	className, isOpen, handleClose, onCreate, availableRewards
}) => {

	// Create state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selectedAction, setSelectedAction] = useState(null);
	const [giftCardActions, setGiftCardActions] = useState([]);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user from hook
	const currentUser = useSelector((state) => state.user.value);

	// Handle create gift card action
	const handleCreateGiftCard = async () => {

		// Validate parameters
		if (!selectedAction) {
			toastError(uiMode, 'Please select a discount code value to redeem.');
			return;
		}

		// Set submitting state
		setIsSubmitting(true);

		// Subscribe to email list
		createGiftCardReward({
			rewardId: selectedAction.id
		}).then(({ data: { card } }) => {

			// Set submitting state
			setIsSubmitting(false);

			// Hide modal
			handleClose();

			// Handle create action
			onCreate(card);

		}).catch(({ response }) => {

			// Set loading state
			setIsSubmitting(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble signing you up. Please try again.');
			}
		});
	};

	// Handle available rewards actions
	useEffect(() => {
		if (availableRewards) {

			// Set gift card actions
			setGiftCardActions(availableRewards.filter((action) => action.available === true && action.type === REWARDS_TYPES.GIFT_CARD));
		}
	}, [availableRewards]);

	// Handle actions on open modal
	useEffect(() => {
		if (isOpen) {

			// Reset state
			setSelectedAction(null);
		}
	}, [isOpen]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<IconContainer>
					<FontAwesomeIcon icon={['fasl', 'gift']} />
				</IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">Select a reward</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Redeem your rewards points for a 700 Rivers Discount Code</Typography>

				{/* Current Rewards Balance */}
				<S.CurrentBalanceContainer>
					<Typography tag="h3" weight="semibold">{currentUser?.currentPointBalance}</Typography>
					<Typography tag="p" variation="3" weight="regular">Rewards Points</Typography>
				</S.CurrentBalanceContainer>

				{/* Gift Card Options */}
				<S.RewardOptionsContainer>
					{giftCardActions.map((action) => (
						<S.RewardAction
							key={action.id}
							$isActive={selectedAction?.id === action.id}
							$isEnabled={action?.pointValue <= currentUser?.currentPointBalance}
							className="animate"
							onClick={() => {
								if (action?.pointValue <= currentUser?.currentPointBalance) {
									setSelectedAction(action);
								}
							}}
						>
							<Typography tag="p" weight="semibold">
								$
								{action.dollarValue}
								{' '}
								Discount Code
							</Typography>
							<Typography tag="p" variation="3" weight="regular">
								{action.pointValue}
								{' '}
								points
							</Typography>
						</S.RewardAction>
					))}
				</S.RewardOptionsContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isSubmitting || !selectedAction} isLoading={isSubmitting} variant="solid" onClick={handleCreateGiftCard}>
						{selectedAction ? `Redeem $${selectedAction.dollarValue} Discount Code` : 'Redeem Discount Code'}
					</Button>
				</S.ActionContainer>

				{/* Disclaimer */}
				<S.DisclaimerContainer>
					<Typography variation="3" weight="light" tag="p">
						Applies to orders online at sevenhundredrivers.com only. Redeeming points for a discount code will send an email to your rewards email address with instructions for redeeming.
					</Typography>
					<Typography variation="3" weight="light" tag="p" style={{ marginTop: '8px' }}>
						By using or sharing this discount code, you agree to 700 Rivers&apos;
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
						{' '}
						and
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Privacy Policy</LocaleLink>
						.
					</Typography>
				</S.DisclaimerContainer>
			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

GetGiftCardModal.displayName = 'GetGiftCardModal';
GetGiftCardModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	onCreate: PropTypes.func,
	availableRewards: PropTypes.arrayOf(PropTypes.shape())
};
GetGiftCardModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	onCreate: null,
	availableRewards: []
};
