/*
 * File: ShopCandles.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { ProductCollection as BaseProductCollection } from '../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const TitleSection = styled.div`
	margin: 40px 0px 40px;

	h1 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;

export const Description = styled.div`
	text-align: center;
	margin: 10px auto 0px;
	width: 100%;
	max-width: 800px;
	font-weight: 300;
	line-height: 180%;

	* {
		font-weight: 300;
		line-height: 180%;
	}
`;

export const ProductCollection = styled(BaseProductCollection)`
    margin: 10px 0px 60px;
`;

export const ContentSection = styled.div`
	margin: 60px 0px 0px;
`;

export const ContentTitle = styled.div`
	margin: 0px 0px 20px;

	& > h3 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
		margin: 0px 0px 0px;
	}
	& > p {
		text-align: left;
	}
`;
