/*
 * File: Home.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import {
	Button, Card, LocaleLink, ProductCollection as BaseProductCollection
} from '../../../components';

// Styles
import { ColorValues } from '../../../styles/colors';

// Constants
import { largeBreakpoint, mediumBreakpoint, mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	padding: 40px 0px;
`;

export const HeroBanner = styled.div`
	height: 700px;
	width: 100%;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	padding: 40px;
	align-items: end;
`;

export const HeroContent = styled.div`
	text-align: left;

	h1,
	h5,
	p {
		color: ${({ $textColor }) => $textColor};
		text-align: left;
	}
	h1 {
		font-family: 'Lexend', sans-serif;
	}
	a,
	a:visited,
	a:link {
		text-decoration: none;
	}
`;

export const CTAButton = styled(Button)`
	margin: 15px 0px 0px;
	color: ${({ $accent }) => $accent};
	opacity: 1 !important;
	cursor: pointer;

	&:hover {
		transform: scale(1.03);
	}
`;

export const HeroAccountCTA = styled.div`
	margin: 0px 0px 22px;

	h3 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
	}
	span {
		font-size: inherit;
	}
	a,
	a:visited,
	a:link {
		font-size: 1.3rem;
	}
`;

export const PublicitySection = styled.div`
	margin: 20px 0px 60px;

	h4 {
		font-family: 'Unna', Georgia, Times, serif;
		margin: 0px 0px 10px;
	}
`;

export const PublicityLogoRow = styled.div`
	display: flex;
	gap: 50px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

export const PublicityLogoContainer = styled.div`
	width: 120px;
	height: 80px;
	position: relative;

	.foregroundLogo {
		opacity: 1;
	}
	.backgroundLogo {
		opacity: 0;
	}
	&:hover {
		.foregroundLogo {
			opacity: 0;
		}
		.backgroundLogo {
			opacity: 1;
		}
	}
`;

export const ForegroundLogo = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;

export const BackgroundLogo = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;

export const FavoritesCollections = styled(BaseProductCollection)`
	margin: 20px 0px 60px;
`;

export const NewArrivalsCollections = styled(BaseProductCollection)`
	margin: 20px 0px 60px;
`;

export const GiftBundlesCollections = styled(BaseProductCollection)`
	margin: 20px 0px 60px;
`;

export const ProductCollections = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 25px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 36px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 36px;
	}
`;

export const ProductCollection = styled(LocaleLink)`
	text-decoration: none;

	&:hover,
	&:focus {
		transform: scale(1.02);
	}
`;

export const CollectionImage = styled.div`
	width: 100%;
	height: 500px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
`;

export const CollectionContent = styled.div`
	margin: 12px 0px 0px;

	span {
		color: ${({ theme }) => theme.secondaryText};
		width: 100%;
		display: block;
		margin: 6px 0px 0px;
	}
`;

export const ContentSection = styled.div`
	padding: 20px 0px 50px;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		font-size: 3.0rem;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 0px;
	}
`;

export const TitleSection = styled.div`
	margin: 0px 0px 40px;
	width: 100%;

	h2 {
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
`;

export const PanelSection = styled.div`
	padding: 0px 0px 40px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: ${({ $wideText }) => ($wideText ? '55%' : '50%')};
	text-align: left;
	margin: 0px auto;
	width: 100%;
	padding: 50px 50px;
	background-color: ${({ $white }) => ($white ? ColorValues.white : 'transparent')};

	* {
		text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 6rem;
		width: 88%;
	}
	p {
		margin: 20px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h4 {
		margin: 12px 0px 20px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: ${({ $wideText }) => ($wideText ? '45%' : '50%')};
`;

export const MediaPanelImage = styled.img`
	width: 100%;
`;

export const LinkContainer = styled(LocaleLink)`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const ReviewSection = styled.div`
	max-width: 900px;
	width: 100%;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	a,
	a:visited,
	a:link {
		text-decoration: none;
	}
`;

export const ReviewActionButton = styled(Button)`
	margin: 40px 0px 0px;
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 50px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;
