/*
 * File: Collections.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:14 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink } from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
`;

export const TitleSection = styled.div`
	margin: 80px 0px 50px;

	h1 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;

export const CollectionSelectors = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 24px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const CollectionSelector = styled(LocaleLink)`
	position: relative;
	display: flex;
	flex: 50%;
	height: 280px;
	min-height: 280px;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	flex-shrink: 0;

	h4 {
		color: ${ColorValues.white};
		position: relative;
		z-index: 2;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const SelectorBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
`;

export const ContentSection = styled.div`
	margin: 60px 0px 0px;

	& > h3 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
		margin: 0px 0px 10px;
	}
`;
