/*
 * File: Artisans.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 7, 2023 at 11:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography, IconContainer, SchemaScript
} from '../../../components';

// Constants
import { UI_MODE_OPTIONS } from '../../../../Constants';

// Styles
import { DarkTheme, LightTheme } from '../../../styles/colors';
import * as S from './Artisans.styles';


/**
 * Component
 */

const Artisans = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Content */}
					<S.HeroSection>
						<S.ContentPanel>
							<IconContainer iconColor={currentTheme.statusDangerBase}>
								<FontAwesomeIcon icon={['fass', 'heart']} />
							</IconContainer>
							<Typography tag="h1" weight="bold">
								We couldn&apos;t do it without our artisans.
							</Typography>
							<Typography tag="h4" weight="light">
								700 Rivers’ artisans are incredibly strong and amazing women that have overcome many obstacles in life. We provide our artisans with a safe place to work, health benefits, and a living wage to support themselves and their families.
							</Typography>
						</S.ContentPanel>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/artisan-family-group.jpg`}
								alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
							/>
						</S.MediaPanel>
					</S.HeroSection>

					{/* Content Padding */}
					<S.ContentPadding left right dynamic isLarge>

						{/* Meet Artisans Section */}
						<S.ContentSection>
							<S.CompactContentPanel>

								{/* Video Section */}
								<S.VideoPanel>

									{/* Schema.org VideoObject */}
									<SchemaScript schema={{
										'@context': 'http://schema.org',
										'@type': 'VideoObject',
										name: 'Meet our 700 Rivers artisans',
										description: 'We employ 20+ artisans in Mymensingh, Bangladesh who have all escaped and overcome human trafficking. Your soap purchases go directly towards continued work for all these women. Thanks for playing a role in empowering women across the globe with a fresh start in life.',
										thumbnailUrl: [
											`${process.env.CDN_URL}/public/video/thumbnails/artisan-introduction-thumb-1.jpg`,
											`${process.env.CDN_URL}/public/video/thumbnails/artisan-introduction-thumb-2.jpg`,
										],
										uploadDate: '2023-11-07T08:00:00+08:00',
										duration: 'PT54S',
										contentUrl: `${process.env.CDN_URL}/public/video/artisan-introduction.mp4`
									}}
									/>

									{/* Video Element */}
									<S.VideoElement
										playsInline
										autoPlay
										loop
										muted
									>
										<source src={`${process.env.CDN_URL}/public/video/artisan-introduction.mp4`} type="video/mp4" />
									</S.VideoElement>
								</S.VideoPanel>

								{/* Content */}
								<S.DetailPanel>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'hand-wave']} />
									</IconContainer>
									<Typography tag="h2" weight="bold">Meet our artisans.</Typography>
									<Typography tag="h4" weight="light">We employ 20+ artisans in Mymensingh, Bangladesh who have all escaped and overcome human trafficking. Your soap purchases go directly towards continued work for all these women. Thanks for playing a role in empowering women across the globe with a fresh start in life.</Typography>
									<Typography tag="p" weight="light">Our artisans are inspiring to us all, but most importantly, they are an inspiration to each other. Since each of these women come from a similar background, they provide mutual support and understanding to one another. Working together with other artisans gives each new artisan hope that they can overcome their past as well.</Typography>
								</S.DetailPanel>
							</S.CompactContentPanel>
						</S.ContentSection>

						{/* Benefits Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">A job is just the beginning.</Typography>
								<Typography tag="p" weight="light">700 Rivers empowers our artisans with the opportunities needed to support themselves and their families.</Typography>
							</S.TitleSection>

							{/* Benefits */}
							<S.BenefitsGrid>

								{/* Benefit #1 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'dollar-sign']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Fair, Living Wages
									</Typography>
									<Typography tag="p" weight="light">
										We partner with a certified Fair Trade Organization to employ 22 artisans to handcraft our all-natural soaps and 32 artisans to handcraft our jute paper soap boxes in Bangladesh. All our artisans are guaranteed fair, living wages for their work.
									</Typography>
								</S.BenefitBlock>

								{/* Benefit #2 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'helmet-safety']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										A Safe Workplace
									</Typography>
									<Typography tag="p" weight="light">
										Safety is our top priority. We ensure our artisans are provided with proper protective equipment to wear while producing soaps and work in a well-ventilated area.
									</Typography>
								</S.BenefitBlock>

								{/* Benefit #3 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'message-heart']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Mental Health Counseling
									</Typography>
									<Typography tag="p" weight="light">
										Our artisans receive mental health counseling when they first transition from a life of human trafficking to a life where they can finally live on their own terms. This is not an easy adjustment, so this mental health counseling is the first priority.
									</Typography>
								</S.BenefitBlock>

								{/* Benefit #4 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'users']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Support Groups
									</Typography>
									<Typography tag="p" weight="light">
										Once our newly hired artisans have the chance to meet and work with other women within the organization that have overcome the trauma of their past, it gives each new artisan hope that they can overcome their past as well.
									</Typography>
								</S.BenefitBlock>

								{/* Benefit #5 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'hand-holding-hand']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Dignity and Respect
									</Typography>
									<Typography tag="p" weight="light">
										Many of our artisans are met with a stigma due to their past. Within our organization, all artisans are treated with kindness, dignity, and respect.
									</Typography>
								</S.BenefitBlock>

								{/* Benefit #6 */}
								<S.BenefitBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'pen-paintbrush']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Growth and Skills
									</Typography>
									<Typography tag="p" weight="light">
										All artisans receive the chance to learn new job skills and grow as a leader. This allows them to build a new, stable career path to support themselves.
									</Typography>
								</S.BenefitBlock>
							</S.BenefitsGrid>
						</S.ContentSection>

						{/* Image Banner Section */}
						<S.ImageBannerSection>
							<S.ImageBanner $image={`${process.env.CDN_URL}/public/images/artisan-orange-turmeric.jpg`} />
							<S.ImageBanner $image={`${process.env.CDN_URL}/public/images/artisan-soap-interview.jpg`} />
							<S.ImageBanner $image={`${process.env.CDN_URL}/public/images/artisan-cinnamon-spice-soap.jpg`} />
						</S.ImageBannerSection>

						{/* CTA Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">More from 700 Rivers</Typography>
							</S.TitleSection>

							{/* Card Container */}
							<S.CardContainer>

								{/* Mission */}
								<S.CardLink to="/mission">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'seedling']} />
										<Typography tag="h4" weight="semibold">Explore Our Mission</Typography>
										<Typography tag="p" weight="light">Our mission is to create high-quality, eco-friendly products that employ women across the world with fair wages</Typography>
										<S.ActionContainer>
											<Typography>Read more about our mission</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>

								{/* Supply Chain */}
								<S.CardLink to="/supply-chain">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fal', 'earth-asia']} />
										<Typography tag="h4" weight="semibold">Our Supply Chain</Typography>
										<Typography tag="p" weight="light">At 700 Rivers, we believe in an ethical and transparent supply chain for all of our products</Typography>
										<S.ActionContainer>
											<Typography>Read more about our supply chain</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>
							</S.CardContainer>
						</S.ContentSection>
					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Artisans.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Artisans.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Artisans;
