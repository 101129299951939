/*
 * File: ProductDetail.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button as BaseButton } from '../../../components/Button';
import { LocaleLink } from '../../../components';

// Constants
import {
	mobileBreakpoint
} from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const ProductWrapper = styled.div`
	margin: 80px 0 0 0;
`;

export const DetailContainer = styled.div`
	margin: 40px 0 100px 0;
	display: flex;
	flex-direction: column;
	gap: 40px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
		gap: 80px;
	}
`;

export const LeftDetail = styled.div`
	padding: 0px 0 0 0;
`;

export const RightDetail = styled.div`
	text-align: center;

	input {
		text-align: left;
	}
	p {
		text-align: left;
	}
	h1 {
		text-align: left;
		font-family: 'Unna', Georgia, Times, serif;
	}
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MadeWith = styled.div`
	margin: 40px 0 0 0;

	p {
		text-align: left;
	}
`;

export const ButtonContainer = styled.div`
	padding: 0px;
	margin: 20px 0 0 0;
	display: flex;
	align-items: center;

	svg {
		font-size: 1.1rem;
	}
`;

export const Button = styled(BaseButton)`
	width: 100%;
	height: 50px;
	margin: 0px 0px 0px;
`;

export const DescriptionContainer = styled.div`
	margin: 20px 0px 0px;
	text-align: left;
	font-weight: 300;
	line-height: 180%;

	* {
		text-align: left;
		font-weight: 300;
		line-height: 180%;
	}
`;

export const ProductImageCollection = styled.div`
	width: 100%;
	display: flex;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	margin: 20px 0px 0px;
	flex-wrap: wrap;
`;

export const PreviewImage = styled.div`
	height: 90px;
	width: 90px;
	cursor: pointer;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	opacity: 1;

	&:hover {
		opacity: 0.7;
	}
`;

export const ShareWidgetContainer = styled.div`
	position: relative;
	display: inline-block;
	align-items: center;
	justify-content: center;
	overflow: visible;
	margin: 10px auto 0px;

	& > button {
		margin: 0px auto;
		width: 123px;
	}
`;

export const ProductDetails = styled.div`
	margin: 0px 0px 30px;
`;

export const ProductOptionContainer = styled.div`
	text-align: left;
`;

export const BundleContainer = styled.div`
	margin: 40px 0px 0px 0px;
`;

export const BundleTitle = styled.div`
	* {
		text-align: left;
	}
	h3 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;

export const BundleProducts = styled.div`
	margin: 10px 0px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const BundleProduct = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	justify-content: center;

	h5 {
		flex-grow: 1;
		text-align: left;
	}
`;

export const BundleImage = styled.div`
	width: 50px;
	height: 50px;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex-shrink: 0;
`;

export const BundleLink = styled(LocaleLink)`
	text-decoration: none;
`;
