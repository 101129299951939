/*
 * File: Sidebar.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Padding } from '../../Padding';
import { Typography } from '../../Typography';

// Styles
import * as S from './Sidebar.styles';


/**
 * Component
 */

export const Sidebar = ({
	toggleOverlay, className
}) => (
	<S.Sidebar className={className}>
		<Padding>

			{/* Mobile Navigation */}
			<S.MobileNavigation>
				<S.NavigationToggle icon={['fal', 'times']} size={1.5} onClick={() => { toggleOverlay(); }} />
			</S.MobileNavigation>

			{/* Primary Navigation */}
			<S.SidebarSection showBorder={false}>
				<S.PrimaryLink to="/shop/soaps" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Soaps</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/shop/candles" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Candles</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/shop/self-care" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Self Care</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/shop/gift-bundles" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Gift Bundles</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/stores" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Corporate Gifts</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/corporate-gifts" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="semibold">Find Us In Store</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/mission" onClick={() => { toggleOverlay(); }} style={{ marginTop: '15px' }}>
					<Typography tag="h5" weight="light">Our Mission</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/collections" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="light">Collections</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/seasonal-subscription" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="light">Seasonal Subscription</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/contact" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="light">Contact Us</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink to="/blog" onClick={() => { toggleOverlay(); }}>
					<Typography tag="h5" weight="light">Blog</Typography>
				</S.PrimaryLink>
			</S.SidebarSection>

			{/* Navigation */}
			<S.SidebarSection showBorder={false}>
				<S.LinkSection>
					<S.Link to="/our-artisans" onClick={() => { toggleOverlay(); }}>Our Artisans</S.Link>
					<S.Link to="/supply-chain" onClick={() => { toggleOverlay(); }}>Supply Chain</S.Link>
					<S.Link to="/wholesale" onClick={() => { toggleOverlay(); }}>Wholesale Orders</S.Link>
					<S.Link to="/legal/refund-policy" onClick={() => { toggleOverlay(); }}>Returns & Refunds</S.Link>
					{/* <S.Link to="/work-with-catherina" onClick={() => { toggleOverlay(); }}>Work with Catherina</S.Link> */}
				</S.LinkSection>
				<S.LinkSection>
					<S.Link to="/retailers" onClick={() => { toggleOverlay(); }}>For Retailers</S.Link>
					<S.Link to="/candle-making-class" onClick={() => { toggleOverlay(); }}>Candle Making Classes</S.Link>
					<S.Link to="/shop/gift-cards" onClick={() => { toggleOverlay(); }}>Gift Cards</S.Link>
					<S.Link to="/refer-a-friend" onClick={() => { toggleOverlay(); }}>Refer-a-Friend</S.Link>
					<S.Link to="/rewards/login" onClick={() => { toggleOverlay(); }}>My Account</S.Link>
				</S.LinkSection>
				<S.LinkSection>
					<S.Link to="/brand" onClick={() => { toggleOverlay(); }}>Brand &amp; Presskit</S.Link>
					<S.Link to="/legal/terms" onClick={() => { toggleOverlay(); }}>Terms of Service</S.Link>
					<S.Link to="/legal/privacy" onClick={() => { toggleOverlay(); }}>Privacy Policy</S.Link>
				</S.LinkSection>
				<S.LinkSection>
					<Typography tag="p" className="fullWidth">
						Made with
						{' '}
						<FontAwesomeIcon icon={['fass', 'heart']} className="heart" />
						{' '}
						in Raleigh, NC
					</Typography>
					<Typography tag="p" className="fullWidth">
						{`© ${new Date().getFullYear()} 700 Rivers`}
					</Typography>
				</S.LinkSection>
			</S.SidebarSection>
		</Padding>
	</S.Sidebar>
);


/**
 * Configuration
 */

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func
};
Sidebar.defaultProps = {
	className: null,
	toggleOverlay: null
};
