/*
 * File: ProgressProvider.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on October 6, 2023 at 2:26 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 6, 2023 at 3:10 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { useState, useEffect } from 'react';


/**
 * Hook
 */

const ProgressProvider = ({ valueStart, valueEnd, children }) => {

	// Create state handlers
	const [value, setValue] = useState(valueStart);

	// Update state when value ends
	useEffect(() => {
		setValue(valueEnd);
	}, [valueEnd]);

	// Return children
	return children(value);
};


/**
 * Exports
 */

export default ProgressProvider;
