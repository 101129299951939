/*
 * File: PointsHistory.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on March 5, 2023 at 1:37 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 10:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
`;

export const TableContainer = styled.div`
	max-height: 400px;
	overflow: scroll;
	width: 100%;
`;
