/*
 * File: Footer.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: April 7, 2023 at 9:51 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Padding } from '../Padding';
import { LocaleLink } from '../LocaleLink';
import { TextInput } from '../TextInput';

// Styles
import { ColorValues } from '../../styles/colors';

// Constants
import { xLargeBreakpoint, xxLargeBreakpoint, mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Footer = styled(Padding)`
	background-color: ${({ theme }) => theme.primaryBackground};
	padding-top: 50px;
	padding-bottom: 60px;
	display: block;

	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
`;

export const TopSection = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	min-height: 380px;
	border-radius: 12px;
	margin: 0 0 15px;
	position: relative;
	overflow: hidden;
`;

export const FeatureImage = styled.div`
	position: absolute;
	${({ image }) => image && css`
		background-image: url(${process.env.CDN_URL}/public/covers/${image});
	`};
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const ImageCover = styled.div`
	background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 2;
`;

export const ContentContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 3;
	padding: 30px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	width: 100%;

	* {
		text-align: left;
	}
	h2 {
		font-size: 2.3rem;
		color: ${ColorValues.white};
		line-height: 120%;
	}
	button {
		margin: 15px 0px 0px;
		font-size: 0.9rem;
		font-weight: 500;
	}
	@media screen and (min-width: ${mobileBreakpoint}em) {
		padding: 60px;
		width: 50%;

		h2 {
			font-size: 3.1rem;
		}
	}
`;

export const NavigationSection = styled.div`
	display: grid;
	padding: 25px 0px 0px;

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
	.a4 {
		grid-area: a4;
	}
	.a5 {
		grid-area: a5;
	}
	grid-template-areas:
		'a5'
		'a1'
		'a2'
		'a3'
		'a4';

	@media screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-areas: 'a1 a2 a3 a4 a5';
		gap: 4.5rem;
		padding: 25px 0px;
	}
	@media screen and (min-width: ${xxLargeBreakpoint}em) {
		grid-template-areas: 'a1 a2 a3 a4 spacer a5';
		gap: 4.5rem;
		padding: 25px 0px;
	}
`;

export const LinkSection = styled.div`
	display: block;
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};
	padding: 25px 0 26px;

	* {
		text-align: left;
		font-size: 0.9rem;

		&:not(:last-child) {
			margin: 0 0 14px;
		}
	}
	p {
		font-size: 1.1rem;
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		border: none;
	}
`;

export const Link = styled(LocaleLink)`
	text-decoration: none;
	display: flex;
	align-items: center;
	font-weight: 300;
	color: ${({ theme }) => theme.primaryText};

	&:hover {
		text-decoration: underline;
	}
	svg {
		font-size: 1.3rem;
		margin-right: 14px;
	}
`;

export const BottomSection = styled.div`
	padding: 25px 0px 25px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	* {
		text-align: left;
	}
	.divider {
		margin: 0 10px;
	}
	span {
		color: ${({ theme }) => theme.primaryText};
		font-size: 0.85rem;
		font-weight: 300;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		align-items: center;
	}
`;

export const LeftSection = styled.div`
	align-items: start;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		align-items: center;
	}
`;

export const RightSection = styled.div`
	align-items: center;
	display: flex;
`;

export const LogoContainer = styled.div`
	align-items: center;
	display: flex;
`;

export const MadeWithContainer = styled.div`
	margin: 6px 0px 0px;
	display: flex;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		margin: 0px;
	}
`;

export const LinkContainer = styled.div`
	align-items: center;
	display: flex;
	margin: 14px 0px 0px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		margin: 0px;
	}
`;

export const BottomLink = styled(LocaleLink)`
	text-decoration: underline;
	font-size: 0.85rem;
	font-weight: 300;
	color: ${({ theme }) => theme.primaryText};

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const SocialLink = styled(LocaleLink)`
	margin: 25px 24px 0px 0px;

	&:hover {
		opacity: 0.7;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		margin: 0px 0px 0px 24px;
	}
`;

export const SocialIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.primaryText};
	font-size: 1.2rem;
`;

export const LogoMark = styled.img`
	width: 34px;
	height: 34px;
	margin-right: 8px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const FormContainer = styled.div`
	padding: 25px 0px 26px;
	max-width: 400px;

	p {
		color: ${({ theme }) => theme.secondaryText};
	}
	p.footerTitle {
		font-size: 1.1rem;
		color: ${({ theme }) => theme.primaryText};
		margin-bottom: 8px;
	}
	* {
		text-align: left;
	}
`;

export const InputContainer = styled.div`
	margin: 10px 0px 18px;
	position: relative;

	button {
		position: absolute;
		top: 16px;
		right: 10px;
	}
	p {
		color: inherit;
	}
`;

export const EmailInput = styled(TextInput)`
	padding-right: 40px;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 14px;
	right: 10px;
`;

export const Spacer = styled.div`
	height: 10px;
	width: 0px;
`;
