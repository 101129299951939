/*
 * File: BlogSection.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 5, 2023 at 2:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div``;

export const StoryGridContainer = styled.div`
	display: grid;
	gap: 60px;
	grid-template-columns: repeat(1, 1fr);

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		grid-template-columns: ${({ $perRow }) => `repeat(${$perRow}, 1fr)`};
		gap: 80px 25px;
	}
`;

export const PaginationContainer = styled.div`
	margin: 70px auto 0px;
	text-align: center;

	button {
		margin: 0px auto;
	}
`;
