/*
 * File: Mission.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Card, LocaleLink } from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, mobileBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding-top: 36px;

	.videoBanner {
		height: 400px;
	}
`;

export const HeroContent = styled.div`
	margin: 40px 0px 0px;

	h1 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
		line-height: 1.2;
	}
	h4 {
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
		text-align: left;
		line-height: 1.8;
	}
`;

export const ContentPadding = styled.div`
	display: block;
`;

export const LargeContentSection = styled.div`
	padding: 140px 0px 100px 0px;
`;

export const ContentSection = styled.div`
	padding: 50px 0px 50px 0px;
`;

export const SocialFeedContainer = styled.div`
	padding: 80px 0px;
	position: relative;
	text-align: center;

	h3 {
		margin-bottom: 25px;
	}
`;

export const PanelSection = styled.div`
	padding: 0px 0px 40px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: ${({ $wideText }) => ($wideText ? '55%' : '50%')};
	text-align: left;
	margin: 0px auto;
	width: 100%;
	padding: 50px 50px;
	background-color: ${({ $white }) => ($white ? ColorValues.white : 'transparent')};

	* {
		text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 6rem;
		width: 88%;
	}
	p {
		margin: 10px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h3 {
		font-family: 'Unna', Georgia, Times, serif;
	}
	h4 {
		margin: 12px 0px 20px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: ${({ $wideText }) => ($wideText ? '45%' : '50%')};
`;

export const MediaPanelImage = styled.img`
	width: 100%;
`;

export const LinkContainer = styled(LocaleLink)`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
	&:hover {
		text-decoration: underline;
	}
`;

export const ProductCollections = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 34px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const ProductCollection = styled(LocaleLink)`
	text-decoration: none;

	&:hover,
	&:focus {
		transform: scale(1.02);
	}
`;

export const CollectionImage = styled.div`
	width: 100%;
	height: 500px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	display: flex;
	position: relative;
`;

export const CollectionContent = styled.div`
	padding: 20px;
	position: relative;
	z-index: 2;

	* {
		text-align: left;
		color: ${ColorValues.white};
	}
	span {
		width: 100%;
		display: block;
		margin: 6px 0px 0px;
	}
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
`;

export const FullVideo = styled.video`
	width: 100%;
	max-width: 900px;
	margin: 0px auto;
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 0px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const FeaturesGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 34px;
	margin: 50px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const FeatureBlock = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 6px 0px 0px;
	}
	* {
		text-align: left;
	}
`;

export const FounderSection = styled.div`
	margin: 0px auto;
	width: 100%;
	max-width: 800px;
`;

export const FounderPhotoContainer = styled.div`
	padding: 10px;
	background-color: ${ColorValues.white};
	border-radius: 50%;
	width: 260px;
	height: 260px;
	margin: 0px auto 0px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
`;

export const FounderPhoto = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 50%;
`;

export const FounderIntro = styled.div`
	margin: 30px 0px 0px;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ImageBackground = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: black;
	opacity: 0.3;
	z-index: 1
`;
