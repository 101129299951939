/*
 * File: Subscription.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 26, 2023 at 4:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { formatOfferDate } from '../../../utilities/product';
import { toastError } from '../../../utilities/toaster';

// Services
import { createSubscription } from '../../../services/subscription';

// Components
import {
	Meta, AppNavigation, Typography, IconContainer, SchemaScript, TextInput, ConfettiBurst, Emoji, LocaleLink, ReviewsSection
} from '../../../components';

// Styles
import * as S from './Subscription.styles';
import { PRODUCT_REVIEWS } from '../../../../Constants';


/**
 * Content
 */

const subscriptionPrice = 30;


/**
 * Component
 */

const Subscription = ({ meta, locale }) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showContactForm, setShowContactForm] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();
	const emailInputRef = useRef();

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Handle create subscription function
	const handleCreateSubscription = async () => {

		// Get parameters
		const {
			email, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your account email address' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('subscription_sign_up'); } catch (e) {}

		// Update create subscription
		createSubscription({
			email,
			question_input: questionInput,
			_magic,
			_magic2,
			recaptchaToken
		}).then(() => {

			// Hide form
			setShowContactForm(false);
			setTimeout(() => {

				// Clear inputs
				setInputValues({});

				// Set loading state
				setIsLoading(false);

				// Show confirmation
				setShowConfirmation(true);
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

			}, 300);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble creating your subscription. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle user update
	useEffect(() => {

		// Set default user email
		if (user && user.email && !inputValues.email) {
			setInputValues({ ...inputValues, email: user.email });
		}

	}, [user]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Schema.org Product */}
					<SchemaScript schema={{
						'@context': 'http://schema.org/',
						'@type': 'Product',
						'@id': `${process.env.APP_URL}/seasonal-subscription/#Product`,
						name: '700 Rivers\' Seasonal Subscription',
						url: `${process.env.APP_URL}/seasonal-subscription`,
						image: `${process.env.CDN_URL}/public/images/web-9-700-Rivers_r1_03D_4121.jpg`,
						description: 'Subscribe to 700 Rivers and get your seasonal favorites delivered right to your door every three months.',
						brand: {
							'@type': 'Brand',
							name: '700 Rivers'
						},
						mpn: '700RIVSEASUBSCR',
						gtin: '',
						productID: '700-rivers-seasonal-subscription',
						category: 'Subscription',
						isFamilyFriendly: true,
						offers: {
							'@type': 'Offer',
							availability: 'http://schema.org/InStock',
							price: parseFloat(`${subscriptionPrice}`),
							priceCurrency: 'USD',
							itemCondition: 'https://schema.org/NewCondition',
							priceValidUntil: formatOfferDate(),
							url: `${process.env.APP_URL}/seasonal-subscription`
						},
						potentialAction: [
							{
								'@type': 'BuyAction',
								target: `${process.env.APP_URL}/seasonal-subscription`,
							},
							{
								'@type': 'ReviewAction',
								target: `${process.env.APP_URL}/seasonal-subscription`,
							},
							{
								'@type': 'ShareAction',
								target: `${process.env.APP_URL}/seasonal-subscription`,
							}
						]
					}}
					/>

					{/* Schema.org ItemPage */}
					<SchemaScript schema={{
						'@context': 'http://schema.org/',
						'@type': 'ItemPage',
						'@id': `${process.env.APP_URL}/seasonal-subscription/#WebPage`,
						name: '700 Rivers\' Seasonal Subscription',
						description: 'Subscribe to 700 Rivers and get your seasonal favorites delivered right to your door every three months.',
						url: `${process.env.APP_URL}/seasonal-subscription`,
						inLanguage: stateLocale.localeBaseId,
						isPartOf: {
							'@id': `${process.env.APP_URL}/#WebSite`
						},
						publisher: {
							'@id': 'https://www.sevenhundredrivers.com/#Organization',
						},
						mainEntity: {
							'@id': `${process.env.APP_URL}/seasonal-subscription/#Product`,
						}
					}}
					/>

					{/* Banner Section */}
					<S.BannerImage $image={`${process.env.CDN_URL}/public/images/web-0-700-Rivers_r1_03D_4140-banner.jpg`} />

					{/* Sign Up Form */}
					<S.SignUpForm id="sign-up">

						{/* Content Pane */}
						<S.ContentPane>
							<S.ContentContainer>

								{/* Content */}
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'gift']} />
								</IconContainer>
								<Typography tag="h1" weight="bold">700 Rivers Seasonal Subscription</Typography>
								<Typography tag="p">
									Looking for a special way to treat yourself all year round? Enjoy your favorite 700 Rivers products, delivered right to your door every three months!
								</Typography>

								{/* Actions */}
								<S.LinkContainer onClick={() => { emailInputRef?.current?.focus(); }}>
									<Typography>Sign up for all the goodness</Typography>
									<FontAwesomeIcon icon={['fass', 'arrow-right']} />
								</S.LinkContainer>

							</S.ContentContainer>
						</S.ContentPane>

						{/* Form Pane */}
						<S.FormPane>

							{/* Contact Form */}
							<S.Form className="animate" isVisible={showContactForm}>

								{/* Instructions */}
								<Typography tag="h3" weight="bold">Confirm your email to get started</Typography>

								{/* Inputs */}
								<TextInput
									label="Your Email"
									placeholder="e.g. hello@acme.com"
									name="email"
									error={emailError}
									value={inputValues.email || ''}
									containerClassName="modalInput"
									autoComplete="email"
									onFocus={() => { setEmailError(null); }}
									onKeyUp={() => { setEmailError(null); }}
									onBlur={() => { setEmailError(null); }}
									onChange={handleOnChange}
									ref={emailInputRef}
								/>

								{/* Question Input */}
								<input
									id="question_input"
									name="question_input"
									type="text"
									value={inputValues.question_input || ''}
									onChange={handleOnChange}
									className="question-input"
								/>

								{/* Honeypots */}
								<input
									type="text"
									name="_magic"
									value={inputValues._magic || ''}
									onChange={handleOnChange}
									className="gotcha-field"
								/>
								<input
									type="text"
									name="_magic2"
									value={inputValues._magic2 || ''}
									onChange={handleOnChange}
									style={{ display: 'none' }}
								/>

								{/* Continue Action */}
								<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={handleCreateSubscription} size="large" className="floatShadow">Continue</S.ActionButton>
							</S.Form>

							{/* Confirmation Content */}
							<S.ConfirmationContainer className="animate" isVisible={showConfirmation}>

								{/* Content Pane */}
								<S.ConfirmationPane>

									{/* Content */}
									<S.ConfirmationContent>
										<Emoji symbol="🎉" size={3.5} label="Celebration" />
										<S.ConfettiTarget ref={confettiTargetRef} />
										<Typography tag="h3" weight="bold">A whole bunch of goodness is on the way!</Typography>
										<Typography tag="p" variation="1">We&apos;re so excited. Check your inbox for a confirmation email to complete the sign up process.</Typography>
									</S.ConfirmationContent>

									{/* Content Background */}
									<S.ConfirmationBackground />

								</S.ConfirmationPane>
							</S.ConfirmationContainer>
						</S.FormPane>
					</S.SignUpForm>

					{/* How It Works Section */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">How the 700 Rivers Seasonal Subscription works</Typography>
						</S.TitleSection>

						{/* Features */}
						<S.StepsGrid>

							{/* Step #1 */}
							<S.StepBlock>
								<IconContainer>
									<FontAwesomeIcon icon={['fal', 'hand-wave']} />
								</IconContainer>
								<Typography tag="h5" weight="bold">
									Sign up for the subscription
								</Typography>
								<Typography tag="p" weight="light">
									Give us a few details like your email and shipping address and we&apos;ll set up your subscription. Then, you can securely add your payment info to confirm your purchase or gift.
								</Typography>
							</S.StepBlock>

							{/* Step #2 */}
							<S.StepBlock>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'truck-fast']} />
								</IconContainer>
								<Typography tag="h5" weight="bold">
									Ships every 3 months
								</Typography>
								<Typography tag="p" weight="light">
									Our Seasonal Subscription boxes ship out on March 1st, June 1st, September 1st, and December 1st of each year. As soon as you sign up, we&apos;ll prep to send your first box on the next upcoming shipping date.
								</Typography>
							</S.StepBlock>

							{/* Step #3 */}
							<S.StepBlock>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'box-open']} />
								</IconContainer>
								<Typography tag="h5" weight="bold">
									Delivered right to your door
								</Typography>
								<Typography tag="p" weight="light">
									Your subscription box is packaged with care by our team and sent on its way to your doorstep. As soon as your products ship, you also earn 700 Rivers rewards points!
								</Typography>
							</S.StepBlock>
						</S.StepsGrid>
					</S.ContentSection>

					{/* What's Included Section */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection $left>
							<Typography tag="h2" weight="bold">What&apos;s included in each box?</Typography>
						</S.TitleSection>

						{/* Features */}
						<S.StepsGrid>

							{/* Soaps */}
							<S.StepBlock>
								<S.ProductImageContainer>
									<S.ProductImage $image={`${process.env.CDN_URL}/public/images/web-f7-700-Rivers_r1_03D_4163.jpg`} />
									<IconContainer className="quantityIcon">
										<FontAwesomeIcon icon={['fasr', '3']} />
									</IconContainer>
								</S.ProductImageContainer>
								<Typography tag="h5" weight="bold">
									3 x 700 Rivers Fresh Start Soap Bars
								</Typography>
								<Typography tag="p" weight="light">
									Our all-natural soaps are handmade by women in Bangladesh who have escaped human trafficking. We&apos;ll send three perfect for the season.
								</Typography>
							</S.StepBlock>

							{/* Candles */}
							<S.StepBlock>
								<S.ProductImageContainer>
									<S.ProductImage $image={`${process.env.CDN_URL}/public/images/web-9-700-Rivers_r1_03D_4121.jpg`} />
									<IconContainer className="quantityIcon">
										<FontAwesomeIcon icon={['fasr', '1']} />
									</IconContainer>
								</S.ProductImageContainer>
								<Typography tag="h5" weight="bold">
									1 x 700 Rivers Signature Scent 8 oz Candle
								</Typography>
								<Typography tag="p" weight="light">
									Shine bright and spread the light with a 700 Rivers candle! Focus on mindfulness and self-care with a perfect signature scent for the season.
								</Typography>
							</S.StepBlock>
						</S.StepsGrid>
					</S.ContentSection>

					{/* Perfect Gift Section */}
					<S.ContentSection style={{ paddingTop: '60px', paddingBottom: '30px' }}>
						<S.PanelSection>
							<S.ContentPanel $wideText $white>
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'sprout']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">
									Perfect as a gift for the ethical and eco-friendly product lovers in your life
								</Typography>
								<Typography tag="p" weight="light">
									All 700 Rivers&apos; soaps and self-care products are designed right here in Raleigh, North Carolina, USA. Our soaps are then handmade in Bangladesh by our artisans: women who have escaped and overcome human trafficking. Our candles, bath bombs, and self-care products are crafted here in the USA by our team.
								</Typography>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/hands-holding-soap.jpg`}
									alt="700 Rivers Orange Clove candles with natural ingredients"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Reviews */}
					<S.ContentSection>

						{/* Title */}
						<S.TitleSection>
							<Typography tag="h2" weight="bold">Reviews from our Customers</Typography>
						</S.TitleSection>

						{/* Review Section */}
						<S.ReviewSection>

							{/* Customer Reviews */}
							<ReviewsSection reviews={PRODUCT_REVIEWS} />

							{/* CTA Button */}
							<LocaleLink to="#sign-up">
								<S.ReviewActionButton variant="outline">
									Get Started
								</S.ReviewActionButton>
							</LocaleLink>

						</S.ReviewSection>
					</S.ContentSection>

				</S.Wrapper>
			</AppNavigation>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

Subscription.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Subscription.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Subscription;
