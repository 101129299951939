/*
 * File: AuthRouteWrapper.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on September 4, 2023 at 4:06 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 4, 2023 at 4:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


/**
 * Route Wrapper
 */

export const AuthRouteWrapper = ({
	children, redirect, user, locale
}) => {

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Allow access to component
	return !userObj || userObj.isAnonymous === true ? children : <Navigate to={`${localeObj.localePath}${redirect}`} />;
};


/**
 * Configuration
 */

AuthRouteWrapper.propTypes = {
	children: PropTypes.element,
	redirect: PropTypes.string,
	user: PropTypes.shape(),
	locale: PropTypes.shape()
};
AuthRouteWrapper.defaultProps = {
	children: null,
	redirect: '/',
	user: null,
	locale: null
};
