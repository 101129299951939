/*
 * File: DateInput.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 19, 2023 at 10:03 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
 	flex-direction: column;
 	position: relative;

 	& > label {
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.secondaryText)};
 	}
	.date-input-popper {
		.react-datepicker {
			border: none;
			border-radius: 0;
			box-shadow: ${ColorValues.elementShadow};
			font-family: 'Lexend', sans-serif;

			* {
				transition: all 0.3s;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				-o-transition: all 0.3s;
			}
		}
		.react-datepicker__month-container {
			width: 100%;

			.react-datepicker__month {
				margin-top: 0;
			}
			.react-datepicker__day {
				width: 2.25rem;
				line-height: 2.25rem;
				font-size: .875rem;

				&:hover {
					border-radius: 500vh;
					width: 2.25rem;
					height: 2.25rem;
				}
			}
			.react-datepicker__day--selected {
				background-color: ${ColorValues.brandPrimaryBase};
				border-radius: 500vh;
				width: 2.25rem;
				height: 2.25rem;
			}
			.react-datepicker__day--outside-month {
				color: ${ColorValues.neutralForm};
			}
		}
		.react-datepicker__year-dropdown,
		.react-datepicker__month-dropdown,
		.react-datepicker__month-year-dropdown {
			border-radius: 0px;
			background-color: ${ColorValues.white};
			box-shadow: ${ColorValues.elementShadow};
		}
		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view--down-arrow,
		.react-datepicker__navigation-icon::before {
			border-color: #ccc;
			border-style: solid;
			border-width: 2px 2px 0 0;
			content: "";
			display: block;
			height: 6px;
			position: absolute;
			top: 9px;
			width: 6px;
		}
		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view--down-arrow {
			top: 4px;
			width: 7px;
			height: 7px;
		}
		.react-datepicker__year-option:hover,
		.react-datepicker__month-option:hover,
		.react-datepicker__month-year-option:hover {
			background-color: ${ColorValues.neutralButtonLighter};
		}
		.react-datepicker__year-option,
		.react-datepicker__month-option,
		.react-datepicker__month-year-option {
			border-radius: 0px;
		}
		.react-datepicker__navigation--years::before {
			border-color: #ccc;
			border-style: solid;
			border-width: 2px 2px 0 0;
			content: '';
			display: block;
			height: 6px;
			left: 11px;
			position: absolute;
			width: 6px;
		}
		.react-datepicker__navigation--years-upcoming::before {
			top: 17px;
			transform: rotate(315deg);
		}
		.react-datepicker__navigation--years-previous::before {
			top: 6px;
			transform: rotate(135deg);
		}
		.react-datepicker__triangle {
			display: none !important;
		}
		.react-datepicker__header {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom: 0;
			background-color: ${ColorValues.white};

			.react-datepicker__current-month {
				font-weight: semibold;
			}
			.react-datepicker__day-name {
				color: ${ColorValues.neutralText};
				width: 2.25rem;
				line-height: 2.25rem;
				font-size: .875rem;
			}
		}
		.react-datepicker__day--keyboard-selected,
		.react-datepicker__month-text--keyboard-selected,
		.react-datepicker__quarter-text--keyboard-selected,
		.react-datepicker__year-text--keyboard-selected {
			border-radius: 0;
			background-color: ${ColorValues.white};
			color: rgb(0, 0, 0);

			&:hover {
				background-color: #f0f0f0;
			}
		}
	}
`;

/* .react-datepicker {
		border: none;
		border-radius: 0;
		box-shadow: ${colors.lightShadow};
		font-family: 'Open Sans', sans-serif;
		min-width: 18.875rem;

		.react-datepicker__month-container {
			width: 100%;

			.react-datepicker__month {
				margin-top: 0;

			}
			.react-datepicker__day {
				width: 2.25rem;
				line-height: 2.25rem;
				font-size: .875rem;

				&:hover {
					border-radius: 500vh;
					width: 2.25rem;
					height: 2.25rem;
				}
			}
			.react-datepicker__day--selected {
				background-color: ${colors.brandPrimary};
				border-radius: 500vh;
				width: 2.25rem;
				height: 2.25rem;
			}
			.react-datepicker__day--outside-month {
				color: ${colors.neutralDisabled}
			}
		}
	}
	.react-datepicker__triangle {
		display: none !important;
	}
	.react-datepicker__header {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom: 0;
		background-color: #FFFFFF;

		.react-datepicker__current-month {
			font-weight: semibold;
		}
		.react-datepicker__day-name {
			color: ${colors.neutralText};
			width: 2.25rem;
			line-height: 2.25rem;
			font-size: .875rem;
		}
	} */

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px;
 	color: ${({ theme }) => theme.statusDangerBase};
 	max-width: 100%;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
 	}
`;

export const IconWrapper = styled.div`
 	position: absolute;
 	display: flex;
 	height: 100%;
 	justify-content: center;
 	align-items: center;
 	padding: 0 0.75rem;
 	pointer-events: none;

 	svg {
	 	color: ${({ theme }) => theme.inputPlaceholder};
 	}
`;
