/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:35 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 3, 2023 at 10:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import blogReducer from './slices/blog/blog.slice';
import componentReducer from './slices/component/component.slice';
import localeReducer from './slices/locale/locale.slice';
import consentReducer from './slices/consent/consent.slice';
import cartReducer from './slices/cart/cart.slice';
import checkoutReducer from './slices/checkout/checkout.slice';
import uiReducer from './slices/ui/ui.slice';
import userReducer from './slices/user/user.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		blog: blogReducer,
		component: componentReducer,
		locale: localeReducer,
		consent: consentReducer,
		cart: cartReducer,
		checkout: checkoutReducer,
		ui: uiReducer,
		user: userReducer,
	},
});
