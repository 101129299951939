/*
 * File: HeroSection.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 1, 2023 at 3:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './HeroSection.styles';


/**
 * Component
 */

export const HeroSection = ({
	className, foregroundImage, backgroundImage, video, isVideo, innerPadding, children
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className}>
			{isVideo ? (
				<S.VideoContainer>
					<S.BackgroundVideo
						playsInline
						autoPlay
						loop
						muted
					>
						<source src={video} type="video/mp4" />
					</S.BackgroundVideo>
					<S.BorderLayer />
				</S.VideoContainer>
			) : (
				<>
					<S.BackgroundLayer $image={backgroundImage}>
						<S.BorderLayer />
					</S.BackgroundLayer>
					<S.ForegroundLayer $image={foregroundImage} />
				</>
			)}
			<S.Content left right dynamic>
				{innerPadding
					? <S.InnerPadding>{children}</S.InnerPadding>
					: children}
			</S.Content>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

HeroSection.displayName = 'HeroSection';
HeroSection.propTypes = {
	className: PropTypes.string,
	foregroundImage: PropTypes.string,
	backgroundImage: PropTypes.string,
	video: PropTypes.string,
	innerPadding: PropTypes.bool,
	isVideo: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
HeroSection.defaultProps = {
	className: null,
	foregroundImage: null,
	backgroundImage: null,
	video: null,
	innerPadding: false,
	isVideo: false,
	children: null
};
