/*
 * File: ProductCard.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 9:39 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 25, 2023 at 8:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Utilities
import { toastError } from '../../utilities/toaster';
import { formatOfferDate, optimizeImage } from '../../utilities/product';
import { recordGTMEcommerceEvent } from '../../utilities/analytics';

// Slices
import { updateCart as updateCartSlice } from '../../store/slices/cart/cart.slice';

// Services
import { updateCart } from '../../services/cart';

// Context
import { AppNavigationContext } from '../AppNavigation/AppNavigation';

// Components
import { LocaleLink } from '../LocaleLink';
import { ProductImage } from '../ProductImage';
import { Typography } from '../Typography';
import { SchemaScript } from '../SchemaScript';
import { Button } from '../Button';

// Styles
import * as S from './ProductCard.styles';


/**
 * Component
 */

export const ProductCard = ({ product, showMarkup }) => {

	// Create state handlers
	const [cardFocus, setCardFocus] = useState(false);

	// Get context from navigation parent
	const {
		dispatch: contextDispatch,
	} = useContext(AppNavigationContext);

	// Use hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle add to cart function
	const addToCart = async (e) => {

		// Prevent default action
		e.preventDefault();

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({ products: [{ id: product.id, variantId: product.variants[0].id, add: 1 }] });

			// Get current variant
			const currentVariant = product?.variants?.length === 1 ? product?.variants[0] : null;

			// Create item id
			let itemId = `${product.id.replace('gid://shopify/Product/', '')}`;
			if (currentVariant) itemId = `${itemId}-${currentVariant.id.replace('gid://shopify/ProductVariant/', '')}`;

			// Record GTM event
			recordGTMEcommerceEvent('add_to_cart', {
				currency: 'USD',
				value: product.numericPrice,
				items: [
					{
						item_id: itemId,
						item_name: product.title,
						index: 0,
						affiliation: product.vendor || '700 Rivers',
						item_brand: product.vendor || '700 Rivers',
						price: product.numericPrice,
						quantity: 1,
						...product.productType ? {
							item_category: product.productType
						} : undefined,
						...product.tags && product.tags.length > 0 ? {
							item_category2: product.tags[0]
						} : undefined,
						...product.tags && product.tags.length > 1 ? {
							item_category3: product.tags[1]
						} : undefined,
						...product.tags && product.tags.length > 2 ? {
							item_category4: product.tags[2]
						} : undefined,
						...product.tags && product.tags.length > 3 ? {
							item_category5: product.tags[3]
						} : undefined,
						...currentVariant && currentVariant.title && currentVariant.title !== 'Default Title' ? {
							item_variant: currentVariant.title,
						} : undefined
					}
				]
			});

			// Update cart
			dispatch(updateCartSlice(cart));

			// Open cart sidebar
			contextDispatch({
				type: 'OPEN_CART_SIDEBAR',
				payload: {}
			});
		} catch (error) {

			// Show error message
			toastError(uiMode, 'We\'re having trouble adding this product to your cart. Give it another go.');
		}
	};

	// Format product url
	const formatProductURL = (productObj) => `${stateLocale.localePath}/products/${productObj.handle}`;

	// Render component
	return (
		<S.Wrapper onMouseEnter={() => { setCardFocus(true); }} onMouseLeave={() => { setCardFocus(false); }} style={{ zIndex: cardFocus ? 2 : 1 }}>

			{/* Background Overlay */}
			<S.CardOverlay className={cardFocus ? 'animate showOverlay' : 'animate'}>

				{/* Cart Overlay */}
				<S.CartOverlay className={cardFocus ? 'animate showOverlay' : 'animate'}>
					{product?.availableForSale && product?.variants?.[0]?.availableForSale && (!product?.variants?.[0]?.currentlyNotInStock || product?.isGiftCard) ? (
						<Button
							onClick={product?.variants?.length > 1 ? () => { navigate(formatProductURL(product)); } : addToCart}
							variant="outline"
							size="medium"
						>
							<Typography variation="button-small" weight="medium">{product?.variants?.length > 1 ? 'See Options' : 'Add to Cart'}</Typography>
						</Button>
					) : (
						<Button
							onClick={() => { navigate(formatProductURL(product)); }}
							variation="secondary"
							variant="outline"
							size="medium"
						>
							<Typography variation="button-small" weight="medium">Out of Stock</Typography>
						</Button>
					)}
				</S.CartOverlay>

			</S.CardOverlay>

			{/* Product Link */}
			<LocaleLink
				key={product.id}
				to={`/products/${product.handle}`}
			>

				{/* Schema.org Product */}
				{showMarkup && (
					<SchemaScript schema={{
						'@context': 'http://schema.org/',
						'@type': 'Product',
						'@id': `${process.env.APP_URL}${formatProductURL(product)}/#Product`,
						name: product?.title,
						url: `${process.env.APP_URL}${formatProductURL(product)}`,
						image: [
							product?.images?.[0]?.url
						],
						description: product?.description,
						brand: {
							'@type': 'Brand',
							name: '700 Rivers'
						},
						mpn: product?.variants?.[0]?.mpn,
						gtin: '',
						sku: product?.variants?.[0]?.sku,
						productID: product?.handle,
						category: product?.productType,
						isFamilyFriendly: true,
						offers: {
							'@type': 'Offer',
							availability: 'http://schema.org/InStock',
							price: product?.numericPrice != null ? product?.numericPrice : product?.displayPrice,
							priceCurrency: 'USD',
							itemCondition: 'https://schema.org/NewCondition',
							priceValidUntil: formatOfferDate(),
							url: `${process.env.APP_URL}${formatProductURL(product)}`
						},
						potentialAction: [
							{
								'@type': 'BuyAction',
								target: `${process.env.APP_URL}${formatProductURL(product)}`,
							},
							{
								'@type': 'ReviewAction',
								target: `${process.env.APP_URL}${formatProductURL(product)}`,
							},
							{
								'@type': 'ShareAction',
								target: `${process.env.APP_URL}${formatProductURL(product)}`,
							}
						]
					}}
					/>
				)}

				{/* Image */}
				<S.ImageContainer>
					<ProductImage image={`${optimizeImage(product.images?.[0]?.url, 800)}`} ratio={0.75} />
				</S.ImageContainer>

				{/* Product Card */}
				<S.InnerCard>

					{/* Details */}
					<S.DetailContainer>
						<Typography tag="h5" weight="medium">{product.title}</Typography>
						{product.displayCompareAtPrice && (
							<Typography tag="p" variation="2" weight="light" className="compareAtPrice">
								{product.displayCompareAtPrice}
							</Typography>
						)}
						<Typography tag="p" variation="2" weight="light">
							{product.displayPrice}
						</Typography>
					</S.DetailContainer>

				</S.InnerCard>
			</LocaleLink>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

ProductCard.displayName = 'ProductCard';
ProductCard.propTypes = {
	product: PropTypes.shape(),
	showMarkup: PropTypes.bool
};
ProductCard.defaultProps = {
	product: null,
	showMarkup: true
};
