/*
 * File: EmptyComponent.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 22, 2022 at 10:36 AM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:17 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mediumBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	padding: 0rem 2rem;

	.description {
		margin-top: 0.6rem;
		font-size: 0.9rem;
		color: ${({ theme }) => theme.secondaryText};
	}

	.empty-icon {
		display: block;
		align-self: center;
		margin-bottom: 1.8rem;
		color: ${({ theme }) => theme.placeholderHighlight};
	}

	@media screen and (min-width: ${mediumBreakpoint}em) {
		padding: 3.4375rem 1rem;
	}
`;
