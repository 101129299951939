/*
 * File: product.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on July 28, 2022 at 10:50 AM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 25, 2023 at 11:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import { formatDateString } from '../../utilities/dateTime';


/**
 * Utilities
 */

// Format offer validity date
export const formatOfferDate = () => {
	const now = new Date();
	now.setFullYear(now.getFullYear() + 10);
	return formatDateString(now, 'YYYY-MM-DD');
};

// Optimize image
export const optimizeImage = (url, width = 800) => {
	if (!url) return url;
	if (url.includes('?')) return `${url}&width=${width}`;
	return `${url}?width=${width}`;
};
