/*
 * File: Footer.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMediaQuery } from 'beautiful-react-hooks';

// Utilities
import { toastError, toastMessage } from '../../utilities/toaster';

// Services
import { postEmailList } from '../../services/contact';

// Components
import { Typography } from '../Typography';
import { LocaleLink } from '../LocaleLink';
import { IconButton } from '../IconButton';
import { ConfettiBurst } from '../ConfettiBurst';

// Constants
import { xLargeBreakpoint } from '../../styles/constants';

// Styles
import * as S from './Footer.styles';


/**
 * Component
 */

export const Footer = ({ isLarge }) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Check if mobile screen size
	const isXLargeBreakpoint = useMediaQuery(`(max-width: ${xLargeBreakpoint}em)`);

	// Handle submit email form function
	const handleSubmitEmail = async () => {

		// Get parameters
		const {
			email, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}

		// Set submitting state
		setIsSubmitting(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('subscribe_email_list'); } catch (e) {}

		// Subscribe to email list
		postEmailList({
			email,
			question_input: questionInput,
			_magic,
			_magic2,
			recaptchaToken,
		}).then(() => {

			// Clear inputs
			setInputValues({});

			// Set submitting state
			setIsSubmitting(false);

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 100);

			// Show confirmation
			toastMessage(uiMode, 'Welcome! Thank you for signing up. Awesome things are on the way to your inbox!');

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 5000);

		}).catch(({ response }) => {

			// Set loading state
			setIsSubmitting(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble adding your email. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Return component
	return (
		<>
			<S.Footer left right isLarge={isLarge}>

				{/* Navigation Links Section */}
				<S.NavigationSection>

					{/* Company */}
					<S.LinkSection className="a1">
						<Typography tag="p">Our Company</Typography>
						<S.Link to="/mission">Our Mission</S.Link>
						<S.Link to="/our-artisans">Artisans</S.Link>
						<S.Link to="/blog">Our Blog</S.Link>
						<S.Link to="/supply-chain">Supply Chain</S.Link>
						<S.Link to="/candle-making-class">Candle Making Classes</S.Link>
						{/* <S.Link to="/work-with-catherina">Work with Catherina</S.Link> */}
					</S.LinkSection>

					{/* Help */}
					<S.LinkSection className="a2">
						<Typography tag="p">Help</Typography>
						<S.Link to="/legal/refund-policy">Returns & Refunds</S.Link>
						<S.Link to="/brand">Brand & Presskit</S.Link>
						<S.Link to="/legal/privacy">Privacy Policy</S.Link>
						<S.Link to="/legal/security">Security</S.Link>
					</S.LinkSection>

					{/* Account */}
					<S.LinkSection className="a3">
						<Typography tag="p">Account</Typography>
						<S.Link to="/rewards/join">Rewards</S.Link>
						<S.Link to="/shop/gift-cards">Gift Cards</S.Link>
						<S.Link to="/refer-a-friend">Refer-a-Friend</S.Link>
						<S.Link to="/seasonal-subscription">Seasonal Subscription</S.Link>
						<S.Link to="/rewards/login">Sign In / Create Account</S.Link>
					</S.LinkSection>

					{/* Have A Question? */}
					<S.LinkSection className="a4">
						<Typography tag="p">Have A Question?</Typography>
						<S.Link to="/corporate-gifts">
							<FontAwesomeIcon icon={['fasl', 'gift']} />
							Corporate Gifts
						</S.Link>
						<S.Link to="/contact">
							<FontAwesomeIcon icon={['fasl', 'envelope']} />
							Contact Us
						</S.Link>
						<S.Link to="/legal/refund-policy">
							<FontAwesomeIcon icon={['fasl', 'arrows-rotate']} />
							Returns & Exchanges
						</S.Link>
						<S.Link to="/retailers">
							<FontAwesomeIcon icon={['fasl', 'tag']} />
							For Retailers
						</S.Link>
						<S.Link to="/wholesale">
							<FontAwesomeIcon icon={['fasl', 'box']} />
							Wholesale Orders
						</S.Link>
						<S.Link to="/stores">
							<FontAwesomeIcon icon={['fasl', 'location-dot']} />
							Find Us In Store
						</S.Link>
					</S.LinkSection>

					{/* Spacer */}
					{isXLargeBreakpoint && (
						<S.LinkSection className="spacer">
							<S.Spacer />
						</S.LinkSection>
					)}

					{/* Email Form */}
					<S.FormContainer className="a5">

						{/* Content */}
						<Typography tag="p" className="footerTitle">Stay In The Know</Typography>
						<Typography tag="p" variation="2" weight="light">Promotions, new products and sales. Directly to your inbox.</Typography>

						{/* Email Input */}
						<S.InputContainer>
							<S.EmailInput
								placeholder="Email"
								name="email"
								type="text"
								error={emailError}
								value={inputValues.email || ''}
								autoComplete="email"
								containerClassName="formInput"
								onFocus={() => { setEmailError(null); }}
								onKeyUp={() => { setEmailError(null); }}
								onBlur={() => { setEmailError(null); }}
								onChange={handleOnChange}
							/>
							<IconButton
								size={1.1}
								icon={['fass', 'arrow-right']}
								disabled={isSubmitting}
								onClick={handleSubmitEmail}
							/>
							<S.ConfettiTarget ref={confettiTargetRef} />
						</S.InputContainer>

						{/* Question Input */}
						<input
							id="question_input"
							name="question_input"
							type="text"
							value={inputValues.question_input || ''}
							onChange={handleOnChange}
							className="question-input"
						/>

						{/* Honeypots */}
						<input
							type="text"
							name="_magic"
							value={inputValues._magic || ''}
							onChange={handleOnChange}
							className="gotcha-field"
						/>
						<input
							type="text"
							name="_magic2"
							value={inputValues._magic2 || ''}
							onChange={handleOnChange}
							style={{ display: 'none' }}
						/>

						{/* Disclaimer */}
						<Typography tag="p" variation="3" weight="regular">
							You can unsubscribe by
							{' '}
							<LocaleLink to="/contact">contacting 700 Rivers</LocaleLink>
							{' '}
							or using the unsubscribe link. 700 Rivers&apos;
							{' '}
							<LocaleLink to="/legal/privacy">Privacy Policy</LocaleLink>
							{' '}
							applies and sets out your rights.
						</Typography>
					</S.FormContainer>

				</S.NavigationSection>

				{/* Bottom Section */}
				<S.BottomSection>

					{/* Copyright Section */}
					<S.LeftSection>
						<LocaleLink to="/">
							<S.LogoMark alt="700 Rivers Logo" title="700 Rivers Logo" src={`${process.env.CDN_URL}/public/assets/700-rivers-logo-mark.png`} />
						</LocaleLink>
						<S.LogoContainer>
							<Typography tag="span" weight="light">{`© ${new Date().getFullYear()} 700 Rivers`}</Typography>
						</S.LogoContainer>
						<S.MadeWithContainer>
							<Typography tag="span" className="divider isNotMobile">·</Typography>
							<Typography tag="span">
								Made with
								{' '}
								<FontAwesomeIcon icon={['fass', 'heart']} className="heart" />
								{' '}
								in Raleigh, NC
							</Typography>
						</S.MadeWithContainer>
						<S.LinkContainer>
							<Typography tag="span" className="divider isNotMobile">·</Typography>
							<S.BottomLink to="/mission">Mission</S.BottomLink>
							<Typography tag="span" className="divider">·</Typography>
							<S.BottomLink to="/legal">Legal</S.BottomLink>
							<Typography tag="span" className="divider">·</Typography>
							<S.BottomLink to="/legal/privacy">Privacy</S.BottomLink>
						</S.LinkContainer>
					</S.LeftSection>

					{/* Social Section */}
					<S.RightSection>
						<S.SocialLink to="https://www.facebook.com/The700Rivers" target="_blank" className="animate" identifier="footer-social-facebook">
							<S.SocialIcon icon={['fab', 'facebook-f']} />
						</S.SocialLink>
						<S.SocialLink to="https://www.tiktok.com/@700rivers/" target="_blank" className="animate" identifier="footer-social-tiktok">
							<S.SocialIcon icon={['fab', 'tiktok']} />
						</S.SocialLink>
						<S.SocialLink to="https://www.instagram.com/700rivers/" target="_blank" className="animate" identifier="footer-social-instagram">
							<S.SocialIcon icon={['fab', 'instagram']} />
						</S.SocialLink>
					</S.RightSection>
				</S.BottomSection>
			</S.Footer>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

Footer.displayName = 'Footer';
Footer.propTypes = {
	isLarge: PropTypes.bool
};
Footer.defaultProps = {
	isLarge: true
};
