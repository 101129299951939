/*
 * File: LegalNavigation.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 13, 2022 at 5:15 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 13, 2023 at 2:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'beautiful-react-hooks';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';
import { Padding } from '../Padding';

// Constants
import { mobileBreakpoint } from '../../styles/constants';

// Styles
import * as S from './LegalNavigation.styles';


/**
 * Constants
 */

const MOBILE_NAVIGATION_HEIGHT = 450;


/**
 * Component
 */

export const LegalNavigation = ({ children, allowOverflow }) => {

	// Set state
	const [sidebarBreakpoint, setSidebarBreakpoint] = useState(-1);
	const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

	// Get location
	const location = useLocation();

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Create references for elements
	const sidebarRef = useRef();
	const contentRef = useRef();
	const isMounted = useRef(true);

	// Check if link is action function
	const isLinkActive = (to) => location.pathname === to || location.pathname === `${locale.localePath}${to}`;

	// Handle mobile navigation visibility
	const toggleMobileNavigation = () => {
		if (mobileNavigationOpen == null) setMobileNavigationOpen(false);
		else setMobileNavigationOpen(!mobileNavigationOpen);
	};

	// Handle actions on mobile mode change
	useEffect(() => {

		// Hide mobile navigation visibility
		setMobileNavigationOpen(false);

	}, [isMobile]);

	// Handle actions on component load
	useEffect(() => {

		// Initialize temporary state
		isMounted.current = true;

		// Attach scroll listener
		if (isMounted.current) {
			document.addEventListener('scroll', () => {
				if (isMounted.current && sidebarRef.current != null && contentRef.current != null) {

					// Get parameters
					const { bottom: sidebarBottom } = sidebarRef.current.getBoundingClientRect();
					const { bottom: contentBottom } = contentRef.current.getBoundingClientRect();

					// Update sidebar position class
					if (contentBottom <= sidebarBottom && sidebarBreakpoint === -1) {
						if (!sidebarRef.current.classList.contains('bottom')) {
							sidebarRef.current.classList.add('bottom');
							setSidebarBreakpoint(sidebarBottom);
						}
					} else if (contentBottom > sidebarBreakpoint && sidebarBreakpoint !== -1 && sidebarRef.current.classList.contains('bottom')) {
						sidebarRef.current.classList.remove('bottom');
						setSidebarBreakpoint(-1);
					}
				}
			}, true);
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, [sidebarBreakpoint]);

	// Render navigation components function
	const renderNavigationItems = () => (
		<>
			{/* Getting Started */}
			<S.NavigationSection>
				<Typography tag="h5">Getting Started</Typography>
				<S.Link to="/legal">
					{isLinkActive('/legal') && <S.LinkActive />}
					Overview
				</S.Link>
			</S.NavigationSection>

			{/* Terms */}
			<S.NavigationSection>
				<Typography tag="h5">Terms</Typography>
				<S.Link to="/legal/terms">
					{isLinkActive('/legal/terms') && <S.LinkActive />}
					Terms of Service
				</S.Link>
			</S.NavigationSection>

			{/* Policies */}
			<S.NavigationSection>
				<Typography tag="h5">Policies</Typography>
				<S.Link to="/legal/privacy">
					{isLinkActive('/legal/privacy') && <S.LinkActive />}
					Privacy Policy
				</S.Link>
				<S.Link to="/legal/refund-policy">
					{isLinkActive('/legal/refund-policy') && <S.LinkActive />}
					Return & Refund Policy
				</S.Link>
				<S.Link to="/legal/cookie-policy">
					{isLinkActive('/legal/cookie-policy') && <S.LinkActive />}
					Cookie Policy
				</S.Link>
			</S.NavigationSection>

			{/* General */}
			<S.NavigationSection>
				<Typography tag="h5">General</Typography>
				<S.Link to="/legal/security">
					{isLinkActive('/legal/security') && <S.LinkActive />}
					Security Promise
				</S.Link>
			</S.NavigationSection>
		</>
	);

	const renderPageTitle = () => {
		if (isLinkActive('/legal')) {
			return 'Overview';
		} if (isLinkActive('/legal/terms')) {
			return 'Terms of Service';
		} if (isLinkActive('/legal/privacy')) {
			return 'Privacy Policy';
		} if (isLinkActive('/legal/cookie-policy')) {
			return 'Cookie Policy';
		} if (isLinkActive('/legal/refund-policy')) {
			return 'Return & Refund Policy';
		} if (isLinkActive('/legal/security')) {
			return 'Security Promise';
		}
		return '';
	};

	// Render component
	return (
		<S.LegalNavigation>
			<S.InnerNavigation>

				{/* Mobile Navigation */}
				<S.MobileNavigation className="isMobile">

					{/* Mobile Header */}
					<S.MobileHeader onClick={() => { toggleMobileNavigation(); }}>
						<Padding left right dynamic>

							{/* Title */}
							<Typography tag="h5">{renderPageTitle()}</Typography>

							{/* Toggle */}
							<S.MobileNavigationToggle icon={mobileNavigationOpen ? ['fasr', 'chevron-up'] : ['fasr', 'chevron-down']} />
						</Padding>
					</S.MobileHeader>

					{/* Mobile Dropdown */}
					<S.MobileDropdown left right dynamic className="animate" isOpen={mobileNavigationOpen} elementHeight={MOBILE_NAVIGATION_HEIGHT}>
						<Padding left right dynamic>
							{renderNavigationItems()}
						</Padding>
					</S.MobileDropdown>
				</S.MobileNavigation>

				{/* Content */}
				<S.Wrapper left right dynamic>

					{/* Sidebar Navigation */}
					<S.SidebarNavigation className="isNotMobile" ref={sidebarRef}>

						{/* Title */}
						<Typography tag="h2" weight="semibold">Legal</Typography>

						{/* Navigation Items */}
						{renderNavigationItems()}

					</S.SidebarNavigation>

					{/* Content */}
					<S.Content className="paragraphContent" id="scroller" allowOverflow={allowOverflow}>
						<S.ContentPadding ref={contentRef}>
							{children}
						</S.ContentPadding>
					</S.Content>
				</S.Wrapper>
			</S.InnerNavigation>
		</S.LegalNavigation>
	);
};


/**
 * Configuration
 */

LegalNavigation.displayName = 'LegalNavigation';
LegalNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	allowOverflow: PropTypes.bool
};
LegalNavigation.defaultProps = {
	children: null,
	allowOverflow: false
};
