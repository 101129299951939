/*
 * File: Card.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 2, 2023 at 9:38 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Card = styled.div`
	background-color: ${({ theme }) => theme.cardBackground};
`;
