/*
 * File: PointsHistory.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on March 5, 2023 at 1:36 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 10:26 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { EmptyComponent, Table, Typography } from '../../../../components';
import { ContentCell, DateCell } from '../../../../components/Table/Table';

// Styles
import * as S from './PointsHistory.styles';
import { REWARDS_EARN_ACTIONS, REWARDS_SPEND_ACTIONS } from '../../../../../Constants';


/**
 * Description Cell
 */

// Component
export const DescriptionCell = ({ column, value }) => {

	// Get available actions
	const actions = column.descriptionOptions?.earned ? REWARDS_EARN_ACTIONS : REWARDS_SPEND_ACTIONS;

	// Get reward action
	const rewardAction = Object.values(actions).find((action) => action.id === value);

	// Return component
	return (
		<Typography variation="2" weight="medium" truncate>{rewardAction.description || rewardAction.name}</Typography>
	);
};

// Configuration
DescriptionCell.propTypes = {
	value: PropTypes.string,
	column: PropTypes.shape()
};
DescriptionCell.defaultProps = {
	value: null,
	column: {
		descriptionOptions: {
			earned: true
		}
	}
};


/**
 * Component
 */

export const PointsHistory = ({ className, history, earned }) => {

	// Render component
	const renderComponent = () => {
		if (history.length === 0) {
			return (
				<EmptyComponent
					icon={['fasl', 'gift']}
					title="Your account is empty at the moment"
					message="As you earn reward points with 700 Rivers, they will appear here."
				/>
			);
		}
		return (
			<S.TableContainer>
				<Table
					data={history}
					alignTop
					isDark
					columns={[
						{
							Header: earned ? 'Earned On' : 'Redeemed On',
							accessor: 'date',
							dateFormatOptions: {
								output: 'MMM D, YYYY'
							},
							Cell: DateCell
						},
						{
							Header: earned ? 'Points Earned' : 'Points Redeemed',
							accessor: earned ? 'pointsEarned' : 'pointsSpent',
							Cell: ContentCell
						},
						{
							Header: 'Rewards Balance',
							accessor: 'adjustedBalance',
							Cell: ContentCell
						},
						{
							Header: '',
							accessor: 'action',
							descriptionOptions: {
								earned
							},
							Cell: DescriptionCell
						},
					]}
				/>
			</S.TableContainer>
		);
	};

	// Return component
	return (
		<S.Wrapper className={className}>
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

PointsHistory.displayName = 'PointsHistory';
PointsHistory.propTypes = {
	className: PropTypes.string,
	history: PropTypes.arrayOf(PropTypes.shape()),
	earned: PropTypes.bool
};
PointsHistory.defaultProps = {
	className: null,
	history: [],
	earned: true
};
