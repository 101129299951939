/*
 * File: HeroSection.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 22, 2023 at 11:39 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../Padding';
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: block;
	width: auto;
	flex-direction: row;
	position: relative;
`;

export const BackgroundLayer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
	padding: 30px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
`;

export const BorderLayer = styled.div`
	border: 3px solid ${({ theme }) => theme.primaryBackground};
	width: 100%;
	height: 100%;
`;

export const ForegroundLayer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 2;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
`;

export const VideoContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const BackgroundVideo = styled.video`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	object-fit: cover;
`;

export const Content = styled(Padding)`
	position: relative;
	z-index: 3;

	h1 {
		color: ${ColorValues.white};
		font-size: 4.3rem;
	}
	h5 {
		color: ${ColorValues.white};
	}
`;

export const InnerPadding = styled.div`
	padding: 250px 0px 250px 0px;
	width: 100%;
`;
