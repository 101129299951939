/*
 * File: ErrorComponent.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:17 AM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';

// Components
import { Meta } from '../Meta';
import { Typography } from '../Typography';
import { Button } from '../Button';

// Animation
import animationData from '../../assets/animations/bicycle.json';

// Styles
import * as S from './ErrorComponent.styles';


/**
 * Component
 */

export const ErrorComponent = ({
	locale, title, description, message, buttons
}) => (
	<>
		{/* Meta */}
		<Meta
			meta={{
				title: 'Page Not Found | 700 Rivers',
				description: 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.',
				bodyClasses: 'hide-captcha',
				showSocial: false,
			}}
			locale={locale}
		/>

		{/* Component Content */}
		<S.Wrapper>
			<S.ContentContainer>
				<S.LottieLink identifier="error-center-lottie">
					<Lottie loop play animationData={animationData} style={{ height: 330 }} />
				</S.LottieLink>
				<S.MessageContainer>
					<Typography tag="h1" weight="semibold">
						{title || 'Whoops!'}
					</Typography>
					<S.DescriptionContainer>
						<Typography tag="h4" weight="light">
							{description || 'We searched high and low but couldn\'t find what you\'re looking for. Let\'s find a better place for you to go.'}
						</Typography>
					</S.DescriptionContainer>
					{message !== '' && (
						<Typography tag="h4" weight="semibold">
							{message || 'Shop all our products'}
						</Typography>
					)}
				</S.MessageContainer>
				<S.ActionContainer fullWidth={!buttons}>
					{
						buttons ? buttons.map((button) => (
							<S.ButtonLink to={button.link} key={button.text}>
								<Button size={button.size} variant={button.variant || 'solid'}>{button.text}</Button>
							</S.ButtonLink>
						)) : (
							<S.ButtonLink to="/">
								<Button size="large">Explore now</Button>
							</S.ButtonLink>
						)
					}
				</S.ActionContainer>
			</S.ContentContainer>
		</S.Wrapper>
	</>
);


/**
 * Configuration
 */

ErrorComponent.displayName = 'ErrorComponent';
ErrorComponent.propTypes = {
	locale: PropTypes.shape(),
	title: PropTypes.string,
	description: PropTypes.node,
	message: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.shape())
};
ErrorComponent.defaultProps = {
	locale: {},
	title: null,
	description: null,
	message: null,
	buttons: null
};
