/*
 * File: BlogCard.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 28, 2023 at 10:53 AM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 18, 2023 at 8:51 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink as LocaleLinkBase } from '../../LocaleLink';
import { SIZES } from '../../Typography/Typography.styles';

// Constants
import { largeBreakpoint, mediumBreakpoint } from '../../../styles/constants';


/**
 * Helpers
 */

const renderBorderRadius = (perRow) => {
	if (perRow === 1) return '20px';
	if (perRow === 2 || perRow === 3) return '16px';
	return '14px';
};


/**
 * Styles
 */

export const Wrapper = styled(LocaleLinkBase)`
	text-decoration: none;

	.h1 {
		font-size: ${SIZES.h1.small};

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			font-size: ${SIZES.h1.medium};
		}
		@media only screen and (min-width: ${largeBreakpoint}em) {
			font-size: ${SIZES.h1.large};
		}
	}
	.animateOpacity {
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		-o-transition: opacity 0.3s;
	}
`;

export const FeatureImage = styled.div`
	width: 100%;
	border-radius: ${({ $perRow }) => renderBorderRadius($perRow)};
	height: ${({ $height }) => `${$height}px`};
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 40%;

	&:hover {
		opacity: 0.8;
	}
`;

export const TopicContainer = styled.div`
	margin: 30px 0px 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
`;

export const DetailContainer = styled.div`
	margin: 10px 0px 15px;

	* {
		color: ${({ theme }) => theme.secondaryText};
	}
	span {
		font-size: 0.9rem;
	}
`;

export const ReadAction = styled.div`
	margin: 25px 0px 0px 0px;

	span {
		font-size: 0.9rem;
	}
	svg {
		margin: 0px 0px 0px 6px;
		font-size: 0.8rem;
	}
`;

export const LocaleLink = styled(LocaleLinkBase)`
	text-decoration: none;
`;
