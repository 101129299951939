/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on December 27, 2022 at 12:31 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 19, 2023 at 8:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Users Services
 */

export const getCurrentUser = (data) => api.get('/users/current', data);

export const updateNotificationPreferences = ({ preferences, token }) => api.post('/user/account/notifications', { preferences, token });

export const validateGeneralAccessAttempt = ({ token }) => api.get('/user/account/access', { params: { token } });

export const joinRewardsProgram = (data) => api.post('/user/account/rewards/join', data);

export const loginRewardsProgram = (data) => api.post('/user/account/rewards/login', data);

export const referFriend = (data) => api.post('/user/account/refer', data);

export const getRewardsOffers = () => api.get('/user/account/rewards/offers');

export const createGiftCardReward = ({ rewardId }) => api.post('/user/account/rewards/generate/card', { rewardId });

export const sendGiftCard = ({ cardId, email }) => api.post('/user/account/rewards/share/card', { cardId, email });

export const updateUserAccountEmail = ({ email }) => api.post('/user/account/email', { email });

export const updateUserAccount = ({ name, birthdate }) => api.post('/user/account', { name, birthdate });
