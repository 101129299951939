/*
 * File: Terms.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: January 18, 2023 at 12:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
    max-width: 700px;
    overflow-wrap: break-word;
    box-sizing: border-box;
	margin: 0 auto;
`;
