/*
 * File: utilities.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on November 14, 2022 at 12:02 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 10:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatToUSD = (number = 0) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);


/**
 * Parsers
 */

export const parseFirstLastName = (name) => {
	if (!name) return {};
	const nameArray = name.split(/\s+/);
	const firstName = nameArray[0].trim();
	const lastName = name.replace(firstName, '').trim();
	return { firstName, lastName };
};

export const toTitleCase = (text) => text.toLowerCase()
	.split(' ')
	.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
	.join(' ');
