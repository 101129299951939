/*
 * File: CorporateGifts.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on September 30, 2024 at 1:18 PM
 * Copyright © 2024 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 8:34 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import {
	Button, Card, LocaleLink, Padding
} from '../../../components';

// Styles
import { ColorValues } from '../../../styles/colors';

// Constants
import {
	largeBreakpoint, mobileBreakpoint
} from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
	padding-bottom: 50px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentSection = styled.div`
	padding: 80px 0px 80px;
	margin: 0px 0px;
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: ${({ $wideText }) => ($wideText ? '55%' : '50%')};
	text-align: left;
	margin: 0px auto;
	width: 88%;
	padding: 100px 0px;
	background-color: ${({ $white }) => ($white ? ColorValues.white : 'transparent')};

	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 8rem;
	}
	h4 {
		margin: 12px 0 0px;
	}
	h5 {
		margin: 12px 0 0px;
		font-size: 1.2rem;
	}
	p {
		margin: 20px 0 0px;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.secondaryText};
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: ${({ $wideText }) => ($wideText ? '45%' : '50%')};
`;

export const HeroImage = styled.img`
	width: 100%;
`;

export const ContactFormContainer = styled.div`
	display: flex;
	padding: 0px 0px 50px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	gap: 100px;
	flex-direction: column;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
	}
	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: left;
	}
`;

export const ContentPane = styled.div`
	margin-top: 50px;
	display: flex;
	align-items: start;
	justify-content: start;
	flex-grow: 1;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		margin-top: 0px;
	}
`;

export const FormPane = styled.div`
	width: 100%;
	max-width: 440px;
	flex-shrink: 0;
	position: relative;
`;

export const ContentContainer = styled.div`
	text-align: left;
	max-width: 680px;

	h1 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
	}
	h4 {
		text-align: left;
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Form = styled.form`
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

	& > button {
		width: 100%;
	}
	.formInput {
		margin: 20px 0 0;
	}
	.topInput {
		position: relative;
		z-index: 9999999;
	}
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const ConfirmationContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const ConfirmationPane = styled.div`
	text-align: center;
	border-radius: 10px;
	padding: 40px 20px;
	position: relative;
	overflow: hidden;

	h3 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfirmationBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const ConfirmationContent = styled.div`
	position: relative;
	z-index: 2;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 0px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
`;

export const CompactContentPanel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 50%;
	gap: 100px;
	background-color: ${ColorValues.white};
	padding: 60px;
	max-width: 1100px;
	margin: 0px auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const VideoPanel = styled.div`
	position: relative;
	flex-shrink: 0;
`;

export const VideoElement = styled.video`
	width: 100%;
	max-height: 600px;
`;

export const DetailPanel = styled.div`
	position: relative;
	flex: 50%;

	* {
		text-align: left;
	}
	p {
		margin: 15px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h4 {
		margin: 15px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;

export const PanelSection = styled.div`
	padding: 0px 0px 40px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const MediaPanelImage = styled.img`
	width: 100%;
`;

export const Content = styled.div`
	max-width: 600px;
	width: 100%;
	margin: ${({ center }) => (center ? '0px auto' : '0px 0px')};

	* {
		text-align: ${({ center }) => (center ? 'center' : 'left')};
	}
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		margin: 20px 0 0px;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const LargeIcon = styled.div`
	margin: 0px 0px 10px;

	svg {
		font-size: 1.8rem;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const LinkContainer = styled(LocaleLink)`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
	&:hover {
		text-decoration: underline;
	}
`;
