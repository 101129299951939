/*
 * File: Blog.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { IconButton } from '../../../components';

// Constants
import { largeBreakpoint, mediumBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
	margin: 0 auto;
`;

export const ContentPadding = styled.div`
	margin: 0px auto;
	max-width: 900px;
	width: 100%;
`;

export const HeroSection = styled.div`
	margin: 120px 0px 60px;
`;

export const HeroContentContainer = styled.div`
	text-align: center;
`;

export const TitleContainer = styled.div`
	a,
	a:link,
	a:visited {
		text-decoration: none;
		display: inline-block;
		position: relative;
	}
	a:hover {
		text-decoration: underline;
	}
	.floatingThe {
		position: absolute;
		left: 40px;
		top: -26px;
		font-size: 25px;
	}
	h1 {
		position: relative;
		font-size: 2.8rem;
		font-family: 'Unna', Georgia, Times, serif;
	}

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		h1 {
			font-size: 3.8rem;
		}
		.floatingThe {
			left: 32px;
			top: -30px;
			font-size: 32px;
		}
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		h1 {
			font-size: 4.8rem;
		}
		.floatingThe {
			left: 45px;
			top: -40px;
			font-size: 40px;
		}
	}
`;

export const TopicContainer = styled.div`
	border: 3px solid ${({ theme }) => theme.primaryText};
	border-radius: 32px;
	display: flex;
	margin: 40px auto 0px;
	padding: 2px;
	flex-direction: column;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
		display: inline-flex;
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`;

export const Topic = styled.div`
	height: 56px;
	padding: 0px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 28px;
	cursor: pointer;

	&:hover,
	&.active {
		background-color: ${({ theme }) => theme.primaryText};
		color: ${({ theme }) => theme.primaryBackground};
	}
`;

export const SearchButton = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
	margin: 10px 0px 0px 0px;
	border-radius: 50%;
	width: 42px;
	height: 42px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		margin: 0px 0px 0px 4px;
	}
	svg {
		font-size: 1.1rem;
		color: ${({ theme }) => theme.primaryText};
	}
	&:hover,
	&.open {
		background-color: ${({ theme }) => theme.primaryText};

		svg {
			color: ${({ theme }) => theme.primaryBackground};
		}
	}
`;

export const SearchBarContainer = styled.div`
	margin: 10px auto 0px;
	width: 100%;
	max-width: 600px;
	position: relative;
	height: 0px;
	opacity: 0;
	overflow: hidden;

	&.open {
		height: 50px;
		opacity: 1;
	}
	input {
		border: 2px solid ${({ theme }) => theme.inputBorder};
		border-radius: 25px;
		height: 50px;
		padding: 0px 45px 0px 20px;
		font-weight: 600;

		&:active,
		&:focus {
			border: 3px solid ${({ theme }) => theme.primaryText};
		}
	}
`;

export const SearchBarButton = styled(IconButton)`
	position: absolute;
	top: 7px;
	right: 7px;
	background-color: ${({ theme }) => theme.layoutBorder};
	height: 36px;
	width: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.primaryBackground};

	&.active {
		background-color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const FeaturedContainer = styled.div``;

export const SectionContainer = styled.div`
	margin: 100px 0px 120px;

	h3 {
		text-align: left;
	}
	.title {
		margin: 0px 0px 20px;
	}
`;

export const CallToAction = styled.div`
	padding: 50px 50px;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.secondaryBackground};

	* {
		color: ${({ theme }) => theme.primaryText};
		text-align: left;
	}
	button {
		margin: 30px 0px 0px;
		color: ${({ theme }) => theme.primaryBackground};
	}
`;

export const CTAInner = styled.div`
	max-width: 650px;
	width: 100%;

	h3 {
		font-family: 'Unna', Georgia, Times, serif;
	}
`;
