/*
 * File: ShareWidget.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 27, 2022 at 6:01 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: October 26, 2023 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import { ColorValues } from '../../styles/colors';

// Constants
import { mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const ShareWidget = styled.div`
	position: absolute;
	height: auto !important;
	z-index: 100;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 8px;
	box-shadow: ${ColorValues.elementShadow};
	left: ${({ adjustOnMobile }) => (adjustOnMobile ? '-70px' : '-18px')};
	${({ fromBottom }) => (!fromBottom ? css`
		bottom: 36px;
	` : css`
		top: 46px;
	`)}
	${({ theme }) => theme.navigationBorder !== 'transparent' && css`
		border: 1px solid ${theme.navigationBorder};
	`}
	opacity: 0;
	visibility: hidden;
	transform: scale(1.05);
	transition: visibility 0s linear 0.3s, opacity 0.3s 0s, transform 0.3s;
	pointer-events: none;
	width: 300px;
	z-index: 999;
	text-align: left !important;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: visibility 0s linear 0s, opacity 0.3s 0s, transform 0.3s;
		pointer-events: all;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		left: -18px;
	}
`;

export const DropdownArrow = styled.svg`
	left: ${({ adjustOnMobile }) => (adjustOnMobile ? '120px' : '70px')};
	display: block;
	width: 24px;
	height: 8px !important;
	position: absolute;
	bottom: -8px;
	fill: ${({ theme }) => theme.primaryBackground};
	filter: drop-shadow(1px 0 0 ${({ theme }) => theme.navigationBorder}) drop-shadow(-1px 0 0 ${({ theme }) => theme.navigationBorder}) drop-shadow(0 -1px 0 ${({ theme }) => theme.navigationBorder});

	${({ fromBottom }) => (!fromBottom ? css`
		transform: scale(1,-1);
		bottom: -8px;
	` : css`
		top: -8px;
	`)}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		left: 70px;
	}
`;

export const NavigationContainer = styled.ul`
	line-height: 22px;
	padding: 8px 0px;
	margin: 0px;
	min-width: 225px;
	list-style: none;
	height: auto !important;
	color: ${({ theme }) => theme.primaryText};
	max-height: 200px;
	overflow: scroll;
`;

export const ShareAction = styled.li`
	padding: 8px 16px;
	height: auto !important;
	font-size: 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	position: relative;

	&:hover {
		background-color: ${({ theme }) => theme.secondaryBackground};
	}
	span {
		flex-grow: 1;
	}
`;

export const NavigationIcon = styled(FontAwesomeIcon)`
	align-items: center;
	position: relative;
	color: ${({ theme }) => theme.primaryBackground};
`;

export const IconContainer = styled.div`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 12px;
	position: relative;
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme, color }) => (theme[color] || ColorValues[color])};

	svg {
		font-size: 1.2rem;
		font-size: ${({ iconSize }) => iconSize} !important;
	}
`;
