/*
 * File: Search.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 17, 2023 at 3:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import validator from 'validator';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Typography, Spinner, ErrorComponent, BlogSection, Button
} from '../../../components';

// Styles
import * as S from './Search.styles';
import { getProducts } from '../../../services/product';
import { getBlogPosts } from '../../../services/blog';


/**
 * Component
 */

const Search = ({ meta, locale }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [products, setProducts] = useState([]);
	const [stories, setStories] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [totalResults, setTotalResults] = useState(0);
	const [searchComplete, setSearchComplete] = useState(true);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle fetch data for page
	const fetchDataForPage = async (query) => {

		// Update page status
		setPageStatus('loading');
		try {

			// Get all products
			const { data: productData } = await getProducts({
				...query && !validator.isEmpty(query, { ignore_whitespace: true }) ? {
					query
				} : undefined
			});

			// Get most recent blog stories
			const { data: blogData } = await getBlogPosts({
				pageSize: 4,
				...query && !validator.isEmpty(query, { ignore_whitespace: true }) ? {
					query
				} : undefined
			});

			// Set new data state
			if (isMounted.current) {

				// Parse blog stories
				const blogStories = { posts: [], totalPosts: 0 };
				if (blogData.featured) {
					blogStories.posts.push(...blogData.featured.posts);
					blogStories.totalPosts += blogData.featured.totalPosts;
				}
				if (blogData.stories) {
					blogStories.posts.push(...blogData.stories.posts);
					blogStories.totalPosts += blogData.stories.totalPosts;
				}

				// Update component data
				setProducts(productData.products);
				setStories(blogStories);

				// Set total results
				setTotalResults(productData.products.length + blogStories.posts.length);

				// Update page statue
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle cancel search
	const resetSearch = () => {
		if (searchText) {

			// Update search value
			setSearchText('');

			// Reset search data
			fetchDataForPage();
		}
	};

	// Handle perform search
	const handlePerformSearch = useDebouncedCallback(async (query) => {
		if (isMounted.current) {

			// Fetch data for page
			await fetchDataForPage(query);

			// Update state
			setSearchComplete(true);
		}
	}, [], 400);

	// Handle on search input change action
	const handleOnChange = (event) => {

		// Update state
		setSearchComplete(false);

		// Update search value
		const { value } = event.target;
		setSearchText(value);

		// Perform search
		handlePerformSearch(value);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderSearchResults = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		} if (totalResults > 0) {
			return (
				<>
					{/* Blog Stories */}
					{stories.posts.length > 0 && (
						<S.ResultsSection>
							<Typography tag="h3" weight="bold">Our Blog</Typography>
							<BlogSection
								blogs={stories.posts}
								perRow={4}
								limit={4}
								total={stories.totalPosts}
								showPagination={false}
								locale={stateLocale}
							/>
						</S.ResultsSection>
					)}

					{/* Products */}
					{products.length > 0 && (
						<S.ResultsSection>
							<Typography tag="h3" weight="bold">Products</Typography>
							<S.ProductCollection products={products} showMarkup={false} showFilterBar />
						</S.ResultsSection>
					)}
				</>
			);
		}
		return null;

	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>

					{/* Search Container */}
					<S.SearchContainer>

						{/* Title */}
						<Typography tag="h2" weight="bold">Search Results</Typography>

						{/* Search Bar */}
						<S.SearchToolbar>

							{/* Bar */}
							<S.SearchBarContainer>
								<FontAwesomeIcon icon={['fass', 'magnifying-glass']} />
								<S.SearchBar
									placeholder="Start typing to search"
									type="text"
									onChange={handleOnChange}
									value={searchText}
								/>
							</S.SearchBarContainer>

							{/* Cancel Button */}
							<Button variant="text" variation="secondary" size="small" onClick={resetSearch}>
								<Typography variation="button-small" weight="medium">cancel</Typography>
							</Button>

						</S.SearchToolbar>
					</S.SearchContainer>

					{/* Results Title */}
					{searchText && searchComplete && (
						<Typography tag="h5" className="searchResultsTitle">
							{totalResults === 0 ? `Sorry, there are no results for ${searchText}` : `Showing ${totalResults} ${totalResults === 1 ? 'result' : 'results'} for ${searchText}`}
						</Typography>
					)}

					{/* Search Results */}
					{renderSearchResults()}

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Search.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Search.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Search;
