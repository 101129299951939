/*
 * File: PageHeader.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on June 26, 2022 at 9:16 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	padding: ${({ isSmall }) => (!isSmall ? '50px 0 20px' : '10px 0 20px')};

	h1,
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		font-weight: bold;
	}
	* {
		text-align: left;
	}
	h4,
	h5 {
		color: ${({ theme }) => theme.secondaryText};
		opacity: 0.6;
	}
`;

export const Icon = styled.div``;
