/*
 * File: ErrorComponent.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 12:20 AM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';

// Constants
import { mediumBreakpoint, smallBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	padding-bottom: 90px;
`;

export const ContentContainer = styled.div`
	margin: -30px auto 0px;
	text-align: center;
	padding-bottom: 30px;

	h1 {
		margin-bottom: 20px;
		font-family: 'Unna', Georgia, Times, serif;
	}
	h4 {
		margin: 5px 0 35px;
	}
	@media only screen and (min-width: ${smallBreakpoint}em) {
		margin: 30px auto 0px;
	}
`;

export const MessageContainer = styled.div`
	width: 100%;
	margin: -40px auto 0px;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		margin: 0px auto 0px;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 800px;
	}

	* {
		text-align: center !important;
	}
	a,
	a:visited,
	a:link {
		color: inherit;
	}
`;

export const DescriptionContainer = styled.div`
	width: 100%;
	margin: 0px auto 50px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 700px;
	}
`;

export const ActionContainer = styled.div`
	text-align: center;
	margin: -30px auto 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	button {
		width: 100%;
		font-size: 1.0rem;
		margin-top: 15px;
		padding-left: 50px;
		padding-right: 50px;
	}

	a {
		${({ fullWidth }) => (fullWidth ? css`
			max-width: 95%;

			@media only screen and (min-width: ${mediumBreakpoint}em) {
				max-width: 400px;
			}
		` : css`
			margin: 0 10px;
		`)};
	}

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-wrap: nowrap;
	}
`;

export const ButtonLink = styled(LocaleLink)`
	text-decoration: none;
`;

export const LottieLink = styled.div`
	text-decoration: none;
	width: 100%;
	display: block;
	margin: 0 auto;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 600px;
	}
`;
