/*
 * File: UpdateProfileModal.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 19, 2023 at 10:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError, toastSuccess } from '../../../../utilities/toaster';
import { formatDateString, isValidDateFormat, parseDateString } from '../../../../../utilities/dateTime';

// Services
import { updateUserAccount } from '../../../../services/user';

// Components
import {
	Button, Modal, Typography, TextInput, DateInput, IconContainer
} from '../../../../components';

// Styles
import * as S from './UpdateProfileModal.styles';


/**
 * Component
 */

export const UpdateProfileModal = ({
	className, isOpen, handleClose, updateUser
}) => {

	// Get current user
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [birthdayError, setBirthdayError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle update user function
	const handleUpdateUser = async () => {

		// Get parameters
		const { name, birthdate } = inputValues;

		// Parse birth date
		let formattedBirthDate = null;
		try {
			formattedBirthDate = formatDateString(birthdate, 'MM-DD-YYYY');
		} catch (e) {}

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (formattedBirthDate && !isValidDateFormat(formattedBirthDate, 'MM-DD-YYYY')) {
			setBirthdayError({ message: 'Please enter a valid date for your birthday' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserAccount({ name, birthdate: formattedBirthDate }).then(({ data }) => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Your 700 Rivers rewards account has been updated.');

			// Set loading state
			setIsLoading(false);
			setNameError(null);
			setBirthdayError(null);

			// Update user
			if (updateUser) updateUser(data.user);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 1500);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setNameError(null);
			setBirthdayError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your profile. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your profile. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen === true) {

			// Set input values
			setInputValues({
				...inputValues,
				...user?.name ? { name: user.name } : undefined,
				...user?.birthdate ? { birthdate: parseDateString(user.birthdate, 'MM-DD-YYYY').toDate() } : undefined,
			});

			// Hide errors
			setNameError(null);
			setBirthdayError(null);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<IconContainer>
					<FontAwesomeIcon icon={['fasl', 'party-horn']} />
				</IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">My Rewards Profile</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">Update your 700 Rivers profile details</Typography>

				{/* Inputs */}
				<TextInput
					label="Your name"
					name="name"
					type="text"
					placeholder="e.g. Malala"
					error={nameError}
					value={inputValues.name || ''}
					autoComplete="name"
					containerClassName="modalInput"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
				/>
				<DateInput
					label="Your birthday"
					name="birthdate"
					type="text"
					placeholderText="e.g. October 1"
					error={birthdayError}
					selected={inputValues.birthdate}
					autoComplete="birthdate"
					containerClassName="modalInput"
					dateFormat="MMMM d"
					onFocus={() => { setBirthdayError(null); }}
					onKeyUp={() => { setBirthdayError(null); }}
					onBlur={() => { setBirthdayError(null); }}
					onChange={handleOnChange}
					showYearDropdown
					showMonthDropdown
					maxDate={new Date()}
					fixedHeight
					popperPlacement="top-start"
				/>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={handleUpdateUser}>Update</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

UpdateProfileModal.displayName = 'UpdateProfileModal';
UpdateProfileModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func
};
UpdateProfileModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null
};
