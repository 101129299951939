/*
 * File: CorporateGifts.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on September 30, 2024 at 1:18 PM
 * Copyright © 2024 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 9:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { postCorporateGiftingForm } from '../../../services/contact';

// Components
import {
	Meta, AppNavigation, Typography, Emoji, TextInput, ConfettiBurst, IconContainer,
	Select,
	DateInput,
	SchemaScript,
	LocaleLink
} from '../../../components';

// Styles
import * as S from './CorporateGifts.styles';


/**
 * Constants
 */

const CUSTOM_PACKAGING_OPTIONS = [
	{
		label: 'Yes, I am interested in custom packaging',
		value: 'Yes, I am interested in custom packaging',
		hash: 'yes'
	},
	{
		label: 'No, I don\'t need custom packaging',
		value: 'No, I don\'t need custom packaging',
		hash: 'no'
	}
];


/**
 * Component
 */

const CorporateGifts = ({ meta, locale }) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [interestedInError, setInterestedInError] = useState(null);
	const [quantityError, setQuantityError] = useState(null);
	const [neededByDateError, setNeededByDateError] = useState(null);
	const [customPackagingError, setCustomPackagingError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showContactForm, setShowContactForm] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle submit contact form function
	const handleSubmitContactForm = async () => {

		// Get parameters
		const {
			name, email, interestedIn, quantity, neededBy, customPackaging, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}
		if (!interestedIn || validator.isEmpty(interestedIn, { ignore_whitespace: true })) {
			setInterestedInError({ message: 'Please enter a few details about which products you\'re interested in' });
			return;
		}
		if (!quantity || validator.isEmpty(quantity, { ignore_whitespace: true })) {
			setQuantityError({ message: 'Please enter the estimated quantity you\'re interested in' });
			return;
		}
		if (!neededBy) {
			setNeededByDateError({ message: 'Please select the estimated date you\'d need the products by' });
			return;
		}
		if (!customPackaging || validator.isEmpty(customPackaging, { ignore_whitespace: true })) {
			setCustomPackagingError({ message: 'Please indicate whether you\'d need custom packaging' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('submit_corporate_form'); } catch (e) {}

		// Submit contact form
		postCorporateGiftingForm({
			name,
			email,
			interestedIn,
			quantity,
			neededBy,
			customPackaging,
			recaptchaToken,
			question_input: questionInput,
			_magic,
			_magic2
		}).then(() => {

			// Hide form
			setShowContactForm(false);
			setTimeout(() => {

				// Clear inputs
				setInputValues({});

				// Show confirmation
				setShowConfirmation(true);
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

			}, 300);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble sending your message. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Content */}
					<S.HeroSection>
						<S.ContentPanel>
							<IconContainer>
								<FontAwesomeIcon icon={['fasl', 'gift']} />
							</IconContainer>
							<Typography tag="h1" weight="bold">
								Corporate gifts your whole team will love.
							</Typography>
							<Typography tag="p" weight="light">
								Whether you&apos;re looking for meaningful corporate gifts or event favors, we can accommodate orders from 100 units to thousands. And with every product, the recipient will understand the global impact behind their gift—how it supports survivors and empowers women.
							</Typography>
							<LocaleLink to="/corporate-gifts#story">
								<Typography tag="h5" style={{ marginTop: '25px' }}>
									What makes us the perfect gift
									<FontAwesomeIcon icon={['fass', 'arrow-down-right']} style={{ marginLeft: '8px', fontSize: '1.1rem' }} />
								</Typography>
							</LocaleLink>
						</S.ContentPanel>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/web-19-700 Rivers_r1_03E_5075.jpg`}
								alt="A display of 700 Rivers' all-natural soaps with ethically sourced ingredients"
							/>
						</S.MediaPanel>
					</S.HeroSection>

					{/* Content Padding */}
					<S.ContentPadding left right dynamic isLarge>

						{/* Section */}
						<S.ContentSection id="story">
							<S.CompactContentPanel>

								{/* Video Section */}
								<S.VideoPanel>

									{/* Schema.org VideoObject */}
									<SchemaScript schema={{
										'@context': 'http://schema.org',
										'@type': 'VideoObject',
										name: 'Meet our 700 Rivers artisans',
										description: 'We employ 20+ artisans in Mymensingh, Bangladesh who have all escaped and overcome human trafficking. Your soap purchases go directly towards continued work for all these women. Thanks for playing a role in empowering women across the globe with a fresh start in life.',
										thumbnailUrl: [
											`${process.env.CDN_URL}/public/video/thumbnails/artisan-introduction-thumb-1.jpg`,
											`${process.env.CDN_URL}/public/video/thumbnails/artisan-introduction-thumb-2.jpg`,
										],
										uploadDate: '2023-11-07T08:00:00+08:00',
										duration: 'PT54S',
										contentUrl: `${process.env.CDN_URL}/public/video/artisan-introduction.mp4`
									}}
									/>

									{/* Video Element */}
									<S.VideoElement
										playsInline
										autoPlay
										loop
										muted
									>
										<source src={`${process.env.CDN_URL}/public/video/artisan-introduction.mp4`} type="video/mp4" />
									</S.VideoElement>
								</S.VideoPanel>

								{/* Content */}
								<S.DetailPanel>
									<IconContainer>
										<FontAwesomeIcon icon={['fas', 'quote-left']} />
									</IconContainer>
									<Typography tag="h2" weight="bold">In February 2023...</Typography>
									<Typography tag="h4" weight="light">I visited Bangladesh with my parents to reconnect with their homeland and, most importantly, to finally meet our artisan partners in person after years of virtual meetings due to the pandemic. Standing in front of 25 remarkable women—survivors of human trafficking who now dedicate their talents to creating our products — I was asked a simple question: “Do people like what we make?”</Typography>
								</S.DetailPanel>
							</S.CompactContentPanel>
						</S.ContentSection>
					</S.ContentPadding>

					{/* Section */}
					<S.HeroSection>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/artisan-family-group.jpg`}
								alt="Group of 700 Rivers artisans with the founder of 700 Rivers, Catherina Gomes"
							/>
						</S.MediaPanel>
						<S.ContentPanel>
							<S.Content>
								<S.LargeIcon>
									<FontAwesomeIcon icon={['fas', 'quote-left']} />
								</S.LargeIcon>
								<Typography tag="h2" weight="bold">
									A huge smile spread across my face as I proudly shared how much our customers cherish the soaps these women lovingly handcraft each day.
								</Typography>
								<Typography tag="p" weight="light">
									I told them how our loyal customers rave about how the all-natural soaps moisturize their skin, are long-lasting, and smell incredible. I also shared how these same products have been gifted by Fortune 500 companies as holiday gifts, used as event favors, and offered as gifts of appreciation. As I spoke, I saw the women stand taller, their faces glowing with pride in their work and their contribution.
								</Typography>
								<S.LinkContainer to="/corporate-gifts#contact">
									<Typography>Share our products with your team</Typography>
									<FontAwesomeIcon icon={['fass', 'arrow-down-right']} />
								</S.LinkContainer>
							</S.Content>
						</S.ContentPanel>
					</S.HeroSection>

					{/* Product Section */}
					<S.ContentPadding left right dynamic isLarge>
						<S.ContentSection center tall>
							<S.Content center>
								<Typography tag="h1" weight="extrabold">This moment reinforced something powerful.</Typography>
								<Typography tag="p" weight="light">A simple thank you and acknowledgment of someone&apos;s effort can be transformative. Every worker, regardless of where they come from, deserves to feel valued and appreciated.</Typography>
							</S.Content>
						</S.ContentSection>
					</S.ContentPadding>

					{/* Section */}
					<S.ContentSection>
						<S.PanelSection>
							<S.ContentPanel $wideText>
								<S.Content>
									<S.LargeIcon>
										<FontAwesomeIcon icon={['fas', 'quote-left']} />
									</S.LargeIcon>
									<Typography tag="h2" weight="bold">
										At 700 Rivers, we are honored to help you express that same appreciation to your clients, partners, or employees.
									</Typography>
									<Typography tag="p" weight="light">
										Whether you&apos;re looking for meaningful corporate gifts or event favors, we can accommodate orders from 100 units to thousands. And with every product, the recipient will understand the global impact behind their gift—how it supports survivors and empowers women.
									</Typography>
									<S.LinkContainer to="/corporate-gifts#contact">
										<Typography>We&apos;ll help you create the perfect gifting package</Typography>
										<FontAwesomeIcon icon={['fass', 'arrow-down-right']} />
									</S.LinkContainer>
								</S.Content>
							</S.ContentPanel>
							<S.MediaPanel $wideText>
								<S.MediaPanelImage
									src={`${process.env.CDN_URL}/public/images/web-16-700 Rivers_r1_03E_5044.jpg`}
									alt="700 Rivers' Cucumber Mint soap bar surrounded by fresh cucumber slices and mint leaves"
								/>
							</S.MediaPanel>
						</S.PanelSection>
					</S.ContentSection>

					{/* Content Padding */}
					<S.ContentPadding left right dynamic isLarge>

						{/* Contact Form Section */}
						<S.ContentSection id="contact">
							<S.ContactFormContainer>

								{/* Content Pane */}
								<S.ContentPane>
									<S.ContentContainer>
										<IconContainer>
											<FontAwesomeIcon icon={['fal', 'hand-wave']} />
										</IconContainer>
										<Typography tag="h2" weight="bold">We invite you to explore the possibilities for your next corporate gift order.</Typography>
										<Typography tag="h4" weight="light">
											Simply fill out the form below, and our team will follow up with you within 1-5 business days. We look forward to partnering with you to make your next gift both thoughtful and impactful!
										</Typography>
									</S.ContentContainer>
								</S.ContentPane>

								{/* Form Pane */}
								<S.FormPane>

									{/* Contact Form */}
									<S.Form className="animate" isVisible={showContactForm}>

										{/* Inputs */}
										<TextInput
											label="Name"
											name="name"
											type="text"
											error={nameError}
											value={inputValues.name || ''}
											autoComplete="name"
											containerClassName="formInput"
											onFocus={() => { setNameError(null); }}
											onKeyUp={() => { setNameError(null); }}
											onBlur={() => { setNameError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<TextInput
											label="Email Address"
											name="email"
											type="text"
											error={emailError}
											value={inputValues.email || ''}
											autoComplete="email"
											containerClassName="formInput"
											onFocus={() => { setEmailError(null); }}
											onKeyUp={() => { setEmailError(null); }}
											onBlur={() => { setEmailError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<TextInput
											label="What product(s) are you interested in?"
											name="interestedIn"
											type="text"
											error={interestedInError}
											value={inputValues.interestedIn || ''}
											containerClassName="formInput"
											placeholder="Holiday candles, lavender soaps, etc."
											onFocus={() => { setInterestedInError(null); }}
											onKeyUp={() => { setInterestedInError(null); }}
											onBlur={() => { setInterestedInError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<TextInput
											label="Quantity Needed"
											name="quantity"
											error={quantityError}
											value={inputValues.quantity || ''}
											containerClassName="formInput"
											onFocus={() => { setQuantityError(null); }}
											onKeyUp={() => { setQuantityError(null); }}
											onBlur={() => { setQuantityError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<DateInput
											label="When would you need your products?"
											name="neededBy"
											type="text"
											error={neededByDateError}
											selected={inputValues.neededBy}
											containerClassName="formInput topInput"
											dateFormat="MMMM d, yyyy"
											onFocus={() => { setNeededByDateError(null); }}
											onKeyUp={() => { setNeededByDateError(null); }}
											onBlur={() => { setNeededByDateError(null); }}
											onChange={handleOnChange}
											showYearDropdown
											showMonthDropdown
											minDate={new Date()}
											fixedHeight
											popperPlacement="top-start"
										/>
										<Select
											label="Do you need custom packaging?"
											name="customPackaging"
											options={CUSTOM_PACKAGING_OPTIONS}
											error={customPackagingError}
											values={Array.isArray(inputValues.customPackaging) ? inputValues.customPackaging : [inputValues.customPackaging || '']}
											containerClassName="formInput"
											onFocus={() => { setCustomPackagingError(null); }}
											onKeyUp={() => { setCustomPackagingError(null); }}
											onBlur={() => { setCustomPackagingError(null); }}
											onChange={handleOnChange}
											smartField
										/>

										{/* Question Input */}
										<input
											id="question_input"
											name="question_input"
											type="text"
											value={inputValues.question_input || ''}
											onChange={handleOnChange}
											className="question-input"
										/>

										{/* Honeypots */}
										<input
											type="text"
											name="_magic"
											value={inputValues._magic || ''}
											onChange={handleOnChange}
											className="gotcha-field"
										/>
										<input
											type="text"
											name="_magic2"
											value={inputValues._magic2 || ''}
											onChange={handleOnChange}
											style={{ display: 'none' }}
										/>

										{/* Continue Action */}
										<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSubmitContactForm(); }} size="large" className="floatShadow">Send Message</S.ActionButton>
									</S.Form>

									{/* Confirmation Content */}
									<S.ConfirmationContainer className="animate" isVisible={showConfirmation}>

										{/* Content Pane */}
										<S.ConfirmationPane>

											{/* Content */}
											<S.ConfirmationContent>
												<Emoji symbol="🎉" size={3.5} label="Celebration" />
												<S.ConfettiTarget ref={confettiTargetRef} />
												<Typography tag="h3" weight="bold">Thanks for reaching out!</Typography>
												<Typography tag="p" variation="1">One of our team members will follow up shortly. In the meantime, check out our blog for other helpful topics.</Typography>
											</S.ConfirmationContent>

											{/* Content Background */}
											<S.ConfirmationBackground />

										</S.ConfirmationPane>
									</S.ConfirmationContainer>
								</S.FormPane>
							</S.ContactFormContainer>
						</S.ContentSection>

						{/* CTA Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">More from 700 Rivers</Typography>
							</S.TitleSection>

							{/* Card Container */}
							<S.CardContainer>

								{/* Seasonal Subscription */}
								<S.CardLink to="/seasonal-subscription">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'party-horn']} />
										<Typography tag="h4" weight="semibold">The ultimate treat yourself</Typography>
										<Typography tag="p" weight="light">Subscribe to get your seasonal favorites delivered every three months</Typography>
										<S.ActionContainer>
											<Typography>Explore the seasonal subscription</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>

								{/* Wholesale */}
								<S.CardLink to="/wholesale">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'tag']} />
										<Typography tag="h4" weight="semibold">700 Rivers Wholesale</Typography>
										<Typography tag="p" weight="light">Our team is happy to put together the perfect package for your customers</Typography>
										<S.ActionContainer>
											<Typography>Explore wholesale opportunities</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>
							</S.CardContainer>
						</S.ContentSection>
					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

CorporateGifts.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
CorporateGifts.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default CorporateGifts;
