/*
 * File: BlogSearch.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: August 9, 2024 at 4:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import validator from 'validator';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { scrollPageTo } from '../../../utilities/position';
import { buildDynamicMeta } from '../../../components/Meta';
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { getBlogTopics, getBlogPosts } from '../../../services/blog';

// Slices
import { updateBlogTopics } from '../../../store/slices/blog/blog.slice';

// Components
import {
	Meta, AppNavigation, LocaleLink, Typography, BlogSection, TextInput, EmptyComponent, Spinner, ErrorComponent, SchemaScript
} from '../../../components';

// Styles
import * as S from './BlogSearch.styles';
import * as B from '../Blog/Blog.styles';


/**
 * Component
 */

const BlogSearch = ({ meta, locale, data }) => {

	// Get current blog state from hook
	const blogState = useSelector((state) => state.blog.value);

	// Get query parameters from hook
	const [searchParams] = useSearchParams();
	const query = decodeURIComponent(searchParams.get('keywords'));

	// Create state handlers
	const [pageStatus, setPageStatus] = useState((blogState.isSet !== true && !data?.topics) || (!data?.stories?.posts || data?.stories?.posts.length === 0) ? 'idle' : 'success');
	const [searchOpen, setSearchOpen] = useState(true);
	const [searchText, setSearchText] = useState(query);
	const [searchActive, setSearchActive] = useState(searchText !== '');
	const [topicData, setTopicData] = useState(data?.topics);
	const [stories, setStories] = useState({
		posts: data?.stories?.posts || [],
		totalPosts: data?.stories?.totalPosts || 0,
		hasNextPage: data?.stories?.hasNextPage || false,
		endCursor: data?.stories?.endCursor
	});

	// Create reference for components
	const heroRef = useRef();
	const isMounted = useRef(true);
	const shouldReload = useRef(false);
	const enableDynamicLoad = useRef(false);

	// Use hooks
	const navigate = useNavigate();

	// Get location state
	const location = useLocation();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Build data from state
	const topics = blogState.isSet === true || !topicData ? blogState.topics : topicData;

	// Check if link is action function
	const isLinkActive = (to) => location.pathname === to || location.pathname === `${stateLocale.localePath}${to}`;

	// Handle search bar visibility
	const toggleSearchBarVisiblilty = () => {
		setSearchOpen(!searchOpen);
	};

	// Handle on search input change action
	const handleOnChange = (event) => {

		// Update search value
		const { value } = event.target;
		setSearchText(value);

		// Update button status
		if (!validator.isEmpty(value, { ignore_whitespace: true })) {
			setSearchActive(true);
		} else {
			setSearchActive(false);
		}
	};

	// Handle search action
	const handleSearchAction = async () => {

		// Validate search content
		if (validator.isEmpty(searchText, { ignore_whitespace: true })) {
			toastError(uiMode, 'Please enter something in the search bar.');
			return;
		}

		// Navigate to search page
		navigate(`${stateLocale.localePath}/blog/search?keywords=${encodeURIComponent(searchText)}`);
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch blog topics if necessary
			if ((blogState.isSet !== true && !topicData) || shouldReload.current === true) {

				// Fetch blog topics
				const { data: datum } = await getBlogTopics();

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setTopicData(datum.topics);

					// Dispatch new state
					dispatch(updateBlogTopics(datum.topics));
				}
			}

			// Fetch blog posts if necessary
			if (!stories.posts || stories.posts.length === 0 || shouldReload.current === true) {

				// Fetch blog posts
				const { data: datum } = await getBlogPosts({ query, pageSize: 12 });

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setStories(datum.stories);
				}
			}

			// Update state
			shouldReload.current = false;

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Set page status if necessary
		if (enableDynamicLoad.current === true && pageStatus !== 'idle') {

			// Update state
			shouldReload.current = true;

			// Reset page status
			setPageStatus('idle');
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [query]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Enable dynamic load
		enableDynamicLoad.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>
				{/* Hero Section */}
				<B.HeroSection ref={heroRef}>
					<B.HeroContentContainer>

						{/* Content */}
						<B.TitleContainer>
							<LocaleLink to="/blog">
								<Typography tag="h1" weight="extrabold" className="animate">
									<span className="floatingThe">The</span>
									700 Rivers Blog
								</Typography>
							</LocaleLink>
						</B.TitleContainer>

						{/* Topic Container */}
						<B.TopicContainer>
							{topics.slice(0, 4).map((topic) => (
								<LocaleLink to={`/blog/topic/${topic.slug}`} key={topic.slug}>
									<B.Topic className={isLinkActive(`/blog/topic/${topic.slug}`) ? 'animate active' : 'animate'}><Typography weight="semibold">{topic.title}</Typography></B.Topic>
								</LocaleLink>
							))}
						</B.TopicContainer>

						{/* Search Button */}
						<B.SearchButton className={searchOpen ? 'animate open' : 'animate'} onClick={toggleSearchBarVisiblilty}>
							<FontAwesomeIcon icon={['fass', 'magnifying-glass']} />
						</B.SearchButton>

						{/* Search Bar */}
						<B.SearchBarContainer className={searchOpen ? 'animate open' : 'animate'}>
							<TextInput
								placeholder="Search..."
								type="text"
								onChange={handleOnChange}
								value={searchText}
							/>
							<B.SearchBarButton
								size={1.1}
								icon={['fass', 'arrow-right']}
								disabled={!searchActive}
								className={searchActive ? 'animate active' : 'animate'}
								onClick={handleSearchAction}
							/>
						</B.SearchBarContainer>

					</B.HeroContentContainer>
				</B.HeroSection>

				{/* Featured Stories */}
				<S.SectionContainer>
					<Typography tag="h2" weight="bold" className="title">
						{`Results for "${query}"`}
					</Typography>
					{stories.posts?.length > 0
						? (
							<BlogSection
								blogs={stories.posts}
								perRow={4}
								limit={12}
								total={stories.totalPosts}
								showPagination
								nextCursor={stories.hasNextPage ? stories.endCursor : undefined}
								fetchParams={{ query, pageSize: 12 }}
								locale={stateLocale}
							/>
						)
						: (
							<EmptyComponent
								icon={['fass', 'magnifying-glass']}
								title="No results found"
								message="Try adjusting your search to find what you're looking for."
							/>
						)}
				</S.SectionContainer>

				{/* Related stories */}
				{stories.posts?.length === 0 && (
					<S.SectionContainer>
						<Typography tag="h3" weight="bold" className="title">More stories</Typography>
						<BlogSection
							perRow={4}
							limit={4}
							fetchParams={{ pageSize: 4, excludedStories: (stories.posts || []).map((post) => post.id), relatedContent: query }}
							locale={stateLocale}
						/>
					</S.SectionContainer>
				)}
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} data={{ ...data != null ? { ...data } : undefined, searchQuery: query }} />

			{/* Schema.org BreadcrumbList */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				'@id': `${process.env.APP_URL}/blog/search/#BreadcrumbList`,
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						item: {
							'@id': `${process.env.APP_URL}/blog/#ListItem`,
							name: 'Blog',
							url: `${process.env.APP_URL}${stateLocale.localePath}/blog`
						}
					},
					{
						'@type': 'ListItem',
						position: 2,
						item: {
							'@id': `${process.env.APP_URL}/blog/search/#ListItem`,
							name: buildDynamicMeta(meta.title, { searchQuery: query }),
							url: `${process.env.APP_URL}${stateLocale.localePath}/blog/search`
						}
					}
				]
			}}
			/>

			{/* Schema.org WebPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'WebPage',
				'@id': `${process.env.APP_URL}/blog/search/#WebPage`,
				name: buildDynamicMeta(meta.title, { searchQuery: query }),
				description: buildDynamicMeta(meta.description, { searchQuery: query }),
				url: `${process.env.APP_URL}${stateLocale.localePath}/blog/search`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				breadcrumb: {
					'@id': `${process.env.APP_URL}/blog/search/#BreadcrumbList`
				},
				potentialAction: [
					{
						'@type': 'ReadAction',
						target: `${process.env.APP_URL}${stateLocale.localePath}/blog/search`
					}
				]
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

BlogSearch.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
BlogSearch.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default BlogSearch;
