/*
 * File: Emoji.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Emoji.styles';


/**
 * Component
 */

export const Emoji = ({ label, symbol, size }) => (
	<S.Emoji
		role="img"
		aria-label={label || ''}
		aria-hidden={label ? 'false' : 'true'}
		size={size}
	>
		{symbol}
	</S.Emoji>
);


/**
 * Configuration
 */

Emoji.displayName = 'Emoji';
Emoji.propTypes = {
	size: PropTypes.number,
	label: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired
};
Emoji.defaultProps = {
	size: 1.75
};
