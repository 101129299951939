/*
 * File: CelebrationModal.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 60px 40px;
	width: 100%;
	text-align: center;

	h2 {
		text-align: center;
		margin: 0px 0px 15px 0px;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', Georgia, Times, serif;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	right: 50%;
`;
