/*
 * File: ProductCollection.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';
import { Card } from '../Card';
import { Button } from '../Button';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, xLargeBreakpoint, xxxLargeBreakpoint
} from '../../styles/constants';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: relative;
`;

export const ProductContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 35px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const FilterBar = styled.div``;

export const ShowMoreContainer = styled.div`
	color: ${({ theme }) => theme.secondaryText};
	top: -40px;
	right: unset;
	position: relative;
	cursor: pointer;

	@media only screen and (min-width: ${xxxLargeBreakpoint}em) {
		position: absolute;
		right: -90px;
		top: 95px;
	}
`;

export const ShowMoreButton = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	color: ${({ theme }) => theme.secondaryText};
	border: none;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;

export const ShowMoreLink = styled(LocaleLink)`
	text-decoration: none;
`;

export const EmptyCard = styled(Card)`
	text-align: center;
	padding: 80px 40px;
	margin: 80px 0px 0px 0px;

	* {
		text-align: center;
	}
	svg {
		color: ${ColorValues.neutralForm};
	}
	h2 {
		font-family: 'Unna', Georgia, Times, serif;
		margin: 30px 0px 10px;
	}
	p {
		color: ${({ theme }) => theme.primaryText};
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`;

export const ActionButton = styled(Button)`
	margin: 40px auto 0px;

	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;
