/*
 * File: BlogTopic.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: March 15, 2023 at 1:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0px 0px 60px;
	margin: 0 auto;
`;

export const SectionContainer = styled.div`
	padding: 20px 0px 100px;

	h2,
	h3 {
		text-align: left;
	}
	.title {
		margin: 0px 0px 30px;
	}
`;
