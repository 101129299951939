/*
 * File: Turnstile.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 18, 2024 at 2:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TurnstileWidget, { useTurnstile } from 'react-turnstile';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Turnstile.styles';


/**
 * Component
 */

export const Turnstile = ({
	className, setVerificationValue
}) => {

	// Create turnstile hook
	const turnstile = useTurnstile();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Reset turnstile on unload
	useEffect(() => () => {
		if (turnstile !== undefined) turnstile.reset();
	}, []);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className}>
			<TurnstileWidget
				sitekey={process.env.TURNSTILE_SITEKEY}
				size="normal"
				theme={uiMode?.mode === UI_MODE_OPTIONS.LIGHT ? 'light' : 'dark'}
				retry="auto"
				fixedSize
				onVerify={(token) => {

					// Set verification value
					if (setVerificationValue) setVerificationValue(token);
				}}
				onError={() => {

					// Set default value
					if (setVerificationValue) setVerificationValue(v4());
				}}
				onUnsupported={() => {

					// Set default value
					if (setVerificationValue) setVerificationValue(v4());
				}}
				onExpire={() => {

					// Reset value
					if (setVerificationValue) setVerificationValue(null);
				}}
				onTimeout={() => {

					// Reset value
					if (setVerificationValue) setVerificationValue(null);
				}}
			/>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Turnstile.displayName = 'Turnstile';
Turnstile.propTypes = {
	className: PropTypes.string,
	setVerificationValue: PropTypes.func
};
Turnstile.defaultProps = {
	className: null,
	setVerificationValue: null
};
