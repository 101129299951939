/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on November 1, 2023 at 3:03 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 11:29 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Subscription Services
 */

export const createSubscription = (data) => api.post('/subscription/seasonal', data);

export const manageSubscription = () => api.post('/subscription/manage');
