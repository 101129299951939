/*
 * File: cost.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on July 1, 2022 at 11:30 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 2:09 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const formatCost = (cost) => (cost ? `${cost.toFixed(2)}` : '0.00');
