/*
 * File: StoreLocator.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 31, 2023 at 10:32 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useState, useRef, createRef
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';
import { useDebouncedCallback } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getRetailLocations } from '../../../services/retail';

// Components
import {
	Meta, AppNavigation, Typography, IconContainer, Map, Spinner, ErrorComponent, TextInput, Button
} from '../../../components';

// Styles
import * as S from './StoreLocator.styles';


/**
 * Component
 */

const StoreLocator = ({ meta, locale, data }) => {

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.locations ? 'idle' : 'success');
	const [locations, setLocations] = useState(data?.locations);
	const [searchAddress, setSearchAddress] = useState('');
	const [selectedLocation, setSelectedLocation] = useState(null);

	// Create reference for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle select location
	const selectLocation = (locationId, shouldScroll) => {

		// Get active location
		const activeLocation = locations.find((loc) => loc.id === locationId);

		// Set selected location
		setSelectedLocation(activeLocation);

		// Scroll ref into view
		if (shouldScroll && activeLocation.ref.current) {
			activeLocation.ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start'
			});
		}
	};

	// Handle fetch data for page
	const fetchDataForPage = async (query) => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch retail locations
			const { data: { locations: retailLocations } } = await getRetailLocations({
				...query && !validator.isEmpty(query, { ignore_whitespace: true }) ? {
					postalCode: query
				} : undefined
			});

			// Set new data state
			if (isMounted.current) {

				// Update component data
				setLocations(retailLocations.map((location) => ({
					...location,
					ref: createRef()
				})));

				// Update page status
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle perform search
	const handlePerformSearch = useDebouncedCallback(async (query) => {
		if (isMounted.current) {

			// Fetch data for page
			await fetchDataForPage(query);
		}
	}, [], 400);

	// Handle on search input change action
	const handleOnChange = (event) => {

		// Update search value
		const { value } = event.target;
		setSearchAddress(value);

		// Perform search
		handlePerformSearch(value);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render store locator
	const renderRetailCards = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return locations.map((location) => (
			<S.RetailCard key={location.id} onClick={() => { selectLocation(location.id, false); }} ref={location.ref}>
				{selectedLocation?.id === location.id && (
					<S.SelectedContainer>
						<FontAwesomeIcon icon={['fass', 'star']} />
					</S.SelectedContainer>
				)}
				<Typography tag="h4" weight="bold">{location.name}</Typography>
				<Typography tag="p" variation="2" weight="light">{location.address}</Typography>
				{location.phone && <Typography tag="p" variation="2" weight="light">{location.phone}</Typography>}
				{location.website && (
					<S.WebsiteLink
						to={location.website}
						target="_blank"
					>
						<Button>
							<Typography variation="button-small" weight="medium">Visit Store</Typography>
						</Button>
					</S.WebsiteLink>
				)}
			</S.RetailCard>
		));
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Map */}
					<S.MapContainer>
						{locations != null ? (
							<Map
								zoom={13}
								isPreview={false}
								locationId="locator"
								flyTo={selectedLocation ? { latitude: selectedLocation.coordinates[1], longitude: selectedLocation.coordinates[0] } : null}
								pointsOfInterest={locations?.filter((location) => location.coordinates != null && !location.isVirtual).map((location, index) => ({
									id: location.id,
									coordinates: {
										latitude: location.coordinates[1],
										longitude: location.coordinates[0],
									},
									type: 'featured',
									index,
									onClick: (id) => { selectLocation(id, true); }
								}))}
							/>
						) : (
							<Map
								zoom={13}
								isPreview={false}
							/>
						)}
					</S.MapContainer>

					{/* Store Locator */}
					<S.LocatorPadding left right dynamic isLarge>
						<S.StoreLocator>

							{/* Title Banner */}
							<S.TitleBanner>

								{/* Title */}
								<IconContainer>
									<FontAwesomeIcon icon={['fasl', 'location-dot']} />
								</IconContainer>
								<Typography tag="h3" weight="bold">Find us in store</Typography>

								{/* Location Count */}
								<Typography tag="p" variation="2">{locations?.length === 1 ? '1 retail location' : `${locations?.length || 0} retail locations`}</Typography>

								{/* Postal Code Search */}
								<TextInput
									placeholder="Search by Zip Code"
									type="text"
									onChange={handleOnChange}
									value={searchAddress}
								/>
							</S.TitleBanner>

							{/* Card Container */}
							<S.CardContainer>{renderRetailCards()}</S.CardContainer>
						</S.StoreLocator>
					</S.LocatorPadding>

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

StoreLocator.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
StoreLocator.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default StoreLocator;
