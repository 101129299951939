/*
 * File: Pagination.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: March 14, 2023 at 1:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Helpers
 */

const positionForWidget = (position) => {
	switch (position) {
		case 'left':
			return 'start';
		case 'right':
			return 'end';
		default:
			return 'center';
	}
};


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	justify-content: ${({ $position }) => positionForWidget($position)};

	ul {
		display: flex;
		align-items: center;
		flex-direction: row;
		list-style-type: none;
		gap: 10px;
		max-width: 100%;
	}
	ul li {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:not(.disabled):hover {
			background-color: ${({ theme }) => theme.secondaryBackground};
		}
	}
	ul li a {
		cursor: pointer;
		color: ${({ theme }) => theme.primaryText};
		font-size: 14px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	ul li.selected {
		background-color: ${({ theme }) => theme.secondaryBackground};
	}
	ul li.disabled a {
		color: ${({ theme }) => theme.layoutBorder};
	}
	ul li.disable,
	ul li.disabled a {
		cursor: default;
	}
`;

export const BreakLabel = styled.div``;

export const NextLabel = styled.div``;

export const PreviousLabel = styled.div``;
