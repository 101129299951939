/*
 * File: OfferWidget.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 5, 2023 at 10:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';
import { IconButton } from '../IconButton';


/**
 * Styles
 */

export const Wrapper = styled.div`
	.__react_component_tooltip {
		top: ${({ $position }) => $position.y}px !important;
		left: ${({ $position }) => $position.x}px !important;
		width: ${({ $width }) => $width}px !important;
	}
`;

export const OfferWidget = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.primaryBackground};
	text-align: center;
	position: fixed;
	bottom: 20px;
	right: 20px;
	box-shadow: ${ColorValues.elementShadow};
	opacity: 1;
	cursor: pointer;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	animation: bounce 0.8s infinite alternate;

	svg {
		font-size: 2rem;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	@keyframes bounce {
		from {
			transform: translateY(0px);
		}
		to {
			transform: translateY(-5px);
		}
	}
	.widgetCloseButton {
		opacity: 0;
	}
	&:hover {
		.widgetCloseButton {
			opacity: 1;
		}
	}
`;

export const CloseButton = styled(IconButton)`
	position: absolute;
	top: -10px;
	right: -10px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.primaryBackground};
	width: 22px;
	height: 22px;
	box-shadow: ${ColorValues.elementShadow};
	border: 1px solid ${({ theme }) => theme.inputBorder};
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${({ theme }) => theme.primaryText};
	}
`;
