/*
 * File: Account.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on April 1, 2023 at 3:18 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, IconButton, NavigationDropdown as Dropdown } from '../../../components';

// Constants
import { mediumBreakpoint, mobileBreakpoint, smallBreakpoint } from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 20px 0px 100px 0px;
	max-width: 800px;
	margin: 0px auto;
`;

export const GreetingContainer = styled.div`
	padding: 50px 0px 50px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		font-family: 'Unna', Georgia, Times, serif;
		font-size: 2.4rem;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			font-size: 4.0rem;
		}
	}
	h5 {
		margin: 6px 0px 0px;
	}
`;

export const RewardsBalanceContainer = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0px 0px 50px;
	position: relative;
`;

export const RewardsBalance = styled.div`
	width: 210px;
	height: 210px;
	padding: 3px;

	@media only screen and (min-width: ${smallBreakpoint}em) {
		width: 250px;
		height: 250px;
	}
`;

export const RewardsInner = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: ${ColorValues.white};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-shadow: ${ColorValues.elementLightShadow};

	span {
		font-size: 4.0rem;
	}
`;

export const GoalContainer = styled.div`
	background-color: ${ColorValues.white};
	padding: 12px 30px;
	border-radius: 30px;
	box-shadow: ${ColorValues.elementShadow};
	position: relative;
	top: -10px;
`;

export const OffersContainer = styled.div`
	padding: 20px 20px;
	background-color: ${ColorValues.white};
	box-shadow: ${ColorValues.elementLightShadow};
`;

export const OfferCardInner = styled.div`
	width: 100%;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const RewardComingSoon = styled.div`
	background-color: ${ColorValues.neutralBackground};
	padding: 20px;
	min-height: 200px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	svg {
		font-size: 1.8rem;
		color: ${ColorValues.neutralText};
		margin: 0px 0px 10px;
	}
	h4 {
		font-family: 'Unna', Georgia, Times, serif;
		color: ${ColorValues.neutralText};
	}
	p {
		color: ${ColorValues.neutralText};
		margin: 3px 0px 0px;
	}
`;

export const RewardGiftCard = styled.div`
	padding: 20px;
	min-height: 200px;
	border: 1px solid ${({ theme }) => theme.brandPrimaryBase};
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h3 {
		font-family: 'Unna', Georgia, Times, serif;
		color: ${({ theme }) => theme.brandPrimaryBase};
		margin-top: -4px;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 20px;
	}
`;

export const PointsHistoryContainer = styled(Card)`
	padding: 20px;
	box-shadow: ${ColorValues.elementLightShadow};

	* {
		text-align: left;
	}
	div {
		width: 100%;
	}
`;

export const AccountSection = styled.div`
	margin: 30px 0px 0px;
`;

export const TitleContainer = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	height: 30px;

	h4 {
		text-align: left;
		margin: 0px;
		font-family: 'Unna', Georgia, Times, serif;
		flex-grow: 1;
	}
	button {
		padding: 0.4225rem 1.25rem;
		width: 100px;
	}
`;

export const LogoMark = styled.img`
	width: 40px;
	height: 40px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ActionsContainer = styled.div`
	flex-direction: column;
	display: flex;
	gap: 20px;
`;

export const ActionRow = styled(Card)`
	display: flex;
	flex-direction: column;
	box-shadow: ${ColorValues.elementLightShadow};
	align-items: center;
	justify-content: start;
	padding: 20px;
	gap: 15px;
	position: relative;

	@media screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
	svg {
		font-size: 1.4rem;
	}
	h5 {
		flex-grow: 1;
		text-align: center;

		@media screen and (min-width: ${mediumBreakpoint}em) {
			text-align: left;
		}
	}
	button {
		z-index: 2;
		padding: 0px;

		svg {
			font-size: 0.94rem;
		}
	}
`;

export const ActionRowHighlight = styled.div`
	width: calc(100% + 16px);
	height: calc(100% + 16px);
	border: 2px solid ${({ theme }) => theme.brandPrimaryLighter};
	position: absolute;
	top: -8px;
	left: -8px;
	z-index: 1;
`;

export const AccountAction = styled.div`
	background-color: ${ColorValues.white};
	border-radius: 50%;
	box-shadow: ${ColorValues.elementShadow};
	height: 40px;
	width: 40px;
	position: absolute;
	right: 40px;
	bottom: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DropdownToggle = styled(IconButton)`
	height: auto;
`;

export const NavigationDropdown = styled(Dropdown)`
	top: 54px;
	right: -60px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		right: -177px;
	}
`;

export const SubscriptionCard = styled(Card)`
	padding: 20px;
	box-shadow: ${ColorValues.elementLightShadow};
	display: flex;
	flex-direction: column;
	gap: 28px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const SubscriptionColumn = styled.div`
	&.flexGrow {
		flex-grow: 1;
	}
`;

export const SubscriptionColumnHeader = styled.div`
	color: ${({ theme }) => theme.secondaryText};
	margin: 0px 0px 5px;
`;

export const SubscriptionColumnContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
`;

export const SubscriptionIcon = styled.div`
	margin: 8px 0px 0px;
	width: 60px;
	height: 60px;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
`;

export const FeatureSet = styled.div`
	margin: 5px 0px 0px;

	* {
		color: ${({ theme }) => theme.secondaryText};
		text-align: left;
	}
`;
